import { IonAccordion, IonAccordionGroup, IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInput, IonItem,  IonItemOption,  IonItemOptions,  IonItemSliding, IonLabel, IonList, IonListHeader, IonLoading, IonModal, IonNote, IonPage, IonPopover, IonRadioGroup, IonRange, IonReorder, IonRippleEffect, IonRow, IonSpinner, IonText, IonTextarea, IonTitle, IonToolbar, useIonActionSheet, useIonAlert,  useIonToast } from "@ionic/react"
import { addCircle, backspaceOutline, carSport, checkmarkCircle, chevronBack, chevronForward, closeCircle, closeCircleOutline, createOutline, ellipseOutline,  informationCircleOutline, notifications, notificationsOutline, search, shapesOutline, squareOutline, sync,  trash, trashOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import {InputLocation } from "./Geofences";
import { addGeometry, allDevices,  allGeozonesOrder,   deleteGeozone, drawCircle, geozoneDetail, geozoneNotifier,   iconPlaces, toastFail, toastSuccess, updateGeoNotify, updateGeozone, updateGeozonePoints } from "../actions";
import { MapContainer, Marker, Polygon, useMap, useMapEvents ,FeatureGroup, Tooltip} from "react-leaflet";
import { Icon , LatLngExpression, Map as MapType, Marker as MarkerType, icon, point } from "leaflet";
import { LayersControlMap, LayersExpandControl } from "../leaflet/LeafletLayers";
import { getAddGeo, getCircle, getFindPoint, getGeoType, getGeoZones,  getPolygon,  getStartDraw,  setCircle, setGeofenceType, setGeozones, setPolygon, setStartDraw  } from "../store/mapSlice";
import { useDispatch, useSelector } from "react-redux";
import { defaultPin, editPin, pinstop } from "../leaflet/PublicMarker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";
import { Device } from "@capacitor/device";
import { DropDownDevice } from "../components/AppComponent";
import { setDevices, setBackupDevices, getBackup } from "../store/appSlice";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"; 
import "./css/Geofences.css" 
import { t } from "i18next";
import L from "leaflet";  
import { Swiper, SwiperSlide } from "swiper/react";
import {Swiper as SwiperType }from "swiper";
import { Options } from "./schema";
import NavBar from "../components/NavBar";
import DrawControl from "../leaflet/DrawControl";
import { Interface } from "readline";

interface Geozone {
    id:any
    show:false 
    canDelete :Boolean 
    geoId:Number
    icon:Number 
    name:String 
    position:{
        lat:Number
        lon:Number 
    }[]
    bound: Number[][]
}


const notifytype = [
    {
        type: 1 ,
        labe:"เข้า" ,
        format:"{0} เข้า  {1} เวลา {3}  {4}   http://maps.google.com/maps?f=q&q={5}&z=16"
    } ,
    {
        type: 2 ,
        labe:"ออก" ,
        format:"{0} ออก {1} เวลา {3}  {4}   http://maps.google.com/maps?f=q&q={5}&z=16"
    }
]
const Geozones=()=>{ 
    const [geoType , setGeoType] = useState("polygon")
    // const [geozone,setGeozone] = useState<Geozone[]>([])
    const [mapref,setMapRef] = useState<MapType|null>(null)
    const [maprefMobile,setMapRefMobile] = useState<MapType|null>(null)
    const [zoneview,setZoneView] = useState<Geozone|null>(null)
    const [zonepreview,setZonePreview] = useState<Geozone|null>(null)
    const [addZone,setAddZone] = useState(false)
    const [name , setName] = useState("")
    const [radius,setRadius] = useState(0.5) 
    const polygon :any= useSelector(getPolygon)
    const circle :any= useSelector(getCircle)  
    const [delLoad , setDelLoad] = useState(false)
    const [toast,dismiss] = useIonToast();
    const dispatch = useDispatch() 
    const modalref = useRef<HTMLIonModalElement>(null);
    const history = useHistory()
    const [currentPage , setCurrentPage] = useState(0)  
    const [geozone,setGeozone]  =  useState<any[]>([])
    const [geozoneBackup,setGeozoneBackup]  =  useState<any[]>([])
    const geozones =  useSelector(getGeoZones)
    // const geozones =  useSelector(getGeoZones)
    // const geozoneBackup :any= useSelector(getGeoZonesBackup) 
    const [geozoneorder,setGeozoneOrder] = useState([])
    const [keyword,setKeyword] = useState("")
    const [modalMobile,setModalModile] = useState(true)
    const [modalSave,setModalSave] = useState(false) 
    const [modalType,setModalType] = useState("add")  
    const [options,setOptions] = useState<Options[]>([]) 
    const [optChecked , setOptChecked ] = useState<Number[]>([])
    const backup :any= useSelector(getBackup)
    const [checkall , setCheckall] = useState<boolean | undefined>(false)
    const [currentIcon , setCurrentIcon] = useState(0)
    const [loading , setLoading] =  useState(false)
    const [swiperRef , setSwiperRef] = useState<SwiperType|null>()
    const [zoneEdit , setZoneEdit] = useState<any>(null)
    const [editName , setEditName] = useState("")
    const [editIcon , setEditIcon] = useState(1)
    const [editPosition,setEditPosition] = useState<any[]>([]) 
    const [dropDownIcon , setDropDownIcon] = useState(false)
    const [geonotify , setGeoNotify] = useState([])
    const [ionalrt , dimissAlrt] = useIonAlert()
    const [inNotiifiId , setInNotifiId] = useState(-1)
    const [outNotiifiId , setOutNotifiId] = useState(-1) 
    const [markerInputPatLng , setMArkerInputLatLng] = useState<MarkerType|null>(null)
    const [actionsheet,dismissActionSheet] = useIonActionSheet();
    const [lonAddHandler ,setLonAddHanler] = useState('')
    const [latAddHandler ,setLatAddHanler] = useState('')
    const [checkin,setCheckin] = useState({   
        label:"เข้า" ,
        checked: true ,
        format:"{0} เข้า  {1} เวลา {3}  {4}   http://maps.google.com/maps?f=q&q={5}&z=16"
    })
    const [checkout,setCheckout] = useState({   
        label:"ออก" ,
        checked: true ,
        format:"{0} ออก {1} เวลา {3}  {4}   http://maps.google.com/maps?f=q&q={5}&z=16"
    })
    
    const getallzone =async()=>{  
        setLoading(true)
        let geofences = await allGeozonesOrder({}) 
        setGeozone(geofences) 
        setGeozoneBackup(geofences)
        setLoading(false)
        dispatch(setGeozones(geofences))  
        let fencesBound:any[] = []
        await geofences.map((e:any)=>{ fencesBound.push([e.latitude , e.longitude]) }) 
        mapref?.fitBounds(fencesBound)  
    }

    useEffect(()=>{  
        getallzone()  
        const getdevices=async()=>{
            let result = await allDevices({})   

            let options = result.map((e:any)=> {return {
                value: e.device_id ,label: e.name, checked:false 
            } })
            await dispatch(setDevices(result))
            await dispatch(setBackupDevices(result))
            setOptions(options)  
        } 
        
        getdevices() 
        
         return(()=>{
            clearSearch()
            setModalModile(false)
        })
    },[  checkin,checkout ])

    const getCentroid = (arr:any[])=>{ 
        return arr.reduce(function (x,y) {
            return [x[0] + y[0]/arr.length, x[1] + y[1]/arr.length] 
        }, [0,0]) 
    }

    const selectedViewZone= async (zone:any)=>{    
        let detail:Geozone|any
        let bound :any[]= []
        let updategeozone:any[] = geozone
        let preview:any = {}
        console.log("zone ",zone)
        if(zone?.bound === undefined || zone?.bound === null || zone?.bound.length === 0 ) {
            detail  = await geozoneDetail({geoid:zone?.id})  
            let bound =await detail.position.map((z:any)=> {
                return [z?.lat , z?.lon]
            }) 

            updategeozone = updategeozone.map((z)=>{
                if(z?.id === zone?.id){
                    return {...z , ...{bound : bound , position: detail.position }} 
                }else{
                    return z
                }
            })
            preview = {...preview ,...{bound:bound}} 
        }else{
            detail = {
                 geoId : zone?.id,
                 name : zone?.name ,
                 canDelete : zone.canDelete,
                 icon : zone?.icon,
                 position : zone?.position
            }
            preview = {...preview ,...{bound:zone?.bound}}
        }
        let show = true //!zone?.show 
        preview = {...preview , ...{
            canDelete : false, 
            geoId:detail?.geoId ,
            icon:detail?.icon ,
            name: detail?.name , 
            position: detail?.position, 
            show: !detail?.show ,
            center: getCentroid(preview?.bound)
        }} 
        
        setZonePreview(preview)   
        setGeozone(updategeozone)
        setGeozoneBackup(updategeozone)

        // let zones = geozones.map((e:Geozone)=>{
        //     return {...e , ...{show: e?.id === zone?.id ? true:false , bound: preview?.bound}}
        // })
        // console.log("zones ",zones)
        // dispatch(setGeozones(zones)) 
        
        let info = await Device.getInfo() 
        let os = info.operatingSystem
        
        if(show &&( os === "android" || os === "ios")){
            maprefMobile?.fitBounds(preview?.bound,{padding:[-30,30]})
            modalref.current?.setCurrentBreakpoint(0.8)
        }else if(show &&( os !== "android" && os !== "ios")){
            mapref?.fitBounds(preview?.bound,{padding:[-30,30]})
        }

        return preview
    }

    const savegeofenceData=()=>{
        setModalSave(true) 
    }
    const saveGeofences=async()=>{
        let geometryArr:any = []
        if(geoType=== "polygon"){ 
          polygon.forEach((el:any) => {
            geometryArr = geometryArr.concat(el)
          }); 
        }else{ 
          circle.forEach((el:any) => {
            geometryArr = geometryArr.concat(el)
          });
        } 
        if(geometryArr.length > 0){ 
            const body = {
                locationName: name ,
                geometry:   JSON.stringify(geometryArr)   ,  
                iconId : currentIcon , 
                checkin:  checkin.checked ,
                checkout: checkout.checked ,
                devices:  optChecked ,
                templatein: checkin.format ,
                templateout: checkout.format 
            }
            if(optChecked.length > 0 && name !== ""){
                setLoading(true)
                const result = await addGeometry(body)
                if(result.status===1){
                    toast({
                        ...toastSuccess,
                        message:"Add geofences success."
                    })
                    dispatch(setCircle([]))
                    dispatch(setPolygon([]))
                    setName("")
                    getallzone()
                    setModalSave(false) 
                    setAddZone(false)
                } 
                setLoading(false)
            }else if(name==="" || name === null || name === undefined){
                toast({
                    ...toastFail ,
                    message:"กรุณา ระบุชื่อพื้นที่ !!"
                })
            }else{ 
                toast({
                    ...toastFail ,
                    message:"กรุณาเลือกรถก่อนการบันทึก !!"
                })
            }
        }else{
            toast({
                ...toastFail ,
                message:"Not found geofences bounds. Please try again."
            })
        }
    }
 
  const deleteZone=async(zone:any)=>{  
    console.log("zone ",zone)
    ionalrt({
        message : "ยืนยันที่จะลบพื้นที่ !!" ,
        buttons : [
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => {
                console.log('Alert canceled');
              },
            },
            {
              text: 'OK',
              role: 'confirm',
              handler: async () => {
                setLoading(true)
                const result = await deleteGeozone({geoId:zone?.id })
                console.log("result ",result)
                setLoading(false)
                if(result.result){
                  toast({
                    ...toastSuccess,
                    message: "ลบพื้นที่ "+zone?.name+" สำเร็จ !!"
                  }) 
                  getallzone()
                }else{
                    toast({
                        ...toastFail,
                        message:  "ลบพื้นที่ "+zone?.name+" ไม่สำเร็จ !!"
                    }) 
                }
                setLoading(false)
              },
            },
          ]
    })

   
 }

 const seachGeozone=()=>{  
    console.log("keyword length " ,keyword.length)
    if(keyword === null || keyword.length === 0){
        setGeozoneBackup(geozoneBackup) 
    }else if(keyword.length > 1){ 
        let filter:any =   geozone.filter((e:any)=>  e?.name.toLowerCase().indexOf(keyword?.toLowerCase()) > -1  ) 
        setGeozone(filter) 
    }
 }

 const clearSearch=()=>{
    setKeyword("")
    setGeozone(geozoneBackup) 
 }

 const changeOption=(checked:any, deviceId:any)=>{ 
    let checkeddevice = optChecked
    checkeddevice = checkeddevice.filter((id)=> id !== deviceId)
    let updateopt =  options.map((e)=>{
        return {...e , ...{ checked: deviceId===e.value?checked: e.checked  } }
    })
    setOptions(updateopt) 
    if(checked){ 
        checkeddevice.push(deviceId)
        setOptChecked(checkeddevice)
    }else{  
        setOptChecked(checkeddevice)
    }  
 } 
 
 const checkAll=async (checked:boolean)=>{  
    setCheckall(checked)
    let checkeddevice: any[] = []
    let options = backup.map((e:any)=> {
    if(checked){ checkeddevice.push( e.device_id) }
    return {
        value: e.device_id ,label: e.name, checked:checked 
    } }) 
    setOptions(options)
    setOptChecked(checkeddevice) 
 }

 const checkboxView=async (checked:any,id:any)=>{
    console.log("checked  ",checked , " id ",id)
    let   updategeozone :any [] = []
    updategeozone =await geozone.map((z)=>{ 
       return {...z, ...{checked: z?.id ===  id ?checked : z?.checked}}  
    })  
    console.log("updategeozone  ",updategeozone)
    setGeozone(updategeozone)
    setGeozoneBackup(updategeozone)
    dispatch(setGeozones(updategeozone))
 }

 const selectEdit=async(zone:any)=>{
    const preview = await  selectedViewZone(zone) 
    console.log("preview ",preview)
    let detail:any  = await geozoneDetail({geoid:zone?.id})  
    setZoneEdit(preview)
    setEditName(zone?.name)
    setEditIcon(zone?.icon) 
    setEditPosition(detail?.position) 
    geozoneNotifty(zone)
    swiperRef?.slideTo(1) 
 }

 const geozoneNotifty=async(zone:any)=>{
    let geonotifier = await geozoneNotifier({geoid:zone?.id}) 
    geonotifier = geonotifier.filter((e:any)=> e.type === -7 && e.enable)
    console.log("geonotifier ",geonotifier)  
    setGeoNotify(geonotifier)
 }

 const clearZoneEditing=()=>{
    setZoneEdit(null) 
     setEditName("")
    setEditIcon(1)
    setEditPosition([]) 
    setDropDownIcon(false)
 }

 const updatePoint=async(latlng:any,before:any)=>{
    let updateZone = zoneEdit 
    let position:any[] = zoneEdit?.position  

    position = zoneEdit?.position .map((e:any)=>{
        if(e === before){
            return { lat:latlng.lat, lon:latlng.lng } 
        }else{
            return e
        }
    }) 
    let bound:any = []
    await position.map((lt)=>{ bound.push([lt?.lat , lt?.lon])}) 

    updateZone ={...updateZone, ...{position: position , bound:bound}}
    setZoneEdit(updateZone)
    setEditPosition(position)
 }

 const saveUpdateGeozone=async ()=>{
    setLoading(true)
    let geoCurrentArr:any = [] 
    let geometryArr:any = []  
    console.log("zoneEdit ",zoneEdit)
    let oldzone = geozone.find((e)=> e.id === zoneEdit?.geoId ) 
 

    zoneEdit.bound.map((el:any) => { 
        geometryArr = geometryArr.concat(el)
    }); 

    const body = {
        locationName: name ,
        geometry:   JSON.stringify(geometryArr)   ,  
        iconId : currentIcon , 
        checkin:  checkin.checked ,
        checkout: checkout.checked ,
        devices:  optChecked ,
        templatein: checkin.format ,
        templateout: checkout.format ,
        notifierInId: inNotiifiId ,
        notifierOutId: outNotiifiId ,
        geoid:  zoneEdit?.geoId
    }
    console.log("saveUpdateGeozone body ",body)
    if(optChecked.length > 0 && name !== ""){
        setLoading(true)
        const result = await updateGeoNotify({body:body})
        console.log("result ",result)
        if(result.result ){
            toast({
                ...toastSuccess,
                message:"Update geofences and notify success."
            })
            geozoneNotifty(zoneEdit)
            dispatch(setCircle([]))
            dispatch(setPolygon([]))
            setName("")
            getallzone()
            geozoneNotifty(zoneEdit)
            setModalSave(false) 
            setAddZone(false)
            setLoading(false)
        } else{
            toast({
                ...toastFail,
                message:"Update geofences and notify fail."
            })
            setLoading(false) 
        }
    } 
    setLoading(false)
 }

 const updateNameAndGeoPoint=async ()=>{
    setLoading(true)
    let geoCurrentArr:any = [] 
    let geometryArr:any = []  
    console.log("zoneEdit ",zoneEdit)
    let oldzone = geozone.find((e)=> e.id === zoneEdit?.geoId ) 
    oldzone?.bound.map((el:any) => {
        geoCurrentArr = geoCurrentArr.concat(el)
    }); 

    zoneEdit.bound.map((el:any) => { 
        geometryArr = geometryArr.concat(el)
    }); 
  if(geometryArr.length > 0){ 
        if(JSON.stringify(geoCurrentArr) !==  JSON.stringify(geometryArr)){
            console.log(JSON.stringify(geoCurrentArr))
            console.log(JSON.stringify(geometryArr))
            const body = {
                geoId: zoneEdit?.geoId ,
                locationName: editName ,
                geometry:   JSON.stringify(geometryArr)   ,  
                iconId : editIcon ,   
            }
            console.log("update point" )
            const result = await updateGeozonePoints({body:body})
            console.log("result ",result)
            if(result.result ){
                toast({
                    ...toastSuccess,
                    message:"Update geozone success."
                })
                getallzone()
            } else{
                toast({
                    ...toastFail,
                    message:"Update geozone false."
                })
            }
        }else{
            console.log("update name only" )
            const body = {
                geoId: zoneEdit?.geoId ,
                locationName: editName ,
                geometry:   JSON.stringify(geoCurrentArr)   ,  
                iconId : editIcon ,   
            }
            const result = await updateGeozone({body:body})
            console.log("result ",result)
            if(result.result ){
                toast({
                    ...toastSuccess,
                    message:"Update geozone success."
                })
                getallzone()
            } else{
                toast({
                    ...toastFail,
                    message:"Update geozone false."
                })
            }
        
        }
    } 
 }
 
 const editNotifier=(notify:any)=>{
    setModalType("edit");
    setName(zoneEdit?.name)
    setCurrentIcon(zoneEdit?.icon) 
    let isCheckin = notify.filter((e:any)=> e.name.toLowerCase().indexOf("เข้า") >-1 || e.name.toLowerCase().indexOf("in") >-1 )
    let isCheckout  = notify.filter((e:any)=> e.name.toLowerCase().indexOf("ออก") >-1|| e.name.toLowerCase().indexOf("out") >-1 )

    setInNotifiId(isCheckin[0]?.id)
    setOutNotifiId(isCheckout[0]?.id)
    setCheckin({   
        label:"เข้า" ,
        checked: isCheckin.length > 0 ? true : false ,
        format: isCheckin.length > 0 ? isCheckin[0].template : checkin?.format
    })
    setCheckout({   
        label:"ออก" ,
        checked: isCheckout.length > 0 ? true : false ,
        format: isCheckout.length > 0 ? isCheckout[0].template : checkout?.format
    }) 
    let devicesin = isCheckin.length > 0 ? isCheckin[0].devices : []
    let devicesout = isCheckout.length > 0 ? isCheckout[0].devices : []
    let merge =  devicesin.concat(devicesout);
    let unique:any = [];
    merge.forEach((element:any) => {
        if (!unique.includes(element)) {
            unique.push(element);
        }
    }); 
    
    let updateopt =  options.map((op:Options)=>{
        if(unique.includes(op.value)){
            return {...op,...{ checked : true} }
        }else{
            return op
        }
    })
    setOptChecked(unique)
    setOptions(updateopt)
    console.log("updateopt ",updateopt)
     
    setModalSave(true)
 }

 const findLocation=(e:any)=>{
    console.log("findLocation ",e)
    if(markerInputPatLng === null){ 
        mapref?.setView([e.lat , e.lng],17)
        let mrk = L.marker([e.lat , e.lng],{
            icon: new Icon({ 
                iconUrl:  `../../assets/icon-place/map-icon-0.png`,
                rotationOrigin: "center",
                iconSize: [30,30] ,
                iconAnchor: [7,15],
                popupAnchor: [0, 0],
                tooltipAnchor: [0, 0],
              })
        })
        mrk.on("context",(c)=>{
            actionsheet({
                header: 'Actions',
                mode:'ios' ,
                buttons: [
                    {
                        text: 'ลบหมุดออกจากแผนที่',
                        role: 'destructive',
                        data: {   action: 'delete' },
                        handler() {
                            mapref?.removeLayer(mrk)
                            setMArkerInputLatLng(null)
                        },
                    },{
                        text: 'Cancel',
                        role: 'cancel',
                        data: {
                          action: 'cancel',
                        },
                    },
                ]
            })
        })
        mapref?.addLayer(mrk) 
        setMArkerInputLatLng(mrk)
    }else{
        mapref?.setView([e.lat , e.lng],17)
        markerInputPatLng.setLatLng([e.lat , e.lng])
    }
 }

 const addLatLonHanler=()=>{
    let point = [Number(latAddHandler) , Number(lonAddHandler)]
    let updatePoly =  [...polygon , point]
    dispatch(setPolygon(updatePoly))
    setTimeout(()=>{
        if(updatePoly.length > 2){
            dispatch(setStartDraw(true))
            mapref?.fitBounds(updatePoly)
        }
        setLatAddHanler('')
        setLonAddHanler('')    
    },200)
 }

 const searchZoneName=(word:any)=>{
    setKeyword(word);  

    if( word.length ===0 ){ 
        setGeozoneBackup(geozoneBackup) 
        setGeozone(geozoneBackup)
    } else  if(word.length >= 1){  
        let filter:any =   geozoneBackup.filter((e:any)=>  e?.name.toLowerCase().indexOf(word?.toLowerCase()) > -1  ) 
        setGeozone(filter)  
    } 
 }
 
return(
    <IonPage>
        <IonLoading isOpen={loading} message={t("loading").toString()} ></IonLoading>
        <IonHeader className="ion-no-border ion-hidden-md-up" > 
        </IonHeader>
        <IonContent> 
            <IonGrid >
                <IonRow>
                    <IonCol sizeSm="12" sizeMd="3" sizeXl="3">
                        <Swiper
                            draggable={ false}   allowTouchMove={false}
                            onSlideChange={(e) => console.log('slide change',e)}
                            onSwiper={(swiper) => setSwiperRef(swiper)} 
                            style={{height:"100%"}}
                        >
                            <SwiperSlide>
                               <IonRow >
                                   <IonCol sizeSm="12" sizeMd="12" sizeLg="12" className="set-center" style={{flexDirection:"row",justifyContent:'flex-start' }} >   
                                        <IonLabel className='title'>  <strong>เขตพื้นที่ / สถานี</strong>  </IonLabel> 
                                    </IonCol>
                                    <IonCol  sizeSm="12" sizeMd="12" sizeLg="12" className="set-center" style={{flexDirection:"row",justifyContent:'flex-start' }} >  
                                        <IonButton  
                                            color={addZone?"danger":"primary" }
                                            fill='clear' size='small'
                                            onClick={()=>{setModalType("add"); setAddZone( !addZone ) }}
                                        > Add  &nbsp; 
                                            <IonIcon icon={addCircle} style={{transform: `rotate(${!addZone?0:45}deg)`,transitionDuration:"300ms" }} /> 
                                        </IonButton> 
                                        <InputLocation  findLatLng={(e:any)=>{findLocation(e)}}/>
                                        <IonButton  color={"secondary"}  fill='clear' size='small'
                                            onClick={()=>{ getallzone() }}
                                        >   Sync  &nbsp;  <IonIcon icon={sync} />
                                        </IonButton> 
                                    </IonCol>    
                                </IonRow>
                                {  addZone &&<IonRow style={{margin:'1rem 0 1rem' , borderBottom:"1px solid #DDD"}} className='add-geofences' > 
                                    {geoType === "circle" && 
                                    <IonCol size='12' >
                                    <IonRow>
                                            <IonCol size='2' className='set-center' > <IonLabel>รัศมี</IonLabel>  </IonCol>
                                            <IonCol size='8'>
                                            <IonRange labelPlacement="start" mode='md' value={radius}  min={0.1} max={10}
                                                onIonInput={(e:any)=>{setRadius(e.detail.value)}}
                                                onIonChange={(e:any)=>{setRadius(e.detail.value)}}></IonRange>
                                            </IonCol>
                                            <IonCol size='2' className='set-center' >
                                                {radius} ม. 
                                            </IonCol>
                                        </IonRow>
                                    </IonCol>
                                    } 
                                    <IonCol size='12' style={{paddingTop:"0",paddingBottom:"0"}} > 
                                        <div className='input' > 
                                            <IonInput mode='ios' placeholder="ชื่อเขตพื้นที่" value={name} 
                                            onIonInput={(e:any)=>{setName(e.detail.value); }}  
                                            ></IonInput> 
                                        </div>
                                    </IonCol>

                                    <IonCol size="2" className="wrapper"> 
                                        <div style={{width:"100%",height:"100%",padding:".2rem"}} 
                                            onClick={()=>{setGeoType(geoType === "circle"?"polygon":"circle")}}
                                            className="set-center cursor-pointer ion-activatable ripple-parent" >
                                            {geoType === "circle" ? <IonIcon icon={ellipseOutline} /> : <IonIcon icon={shapesOutline} />}
                                            <IonRippleEffect></IonRippleEffect>
                                        </div>
                                    </IonCol> 
                                    <IonCol size="8"  >
                                        <IonButton expand="block" size="small"  color={"secondary"} mode="ios"  
                                        onClick={()=>{savegeofenceData() }}
                                        >
                                        <IonLabel> เพิ่มข้อมูล และบันทึก</IonLabel>
                                        </IonButton> <br/>
                                    </IonCol>

                                    <IonCol size="12" >
                                        <IonRow style={{borderTop:"1px solid #DDD"}}>
                                            <IonCol size="12" > 
                                                <IonLabel> ระบุชื่อ ละติจูด ลองติจูดด้วยตัวเอง </IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        {
                                            polygon.map((poi:any , index:any)=> 
                                                <IonRow key={index} > 
                                                    <IonCol size="5" > 
                                                        <div className="input"> 
                                                        <IonInput placeholder="lat" mode="ios" value={poi[0]} ></IonInput>
                                                        </div>
                                                    </IonCol>
                                                    <IonCol size="5" > 
                                                    <div className="input"> 
                                                        <IonInput placeholder="lat"  mode="ios"  value={poi[1]} ></IonInput>
                                                        </div>
                                                    </IonCol>
                                                    <IonCol size="2" className="set-center" > 
                                                        <IonButton size="small" fill="clear" >
                                                            <IonIcon icon={addCircle} />
                                                        </IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            )
                                        }

                                        <IonRow  > 
                                            <IonCol size="5" > 
                                                <div className="input"> 
                                                <IonInput placeholder="lat" mode="ios" value={latAddHandler}  onIonChange={(e:any)=>{setLatAddHanler(e.detail.value)}}></IonInput>
                                                </div>
                                            </IonCol>
                                            <IonCol size="5" > 
                                            <div className="input"> 
                                                <IonInput placeholder="lon"  mode="ios"  value={lonAddHandler}  onIonChange={(e:any)=>{setLonAddHanler(e.detail.value)}} ></IonInput>
                                                </div>
                                            </IonCol>
                                            <IonCol size="2" className="set-center" > 
                                                <IonButton size="small" fill="clear" onClick={()=>{addLatLonHanler()}} >
                                                    <IonIcon icon={addCircle} />
                                                </IonButton>
                                            </IonCol>
                                       </IonRow>
                                    </IonCol>
                                </IonRow>
                            }  { !addZone &&  <IonRow>
                            <IonCol   > 
                                    <IonItem lines="none" > 
                                        <div className='input' style={{width:"100%"}} > 
                                            <IonInput mode="ios" 
                                                value={keyword} placeholder="ค้นหาด้วยชื่อ"   
                                                onIonInput={(e:any)=>{ searchZoneName(e.detail.value) }}  
                                            > 
                                            </IonInput>
                                        </div>  
                                    {
                                        keyword.length > 0 ?  
                                        <IonButton size="small" fill="clear" mode="ios" onClick={()=>{clearSearch()}}  >
                                            <IonIcon icon={closeCircle}  />
                                        </IonButton>:
                                        <IonButton size="small" fill="clear" mode="ios" onClick={()=>{seachGeozone()}} >
                                            <IonIcon icon={search}  />
                                        </IonButton>
                                    }  
                                    </IonItem>
                                </IonCol>  
                                <IonCol size="12" > 
                                <IonList className="geozone-list-scroll" style={{backgroudColor:"#000",height:"70vh",overflowY:"scroll"}}>
                                    {  geozone.map((zone:Geozone,index:any)=>
                                    <ItemGeozone key={index}
                                        zone ={zone} 
                                        checkboxView={(checked:any)=>{return checkboxView (checked , zone.id)}} 
                                        selectedViewZone={(z:any)=>{console.log("e ",z );return selectedViewZone(zone)}} 
                                        deleteZone = {(z:any)=>   deleteZone(zone) }
                                        delLoad={delLoad} 
                                        selectEdit={(z:any)=>{selectEdit(z)}}
                                    /> 
                                        )
                                    }
                                    </IonList>
                                </IonCol>
                                </IonRow>   
                                }    
                            </SwiperSlide>
                            <SwiperSlide style={{fontSize:".9em"}} >
                                <IonRow>
                                    <IonCol size="12" style={{ display:"flex" , flexDirection:"row" ,alignItems:'center' ,justifyContent:"space-between" }} >
                                      <IonButton mode="ios" fill="clear"  onClick={()=>{swiperRef?.slidePrev();clearZoneEditing();}} > <IonIcon icon={chevronBack} /> <IonText>Back </IonText></IonButton>
                                      <IonTitle className="ion-text-right" >แก้ไขพื้นที่</IonTitle>
                                    </IonCol>
                                    <IonCol size="12" >
                                        <div className="input" >
                                            <IonInput mode="ios" value={editName} placeholder="Name" onIonChange={(e:any)=>{setEditName(e.detail.value)}} ></IonInput>
                                        </div>
                                    </IonCol>
                                    <IonCol size="12" style={{position:"relative"}} >
                                        <div className="input" style={{height:"2.4rem" , padding:".3rem 0 .3rem .5rem" , display:"flex" , flexDirection:"row" ,alignItems:'center' }} 
                                         onClick={()=>{setDropDownIcon(!dropDownIcon)}}
                                        >
                                            <img src={`../../assets/icon-place/map-icon-${editIcon? editIcon:0}.png`} style={{width:"1.7rem", marginRight:".5rem"}} />
                                            <IonLabel> <strong>ไอคอนปัจจุบัน .</strong></IonLabel>
                                        </div>
                                        {dropDownIcon && <div style={{position:"absolute" ,width:"95%", zIndex:99,background:"#FFF",border:"1px solid #DDD"}} > 
                                            <IonRow>
                                                <IonCol size="12" className="ion-no-padding ion-text-right" > 
                                                   <IonButton fill="clear"     onClick={()=>{setDropDownIcon(false)}}> <IonIcon icon={closeCircle} color="danger" /> </IonButton>
                                                </IonCol>
                                                <IonCol size="12" className="ion-no-padding">
                                                    <div style={{height:"40vh",background:"#FFF" }} >
                                                     <IconsZones current={editIcon} selectIcon={(icon:any)=>{setEditIcon(icon)}} />
                                                    </div>
                                                </IonCol>
                                            </IonRow> 
                                           
                                        </div>}
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="12" > 
                                    <Tabs className={"home-screen-tabs"} style={{height:"50vh"}} >
                                        <TabList>
                                            <Tab> ขอบเขตพื้นที่ </Tab>
                                            <Tab> การแจ้งเตือน </Tab>
                                        </TabList>
                                        <TabPanel>  
                                            <IonList style={{height:"50vh",overflowY:"scroll"}} > 
                                            {
                                                editPosition.map((p:any,index:any)=> 
                                                    <IonRow key={index} >
                                                        <IonCol size="6" style={{paddingBottom:"0px", paddingTop:"0px"}}> 
                                                        <div className="input" >
                                                            <IonInput disabled mode="ios" value={p.lat} style={{fontSize:".9em"}} ></IonInput>
                                                        </div>
                                                        </IonCol>
                                                        <IonCol size="6" style={{paddingBottom:"0px", paddingTop:"0px"}} > 
                                                        <div className="input" >
                                                            <IonInput disabled mode="ios" value={p.lon}  style={{fontSize:".9em"}}></IonInput>
                                                        </div>
                                                        </IonCol>
                                                    </IonRow> 
                                                )
                                            }
                                            </IonList>
                                        </TabPanel>
                                        <TabPanel>   
                                            <IonButton fill="clear" mode="ios" style={{fontSize:".9em" }} onClick={()=>{editNotifier(geonotify)}} >
                                              <IonText>แก้ไขการแจ้งเตือน</IonText>  &nbsp; <IonIcon icon={createOutline} />
                                            </IonButton>
                                            <div style={{width:"100%",padding:".5rem"  ,height:"40vh",overflowY:"scroll"}} >
                                                
                                                {geonotify.map((noti:any , index:any)=>
                                                <IonItem type="button" disabled={!noti.enable} key={index} color="light">
                                                    <IonLabel>{noti.name}</IonLabel>  
                                                </IonItem> 
                                                )}  
                                            </div>
                                        </TabPanel>
                                    </Tabs>
                                    </IonCol> 
                                </IonRow>
                                <IonRow>
                                    <IonCol size="12" >
                                        <IonButtons>
                                            <IonButton color={"danger"} mode="ios" fill="outline" onClick={(e)=>{swiperRef?.slidePrev()}} > ยกเลิก </IonButton>
                                            <IonButton color={"primary"} mode="ios"fill="solid"
                                                onClick={()=>{
                                                    updateNameAndGeoPoint() // saveUpdateGeozone()
                                                }}
                                            > บันทึกขอบเขตพื้นที่ใหม่ </IonButton>
                                        </IonButtons>
                                    </IonCol>
                                </IonRow>
                            </SwiperSlide>
                        </Swiper>
                     
                        </IonCol>
                        <IonCol   className='ion-hide-md-down' >
                            <MapGeofence  addZone={addZone}  setModalSave={setModalSave}
                            zoneview={zonepreview} zones={geozone} zoneEdit={zoneEdit} 
                            setMapRef={(e:any)=>{setMapRef(e)}}  geoType={geoType} radius={radius} 
                            updatePoint={({latlng,before}:any)=>{updatePoint(latlng,before)}}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            <IonModal className='ion-hide-md-up'  isOpen={modalMobile}  onIonModalDidDismiss={()=>{setModalModile(false)}}   ref={modalref}  
              initialBreakpoint={0.05}  
              breakpoints={[0.05,0.1,0.2, 0.3,0.4,0.5,0.6,0.7, 0.8,0.9, 0.95,1]}
              backdropDismiss={false}
              showBackdrop={false}
              keepContentsMounted={true}
              backdropBreakpoint={1}
            >
                <IonHeader className="ion-no-border" onClick={()=>{modalref.current?.setCurrentBreakpoint(0.7)}} >
                    <IonToolbar>
                        <IonButtons slot="end">
                            <IonButton fill="clear" onClick={()=>{modalref.current?.setCurrentBreakpoint(0.1)}}>
                                <IonIcon icon={closeCircle} />
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                   <MapGeofence  
                    addZone={addZone} 
                    setModalSave={setModalSave} 
                    modalSave={modalSave}
                    zoneview={zonepreview} 
                    zones={geozone} 
                    zoneEdit={zoneEdit} 
                    setMapRef={(e:any)=>{setMapRefMobile(e)}}  
                    geoType={geoType} radius={radius} 
                    updatePoint={({latlng,before}:any)=>{updatePoint(latlng,before)}}
                   />
                </IonContent>
            </IonModal>
            <ModalGeozoneNotify 
                modalSave={modalSave}
                modalType={modalType}
                setModalType={(e:any)=>setModalType(e)}
                setModalSave={(e:any)=>setModalSave(e)}
                name={name}
                setName={setName}
                saveUpdateGeozone={()=>saveUpdateGeozone()}
                saveGeofences={()=>saveGeofences()} 
                changeOption={(a:any,b:any)=>changeOption(a,b)}
                checkin={checkin}
                setCheckin={(e:any)=>setCheckin(e)}
                checkout={checkout}
                setCheckout={(e:any)=>setCheckout(e)}
                currentIcon={currentIcon}
                setCurrentIcon={(e:any)=>setCurrentIcon(e)}
                options={options}
                checkAll={(e:any)=>checkAll(e)}
                checkall={checkall}
            />
        </IonContent>
    </IonPage>
)
}
export default Geozones;

export const ModalGeozoneNotify=(props:any)=>{

    return(
        <IonModal mode="md" className="modal-save-geozone" isOpen={props?.modalSave} onIonModalDidDismiss={()=>{props?.setModalType("add");props?.setModalSave(false)}}>
                <IonContent className="ion-padding" >
                        <IonGrid>
                            <IonCol size="12" >  <IonLabel><strong> {props?.modalType === "add" ? "เพิ่ม" : "แก้ไข" }เขตพื้นที่</strong> </IonLabel>  </IonCol>
                            <IonRow>
                                <IonCol size="4" >   
                                <IonListHeader><IonIcon icon={notifications} color="secondary" /> &nbsp; รายละเอียดการแจ้งเตือน </IonListHeader>
                                <div className='input' style={{marginTop:"1rem"}}> 
                                    <IonInput mode='ios' placeholder="ชื่อเขตพื้นที่" value={props?.name} 
                                    onIonInput={(e:any)=>{props?.setName(e.detail.value); }}  
                                    ></IonInput> 
                                </div>  
                                
                                {/* { notifytype.map((type , index)=>
                                    <IonCheckbox key={index} value={type?.type} labelPlacement="end"
                                        style={{marginRight:"1rem",marginLeft:".5rem"}} 
                                        onClick={(e)=>{ setNotifyTemplate(type?.format) }}
                                    > 
                                        <IonLabel> {type?.labe} </IonLabel>
                                    </IonCheckbox>
                                    )
                                } */}
                                 <IonCheckbox  checked={props?.checkin?.checked} labelPlacement="end"
                                    style={{marginRight:"1rem",marginLeft:".5rem"}} 
                                    onIonChange={(e:any)=>{ props?.setCheckin({...props?.checkin , ...{checked: !props?.checkin?.checked }})}}
                                 > 
                                    <IonLabel> {props?.checkin?.label} </IonLabel>
                                  </IonCheckbox>
                                  <IonCheckbox  checked={props?.checkout?.checked} labelPlacement="end"
                                    style={{marginRight:"1rem",marginLeft:".5rem"}} 
                                    onIonChange={(e:any)=>{ props?.setCheckout({...props?.checkout , ...{checked: !props?.checkout?.checked }})}} 
                                  > 
                                    <IonLabel> {props?.checkout?.label} </IonLabel>
                                  </IonCheckbox>
                                
                                <br/><br/> 
                                <IonText>รูปแบบ</IonText>
                                {/* <div className='input' style={{marginTop:"1rem",paddingBottom:"1rem",height:"fit-content"}}> 
                                  <IonTextarea value={notifyTemplate} mode="md"  style={{fontSize:".8em"}} > </IonTextarea>
                                </div>   */}
                                {props?.checkin?.checked === true &&<div className='input' style={{marginTop:"1rem",paddingBottom:"1rem",height:"fit-content"}}> 
                                  <IonTextarea value={props?.checkin?.format} mode="md"  style={{fontSize:".8em"}} > </IonTextarea>
                                </div> } 
                                {props?.checkout?.checked  === true &&<div className='input' style={{marginTop:"1rem",paddingBottom:"1rem",height:"fit-content"}}> 
                                  <IonTextarea value={props?.checkout?.format} mode="md"  style={{fontSize:".8em"}} > </IonTextarea>
                                </div> }
                                <br/> 
                                <IonButtons  >
                                    <IonButton style={{fontSize:".8em"}} > 0 :อุปกรณ์ </IonButton>
                                    <IonButton style={{fontSize:".8em"}} > 1 :พื้นที่ </IonButton>
                                    <IonButton disabled style={{fontSize:".8em"}} > 2 :ความเร็วเกิน </IonButton>
                                </IonButtons>
                                <IonButtons>
                                    <IonButton style={{fontSize:".8em"}} > 3 :เวลา </IonButton>
                                    <IonButton style={{fontSize:".8em"}} > 4 :สถานที่ </IonButton>
                                    <IonButton style={{fontSize:".8em"}} > 5 :พิกัด </IonButton>
                                </IonButtons>
                                <IonButtons>
                                    <IonButton style={{fontSize:".8em"}} > 6 :ความเร็ว </IonButton>
                                    <IonButton style={{fontSize:".8em"}} > 7 :เลขบัตร </IonButton>
                                    <IonButton style={{fontSize:".8em"}} > 8 :คนขับ </IonButton>
                                </IonButtons>
                                <IonButtons>
                                    <IonButton disabled style={{fontSize:".8em"}} > 9 : เปิด/ปิด </IonButton>
                                    <IonButton disabled style={{fontSize:".8em"}} > 10 : อุณหภูมิ </IonButton>
                                </IonButtons>
                                <br/><br/>

                                </IonCol>
                                <IonCol size="4" >
                                    <IonListHeader>  <IonIcon icon={checkmarkCircle} color="secondary" /> &nbsp;  เลือกไอคอน </IonListHeader>
                                    <div style={{width:"100%", height:"100%"}} >
                                    <IconsZones current={props?.currentIcon} selectIcon={(icon:any)=>{props?.setCurrentIcon(icon)}} />
                                    </div> </IonCol>
                                <IonCol size="4" >  
                                    <IonListHeader> <IonIcon icon={carSport} color="secondary" />&nbsp; เลือกอุปกรณ์ </IonListHeader>
                                    <IonItem  >
                                        <IonCheckbox value={0} checked={props?.checkall} onIonChange={(e)=>{props?.checkAll(e.detail.checked)}} /> &nbsp;
                                        <IonLabel style={{fontSize:"small"}} > ทั้งหมด </IonLabel>
                                    </IonItem>
                                    <IonList style={{overflowY:"scroll" ,height:"70vh"}} >
                                        { props?.options.map((op:Options,index:any)=> 
                                           <IonItem key={index} disabled={props?.checkall}  >
                                             <IonCheckbox 
                                               value={op.value} 
                                               checked={op?.checked}  
                                               onIonChange={(e:any)=>{ props?.changeOption(!op.checked , op.value)}} 
                                             /> 
                                               &nbsp;  <IonLabel style={{fontSize:"small"}} > {op?.label} </IonLabel>
                                           </IonItem>  
                                        )}
                                    </IonList>
                                </IonCol>
                                <IonCol size="12"  className="set-center ion-padding " style={{ alignItems:"flex-end"}}>
                                <IonButtons>
                                    <IonButton color={"danger"} fill="outline" mode="ios" onClick={()=>{props?.setModalSave(false)}} > ยกเลิก </IonButton> <br/>
                                    {
                                    props?.modalType === "add" ?
                                    <IonButton color={"primary"} fill="solid" mode="ios" onClick={()=>{props?.saveGeofences()}}>
                                        บันทึก 
                                    </IonButton> 
                                    :<IonButton color={"primary"} fill="solid" mode="ios" onClick={()=>{ props?.saveUpdateGeozone() }}> 
                                       บันทึกแก้ไข
                                     </IonButton> 
                                    }
                                   <br/>
                                </IonButtons>
                                </IonCol>  
                            </IonRow>
                        </IonGrid>
                </IonContent>
            </IonModal>
    )
}

const ItemGeozone=({zone ,checkboxView , selectedViewZone ,deleteZone , delLoad ,selectEdit}:any)=>{
    useEffect(()=>{
       
    },[zone])
    return( 
        <IonItem className="ion-hide-md-down"  onClick={()=>{ checkboxView(false ) ; return selectEdit(zone)}}
         id={`geozone-${zone.geoId}`}  
         >  <IonCheckbox labelPlacement="end"  mode='ios'  checked={zone?.checked}   justify="start"
                 onIonChange={(e:any)=>{return checkboxView( e.detail?.checked )}} 
             > <IonLabel class="ion-text-wrap" >{zone?.name}   </IonLabel>
            </IonCheckbox>  
            <IonButtons slot="end">
                <IonButton fill='clear' size="small" color="medium" slot="end" style={{fontSize:"1em"}}
                  onClick={(e:any)=>{console.log("e ",zone );return selectedViewZone(zone)}} >
                  <IonIcon icon={informationCircleOutline} />
                </IonButton>
                <IonButton  fill='clear' size="small" color="medium" slot="end" 
                   onClick={(e:any)=>{console.log("e ",zone );return deleteZone(zone)}} >
                 {delLoad ? <IonSpinner name={"circles"} /> :   <IonIcon icon={trash} />  }
                </IonButton> 
                <IonButton fill='clear' size="small" color="medium" slot="end" onClick={()=>{ checkboxView(false ) ; return selectEdit(zone)}}>
                 <IonIcon icon={chevronForward} />
                </IonButton>
            </IonButtons>
         </IonItem>    
    )
}

interface MapGeofence{
    zoneview:any ,setMapRef:any,geoType:any,radius:any , zones:any , zoneEdit:any , updatePoint:any , addZone:any ,
    setModalSave:(e:any)=>{ } ,
    modalSave:any
}

const MapGeofence=({zoneview,setMapRef,geoType,radius , zones , zoneEdit , updatePoint , addZone ,setModalSave ,modalSave}:any)=>{
    const [zoom,setZoom] = useState(14)
    // const [startDraw , setStartDraw] = useState(false)
    const geozones =  useSelector(getGeoZones)
    const [mapref , setMapref] = useState<MapType|null>(null)
    const startDraw :any= useSelector(getStartDraw)
    const dispatch = useDispatch()
    //setStartDraw

    useEffect(()=>{  
        console.log("setModalSave ",setModalSave)
        if(zoneview !== null){
        }
    },[geozones])
 
 
    return(<> 
    {/* <ButtonReadyDraw ready={startDraw} setReady={(e:any)=>{dispatch(setStartDraw(e))}} /> */}
    {/* {startDraw && <ControlDrawGeofences type={geoType} /> } */}
    <MapContainer
        id="map-geofences"
        center={[13.714538, 100.510928]}
        zoom={10}
        scrollWheelZoom={true}
        zoomControl={false}
        style={{borderRadius:"15px"  ,marginBottom:".3rem"}}
        ref={async (comp)=>{  
            comp?.invalidateSize()  
            comp?.on("zoomlevelschange",(e)=>{
                comp?.invalidateSize() 
                setZoom(e.target._zoom)
            }) 
            if(comp){ 
                setMapref(comp)
                return setMapRef(comp)
            } 
        }} 
    >
    <LayersControlMap  />
    {/* <LayersExpandControl /> */}
    {/* <FindPoint /> */}
    {setModalSave != undefined && <DrawControl saveModal={(e:any)=>{  setModalSave(e)}}/> }
    {zoneEdit && <FeatureGroup>
      {zoneEdit.position.map((p:any, index:any)=> 
        <Marker  key={index}
            draggable
            position={[p?.lat , p?.lon]} 
            icon={ new Icon({ 
                iconUrl: `../../assets/icon-place/map-icon-0.png`,
                iconSize: [25, 25],
                rotationOrigin: "center", 
            })}   
            ref={mrk=>{
                if(mrk){
                    mrk.on("dragend",(e)=>{
                        return updatePoint({latlng: e.target._latlng , before : p  })
                    })
                }
            }}
        >  </Marker>)}   
         {zoneEdit?.bound && <Polygon positions={zoneEdit?.bound} /> }
    </FeatureGroup>}
    {startDraw && addZone && <DrawGeozones geoType={geoType} startdraw={startDraw} circleradius={radius} /> }
        {
            zones&& zones.map((z:any,index:any)=> 
             z?.checked && <FeatureGroup key={index} >
                {z?.bound && <Polygon positions={z?.bound}
                 ref={(p)=>{
                    mapref?.on("zoom", (e)=> {
                        if(e.target._zoom < 10){
                            p?.closeTooltip()
                        }else{
                            p?.openTooltip()
                        }
                    })
                 }}
                >
                <Tooltip 
                    direction="bottom" 
                    className="marker-place-name"
                    offset={[ 0, 20]}
                    opacity={1} permanent
                      >{z?.name}
                </Tooltip>  
                </Polygon>  }
                {z?.center && <Marker 
                position={z?.center} 
                icon={ new Icon({ 
                    iconUrl: `../../assets/icon-place/map-icon-${z?.icon? z?.icon:0}.png`,
                    iconSize: [25, 25],
                    rotationOrigin: "center", 
                })}   
                >  </Marker> }
                
             </FeatureGroup>
            )
        }
        { zoneview !== null   &&  
        <FeatureGroup >
            {/* <Polygon positions={zoneview?.bound} />  */}
            <Marker 
             position={zoneview?.center} 
             icon={ new Icon({ 
                 iconUrl: `../../assets/icon-place/map-icon-${zoneview?.icon? zoneview?.icon:0}.png`,
                 iconSize: [25, 25],
                 rotationOrigin: "center", 
             })}   
            />
        </FeatureGroup>
            }
   
    </MapContainer>
</>)
}

const IconsZones=({current , selectIcon}:any)=>{
    const [openmodal,setOpenModal] = useState(false)
    const [icons,setIcons] = useState([{
      category_id: 1 ,
      category_name: "การเดินทาง, การติดต่อสื่อสาร",
      icon: [  {id: 9, name: 'PTT'}]
    }])

  useEffect(()=>{
    const fetchIcons=async()=>{  
          let icon:any = await iconPlaces() //iconList({token,apiHost})
          console.log("iconPlaces ",icon) 
          setIcons(icon.category)
        }  
      fetchIcons()
  },[current]) 
 
  
    return(
    <div className="set-center"  style={{width:"100%",height:"100%" }}  >  
        {/* <div id="icons-zones-modal"  onClick={()=>{setOpenModal(true)}} 
            style={{width:"100%",height:"100%",padding:".2rem"}} 
            className="set-center cursor-pointer ion-activatable ripple-parent" >
                <IonIcon icon={flowerOutline} /> 
                <IonRippleEffect></IonRippleEffect>
        </div>
        <IonModal mode="ios" isOpen={openmodal} onIonModalDidDismiss={()=>{setOpenModal(false)}} initialBreakpoint={0.95}>
            <IonHeader className="ion-no-border" >
                <IonToolbar>
                    <IonLabel style={{fontWeight:"bold"}} >ICON's Place</IonLabel>
                   
                      <IonButton fill="clear" slot="end" onClick={()=>{setOpenModal(false)}} > <IonIcon icon={closeCircleOutline} /> </IonButton>
                  
                </IonToolbar>
            </IonHeader> */}
            <IonContent className="ion-padding" > 
               {
                icons.map((e,index:any)=>
                  <IonRow key={index} style={{marginBottom:"1rem"}}>
                    <IonCol size="12" >
                      <IonLabel>{e.category_name}</IonLabel>
                    </IonCol>
                    {
                        e.icon.map((ic:any,i:any)=>
                        <IonCol 
                           key={i} size="2" 
                           className={`cursor-pointer set-center`} 
                           onClick={()=>{return selectIcon(ic?.id)}} 
                        >  
                        {current === ic?.id  && <IonIcon icon={checkmarkCircle} color="primary" style={{position: "absolute", left:"0" , top:"20%"}} />  }
                        <IonImg 
                              className={`geo-icon-choice `}
                              src={`../../assets/icon-place/map-icon-${ic?.id}.png`} 
                            /> <small>{ic.name}</small>
                          </IonCol>
                        )
                    }
                  </IonRow>
                )
               }
            </IonContent>
        {/* </IonModal> */}
    </div>
    )
}


const ButtonReadyDraw=({ready,setReady}:any)=>{ 

    return(
    <button className={`btn-ready-draw ${ready && "active"}   `} onClick={()=>{return setReady(!ready)}}> 
      <IonLabel color={"dark"} > <FontAwesomeIcon icon={faPencil} /></IonLabel> 
    </button>)
}

const FindPoint=()=>{
    const findPoint :any= useSelector(getFindPoint)
    const map = useMap()

    useEffect(()=>{ 
        if(findPoint){
            map.setView(findPoint)
        }
    },[findPoint])

    return(
        <>
         {findPoint && <Marker position={findPoint}  icon={pinstop}  /> }
        </>
    )
}
const GeozoneList=({zone,changeZone,deleteZone,delload,deletezone}:any)=>{
    const history = useHistory()
    
    return(  
    <IonRow key={zone.geoId}  className="ion-hide-md-down" id={`geozone-${zone.geoId}`} onClick={()=>{console.log("e ",zone ) }} onContextMenu={()=>{}}>
      <IonCol size='2' className='set-center' style={{padding:"0"}} >
        <IonCheckbox 
            mode='ios'  aria-label=""  
            checked={zone.show} 
            onIonChange={(e)=>{return changeZone({show: !e.detail.checked, ...zone})}}
        ></IonCheckbox>
      </IonCol> 
      <IonCol 
        size='8' className="set-center"  
        style={{padding:"0", alignItems:'flex-start',cursor: 'pointer'}}  
        onClick={()=>{return changeZone({show: !zone?.show  ,...zone}); }}
      > 
          <IonLabel color={"dark"} >{zone.name}</IonLabel>  
      </IonCol>
      <IonCol size='2' style={{padding:"0"}}>
        <IonButton fill='clear' color="medium" onClick={()=>{
         console.log("zone ",zone );
         return changeZone(zone)
        }} > 
         {delload ? <IonSpinner name={"circles"} /> :   <IonIcon icon={trash} />  }
        </IonButton> 
      </IonCol>
    <IonPopover mode="ios" trigger={`geozone-${zone.geoId}`} side="right" triggerAction={'context-menu'} dismissOnSelect >
        <IonContent className="ion-padding" >
            <strong><IonLabel color="dark" style={{fontSize:".8em"}}>Actions...</IonLabel></strong>
            <IonItem disabled={!zone?.canDelete} className="cursor-pointer" style={{fontSize:".8em"}} onClick={()=>{ history.push("/notificationSetting",{geozones : zone}) }} >
                <IonIcon icon={notificationsOutline} /> &nbsp;
                <IonLabel color="dark">ตั้งค่าแจ้งเตือน</IonLabel>
            </IonItem>
        </IonContent>
    </IonPopover>
    </IonRow>  
    )
  }



const DrawGeozones=({geoType,startdraw,circleradius}:any)=>{  
    const polygon :any= useSelector(getPolygon)
    const circle :any= useSelector(getCircle)  
    const type :any= useSelector(getGeoType)
    const addgeo :any= useSelector(getAddGeo)  
    const [activeMarker,setActiveMarker] = useState(0)
    const dispatch = useDispatch() 
    // const circleradius :any= useSelector(getRadius)
    const [position,setPosition] = useState({lat:0,lng:0})
    const [toastalert] = useIonToast();

    const map = useMapEvents({
        async click(e) {
            if(startdraw){  
                console.log("click ", e.latlng )
                setPosition(e.latlng)
                if(geoType === "polygon"){
                    drawPolygon( e.latlng.lat ,e.latlng.lng)
                }else{
                    let positons = await drawCircle( e.latlng.lat ,e.latlng.lng ,circleradius)
                    dispatch( setCircle(positons))   
                }
            }else{
                toastalert({
                    message:"Please  Select Pencil Button.",
                    position:"top" ,
                    color:"danger",
                    duration: 3000
                })
            }
        }, 
    })
 
    const dragMarker= async(e:any,posit:any)=>{ 
        let position:any = []  
        position = geoType==='polygon'? polygon.slice(0): circle.slice(0) // copy
        let index:number =  geoType==='polygon'?  polygon.indexOf(posit) :  circle.indexOf(posit)
        
        if(geoType==='polygon'){ //@ts-ignore
            if(polygon[index] != [e.target._latlng.lat,e.target._latlng.lng]  ){   
                position.splice(index, 0);
                position[index] = [e.target._latlng.lat,e.target._latlng.lng ]
                dispatch(setPolygon(position)) 
            } 
        }else{//@ts-ignore
            if(circle[index] != [e.target._latlng.lat,e.target._latlng.lng]  ){   
                position.splice(index, 0);
                position[index] = [e.target._latlng.lat,e.target._latlng.lng ]
                dispatch(setCircle(position)) 
            } 
        }
    }

    

    const drawPolygon=(lat:any,lon:any)=>{
        let position = [lat,lon]
        let addToPolygon:any = [...polygon,position]
        console.log("addToPolygon ",addToPolygon)
        dispatch( setPolygon(addToPolygon)) 
    }
       
    useEffect(()=>{ 
        if(type === "circle"){ 
            const updateCircle=async()=>{
                console.log("circleradius change ",circleradius)
                let positons = await drawCircle(position.lat, position.lng ,circleradius)
                dispatch( setCircle(positons)) 
            }
            updateCircle()
        }
    },[circleradius,addgeo])
    
    return(<FeatureGroup> 
         <Polygon positions={polygon}  color='#ebdb34'  />
         <Polygon positions={circle}   color='#ebdb34'  />
         {
            geoType === "polygon"  &&
            <FeatureGroup> 
                {polygon.map((position:any,index:any)=> 
                  <Marker 
                    key={index}
                    draggable={index===activeMarker?true:false} 
                    icon={
                        index===activeMarker?
                        editPin : defaultPin
                    } 
                    position={position}   
                    ref={(m)=>{
                        m?.on("click",(e)=> {
                            setActiveMarker(index)
                        })
                        m?.on("dragend",(e)=> {
                            dragMarker(e,position)
                        })
                    }} 
                  ></Marker>
                )}
            </FeatureGroup> }
           { geoType === "circle" && <FeatureGroup>
                {circle.map((position:any,index:any)=> 
                  <Marker 
                    key={index}
                    draggable={index===activeMarker?true:false} 
                    icon={
                        index===activeMarker?
                        editPin : defaultPin
                    } 
                    position={position}
                    ref={(m)=>{
                        m?.on("click",(e)=> {
                            setActiveMarker(index)
                        })
                        m?.on("dragend",(e)=> {
                            dragMarker(e,position)
                        })
                    }} 
                  ></Marker>
                )}
            </FeatureGroup>
         }
    </FeatureGroup>)
}
 
const ControlDrawGeofences=({type}:any)=>{ 
    const dispatch =useDispatch()
    // const type :any= useSelector(getGeoType)
    const polygon :any= useSelector(getPolygon)
    const circle :any= useSelector(getCircle)

    useEffect(()=>{},[polygon,circle]) 
    const deleteGeofencesDraw=()=>{
        if(type === "polygon"){
            dispatch(setPolygon([]))
        }else{
            dispatch(setCircle([]))
        }
    }

    const removeGeofencesDraw=()=>{ 
        if(type === "polygon"){
            let lstPolygon = polygon
            lstPolygon = lstPolygon.slice(1,lstPolygon.length)
            dispatch(setPolygon(lstPolygon))
        }else{
            let lstCircle = circle
            lstCircle = lstCircle.slice(1,lstCircle.length)
            dispatch(setCircle(lstCircle))
        }
    }

    return(<div className='control-draw-geofences' >
        <div  className="tooltip">
            <button onClick={()=>{deleteGeofencesDraw()}}   color={"primary"} >
                <IonIcon icon={trashOutline} /> 
            </button>
             <span className="tooltiptext">Delete</span> 
        </div>
        <div style={{marginLeft:"10%",width:'80%',height:'1px',borderTop:'1px solid #EEE'}}></div>
        <div  className="tooltip">
            <button onClick={()=>{removeGeofencesDraw()}}   color={"primary"} className="tooltip"  > 
                <IonIcon icon={backspaceOutline} /> 
            </button>  
            <span className="tooltiptext">Remove</span> 
        </div>
        <div style={{marginLeft:"10%",width:'80%',height:'1px',borderTop:'1px solid #EEE'}}></div>
        <div  className="tooltip">
        <button  color={"primary"} className="tooltip"  onClick={()=>{dispatch(setGeofenceType({value:"polygon",label:"Polygon"}))}}> 
            <IonIcon icon={squareOutline} style={{transform:"rotate(45deg)"}} /> 
        </button> <span className="tooltiptext">Polygon</span> 
        </div>
        <div style={{marginLeft:"10%",width:'80%',height:'1px',borderTop:'1px solid #EEE'}}></div>
        <div  className="tooltip">
        <button  color={"primary"} className="tooltip"    onClick={()=>{dispatch(setGeofenceType({value:"circle",label:"Circle"}))}}> 
            <IonIcon icon={ellipseOutline} /> 
        </button><span className="tooltiptext">Circle</span> 
        </div>

    </div>)
}

