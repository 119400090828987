import { IonButton, IonLabel, IonIcon, IonGrid, IonRow, IonCol, IonText } from "@ionic/react";
import { table } from "console";
import { printOutline } from "ionicons/icons";
import moment from "moment"; 
import { pdfMake } from "../../lib/pdfMake" 
import { borderCells } from "../../lib/Excels";
import FileSaver from "file-saver";  
import * as Excel from "exceljs"; 
//import 'sheetjs-style' 

const RepFuelUasage=({details,table,orientation}:any)=>{
    console.log("details,table" ,details,table)


    const printExcel=async ()=>{
        try{   
            var options = {
                filename: './streamed-workbook.xlsx',
                useStyles: true,
                useSharedStrings: true
            }; //@ts-ignore 
            let workbook = new Excel.Workbook(options);
            var ws = workbook.addWorksheet( `${details?.reportName}` , {properties:{tabColor:{theme:1}}});
 
            let header:any = []
            let columns = await table?.headers.map((el:any,index:any)=>{  
                header = [...header,el?.label] 
                return  {width: index === 0 ? 30  :  index === 3 || index === 6 ? 20 :15 }
            }) 
            ws.columns = columns
            ws.mergeCells('A1', 'J1'); 
            ws.getCell("A1").value = details?.reportName
            ws.mergeCells('A4', 'J4'); 
            
            ws.getCell("A4").value =  `วันที่ ${ moment(details.start).format("DD/MM/YYYY HH:mm") } - ${ moment(details.end).format("DD/MM/YYYY HH:mm") } `
            ws.getCell("A4").alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }; 
            ws.addRow(['']) 
            ws.addRow([''])
            ws.addRow([''])
            ws.addRow(header)
            const rowtitle = ws.getRow(1); 
            rowtitle.eachCell({includeEmpty: true}, ((cell:any) => {
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }; 
                cell.font = { bold: true ,  family: 4, size: 16  }
                cell.border = borderCells
            }));  
            const rowheader = ws.getRow(8); 
            rowheader.eachCell({includeEmpty: true}, ((cell:any) => {
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }; 
                cell.font = { bold: true ,  family: 4, size: 14  }
                cell.border = borderCells
            }));  
            
            table?.rows.map((e:any,index:any)=>{   
                let row = [e?.name ,e?.first ,e?.timeFirst,e?.locationFirst,e?.last,e?.timeLast,e?.locationLast,e?.usage,e?.added,e?.times,e?.distance]  
                let rowadded = ws.addRow(row)
                rowadded.height =   rowadded.height*2 ;
                rowadded.eachCell({includeEmpty: true}, ((cell:any,column:any) => {  
                    cell.border = borderCells 
                    cell.inset= [0.25, 0.25, 0.35, 0.35]
                    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }; 
                }));  
            })
            

            ws.addRow([''])  
            let fileName=`${details?.reportName}-${ moment(details.start).format("DD/MM/YYYY HH:mm") } - ${ moment(details.end).format("DD/MM/YYYY HH:mm") }.xlsx`;  
            workbook.xlsx.writeBuffer() 
            .then(function(buffer:any) { 
                const data: Blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                FileSaver.saveAs(data, fileName);
            }); 
        
        }catch(err){
            console.log("err ",err)
        }
    }

    const printReport=()=>{  
        let bodyTable:any = []  
        let headers = table?.headers.map((el:any)=>{ 
             return  {text: el.label   , alignment:'center', bold:true  } 
        })  
        bodyTable = table?.rows.map((e:any,index:any)=>{   
           return [e?.name ,e?.first ,e?.timeFirst,e?.locationFirst,e?.last,e?.timeLast,e?.locationLast,e?.usage,e?.added,e?.times,e?.distance]  
        })

        bodyTable.unshift(headers)  
                pdfMake.createPdf({ 
                    watermark: { text: 'Andaman Tracking', color: '#FD5F06', opacity: 0.1, bold: true, italics: false },
                    content:[  
                        {text: details?.reportName , fontSize: 16 , bold:true  , alignment:'center', marginBottom: 15},
                        {text:`วันที่ ${ moment(details.start).format("DD/MM/YYYY HH:mm") } - ${ moment(details.end).format("DD/MM/YYYY HH:mm") } `, alignment:'center'},
                        // {text:`ทะเบียนรถ  ${details?.vehicle} `, alignment:'center'}, 
                        {text:""},
                        {   
                            layout: 'lightHorizontalLines',
                            marginTop: 10 ,
                            table:{
                              dontBreakRows:true,
                              headerRows: 1,
                              widths:[60,"*","*",80,"*" ,"*",80,"*","*","*","*" ],
                              body: bodyTable  
                            }
                          } 
                    ],
                    defaultStyle: {
                        font: "THSarabunNew",
                    },
                    header: function (currentPage, pageCount) {
                        return {
                            columns: [
                                { 
                                    text: details?.reportName ,
                                    fontSize: 10 ,
                                    marginLeft: 16,
                                    margin: [20, 20, 50, 20] 
                                } ,
                                { text: "Page " + currentPage.toString() + ' of ' + pageCount, alignment: 'right', style: 'normalText', margin: [0, 20, 50, 0] }
                            ]
                        }
                },
                pageOrientation: orientation, 
            }).open()

    }

    return(
    <div className="ion-padding" >    
        <IonButton  
            onClick={()=>{printReport()}}
            mode="ios" fill="clear" 
            style={{position:"relative", top:"1rem", right:"1rem"}} 
        >
            <IonLabel  style={{fontSize:".8em"}}>Print</IonLabel>  &nbsp;
            <IonIcon icon={printOutline}  /> 
        </IonButton>
        <IonButton onClick={()=>{printExcel()}}  
            mode="ios" fill="clear"  
            style={{position:"relative", top:"1rem", right:"1rem"}}   >
            <IonLabel style={{color:"#12753D", fontSize:".8em"}}> Excel </IonLabel> &nbsp;
            <img src="../assets/icon/excel.svg" style={{width:"1.7rem"}} />
        </IonButton>
        <IonGrid>
            <IonRow> 
                <IonCol size="12" >
                <IonLabel style={{fontSize:"1.2em",fontWeight:"bold"}} >{details.reportName}</IonLabel><br/>
                <IonLabel style={{fontSize:'.8em'}}>
                    <IonText>วันที่ {moment(details.start).format("DD MMM YYYY HH:mm")}</IonText>
                    <IonText>-</IonText>
                    <IonText>วันที่ {moment(details.end).format("DD MMM YYYY HH:mm")}</IonText>
                </IonLabel>
                </IonCol>
            </IonRow><br/> 
    
            <IonRow>  
                <IonCol size="12" >   
                {/* <tr className="bg-light-shade">
                <th  className="ion-text-center"  style={{width:"3%"}}>#</th> */}

                    <table style={{width:"100%" , fontSize:".9em"}}>
                        <tr>
                            {table?.headers.map((e:any , index:any)=>
                               <th 
                                key={index}  style={{minWidth: e?.width }} 
                                className="ion-text-center bg-light-shade"
                               > {e?.label} </th> 
                            )}
                        </tr>
                        {
                            table?.rows.map((e:any,index:any)=>
                             <tr key={index}  > 
                                <td  className={`ion-text-left ion-padding-horizontal ${index%2 ==0 ? "bg-light" : "bg-light-tint"}`} style={{padding:"5px"}} >{e?.name}</td>
                                <td className={`ion-text-center ion-padding-horizontal ${index%2 ==0 ? "bg-light" : "bg-light-tint"}`} style={{padding:"5px"}} >{e?.first}</td>
                                <td className={`ion-text-center ion-padding-horizontal ${index%2 ==0 ? "bg-light" : "bg-light-tint"}`} style={{padding:"5px"}} >{e?.timeFirst}</td>
                                <td className={`ion-text-left ion-padding-horizontal ${index%2 ==0 ? "bg-light" : "bg-light-tint"}`} style={{padding:"5px"}} >{e?.locationFirst}</td>
                                <td className={`ion-text-center ion-padding-horizontal ${index%2 ==0 ? "bg-light" : "bg-light-tint"}`} style={{padding:"5px"}} >{e?.last}</td>
                                <td className={`ion-text-center ion-padding-horizontal ${index%2 ==0 ? "bg-light" : "bg-light-tint"}`} style={{padding:"5px"}} >{e?.timeLast}</td>
                                <td className={`ion-text-left ion-padding-horizontal ${index%2 ==0 ? "bg-light" : "bg-light-tint"}`} style={{padding:"5px"}} >{e?.locationLast}</td>
                                <td className={`ion-text-center ion-padding-horizontal ${index%2 ==0 ? "bg-light" : "bg-light-tint"}`} style={{padding:"5px"}} >{e?.usage}</td>
                                <td className={`ion-text-center ion-padding-horizontal ${index%2 ==0 ? "bg-light" : "bg-light-tint"}`} style={{padding:"5px"}} >{e?.added}</td>
                                <td className={`ion-text-center ion-padding-horizontal ${index%2 ==0 ? "bg-light" : "bg-light-tint"}`} style={{padding:"5px"}} >{e?.times}</td>
                                <td className={`ion-text-center ion-padding-horizontal ${index%2 ==0 ? "bg-light" : "bg-light-tint"}`} style={{padding:"5px"}} >{e?.distance}</td>
                             </tr>
                            )
                        }
                    </table>
                </IonCol>
            </IonRow>
        </IonGrid>
    </div>
    )
}
export default RepFuelUasage;