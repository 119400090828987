import { IonIcon, IonRow, IonCol, IonMenuButton, IonSearchbar, IonCheckbox, IonItem, IonLabel, IonSpinner, IonText, IonList, IonContent, IonAccordionGroup, IonAccordion, IonInfiniteScroll, IonInfiniteScrollContent, IonGrid, IonButton, IonButtons, IonHeader, IonInput, IonListHeader, IonModal, IonRadio, IonRadioGroup, IonSegment, IonSegmentButton, IonTextarea, IonToolbar, useIonAlert, useIonToast, IonChip, IonPage,  IonImg, IonLoading, IonDatetime, IonFooter, IonPopover } from '@ionic/react';
import { t } from 'i18next';
import { carSportOutline,  gridOutline, closeOutline, swapVerticalOutline, arrowForward, filterOutline, addCircleOutline, carSport, closeCircle, closeCircleOutline, cloudOffline, cloudOfflineOutline, grid, move, stopOutline, trashBin, stop, locationSharp, speedometer, batteryFull, thermometer, idCard, createOutline,  trashBinOutline,   syncOutline,   saveOutline } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import SplitPane, { Pane } from 'split-pane-react';
import { Swiper ,SwiperSlide } from 'swiper/react';
import { addGeometry, allDevices, allGeozonesOrder, allRoutesSet, deleteGeozone, deviceAroundGeometry, deviceByGroup, deviceInGeometry, devices, editDevice, editDeviceRoute, geozoneDetail,   getFasttrack,   getPttpricing, getRang, getStorage, notifications, repCarsDailyUse, setBehaviorIdleTime, setBehaviorSpeeding, setDeviceIcon, setDeviceName, setStorage, settingConsumption, toastFail, toastSuccess, userSetting } from '../actions';
import { LDevice } from '../components/CardDevice';
import { DeviceSensorTable, PreviewRouteHistory, SummaryDevice } from '../components/DevicesContainer';
import { NotificationsButton, NotiferMessageContent } from '../components/Menu';
import { getCircle, getFastTrack, getGeoZones, getIsMeasure, getMapRoute, getMapRouting, getPolygon, getPreviewDevice, getRouteIndex, setCircle, setFastTrack, setFocusZone, setGeozones, setGeozonesBackup, setMapRoute, setMapRouting, setPlayAction, setPolygon, setPreviewDevice, setQuickTrackList, setRouteIndex, setSignin } from '../store/mapSlice';
import { DeviceAsGroup, GeoJson,   PreveiewDevice, PttPriceType  } from './schema';
import { faTurnUp, faTurnDown, faUpLong, faCircle, faBridge, faLocationDot, faFerry, faTachometerAltFast, faGasPump, faSatelliteDish, faDoorOpen, faTruckFast, faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { useSelector, useDispatch } from 'react-redux';
import { DevicesInZone, LayersExpandControl, MarkerIcon, PlayControl, RouteMapSearch, ToolbarGeofenceItem, ToolbarMap } from '../leaflet/LeafletLayers';
import { getBackup, getDeviceGroup, setDevices, setBackupDevices, setDeviceGroup, setFilterDevices, setUnreadMsg, setNotifierMessage, getIsGroupDevice, getDevices, setDeviceGroupBackup, getDeviceGroupBackup, setDefaultMap, setGoogleEnable, setIsGroupDevice, setShowLicense, setDateEnd, setDateStart } from '../store/appSlice';
import { getContentFilter,  setContentFilter } from '../store/filterSlice';
import { getNotify, setNotify } from '../store/menuSlice';
import { getVoiceEnable } from '../store/settingSlice';
import { Swiper as SwiperType } from "swiper"
import L, { Map as MapType , Marker as MarkerType, PointExpression} from "leaflet"
import { MapContainer ,FeatureGroup} from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster'; 
import { Redirect } from 'react-router';
import { DeviceStatusNameTime } from '../components/IconStatus';
import { DialogDevice, SettingIcon } from '../components/OptionDialogs';
import { SelectPlaceToStreetView, RouteQuickTrack, GeofencesMarker, MeasureDistanceFeature } from '../leaflet/TrackingMap';
import NavBar from '../components/NavBar';
import LoadingProgress from '../components/LoadingProgress'; 
import DrawControl from '../leaflet/DrawControl';
import { ModalGeozoneNotify } from './Geozones';
import { useTranslation } from 'react-i18next';
import { convertMsToTime } from './Reports/ReportUtils';
import DevicesTable from '../components/DevicesTable';
import ListDevice from '../components/ListDevice';

import moment from "moment" 
import sha1 from 'sha1'; 
import appconf from "../api.config.json"
import "./css/Home.css"
import 'split-pane-react/esm/themes/default.css'; 
 
export interface Options {
    value: Number 
    label:String 
    checked: boolean
  }

  
let lastUpdate: any = null;
const HomeSplitpane=()=>{
    const [loading,setLoading] = useState(true)
    const backup:any= useSelector(getBackup)
    const devices:any =  useSelector(getDevices)
    const [RfSwip, setRfSwip] = useState<SwiperType | null>(null)
    const [rfSwip, setrfSwip] = useState<SwiperType | null>(null)
    const [status,setStatus] = useState("all")
    const [keyword,setKeyword] = useState("")
    const template :any= useSelector(getContentFilter)
    const [limit,setLimit] = useState(20) 
    // const [cookies, setCookie] = useCookies(['token']);
    const [modalref,setModalRef] = useState<HTMLIonModalElement|null>(null);
    const maprouting :any= useSelector(getMapRouting)
    const [zoneselected , selectZone] = useState<null>(null) 
    const maproute:RouteMapSearch|any = useSelector(getMapRoute) 
    const previewDevice :any= useSelector(getPreviewDevice)
    const [map ,setMapRef] = useState<MapType|null>(null)
    const [mobileMap ,setMobileMapRef] = useState<MapType|null>(null) 
    const fastTrack :any= useSelector(getFastTrack)  
    const [tabs,setTabs] = useState("track")
    const [openEdit ,setOpenEdit] = useState(false)  
    const [group,setGroup] = useState("-1")
    const [showgroup , setSowGroup] = useState(false) 
    const voiceEnable  :any= useSelector(getVoiceEnable) 
    const notify:any = useSelector(getNotify)
    const [signin , setsignin] = useState<any>({username:""})
    const geozones :any= useSelector(getGeoZones)   
    const [activity,setActvity] = useState({
        "maxSpeed": 102,
        "move": "03:51:39",
        "stop": "14:56:19",
        "allExpense": 215.19893333333334,
        "lastPttPrice": "* อ้างอิงราคาน้ำมันจาก บริษัท ปตท.จำกัด (มหาชน) วันที่ 09/08/2024 05:00:00 Gasohol E20 35.24",
        "idle": "00:37:04"
    })
    const deviceGroup:DeviceAsGroup[] |any= useSelector(getDeviceGroup); 
    const [sizes, setSizes] = useState<any[]>([500,  "auto"]); 
    const [hsizes, setHSizes] = useState(['100%', 50 ]); 
    const dispatch = useDispatch() 
    const [showDrawControl , setShowDrawControl] = useState(false)
    const [ionalrt , dimissAlrt] = useIonAlert()
    const [devicesInZon,setDeviceInZone] = useState([])
    const [accordionGroup,setaccordionGroup] = useState([-1])
    const accordionGroupRef = useRef<null | HTMLIonAccordionGroupElement>(null);
    const deviceGroupBackup = useSelector(getDeviceGroupBackup)
    const [modalSave,setModalSave] = useState(false) 
    const [modalType,setModalType] = useState("add")
    const [addZone,setAddZone] = useState(false)
    const [name , setName] = useState("")
    const [radius,setRadius] = useState(0.5) 
    const polygon :any= useSelector(getPolygon)
    const circle :any= useSelector(getCircle)   
    const [currentIcon , setCurrentIcon] = useState(0) 
    const [optChecked , setOptChecked ] = useState<Number[]>([])
    const geoType:any = "circle"
    const [ toast , dimisstoast] = useIonToast();
    const [checkall , setCheckall] = useState<boolean | undefined>(false)
    const [options,setOptions] = useState<Options[]>([])  
    const [geozoneSearch , setGeozoneSearch ] = useState([])
    const [datestart,setDateStart] = useState(moment().format("YYYY-MM-DDT00:00:00"))
    const [dateend,setDateEnd]= useState(moment().format("YYYY-MM-DDT23:59:59"))
    const [checkin,setCheckin] = useState({   
        label:"เข้า" ,
        checked: true ,
        format:"{0} เข้า  {1} เวลา {3}  {4}   http://maps.google.com/maps?f=q&q={5}&z=16"
    })
    const [checkout,setCheckout] = useState({   
        label:"ออก" ,
        checked: true ,
        format:"{0} ออก {1} เวลา {3}  {4}   http://maps.google.com/maps?f=q&q={5}&z=16"
    }) 
  
     
  
    const boudsdevice =async(d:any )=>{
        if(devices.length > 0 && !previewDevice?.active && !zoneselected && !fastTrack){ 
            if(lastUpdate === null){
                let bound =await devices.map((el:any)=>{ 
                    return [el.latitude , el.longitude]
                })   
                bound = bound.filter((e:any) =>  e[0] != null &&  e[0] != '-') 
                map?.fitBounds(bound,{ padding: [50 ,-50]}) 
            } 
            map?.on("move",(e)=>{
                map?.invalidateSize()
            })
        }else if(devices.length === 0){ 
            map?.fitBounds([[20.326948, 97.676988] , [18.410117, 104.029578] , [8.303058, 98.217127],[5.638321, 101.143800]],)
            
        }  
    } 

    const getSetting=async()=>{ 
        /* Setting Lang */
        // let locaLang = getStorage('language')  
        // if(locaLang !== null && locaLang !== undefined){  
        //   i18n.changeLanguage(locaLang) 
        // }else{ 
        //   i18n.changeLanguage('th') 
        //   localStorage.setItem('language','th') 
        // }  
      
        const token = getStorage('token')
        if(token){  
          const settings = await userSetting()  
          console.log("settings ",settings)
          const showLice =  settings.showName ==="1" ? true :false 
          dispatch(setShowLicense( showLice ))
          dispatch(setIsGroupDevice(settings.groupDevice))
          setStorage('defaultTile',settings.defaultMap)   
          dispatch(setDefaultMap(settings.defaultMap)) 
          dispatch(setGoogleEnable(settings.googleMapEnabled === 1?true:false))
        }else{ 
          dispatch(setShowLicense( false ))
          dispatch(setIsGroupDevice(false))
          setStorage('defaultTile',false)   
          dispatch(setDefaultMap("Longdo")) 
          dispatch(setGoogleEnable(false))
        } 
      }

    const logOut=async()=>{     
        setTimeout(()=>{ 
          localStorage.removeItem("isSignin")
          localStorage.removeItem("token") 
          dispatch(setSignin(false))
        },200)   
    } 
    const layoutCSS = {
        height: '100vh',
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'center'
    };
    const getGeozones=async()=>{
        let geozoneorder:any = await allGeozonesOrder({})
        geozoneorder = await geozoneorder.map((e:any)=>{
            return {...e , ...{ show: true}}
        })
        dispatch(setGeozones(geozoneorder))
        dispatch(setGeozonesBackup(geozoneorder))
    }
 

    useEffect(()=>{  
        setTimeout(()=>{
            setLoading(false)
        },1000)
        const getdevices=async()=>{ 
           
            let signinuser = await getStorage("sigin")
            let contFil = await getStorage('content') 
            getGeozones()
            dispatch(setContentFilter(contFil))  
            setsignin(signinuser) 
            // setLoading(true)
            const results = await allDevices({})  
            console.log("allDevices  ",results)
            let result = await results.filter((value:any, index:any, array:any) => 
              array.indexOf(value) === index
            )  
            
            result =  await results.map((e:any)=> {
                return { ...e , ...{show : true} }
            }) 
  
            let options = result.map((e:any)=> {return {
                value: e.device_id ,label: e.name, checked:false 
            } }) 
            setOptions(options)  

            console.log("allDevices result ",result)
            dispatch(setDevices(result))
            dispatch(setBackupDevices(result)) 
            // setLoading(false)


            let deviceGroup = await deviceByGroup({})   
            console.log("deviceGroup ",deviceGroup)
            if(deviceGroup.status === 200){
                let groupsdcheck =await deviceGroup?.devices.map((group:any)=>{
                    const dcheck = group.devices.map((device:any)=>{
                        return { ...device , ...{show : true} }
                    })
                    return {...group , ...{devices:dcheck , show : true}}
                }) 
                dispatch(setDeviceGroup(groupsdcheck)) 
                dispatch(setDeviceGroupBackup(groupsdcheck))
            }else{
                logOut()
            } 

            if (!accordionGroupRef.current) {
                return;
            } 
            accordionGroupRef.current.value = ["-1"];
        } 
        getdevices() 
        getSetting()
        getNotifyMess() 

        const authenSignin=async()=>{
            let isSign =await getStorage("isSignin")
            let token = await getStorage("token")
            let isSigninParse = JSON.parse(isSign||"false")
            dispatch(setSignin(isSigninParse))
      
            let appVersion = await getStorage('appVersion')  
       
            if(!token ){ 
              dispatch(setSignin(false))
              setStorage('isSignin', false)
              setTimeout(()=>{ 
                  window.location.pathname = '/'
              },200) 
            }
            console.log(" appVersion != appconf.versionNumber", appVersion ,"  " ,appconf.versionNumber)
            if(appVersion && appVersion != appconf.versionNumber){  
              localStorage.clear()
              setStorage("signin",signin)
              dispatch(setSignin(false))
              setStorage('isSignin', false)
              setTimeout(()=>{ 
                  window.location.pathname = 'signin'
              },200)
            }
          }
          authenSignin()
 
    },[maprouting , window.location.pathname ,lastUpdate ])

    
    const searchDevice=async(val:any)=>{
        setKeyword(val)
        if(val.length > 1){
            setSowGroup(false)
            const needle = val 
            const filter = await backup.filter((e:any)=> e.name?.toLowerCase().indexOf(needle) > -1) 
            dispatch(setDevices(filter))
        }else{
            dispatch(setDevices(backup))
        }
    }

    const changeStatus=async(val:any)=>{ 
        setStatus(val)
        await dispatch(setFilterDevices(val))  
  
            let groupsdcheck =  deviceGroupBackup.map( (group:any)=>{
                let dgroup = []
                if (val === "offline") {
                    dgroup =    group.devices.filter(
                      (device:any) => device.online === 0
                    );
                }  else if (val === "all") {
                    dgroup = group.devices;
                } else if (val === "move") {
                    dgroup =  group.devices.filter(
                      (device:any) => device.status === 7 && device.online === 1
                    );
                } else if (val === "idle") {
                    dgroup =  group.devices.filter(
                      (device:any) => device.status === 24 && device.online === 1
                    );
                } else if (val === "stop") {
                    dgroup =  group.devices.filter(
                      (device:any) => device.status === 23 && device.online === 1
                    );
                } else {
                    dgroup = group.devices;
                } 
                    
                const dcheck = dgroup.map((device:any)=>{
                    return { ...device , ...{show : true} }
                })
                return {...group , ...{devices:dcheck , show : true}}
            })  
            dispatch(setDeviceGroup(groupsdcheck)) 
             
    }
    const distanceCal=(dis:any)=>{
        if(dis >= 1000 ){ 
            let km = dis/1000 
            return km.toFixed(1)+" กิโลเมตร"
        }else{
            return dis+" เมตร"
        }
    }

    const turncode=(code:any, type:any)=>{
        if(type === 1 ){
            if(code === 0){ return "เลี้ยวซ้าย"}        
            else if(code === 1){ return "เลี้ยวขวา"}
            else if(code === 2){ return "เลี้ยวซ้ายเล็กน้อย"}
            else if(code === 3){ return "เลี้ยวขวาเล็กน้อย"}
            else if(code === 4){ return ""}
            else if(code === 5){ return "ตรงไป"}
            else if(code === 6){ return "เข้าโทลเวย์"}
            else if(code === 9){ return "มาถึง"}
            else if(code === 11){ return "การเดินทางด้วยเรือเฟอร์รี่"}
        }else if(type === 2 ){
            if(code === 0){ return  <FontAwesomeIcon icon={faTurnUp} style={{transform: "rotate(-90deg)"}}/>}        
            else if(code === 1){ return <FontAwesomeIcon icon={faTurnDown} style={{transform: "rotate(-90deg)"}} /> }
            else if(code === 2){ return <FontAwesomeIcon icon={faUpLong} style={{transform: "rotate(-20deg)"}} />}
            else if(code === 3){ return <FontAwesomeIcon icon={faUpLong} style={{transform: "rotate(20deg)"}} />}
            else if(code === 4){ return <FontAwesomeIcon icon={faCircle}  />}
            else if(code === 5){ return <FontAwesomeIcon icon={faUpLong} />}
            else if(code === 6){ return <FontAwesomeIcon icon={faBridge} />}
            else if(code === 9){ return <FontAwesomeIcon icon={faLocationDot} />}
            else if(code === 11){ return <FontAwesomeIcon icon={faFerry} />}
        }
    }
    const viewdistance=(fea:any)=>{  
        const coordinates = fea?.geometry?.coordinates.map((e:any)=>{ return [e[1],e[0]] })
        map?.fitBounds(coordinates) 
    }

    const distanceTime=(seconds:any)=>{
        let result = ""; 
        if (seconds >= 60 && seconds < 3600) {
            const minutes = Math.floor(seconds / 60);
            result += minutes + ` ${t("min")}`;
            seconds %= 60;
        } 
        if (seconds >= 3600  && seconds < 86400 ) {
            const hours = Math.floor(seconds / 3600);
            result += hours + ` ${t("hh")}`;
            seconds %= 3600;
        } 
        if (seconds >= 86400) {
            const days = Math.floor(seconds / 86400);
            result += days + ` ${t("dd")}`;
            seconds %= 86400;
        }  
        return result.trim();
    }
    
    const closeRouting=()=>{  
        dispatch(setMapRouting({ start: null , end: null  }))
        dispatch(setMapRoute(null))
        RfSwip?.slideTo(0)
    }

    const stsSize=(online:any , sts:any)=>{
        let dests:any[]
        if(online > 0 ){ 
            dests  = backup.filter((e:any)=> e.online === 1 && e.status === sts)
        }else{
            dests = backup.filter((e:any)=> e.online === 0)
        }
      return dests.length
    }

    
    const getNotifyMess=async()=>{ 
        let mbegin = moment().add(7,'hour').format("YYYY-MM-DDT00:00:00")
        let mend = moment().add(7,'hour').format("YYYY-MM-DDT23:59:59")
       
        let begin = new Date(mbegin).getTime()
        let end =  new Date(mend).getTime() 
        let message = await notifications({begin,end}) 
        let unread = message.filter((e:any)=> !e.readed)  
        dispatch(setUnreadMsg(unread))
    
        dispatch(setNotifierMessage(message)) 
        dispatch(setNotify({
          update:  new Date().getTime(),
          message:message
        }))  
      }

    const changeCheck=async(checked:any ,de:any,gid:any)=>{ 
        try{
            let dup = await devices.map((e:any)=>{
                if(de?.device_id === e?.device_id){
                    return {...e ,...{show: checked }} 
                }else{
                    return e
                }
            }) 
            dispatch(setDevices(dup))
            dispatch(setBackupDevices(dup)) 

            const dgroupSelect = deviceGroup.find((e:any)=> e.group_id === gid)
            const dcheck = dgroupSelect.devices.map((device:any)=>{
                if(device.device_id === de?.device_id ){
                    return { ...device , ...{show : checked} }
                }else{
                    return device
                } 
            })
            
            let updatedgroup = await deviceGroup.map((e:any)=>{
                if(e.group_id === gid  ){
                    return {...{...e,  ...{show : checked}},...{devices : dcheck }}
                }else{
                    return e
                }
            }) 
            dispatch(setDeviceGroup(updatedgroup)) 
            
        }catch(err){
            console.log("err ",err)
        }
    }
    const selectDevice=(device:any)=>{
        map?.flyTo([device?.latitude , device?.longitude],14);  
        modalref?.setCurrentBreakpoint(0.85) 
        dispatch(setPreviewDevice({active:true , device: device}))
        setHSizes(['auto', 350])
        setTimeout(()=>{
            map?.setView([device?.latitude , device?.longitude]);  
        })
    }

    const changeCheckGroup=async(groupd:any,checked:any)=>{ 
        const dcheck = groupd.devices.map((device:any)=>{
            return { ...device , ...{show : checked} }
        })
        
        let updatedgroup = await deviceGroup.map((e:any)=>{
            if(e.group_id === groupd.group_id ){
                return {...{...e,  ...{show : checked}},...{devices : dcheck }}
            }else{
                return e
            }
        })
        console.log("changeCheckGroup ",updatedgroup)
        dispatch(setDeviceGroup(updatedgroup)) 
    }
    const getDeviceInGeo=async(zone:any)=>{
        console.log("getDeviceInGeo zone ",zone , RfSwip)
        let updatezone:any[] = geozones
        let zoneindex = updatezone.findIndex((gz)=> gz?.id ===  zone?.id ) 
        if(zoneindex >= 0){
            let zonedetail:any = await geozoneDetail({geoid: zone?.id})   
            let zoneWithPoint = { ...zonedetail, ...zone }
            let bound:any =  []
            bound = await zonedetail?.position.map((p:any) =>{ return [p.lat , p.lon ] })
            let zoneWithBound =  {...zoneWithPoint,...{bound: bound , show: true}} 
            selectZone({...zoneWithPoint,...bound})
            rfSwip?.slideNext()
           setTimeout(()=>{ bound.length > 1 ? map?.fitBounds(bound):   map?.setView([zone?.latitude,zone?.longitude],16) },200)
            updatezone = await geozones.map((z:any,index:any)=>{
                if(index === zoneindex){
                    return zoneWithBound
                }else{
                    return {...z , ...{ show: true}}
                }
            })  
            dispatch(setGeozones(updatezone))  
            if(zoneWithBound.position ){  
                const deviceingeo = await deviceInGeometry({geoId: zone.id})  
                console.log("deviceingeo ",deviceingeo)
                setDeviceInZone(deviceingeo)
                dispatch(setFocusZone(zoneWithBound)) 

                const devicearoundgeo = await deviceAroundGeometry({geoId: zone.id})  
                console.log("devicearoundgeo ",devicearoundgeo)
            }
            selectZone({...zoneWithPoint,...{bound:bound}})
        }else{
            map?.setView([zone?.latitude,zone?.longitude],16)
        }
    } 

       const saveGeofences=async()=>{
            let geometryArr:any = [] 
            polygon.forEach((el:any) => {
                geometryArr = geometryArr.concat(el)
              });
            console.log("saveGeofences circle ",circle)
            if(geometryArr.length > 0){ 
                const body = {
                    locationName: name ,
                    geometry:   JSON.stringify(geometryArr)   ,  
                    iconId : currentIcon , 
                    checkin:  checkin.checked ,
                    checkout: checkout.checked ,
                    devices:  optChecked ,
                    templatein: checkin.format ,
                    templateout: checkout.format 
                }
                console.log("saveGeofences body ",body)
                if(optChecked.length > 0 && name !== ""){
                    setLoading(true)
                    const result = await addGeometry(body)
                    if(result.status===1){
                        toast({
                            ...toastSuccess,
                            message:"Add geofences success."
                        })
                        dispatch(setCircle([]))
                        dispatch(setPolygon([]))
                        setName("") 
                        setModalSave(false) 
                        setAddZone(false)
                        getGeozones()
                    } 
                    setLoading(false)
                }else if(name==="" || name === null || name === undefined){
                    toast({
                        ...toastFail ,
                        message:"กรุณา ระบุชื่อพื้นที่ !!"
                    })
                }else{ 
                    toast({
                        ...toastFail ,
                        message:"กรุณาเลือกรถก่อนการบันทึก !!"
                    })
                }
            }else{
                toast({
                    ...toastFail ,
                    message:"Not found geofences bounds. Please try again."
                })
            }
    }

    const checkAll=async (checked:boolean)=>{  
        setCheckall(checked)
        let checkeddevice: any[] = []
        let options = backup.map((e:any)=> {
        if(checked){ checkeddevice.push( e.device_id) }
        return {
            value: e.device_id ,label: e.name, checked:checked 
        } }) 
        setOptions(options)
        setOptChecked(checkeddevice) 
    }

    const changeOption=(checked:any, deviceId:any)=>{ 
        let checkeddevice = optChecked
        checkeddevice = checkeddevice.filter((id)=> id !== deviceId)
        let updateopt =  options.map((e)=>{
            return {...e , ...{ checked: deviceId===e.value?checked: e.checked  } }
        })
        setOptions(updateopt) 
        if(checked){ 
            checkeddevice.push(deviceId)
            setOptChecked(checkeddevice)
        }else{  
            setOptChecked(checkeddevice)
        }  
    } 
    const getallzone =async()=>{   
        let geofences:any = await allGeozonesOrder({}) 
        geofences = await geofences.map((e:any)=>{
            return {...e , ...{ show: true}}
        })
        dispatch(setGeozones(geofences))   
    }
    
    const deleteZone=async(zone:any)=>{  
        console.log("zone ",zone)
        ionalrt({
            message : "ยืนยันที่จะลบพื้นที่ !!" ,
            buttons : [
                {
                  text: 'Cancel',
                  role: 'cancel',
                  handler: () => {
                    console.log('Alert canceled');
                  },
                },
                {
                  text: 'OK',
                  role: 'confirm',
                  handler: async () => {
                    setLoading(true)
                    const result = await deleteGeozone({geoId:zone?.id })
                    console.log("result ",result)
                    setLoading(false)
                    if(result.result){
                      toast({
                        ...toastSuccess,
                        message: "ลบพื้นที่ "+zone?.name+" สำเร็จ !!"
                      }) 
                      getallzone()
                    }else{
                        toast({
                            ...toastFail,
                            message:  "ลบพื้นที่ "+zone?.name+" ไม่สำเร็จ !!"
                        }) 
                    }
                    setLoading(false)
                  },
                },
              ]
        }) 
     }

     const searchGeozone=(word:any)=>{ 
        setKeyword(word)
        if(word.length >= 2 ){
            let filterName = geozones.filter((e:any)=> e.name.toLowerCase().indexOf(word.toLowerCase()) > -1 )  
            setGeozoneSearch(filterName)
        }else{ 
            setGeozoneSearch(geozones)
        }
    } 

    return( 
        loading  ? <IonPage> 
            <div className='set-center' id="main-page"  style={{minHeight:"100vh"}} > 
                <IonLabel> <IonSpinner name="crescent"  />  {t("loading")} </IonLabel> 
            </div>
        </IonPage>: 
        <IonPage>
            <div style={{ height: 500 }} id="main-page"> 
            <LoadingProgress mapref={map} updatetime={(e:any)=>{ lastUpdate=e }} />   
           {/* @ts-ignore */}
            <SplitPane
                split='vertical'
                sizes={sizes}
                onChange={setSizes}
            >
                <Pane  style={{ height: "100vh" }}  >
                <div className='splitpane pane' style={{  height: '100vh', }}> 
                 <Tabs className={"home-screen-tabs "} >
                        <TabList   >
                            <Tab onClick={()=>{setTabs("track");setSizes([ 500,'auto']); }} style={{fontSize:"1.15em"}}>  
                                <IonIcon mode="ios" icon={carSportOutline} />
                            </Tab>
                            <Tab onClick={()=>{setTabs("zone");setSizes([ 500,'auto']);  }}  style={{fontSize:"1.1em"}}>  
                            <IonIcon mode="ios" icon={"assets/icon/draw-polygon.svg"} /> 
                            </Tab>
                            <Tab onClick={()=>{setTabs("device");setSizes(['auto',  300]); }}style={{fontSize:"1.1em"}}>    
                            <IonIcon mode="ios" icon={gridOutline} /> 
                            </Tab>
                            <Tab onClick={()=>{setTabs("notify"); setSizes([ 500,'auto']);  }}style={{fontSize:"1.1em"}}>   
                            <NotificationsButton /> 
                            </Tab>
                        </TabList>
                        <TabPanel> 
                            <Swiper
                                slidesPerView={1}
                                draggable={false}
                                allowTouchMove={false}
                                onSlideChange={() => console.log('slide change')}
                                onSwiper={(swiper:any) =>{setRfSwip(swiper); console.log(swiper)}}
                            >
                            <SwiperSlide style={{ borderRadius:"5px"}}> 
                                <IonRow> 
                                    <IonCol size="1.5" className="ion-hide-md-up"  >
                                        <IonMenuButton />
                                    </IonCol>
                                    <IonCol >
                                    <IonSearchbar style={{paddingLeft:"0px"}} mode="ios" placeholder={t("home_search").toString() } value={keyword} onIonInput={(e:any)=>{ searchDevice(e.detail.value)}}  ></IonSearchbar>
                                    </IonCol> 
                                </IonRow> 
                                    {!showgroup && 
                                    <SegmentStatus 
                                        status={status} 
                                        stsSize={(a:any,b:any)=>stsSize(a,b)} 
                                        changeStatus={(e:any)=>{changeStatus(e)}} 
                                        devices={devices} 
                                    />  
                                    }
                                    <div id='list-colred-scroll' style={{paddingBottom:"1rem"}} >
                                        {signin?.username === "admin" ?  
                                        <IonContent> 
                                            <IonList>
                                                { devices.slice(0, limit).map((device:any,index:any)=>
                                                    <LDevice 
                                                    device={device} key={index} modal={modalref} swiper={RfSwip} 
                                                    select={(el:any)=>{ 
                                                        selectDevice(el) 
                                                    }} 
                                                    changeCheck={(e:any)=>{ changeCheck(e,device,null) }}
                                                    mapref={map} 
                                                /> 
                                                )}
                                                <IonInfiniteScroll
                                                    onIonInfinite={(ev:any) => {
                                                    setLimit(limit+50)
                                                    setTimeout(() => ev.target.complete(), 500);
                                                    }}
                                                >
                                                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                                </IonInfiniteScroll>
                                            </IonList>
                                        </IonContent>:
                                        <IonContent> 
                                        <IonAccordionGroup  ref={accordionGroupRef}  multiple={true}  >
                                        { deviceGroup.map((dg:any,i:any)=>
                                        <IonAccordion value={dg?.group_id.toString()}  key={i}>
                                       
                                            <IonItem slot="header" color="light">
                                                <IonCheckbox value={dg?.group_id}  checked={dg?.show} onIonChange={(e:any)=>{ changeCheckGroup(dg , e.detail.checked) }} /> &nbsp;
                                                <IonLabel>{dg?.group}</IonLabel> 
                                            </IonItem>
                                            <div className="ion-no-padding" slot="content">
                                          
                                            {
                                                dg?.devices.slice(0, limit).map((device:any,di:any)=>
                                                    <LDevice 
                                                        device={device} key={di} modal={modalref} swiper={RfSwip} 
                                                        select={(el:any)=>{ 
                                                            selectDevice(el) 
                                                        }} 
                                                        changeCheck={(e:any)=>{ changeCheck(e,device,dg?.group_id) }}
                                                        mapref={map} 
                                                    /> 
                                                )
                                            }
                                            </div> 
                                        </IonAccordion>
                                        )}  
                                            <IonInfiniteScroll
                                            onIonInfinite={(ev:any) => {
                                                setLimit(limit+50)
                                                setTimeout(() => ev.target.complete(), 500);
                                            }}
                                            >
                                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                            </IonInfiniteScroll>
                                        </IonAccordionGroup>
                                        </IonContent>
                                        }
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="bg-white"  style={{ borderRadius:"5px"}}>
                                    <PreviewRouteHistory mapref={map} startdate={datestart} enddate={dateend}  >
                                        <DateTimeRange 
                                            start={datestart} end={dateend} 
                                            loading={loading} setLoading={setLoading}
                                            setDateStart={(e:any)=>{return setDateStart(e)}} 
                                            setDateEnd={(e:any)=>{return setDateEnd(e)}} 
                                        />
                                    </PreviewRouteHistory>
                                </SwiperSlide>  
                                <SwiperSlide  className="bg-white"  style={{ borderRadius:"5px"}}>
                                <IonGrid   className="ion-padding"> 
                                    <IonRow >
                                        <IonCol size="1" className="set-center" > 
                                        <img src="../assets/icon/dotRouting.svg" style={{height:"60%"}} />
                                        </IonCol> 
                                        <IonCol size="11" style={{fontSize:"small"}} > 
                                            <IonRow>
                                                <IonCol size="10" > 
                                                    <div style={{border:".5px solid #DDD" , padding:"2px 0 2px 5px" , borderRadius:"5px"}} >
                                                        <IonLabel style={{fontSize:".9em"}} >{maprouting?.start?.name}</IonLabel> <br/>
                                                        <small>{maprouting?.start?.position?.lat}  -  {maprouting?.start?.position?.lng} </small>
                                                    </div>
                                                </IonCol>
                                                <IonCol className="set-center" size="2" >
                                                    <IonButton fill="clear" color={"primary"} onClick={()=>{closeRouting()}} >
                                                        <IonIcon icon={closeOutline} />
                                                    </IonButton>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol size="10" > 
                                                    <div style={{border:".5px solid #DDD" , padding:"2px 0 2px 5px" , borderRadius:"5px"}} >
                                                        <IonLabel>{maprouting?.end?.name}</IonLabel> <br/>
                                                        <small>{maprouting?.end?.position?.lat}  -  {maprouting?.end?.position?.lng} </small>
                                                    </div>
                                                </IonCol>
                                                <IonCol className="set-center" size="2" >
                                                    <IonButton fill="clear" color={"dark"} >
                                                        <IonIcon icon={swapVerticalOutline} />
                                                    </IonButton>
                                                </IonCol>
                                            </IonRow>
                                        
                                        </IonCol> 
                                    </IonRow>  <br/>  
                                    <div style={{width:"100%", height:".3rem",backgroundColor:"#EEE",marginBottom:".5rem"}} ></div>
                                    <IonRow> 
                                        <IonCol size="10" > 
                                            <IonLabel color={"dark"} > 
                                                <IonText color={"primary"} > 
                                                <strong style={{fontSize:"1.2em"}}>{distanceTime(maproute?.data?.interval) }</strong> 
                                                </IonText> ({distanceCal(maproute?.data?.distance)} )
                                            </IonLabel> <br/>
                                            <IonLabel color={"dark"} style={{display:"flex", alignItems:"center"}}><small>{maprouting?.start?.name} &nbsp; <IonIcon icon={arrowForward} />  &nbsp; {maprouting?.end?.name}</small></IonLabel> 
                                        </IonCol> 
                                    </IonRow> 
                                    <IonRow> 
                                        <IonCol size="12" >
                                            <div  style={{overflowY:"scroll",height:"71vh"}} >
                                                {
                                                    maproute?.features.map((e:any, findex:any)=> 
                                                        <RowDirection key={findex } 
                                                            viewdistance={(fea:any)=>{viewdistance(e)}}   
                                                            turncode={(e:any,type:any)=> turncode(e ,type)}   
                                                            distanceCal={(e:any)=>{distanceCal(e)}}  
                                                            properties={e.properties}
                                                            feature={e}
                                                        /> 
                                                    )
                                                }
                                            </div>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid><br/><br/><br/>
                                </SwiperSlide>
                            </Swiper>
                                
                    </TabPanel>
                    <TabPanel >
                        <GeozoneStation  setSwRef={(ref:any)=>{setrfSwip(ref)}} devicesInZon={devicesInZon}
                            mapref={map} selectzone={(e: any)=>{ selectZone(e) }}  getDeviceInGeo={(e:any)=>{getDeviceInGeo(e)}}
                            isadd={showDrawControl}  
                            addZone={()=>{setShowDrawControl(!showDrawControl)}}  
                            saveModal={(e:any)=>{setModalSave(e)}} 
                            deleteZone={(zone:any)=>{deleteZone(zone)}}
                        >
                        <IonRow  style={{fontSize:"small"}} >
                            <IonCol size={showDrawControl?'8':'9'} >   
                                <IonSearchbar 
                                    style={{paddingLeft:"0px"}} mode="ios" value={keyword} 
                                    onIonInput={(e:any)=>{ searchGeozone(e.detail.value)}} 
                                    placeholder={t("search").toString() } 
                                ></IonSearchbar>
                            </IonCol> 
                            <IonCol  className='set-center'>
                                <IonButtons   > 
                                    <IonButton  className='set-center'> <IonIcon icon={syncOutline} /></IonButton>
                                    <IonButton  className='set-center' onClick={()=>{setShowDrawControl(!showDrawControl)}} > 
                                        {showDrawControl  ? <IonIcon icon={"assets/icon/draw-polygon-close.svg"} />  : 
                                        <IonIcon icon={"assets/icon/draw-polygon-add.svg"} /> } 
                                    </IonButton>
                                    {showDrawControl &&<IonButton  className='set-center' onClick={()=>{setModalSave(true)}} > 
                                        <IonIcon icon={saveOutline} />
                                    </IonButton>}
                                </IonButtons>
                            </IonCol>
                        </IonRow> 
                        <div> 
                        { keyword.length>0 ? 
                            <IonList  style={{overflowY:"scroll",width:"100%",height:"70vh"}}> 
                                {  geozoneSearch.map((zone:any,index:any)=>
                                <ToolbarGeofenceItem 
                                    key={index} zone={zone} selected={(e:any)=>{getDeviceInGeo(e);  }}
                                /> 
                                )} 
                            </IonList>:
                            <IonList  style={{overflowY:"scroll",width:"100%",height:"70vh"}}>  
                                    {
                                        geozones.map((zone:any,index:any)=> 
                                            <IonRow key={index} style={{fontSize:"small"}} >
                                                <IonCol size='1' > <IonCheckbox  checked={zone?.show} /> </IonCol>
                                                <IonCol className='set-center-row cursor-pointer ' style={{justifyContent:"flex-start"}} > 
                                                    <IonImg src={`../assets/icon-place/map-icon-${zone?.icon !== null && zone?.icon !==  undefined?zone?.icon: 0}.png`} style={{ width:"1.3rem",marginRight:".5rem" }} />
                                                    <IonLabel className='ionn-text-no-wrap'
                                                    onClick={()=> map?.setView([zone?.latitude,zone?.longitude],16)}
                                                    > { zone?.name }</IonLabel> 
                                                </IonCol>
                                                <IonCol sizeSm='4' sizeXl='3' >  
                                                    <IonButtons  > 
                                                        <IonButton size='small'  onClick={(e:any)=>{getDeviceInGeo(zone); console.log("selected ",zone) }}> <IonIcon icon={carSportOutline} /></IonButton>
                                                    
                                                        <IonButton size='small' onClick={()=>{deleteZone(zone)}}> <IonIcon icon={trashBinOutline} /></IonButton>
                                                    </IonButtons>
                                                </IonCol> 
                                            </IonRow> 
                                        )
                                    }
                                    <IonInfiniteScroll onIonInfinite={(ev:any) => {
                                        setLimit(limit+50)
                                        setTimeout(() => ev.target.complete(), 500);
                                    }}>
                                        <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                    </IonInfiniteScroll> 
                                </IonList> 
                                }
                            </div>
                        </GeozoneStation>
                    </TabPanel>
                    <TabPanel>
                        <DevicesTable 
                        devices={devices} mapref={map} 
                        editDevice={(d:any)=>{dispatch(setPreviewDevice({active:true ,device:d }));setOpenEdit(true);map?.setView([d.latitude , d.longitude]) }} 
                        >
                            <IonRow>
                                <IonCol sizeXs="12" sizeSm="12" sizeMd="1.5"  className="set-center-row" style={{ justifyContent:"center"}}> 
                                    <IonLabel>
                                        <IonIcon icon={filterOutline} /> &nbsp;
                                        <strong style={{fontSize:"1.2em"}}>{t("home_setting_device")}</strong>
                                    </IonLabel> 
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="6" className="set-center" > 
                                    <IonSearchbar 
                                    style={{paddingLeft:"0px"}} mode="ios" placeholder="ค้นหา" value={keyword} 
                                    onIonInput={(e:any)=>{ searchDevice(e.detail.value)}}  
                                    ></IonSearchbar>
                                </IonCol>
                                <IonCol> 
                                {!showgroup && <SegmentStatus 
                                status={status} 
                                stsSize={(a:any,b:any)=>stsSize(a,b)} 
                                changeStatus={(e:any)=>{changeStatus(e)}} 
                                devices={devices} 
                                /> }
                                </IonCol>
                                
                            </IonRow>

                        </DevicesTable>
                    </TabPanel>
                    <TabPanel>
                    <NotiferMessageContent  notify={notify}    voiceEnable={voiceEnable} />
                    </TabPanel>
                
                    </Tabs>
                </div>
                </Pane> 
                <Pane  style={{ height: "100vh",background: '#000' }}  >
                    <div style={{ ...layoutCSS, background: '#fff' }}>
                        <DialogDevice   />  
                        {/* @ts-ignore */}
                        <SplitPane
                                split='horizontal'
                                sizes={hsizes}
                                onChange={setHSizes}
                            >
                                <Pane>
                                    <ToolbarMap />
                                    <MapHomeScreen 
                                        setMapRef={(map:any)=>{ setMapRef(map);boudsdevice(devices )}}  
                                        swiper={RfSwip} 
                                        showDrawControl={showDrawControl} 
                                        saveModal={(e:any)=>{setModalSave(e)}}
                                        select={(el:any)=>{ 
                                            selectDevice(el) 
                                        }} 
                                    />
                                </Pane>
                                {previewDevice?.active && tabs === "track" && <Pane>
                                    <DeviceDetailHorizontal 
                                        hsizes={hsizes} map={map} gotoAddresss={(d:any)=>{ map?.setView([d?.latitude ,d?.longitude])}}
                                        device={previewDevice?.device} 
                                        closed={()=>{ dispatch(setPreviewDevice({active:false , device: null}));setHSizes(["100%",50])  }} 
                                    />
                                </Pane> }
                            </SplitPane> 
                    </div> 
                </Pane>
            </SplitPane>  
            </div>
        </IonPage> 
)}
export default HomeSplitpane;





const SegmentStatus=({status,stsSize,changeStatus,devices}:any)=>{

    return(
        <IonSegment color='light' mode='ios' value={status} className="status-device "  onIonChange={(e:any)=>{changeStatus(e.detail.value!)}}>
        <IonSegmentButton mode='ios' value="all"  > 
            <IonText className="status-size" > <strong> ( {devices.length} ) <br/> {t('home_segment_all')} </strong></IonText>
            <IonText className="status-name" >
            <IonIcon icon={status==='all'?grid:gridOutline} /><br/>
            <IonLabel>  
                {t('home_segment_all')}
            </IonLabel>
            </IonText>  
        </IonSegmentButton>
        <IonSegmentButton mode='ios' value="move">
            <IonText className="status-size" > <strong> ( {stsSize(1,7)} )  <br/> {t('home_segment_move')} </strong></IonText>
            <IonText className="status-name" >
                <IonIcon icon={move} />  <br/>
                <IonLabel> 
                    {t('home_segment_move')}
                </IonLabel>
            </IonText>   
        </IonSegmentButton>
        <IonSegmentButton mode='ios' value="idle"> 
            <IonText className="status-size" > <strong> ( {stsSize(1,24)} )  <br/> {t('home_segment_idle')} </strong></IonText>
            <IonText className="status-name" >
                <IonIcon icon={status==='idle'? carSport:carSportOutline} /> <br/>
                <IonLabel> 
                    {t('home_segment_idle')}
                </IonLabel>
            </IonText>    
        </IonSegmentButton>
        <IonSegmentButton mode='ios' value="stop">
            <IonText className="status-size" > <strong> ( {stsSize(1,23)} )  <br/> {t('home_segment_stop')} </strong></IonText>
            <IonText className="status-name" >
                <IonIcon icon={status ==='stop'? stop:stopOutline} /> <br/>
                <IonLabel>  
                    {t('home_segment_stop')}
                </IonLabel>
            </IonText>    
        </IonSegmentButton>
    <IonSegmentButton mode='ios' value="offline">
            <IonText className="status-size" > <strong> ( {stsSize(0,1)} ) <br/> {t('home_segment_offline')}</strong></IonText>
            <IonText className="status-name" >
                <IonIcon icon={status==='offline'? cloudOffline:cloudOfflineOutline} /> <br/>
                <IonLabel> 
                    {t('home_segment_offline')}
                </IonLabel>
            </IonText>     
        </IonSegmentButton>
    </IonSegment>
    )
}


const RowDirection=({viewdistance , turncode , distanceCal ,properties ,feature}:any)=>{
    return(
        <IonRow  onClick={()=>{viewdistance(feature)}}  className="row-direction"  >
            <IonCol size="2" className="set-center" >
               <IonLabel color={properties.turn=== 4 ?"primary":"dark"} style={{fontSize:properties.turn=== 4 ?".7em": "1em"}}> {turncode(properties.turn , 2 )}  </IonLabel>
            </IonCol>
            <IonCol size="10" style={{borderBottom:"1px solid #DDD"}}> 
                <IonLabel  color={"dark"} style={{fontSize:".9em"}}>
                 {turncode(properties.turn , 1 )}  {distanceCal(properties.distance)}
                </IonLabel><br/>
                <IonLabel  color={"dark"}  style={{fontSize:".8em"}}>
                 {properties.name}
                </IonLabel>
            </IonCol>
       </IonRow>
    )
}

const ModalMapMobile=({setMapRef,setModalRef,selectDevice}:any)=>{
    const modal = useRef<HTMLIonModalElement>(null);
    const [isOpen , setIsOpen] = useState(false)
    const page = useRef(null);
  
    const [presentingElement, setPresentingElement] = useState<HTMLElement | null>(null);
    function dismiss() {
        setIsOpen(false)
        modal.current?.dismiss();
    }
    useEffect(()=>{ 
        if( window.location.pathname.indexOf("/home") < 0){
            dismiss()
        }else{
            setIsOpen(true)
        } 
    },[window.location.pathname])

    return(
    <IonModal  className="ion-hide-md-up"  isOpen={isOpen}   ref={modal}  onIonModalDidPresent={()=>{return setModalRef(modal)}}
        initialBreakpoint={0.05}  
        breakpoints={[0.05,0.1,0.2, 0.3,0.4,0.5,0.6,0.7, 0.8 , 0.9, 0.95,  1]}
        backdropDismiss={false}
        showBackdrop={false}
        keepContentsMounted={true}
        backdropBreakpoint={1}
    >
        <IonHeader className="ion-no-border " >
           <IonToolbar  >
              <IonButton size="small" fill="clear" mode="ios" slot="end" onClick={()=>{modal.current?.setCurrentBreakpoint(0.25)}}>
                 <IonIcon icon={closeCircleOutline} />
              </IonButton>
           </IonToolbar>
        </IonHeader>
        <IonContent> 
            <ToolbarMap  mode={"mobile"} />
            <MapHomeScreen  
                setMapRef={(map:any)=>{setMapRef(map); }}   
                select={(el:any)=>{ 
                   selectDevice(el) 
                }}  
                mode={"mobile"} 
            />
        </IonContent>
    </IonModal> 
    )
}

const Signout=()=>{ 
    const dispatch = useDispatch()
    // const [cookies, setCookie, removeCookie] = useCookies(['token']);

    useEffect(()=>{
        localStorage.removeItem("server")
        localStorage.removeItem("domain")
        localStorage.removeItem("profile")
        localStorage.removeItem("url")
        localStorage.removeItem("token")
        localStorage.removeItem("isSignin") 
        

        dispatch(setSignin(false))
    },[])
    return(<Redirect to="/signin" />)
}


interface iconsizes{
    iconSize:  PointExpression 
    iconAnchor: PointExpression 
    statusiconSize:PointExpression 
    statusiconAnchor: PointExpression 
} 
const iconsizes:iconsizes[] = [
    {
      iconSize: [40, 40], 
      iconAnchor: [15, 50],
      statusiconSize: [15, 15], 
      statusiconAnchor: [0, 58],  
    },
    {
      iconSize: [50, 50], 
      iconAnchor: [15, 60],
      statusiconSize: [15, 17], 
      statusiconAnchor: [0, 68],  
    },
    {
      iconSize: [60, 60],
      iconAnchor: [25, 70],
      statusiconSize: [20, 20], 
      statusiconAnchor: [5, 78],
    },
]

let max = 0
let playindex =0  
let count = 0
const MapHomeScreen=({setMapRef, swiper,showDrawControl,saveModal,select}:any)=>{ 
    const dispatch = useDispatch()
    const [zoom,setZoom] = useState(16)
    const backup :any= useSelector(getBackup)
    const devices:any =  useSelector(getDevices)
    const isGroup :any= useSelector(getIsGroupDevice) 
    const isMeasure :any= useSelector(getIsMeasure)   
    const [markerGroup, setMarkerGroup] = useState<MarkerType[]|[]>([])
    const [mapref , setMapref] = useState<MapType|null>(null)
    const [markerJsonGroup , setMarkerJsonGroup] = useState<GeoJson[]>()
    const fastTrack :any= useSelector(getFastTrack)  
    const routeIndex :any= useSelector(getRouteIndex)
    const [speed,setSpeed] = useState ({name:"4x",val:200})
    const [interval,setinterval] = useState(0) 
    const deviceGroup:DeviceAsGroup[] |any= useSelector(getDeviceGroup); 
    const [signin , setsignin] = useState<any>({username:""})
    const [dSetting , setDefaultSetting] = useState({
         showName :  "1" ,
         defaultMap :  "Longdo" ,
         groupDevice : false,
         googleMapEnabled : null
    })
    
    const randomIconSize = ()=>{ 
        let index = Math.floor(Math.random()* iconsizes.length) 
        return  iconsizes[zoom>17 ?2 : 1]
    }
    const createClusterCustomIcon = function (cluster:any) {
        return L.divIcon({
          html:  
            `<div class="cluster-inner set-center" >${ cluster.getChildCount()/2  }</div>  `, 
          className: `cluster-outer ${ cluster.getChildCount()/2 >5 ?'large':'small' }  `,
          iconSize: L.point(40, 40, true)
        });
    };

    useEffect(()=>{  
        console.log("deviceGroup ",deviceGroup)
        const getDefaultMap=async()=>{ 
            let setting = await userSetting()  
            setDefaultSetting(setting)
             
            let signinuser = await getStorage("sigin") 
            setsignin(signinuser)
        }
        getDefaultMap()
        if(markerGroup.length  > backup.length ){
           // placeMarkersInBounds()
        } 
        // parseToGeoJson() 
    },[backup])

    const play=(e:any)=>{  
        if(routeIndex < fastTrack?.list.length-2){ 
            let interval:any = setInterval(()=>{  
                count+=1 
                dispatch(setRouteIndex(count)) 
            },speed.val)
            setinterval(interval)
            dispatch(setPlayAction("playing"))
        }else{
            stop(null) 
            count=0
            dispatch(setRouteIndex(0))
        }
    }
    const pause=(e:any)=>{
        if(routeIndex<15){
            console.log("please wait ")
        }else{
            console.log("click pause ",e)
            dispatch(setPlayAction("pause")) 
            clearInterval(interval) 
        }
    }
    const stop=(e:any)=>{
        if(routeIndex<15){
            console.log("please wait ")
        }else{
            console.log("click stop ",e)
            count=0 
            dispatch(setPlayAction("stop")) 
            dispatch(setRouteIndex(0)) 
            clearInterval(interval)
        }
    } 
 
    return(<>  
     {fastTrack&&<PlayControl speed={speed} setSpeed={(e:any)=>{return setSpeed(e)}} play={(e:any)=>{return  play(e)}} pauseAction={(e:any)=>{return  pause(e)}} stopAction={(e:any)=>{return  stop(e)}}/> }
     <MapContainer 
        id="map-homescreen" className="mobile"
        center={[13.714538, 100.510928]}
        zoom={16}
        minZoom={1}  
        scrollWheelZoom={true}
        zoomControl={false}  
        style={{borderRadius:"15px"  ,marginBottom:".3rem"}}
        ref={async (comp)=>{   
            comp?.invalidateSize()  
            comp?.on('load',(e)=>{ })
            comp?.on("zoomlevelschange",(e)=>{
                comp?.invalidateSize() 
                setZoom(e.target._zoom)
            }) 
           
            if(comp){
                setMapref(comp)
                return setMapRef(comp)
            } 
        }} 
    > 
        {showDrawControl && <DrawControl saveModal={(e:any)=>{saveModal(e)}}/> }
       
        <SelectPlaceToStreetView /> 
        <LayersExpandControl defaultmap={dSetting?.defaultMap}  />
       {fastTrack &&  <RouteQuickTrack current={routeIndex} stop={stop} />  }
        <GeofencesMarker />
          {isMeasure && <MeasureDistanceFeature /> }
 
        { isGroup || backup.length > 200?
          <MarkerClusterGroup   
            iconCreateFunction={createClusterCustomIcon}  
            iconAnchor={[25, 70]}
            disableClusteringAtZoom={13}
            spiderfyDistanceMultiplier={2}
            spiderfyOnMaxZoom={false}  
            showCoverageOnHover= {false}
            zoomToBoundsOnClick={false} 
            ref={ (markers:any) =>{
                 markers?.on('clusterclick',(a:any)=> {
                    a.layer.zoomToBounds(); 
                }); 
            }
            }>
                 {signin?.username === "admin" ?    devices.filter((e:any)=> e.latitude !== null && e.latitude != "-").map((device:any,index:any)=> 
                       device?.show&& <MarkerIcon key={index} size={backup.length} swiper={swiper}
                               device={device} vehicle={device.name}  markersize={randomIconSize()}
                               select={()=>{return select(device)}}  
                            />)
                     :
                 
                    deviceGroup.map((group:any,index:any)=>
                     <FeatureGroup key={index}>
                        {group?.devices.filter((e:any)=> e.latitude !== null && e.latitude != "-").map((device:any,i:any)=>
                          device?.show&& <MarkerIcon key={i} size={backup.length} swiper={swiper}
                                device={device} vehicle={device.name}  markersize={randomIconSize()}  
                                select={()=>{return select(device)}}  
                            />
                        )}
                     </FeatureGroup>
                    )
                } 
            </MarkerClusterGroup>:
             <FeatureGroup> 
                {signin?.username === "admin" ?   devices.filter((e:any)=> e.latitude !== null && e.latitude != "-").map((device:any,index:any)=>  
                    device?.show && <MarkerIcon 
                        key={index} size={backup.length} 
                        swiper={swiper}
                        device={device} vehicle={device.name}  
                        markersize={randomIconSize()}  
                        select={()=>{return select(device)}}  
                     />)
                   :
                    deviceGroup.map((group:any,index:any)=>
                     <div key={index}>
                        {group?.devices.filter((e:any)=> e.latitude !== null && e.latitude != "-") .map((device:any,i:any)=>
                           device?.show && <MarkerIcon key={i} size={backup.length} swiper={swiper}
                                device={device} vehicle={device.name}  markersize={randomIconSize()}  
                                select={()=>{return select(device)}}  
                            />
                        )}
                     </div>
                    )
                }
                
             </FeatureGroup>
        }   
       
        <RouteMapSearch modalref={null}/>
    </MapContainer>
    </>
    )
} 

 
interface GeozoneStation{
    mapref: MapType | null  
    selectzone: (e: any)=> void
    addZone: ()=> void
    isadd: Boolean
    saveModal: (e: any)=> void
    deleteZone:(e: any)=> void 
}
const GeozoneStation=({mapref , selectzone ,addZone ,isadd,saveModal,devicesInZon,setSwRef , children}:any)=>{ 
    // const [devicesInZon,setDeviceInZone] = useState([])
    const [selectZone,setSelectZone] = useState(null) 
    
    useEffect(()=>{   
    },[devicesInZon]) 

    const slidechange=(e:SwiperType)=>{
        if(e?.activeIndex === 0){
            selectzone(null)
        }
    }

    return(
    <div style={{}} > 
      <Swiper 
        slidesPerView={1} 
        draggable={false}  
        onSlideChange={(e) => {slidechange(e);console.log('slide change',e)}}
        onSwiper={(swiper) => setSwRef(swiper)}
        allowTouchMove={false}
        style={{height:"100%"}}
      >
        <SwiperSlide  > 
               {children}
                {/* { keyword.length>0 ? 
                    <IonList  style={{overflowY:"scroll",width:"100%",height:"70vh"}}> 
                        {  geozoneSearch.map((zone:any,index:any)=>
                         <ToolbarGeofenceItem 
                            key={index} zone={zone} selected={(e:any)=>{getDeviceInGeo(e);  }}
                         /> 
                        )} 
                    </IonList>: 
                    children
                        
                }  */} 
        </SwiperSlide> 
        <SwiperSlide>
            <DevicesInZone devices={devicesInZon} zone={selectZone} slideBack={(e:any)=>{selectzone(null)}} />
        </SwiperSlide>  
      </Swiper> 
    </div>
    )
}

const DeviceDetailHorizontal=({device,gotoAddresss,closed , hsizes}:any)=>{
    const dispatch = useDispatch()
    const modal = useRef<HTMLIonModalElement>(null);
    const [routes , setRoutes] = useState<any>([])
    const [ionalert] = useIonAlert();
    const [openedit,setOpenEdit] = useState(false) 
    const [iontoast] = useIonToast(); 

    const confirmRemoveRoute=(name:any)=>{
        ionalert({
            mode:'ios',
            header: ` นำเส้นทางออก`,
            message: `ยืนยันที่จะนำเส้นทาง ${name}  ออกจากทะเบียน ${device?.name} หรือไม่ ?`,
            buttons: [
              {
                text: 'ยกเลิก',
                role: 'cancel', 
              },
              {
                text: 'ยืนยันลบเส้นทาง',
                role: 'confirm',
                handler: () => { 
                    removeRoutefromDevice()
                },
              },
            ], 
          })
    }
    const removeRoutefromDevice=async()=>{
        const body = {
            routeId: null ,  
            deviceId: device?.device_id
        }
        const res = await editDeviceRoute(body)
        console.log("setupRoute res" ,res )
        iontoast({
            message: res?.message,
            duration: 3000,
            position: "top",
            color: res?.result ? "success" : "danger" ,
            mode:"ios"
       });
       modal?.current?.dismiss()
        
       if(res.result){
        const devices = await allDevices({})  

        const result =  await devices.map((e:any)=> {
            return { ...e , ...{show : true} }
        }) 
        dispatch(setDevices(result))
        dispatch(setBackupDevices(result))     
       }
    }
    const setRouteToDevice=async(routeid:any)=>{
        const body = {
            routeId: routeid ,  
            deviceId: device?.device_id
        }
        const res = await editDeviceRoute(body)
        console.log("setupRoute res" ,res )
        iontoast({
            message: res?.message,
            duration: 3000,
            position: "top",
            color: res?.result ? "success" : "danger" ,
            mode:"ios"
          }); 
       modal?.current?.dismiss()
       if(res.result){
        const devices = await allDevices({})  
        const result =  await devices.map((e:any)=> {
            return { ...e , ...{show : true} }
        }) 
        dispatch(setDevices(result))
        dispatch(setBackupDevices(result))     
       }
    }
    const getroute=async()=>{
        let res = await allRoutesSet()
        console.log("home get allRoutesSet res ",res)
        setRoutes(res)
    }

    useEffect(()=>{
        // return(()=>{
        //     closed()
        // })
    },[])

    return(device && <div className='ion-padding-vertical splitpane pane' style={{overflowY:"scroll", height: hsizes[1]+"px"}} >
        <IonGrid>
            <IonRow> 
                <IonCol size='4'  > 
                    <IonLabel color={"dark"} className='flex-content'> 
                        <IonText  className='flex-content ion-no-margin'> 
                            <IonButton fill='clear' size='small' onClick={()=>{closed()}} > 
                                <IonIcon icon={closeCircle} />  
                            </IonButton>   
                            <strong>{device?.name2 ? device?.name2 : device?.name}</strong>
                        </IonText>
                        <strong > <br/>
                            <IonChip style={{fontSize:"smaller" }}  className={
                                device?.online === 0?"chip-offline": 
                                (device?.status === 7 && device?.speedLimit && device?.speed > device?.speedLimit) || 
                                (device?.status === 7 && !device?.speedLimit && device?.speed >90 ) ? "chip-overspeed":  
                                device?.status === 7 ?"chip-move": 
                                device?.status === 24 ? "chip-idle" : 
                                device?.status === 23 ? "chip-stop" :"chip-offline"}
                            > { <strong><small>{
                                device?.online === 0  ? t("home_status_offline").toUpperCase() :
                                (device?.status === 7 && device?.speedLimit && device?.speed > device?.speedLimit) || 
                                (device?.status === 7 && !device?.speedLimit && device?.speed >90 ) ? t("home_segment_overspeed") :   
                                device?.status === 7  ? t("home_segment_move").toUpperCase() : 
                                device?.status === 24 ? t("home_status_idle").toUpperCase() : 
                                device?.status === 23 ? t("home_status_stop").toUpperCase() : t("home_status_offline").toUpperCase()
                            } {device?.status_time}</small></strong>} 
                            </IonChip>
                            <small>{t('title_lastupdate')} {device?.event_stamp} </small>
                        </strong> 
                        <IonButton size='small' fill='clear' onClick={()=>{setOpenEdit(true)}} > <IonIcon icon={createOutline} />  </IonButton>
                    </IonLabel> 
                    <br/> 
                    {/* <DeviceStatusNameTime online={device?.online} status={device?.status} statusName={device?.status_name} statusTime={device?.status_time} /><br/>     */}
                 
                    <IonChip className='flex-content' onClick={()=>{ gotoAddresss(device) }} >
                        <IonIcon icon={locationSharp} color='dark' className='icon'/>
                        <small className='text-content'>{device?.address}</small>
                    </IonChip> &nbsp;&nbsp;
                      <IonChip className='flex-content' >
                        <IonLabel className='icon'> <FontAwesomeIcon icon={faClockRotateLeft} /> </IonLabel>   &nbsp;
                        <IonLabel className='text-content'  > <small> {t('title_lastupdate')} </small> </IonLabel>   &nbsp;
                        <small className='text-content'>{device?.event_stamp }</small>
                    </IonChip>  
                    <IonChip className='flex-content' color={
                      device?.speedLimit && device?.speed > device?.speedLimit ? "tertiary" :
                      !device?.speedLimit && device?.speed >  90 ? "tertiary" 
                      :"dark"
                    }> 
                        <IonIcon icon={speedometer} className='icon'/> 
                        <IonLabel className='text-content'  > <small> {t('home_speed')} </small> </IonLabel>   &nbsp;
                        <IonLabel className='text-bold-content'  ><strong>{ device?.speed  } {t('kmHUnit')} </strong></IonLabel>
                    </IonChip>&nbsp;&nbsp;
 
                    <IonChip className='flex-content'> 
                        <IonLabel className='icon'> <FontAwesomeIcon icon={faGasPump} /> </IonLabel>  &nbsp;
                        <IonLabel className='text-content' > <small> {t('home_fuel')} </small> </IonLabel>  &nbsp;
                        <IonLabel className='text-bold-content'><strong>{device?.fuel_liters} {t('lit')}</strong></IonLabel>
                    </IonChip>&nbsp;&nbsp;

                    <IonChip className='flex-content'> 
                        <IonLabel className='icon'> <FontAwesomeIcon icon={faSatelliteDish} /> </IonLabel>   &nbsp;
                        <IonLabel className='text-content' > <small> GPS/GSM </small> </IonLabel>  &nbsp;
                        <IonLabel className='text-bold-content'><strong> {device?.satellites}/{device?.fld_signalStrength} </strong></IonLabel>
                    </IonChip>&nbsp;&nbsp;

                    <IonChip className='flex-content'> 
                        <IonIcon className='icon' color='success' icon={batteryFull} /> &nbsp;
                        <IonLabel className='text-content' > <small> {t('home_battery')} </small> </IonLabel> &nbsp; 
                        <IonLabel className='text-bold-content'><strong> { device?.fld_engineLoad ? "Charging":"-"} </strong></IonLabel>
                    </IonChip>&nbsp;&nbsp; 

                    <IonChip className='flex-content'> 
                        <IonIcon className='icon' icon={thermometer} />   
                        <IonLabel className='text-content' > <small> {t('home_temp')} </small> </IonLabel>
                        <IonLabel className='text-bold-content'><strong>{device?.temperature } c</strong></IonLabel>
                    </IonChip>&nbsp;&nbsp;


                    <IonChip className='flex-content'> 
                        <IonLabel className='icon' color='danger'> <FontAwesomeIcon icon={faDoorOpen} /> </IonLabel>  &nbsp;
                        <IonLabel className='text-content' > <small> {t('home_pto')} </small> </IonLabel> &nbsp;
                        <IonLabel className='text-bold-content'><strong> {device?.closeOpenSensor === "1" ? "Door Open":"Door Close"}</strong></IonLabel>
                    </IonChip>&nbsp;&nbsp;

                    {device?.fld_driverID && <IonChip className='flex-content'> 
                        <IonIcon className='icon' color='success' icon={idCard} />
                        <IonLabel className='text-content' > &nbsp;
                        <small > {device?.fld_driverID} <br/> {device?.fld_driverMessage} </small> 
                        </IonLabel> 
                    </IonChip>} 

                    <div className="devider"></div>
                </IonCol> 
               <IonCol size='4' > 
                        <IonLabel color={"dark"}>  <strong>เชื่้อเพลิง</strong>  </IonLabel>
                        <IonRow   style={{fontSize:".9em"}} >
                       
                        <IonCol size="7" className='ion-no-padding' ><IonLabel color={"dark"}>  คงเหลือ </IonLabel></IonCol>
                        <IonCol size="2" className="ion-text-center ion-no-padding"> <IonLabel color={"dark"}>{device?.fuel_liters}  </IonLabel></IonCol>
                        <IonCol size="3" className="ion-text-right ion-no-padding" > <IonLabel color={"dark"}>  <small><strong>{t("lit")}</strong></small></IonLabel></IonCol>

                        <IonCol size="7" className='ion-no-padding' > <IonLabel color={"dark"}> สิ้นเปลืองขณะเคลื่อนที่</IonLabel></IonCol>
                        <IonCol size="2" className="ion-text-center ion-no-padding"  > <IonLabel color={"dark"}>{device?.consumptionMove}</IonLabel></IonCol>  
                        <IonCol size="3" className="ion-text-right ion-no-padding" > <IonLabel color={"dark"}> <small><strong>{t("kmLit")}</strong></small></IonLabel></IonCol>  

                        <IonCol size="7" className='ion-no-padding' > <IonLabel color={"dark"}> สิ้นเปลืองขณะจอดติด</IonLabel></IonCol>
                        <IonCol size="2" className="ion-text-center ion-no-padding"  > <IonLabel color={"dark"}>{device?.consumptionIdle}</IonLabel></IonCol>  
                        <IonCol size="3" className="ion-text-right ion-no-padding" > <IonLabel color={"dark"}><small><strong>{t("litHour")}</strong></small></IonLabel></IonCol>  

                        <IonCol size="7" className='ion-no-padding' > <IonLabel color={"dark"}> PTT</IonLabel></IonCol>
                        <IonCol size="5"   > <IonLabel color={"dark"}>  <strong> {device?.ptt} </strong> </IonLabel></IonCol>   
                        </IonRow>
                        <div className="devider"></div>
                        <IonLabel color={"dark"}><strong>Network Parameters</strong></IonLabel><br/>
                        <IonGrid>
                        <IonRow  style={{fontSize:".9em"}}>
                            <IonCol size="6" className='ion-no-padding' ><IonLabel color={"dark"}>GSM</IonLabel> </IonCol>
                            <IonCol size="6" className="ion-no-padding ion-text-right" > <IonLabel color={"dark"}>{device?.fld_signalStrength}</IonLabel></IonCol>  

                            <IonCol size="6" className='ion-no-padding' ><IonLabel color={"dark"}>Country</IonLabel> </IonCol>
                            <IonCol size="6" className="ion-no-padding ion-text-right" > <IonLabel color={"dark"}> Thailand </IonLabel></IonCol> 
                            
                            <IonCol size="6" className='ion-no-padding' ><IonLabel color={"dark"}>ความเร็วที่ยอมรับได้</IonLabel> </IonCol>
                            <IonCol size="6" className="ion-no-padding ion-text-right" > <IonLabel color={"dark"}>  {device?.speedLimit} {t("kmUnit")}</IonLabel></IonCol> 
  
                        </IonRow>
                        </IonGrid>

                        <ModalEditDevice openedit={openedit} setOpenEdit={(e:any)=>{setOpenEdit(e)}} /> 
                    </IonCol>
                <IonCol size="4" > 
                    <IonLabel color={"dark"} >  เส้นทางที่กำหนด </IonLabel>
                    {
                        device?.deviceRoute ?
                        <IonButton fill="outline" mode="ios" size="small" onClick={()=>{confirmRemoveRoute(device?.deviceRoute)}}  >
                           <IonLabel>{device?.deviceRoute}</IonLabel> &nbsp;
                           <IonIcon icon={trashBin} />
                        </IonButton> :
                        <IonButton fill="outline" mode="ios" size="small" id={"set-route-"+device?.device_id}> ยังไม่กำหนดเส้นทาง 
                            <IonModal ref={modal} mode="ios" trigger={"set-route-"+device?.device_id} onIonModalWillPresent={()=>{getroute()}}   > 
                                <IonContent className="ion-padding" >
                                     <IonList  >
                                        <IonListHeader> รายการเส้นทาง </IonListHeader>
                                        {
                                            routes.map((route:any, index:any)=> 
                                             <IonItem  key={index} >
                                               <IonLabel>
                                                 <strong>{route.name}</strong><br/>
                                                 <small>ระยะทาง {(route.allDistance/1000).toFixed(2)} ก.ม. </small>
                                               </IonLabel> <br/>
                                               <IonButtons>
                                                 <IonButton fill="clear"  onClick={()=>{setRouteToDevice(route.id)}} > <IonIcon icon={addCircleOutline} />  <IonLabel> กำหนดให้อุปกรณ์ </IonLabel></IonButton>
                                               </IonButtons>
                                             </IonItem>
                                            )
                                        }
                                     </IonList>
                                </IonContent> 
                            </IonModal>
                        </IonButton>
                    }<br/>
                    {device?.cardLicense?.licenseNumber && <>
                    <div className="devider"></div>
                        <IonLabel color={"dark"}>  <strong>ใบขับขี่</strong>  </IonLabel>
                        <IonRow   style={{fontSize:".9em"}} >
                            <IonCol size='5' className='ion-no-padding' > เลขบัตร:  </IonCol><IonCol size='7'className='ion-no-padding' > {device?.fld_driverID } </IonCol>
                            <IonCol size='5' className='ion-no-padding' > TYPE:  </IonCol><IonCol size='7'className='ion-no-padding' > {device?.cardLicense?.driverCardType } </IonCol>
                            <IonCol size='5' className='ion-no-padding'> License Number:  </IonCol><IonCol size='7' className='ion-no-padding'> {device?.cardLicense?.licenseNumber }</IonCol>
                            <IonCol size='5' className='ion-no-padding'> Office:  </IonCol><IonCol size='7' className='ion-no-padding'> {device?.cardLicense?.office } </IonCol>
                            <IonCol size='5' className='ion-no-padding'> Year Issue:  </IonCol><IonCol size='7' className='ion-no-padding'> {device?.cardLicense?.yearIssue }</IonCol>
                            <IonCol size='5' className='ion-no-padding'> Branch:  </IonCol><IonCol size='7' className='ion-no-padding'> {device?.cardLicense?.branch } </IonCol>
                            <IonCol size='5' className='ion-no-padding'> Gender:  </IonCol><IonCol size='7' className='ion-no-padding'> {device?.cardLicense?.gender }</IonCol>
                        </IonRow> 
                     </>} 
                     
                </IonCol>
            </IonRow>
        </IonGrid>
        </div>
    )
}

const DeviceSummaryPopup=({device,swiper}:any)=>{
    const dispatch = useDispatch()
    const [activity,setActvity] = useState<any>(null)
    const previewDevice :any= useSelector(getPreviewDevice)
    const [routes , setRoutes] = useState<any>([])
    const [iontoast] = useIonToast();
    const modal = useRef<HTMLIonModalElement>(null);
    const [ionalert] = useIonAlert();
    const [openedit,setOpenEdit] = useState(false) 

    const getActivity=async()=>{
        let result = await repCarsDailyUse({
            deviceId:  device?.device_id,
            start: new Date(moment().format("YYYY-MM-DD")+" 00:00:00").getTime() ,
            end: new Date(moment().format("YYYY-MM-DD")+" 23:59:59").getTime()
        })
        setActvity(result)
        console.log("repCarsDailyUse result ",result) 
} 
    useEffect(()=>{
        if(previewDevice?.active){ 
            console.log("preview active ")
            getActivity()
        } 
    },[previewDevice])

    const getroute=async()=>{
        let res = await allRoutesSet()
        console.log("home get allRoutesSet res ",res)
        setRoutes(res)
    }

    const setRouteToDevice=async(routeid:any)=>{
        const body = {
            routeId: routeid ,  
            deviceId: device?.device_id
        }
        const res = await editDeviceRoute(body)
        console.log("setupRoute res" ,res )
        iontoast({
            message: res?.message,
            duration: 3000,
            position: "top",
            color: res?.result ? "success" : "danger" ,
            mode:"ios"
          }); 
       modal?.current?.dismiss()
       if(res.result){
        const devices = await allDevices({})  
        await dispatch(setDevices(devices))
        await dispatch(setBackupDevices(devices))   
       }
    }

    const confirmRemoveRoute=(name:any)=>{
        ionalert({
            mode:'ios',
            header: ` นำเส้นทางออก`,
            message: `ยืนยันที่จะนำเส้นทาง ${name}  ออกจากทะเบียน ${device?.name} หรือไม่ ?`,
            buttons: [
              {
                text: 'ยกเลิก',
                role: 'cancel', 
              },
              {
                text: 'ยืนยันลบเส้นทาง',
                role: 'confirm',
                handler: () => { 
                    removeRoutefromDevice()
                },
              },
            ], 
          })
    }

    const removeRoutefromDevice=async()=>{
        const body = {
            routeId: null ,  
            deviceId: device?.device_id
        }
        const res = await editDeviceRoute(body)
        console.log("setupRoute res" ,res )
        iontoast({
            message: res?.message,
            duration: 3000,
            position: "top",
            color: res?.result ? "success" : "danger" ,
            mode:"ios"
       });
       modal?.current?.dismiss()
        
       if(res.result){
        const devices = await allDevices({})  
        await dispatch(setDevices(devices))
        await dispatch(setBackupDevices(devices))   
       }
    }
    

    return(device&&<div className="ion-padding device-summary-popup ion-hide-lg-down" >
        <div style={{width:"100%"}} className="ion-text-right" > 
           
        </div>
        <IonToolbar>
            <IonRow>
                <IonCol size="2.5" >
                <IonButton 
                    fill="clear" mode="ios"   
                    onClick={()=>{
                        swiper.slideTo(0); 
                        dispatch(setPreviewDevice({active:false , device: null}))
                    }}
                 > 
                    <IonIcon icon={closeCircleOutline} /> 
                </IonButton> 
                </IonCol>
                <IonCol size="9.5" >
                    <IonLabel style={{fontSize:"larger"}}><strong>{device?.name2 ? device?.name2 : device?.name}</strong> </IonLabel> <br/> 
                    <DeviceStatusNameTime online={device.online} status={device.status} statusName={device.status_name} statusTime={device.status_time} /><br/> 
                </IonCol> 
            </IonRow>
            
        </IonToolbar> 
        <DeviceSensorTable device={device} />
        <div className="devider"></div>
        <SummaryDevice device={device} edit={(e:any)=>{setOpenEdit(e)}} /> 
            <IonRow> 
                    <IonCol size="12"  className="set-center" style={{alignItems:"flex-start"}} >
                        <IonLabel> เส้นทางที่กำหนด </IonLabel>
                        {
                        device?.deviceRoute ?
                        <IonButton fill="outline" mode="ios" size="small" onClick={()=>{confirmRemoveRoute(device?.deviceRoute)}}  >
                           <IonLabel>{device?.deviceRoute}</IonLabel> &nbsp;
                           <IonIcon icon={trashBin} />
                        </IonButton> :
                        <IonButton fill="outline" mode="ios" size="small" id={"set-route-"+device?.device_id}> ยังไม่กำหนดเส้นทาง 
                            <IonModal ref={modal} mode="ios" trigger={"set-route-"+device?.device_id} onIonModalWillPresent={()=>{getroute()}}   > 
                                <IonContent className="ion-padding" >
                                     <IonList  >
                                        <IonListHeader> รายการเส้นทาง </IonListHeader>
                                        {
                                            routes.map((route:any, index:any)=> 
                                             <IonItem  key={index} >
                                               <IonLabel>
                                                 <strong>{route.name}</strong><br/>
                                                 <small>ระยะทาง {(route.allDistance/1000).toFixed(2)} ก.ม. </small>
                                               </IonLabel> <br/>
                                               <IonButtons>
                                                 <IonButton fill="clear"  onClick={()=>{setRouteToDevice(route.id)}} > <IonIcon icon={addCircleOutline} />  <IonLabel> กำหนดให้อุปกรณ์ </IonLabel></IonButton>
                                               </IonButtons>
                                             </IonItem>
                                            )
                                        }
                                     </IonList>
                                </IonContent> 
                            </IonModal>
                        </IonButton>
                    }
                    </IonCol>
            </IonRow>
          <div className="devider"></div>
          
          <strong>กิจกรรมวันนี้</strong><br/>
          <IonGrid>
            <IonRow  style={{fontSize:".9em"}}>
                <IonCol size="6" ><IonLabel color={"daker"}>{t("status_move")}</IonLabel> </IonCol>
                <IonCol size="6" className="ion-text-right" > <IonLabel color={"success"}><strong>{activity?.overview?.move} {t("hrs")}  </strong></IonLabel></IonCol> 
                
                <IonCol size="6" ><IonLabel color={"daker"}>{t("status_idle")}</IonLabel> </IonCol>
                <IonCol size="6" className="ion-text-right" > <IonLabel  color={"warning"}><strong>{activity?.overview?.idle} {t("hrs")}</strong></IonLabel></IonCol>
                
                <IonCol size="6" ><IonLabel color={"daker"}>{t("status_stop")}</IonLabel> </IonCol>
                <IonCol size="6" className="ion-text-right" > <IonLabel  color={"danger"}><strong>{activity?.overview?.stop} {t("hrs")}</strong></IonLabel></IonCol> 
               
                <IonCol size="6" ><IonLabel color={"daker"}>ค่าใช้จ่าย</IonLabel> </IonCol>
                <IonCol size="6" className="ion-text-right" > <IonLabel  color={"danger"}><strong>{activity?.overview?.allExpense?.toFixed(2)} {t("bath")}  </strong></IonLabel></IonCol>
            </IonRow>
            {
                device?.odoToday !== null && 
                <IonRow>
                    <IonCol size="6" ><IonLabel color={"daker"}>{t("distance_today")}</IonLabel> </IonCol>
                    <IonCol size="6" className="ion-text-right" > <IonLabel  color={"dark"}><strong>{device?.odoToday? device?.odoToday.toFixed(2):"-"} {t("kmUnit")}</strong></IonLabel></IonCol>
                </IonRow>
            }
          </IonGrid>
          <div className="devider"></div>
          <strong>เชื้อเพลิง</strong><br/>

          <IonRow   style={{fontSize:".9em"}} >
            <IonCol size="12" className="set-center" > 
              <img src="../../assets/img/fuel.svg"  style={{width:"80%"}} />
            </IonCol> 
            <IonCol size="7" ><IonLabel color={"daker"}>  ใช้ไป </IonLabel></IonCol>
            <IonCol size="2" className="ion-text-center"> <IonLabel color={"dark"}>{device?.fuel_liters}  </IonLabel></IonCol>
            <IonCol size="3" className="ion-text-right" > <IonLabel color={"dark"}>  <small><strong>{t("lit")}</strong></small></IonLabel></IonCol>

            <IonCol size="7" > <IonLabel color={"daker"}> สิ้นเปลืองขณะเคลื่อนที่</IonLabel></IonCol>
            <IonCol size="2" className="ion-text-center"  > <IonLabel color={"dark"}>{device?.consumptionMove}</IonLabel></IonCol>  
            <IonCol size="3" className="ion-text-right" > <IonLabel color={"dark"}> <small><strong>{t("kmLit")}</strong></small></IonLabel></IonCol>  

            <IonCol size="7" > <IonLabel color={"daker"}> สิ้นเปลืองขณะจอดติด</IonLabel></IonCol>
            <IonCol size="2" className="ion-text-center"  > <IonLabel color={"dark"}>{device?.consumptionIdle}</IonLabel></IonCol>  
            <IonCol size="3" className="ion-text-right" > <IonLabel color={"dark"}><small><strong>{t("litHour")}</strong></small></IonLabel></IonCol>  

            <IonCol size="7" > <IonLabel color={"daker"}> PTT</IonLabel></IonCol>
            <IonCol size="5"   > <IonLabel color={"dark"}>  <strong> {device?.ptt} </strong> </IonLabel></IonCol>   
          </IonRow>
          <div className="devider"></div> 
          <strong>Network Parameters</strong><br/>
          <IonGrid>
            <IonRow  style={{fontSize:".9em"}}>
                <IonCol size="6" ><IonLabel color={"daker"}>GSM</IonLabel> </IonCol>
                <IonCol size="6" className="ion-text-right" > <IonLabel color={"dark"}>{device?.fld_signalStrength}</IonLabel></IonCol>  

                <IonCol size="6" ><IonLabel color={"daker"}>Country</IonLabel> </IonCol>
                <IonCol size="6" className="ion-text-right" > <IonLabel color={"dark"}> Thailand </IonLabel></IonCol> 
            </IonRow>
         </IonGrid> 
         <div className="devider"></div>
         <strong>GPS Device Parameter</strong><br/>
          <IonGrid>
            <IonRow  style={{fontSize:".9em"}}>  
                <IonCol size="8" ><IonLabel color={"daker"}>GPS</IonLabel></IonCol>
                <IonCol size="4" className="ion-text-right" > <IonLabel color={"dark"}>{device?.satellites}</IonLabel></IonCol>  
 
                <IonCol size="8" ><IonLabel color={"daker"}>Battery</IonLabel></IonCol>
                <IonCol size="4" className="ion-text-right" > <IonLabel color={"dark"}>{device?.fld_sensorHigh}</IonLabel></IonCol>  

                <IonCol size="8" > <IonLabel color={"daker"}>Temperature</IonLabel></IonCol>
                <IonCol size="4" className="ion-text-right" > <IonLabel color={"dark"}>{device?.temperature}</IonLabel></IonCol>   
            </IonRow>
         </IonGrid>
 
         <ModalEditDevice openedit={openedit} setOpenEdit={(e:any)=>{setOpenEdit(e)}} />
    </div>)
}


export const ModalEditDevice=({openedit,setOpenEdit}:any)=>{
    const preview:PreveiewDevice |any= useSelector(getPreviewDevice)   
    const [pttPrices , setPttPrices] = useState<PttPriceType[]>([])
    const [ionalert,dimissAlert] = useIonAlert()
  
    const [toast] = useIonToast(); 
    const dispatch = useDispatch();
  
    const [name,setName] = useState("")
    const [name2,setName2] = useState("")
    const [phone,setPhone] = useState("") 
    const [expire,setExpire] = useState("") 
    const [note,setNote] = useState("")
  
    const [cIdle,setCIdle] = useState<any>(null)  
    const [cMove,setCMove] = useState<any>(null)  
    const [pttType , setPttType] = useState<any>(null)

    const [icon,setIcon] = useState(1) 
    const [idleTime , setIdleTime] = useState<any>(null)
    const [speeding , setSpeeding] = useState<any>(null)
    const [iontoast,dimisstoast] = useIonToast()
  
    const speedFormat=(speed:any)=>{ 
      return Number(speed).toFixed(0)
    }

    const fetchDevice=async(id:any)=>{
        const devices = await allDevices({}) //devicesAll({user_id: parseInt(user_id) }) 
        console.log("fetchDevice devices ",devices)
        await dispatch(setDevices(devices))
        await dispatch(setBackupDevices(devices))   
        let view = devices.find((e:any)=> e.device_id == id)
        dispatch(setPreviewDevice({active:true, device:view})) 
    }
  
    const pttpriceing=async()=>{
      const ptts = await getPttpricing()
      setPttPrices(ptts) 
    }
  
    useEffect(()=>{   
      pttpriceing()
      if(preview?.device){
        // console.log("preview?.device ",preview?.device)
        setName(preview?.device?.name ? preview?.device.name : "" )
        setName2(preview?.device?.name2 ? preview?.device.name2 : "" )
        setPhone(preview?.device?.phone_number ? preview?.device.phone_number : "" ) 
        setExpire(preview?.device?.expiration_date ? preview?.device.expiration_date : "")
        setCIdle(preview?.device?.consumptionIdle)
        setCMove(preview?.device?.consumptionMove)
        setPttType(preview?.device?.pttType)
        setIcon(preview?.device?.deviceIconId)
        setIdleTime(preview?.device?.minuteIdle)
        setSpeeding(preview?.device?.speedLimit) 
      }
    },[preview])
  
  
    const updateDInfo=async()=>{ 
      const expire = new Date(preview?.device.expiration_date).getTime()
      const body ={
        deviceId: preview?.device.device_id ,
        expire:  expire,
        phoneNumber: phone ,
        note:"",
        name , name2  ,
      }
      const result = await editDevice(body)
      const res = await setDeviceName({name2: name2 ,deviceId:  preview?.device.device_id  }) 
      console.log("setDeviceName res ",res)
      if(result.result){
        toast({
          message: 'Update device success!!',
          ...toastSuccess
        }); 
      }
      await fetchDevice(preview?.device?.device_id)
      setOpenEdit(false)
    }
  
    const updateConsumtion=async()=>{
        if(cIdle === null || cMove === null ||pttType === null ){
            ionalert({
                header: "กรุณาระบุข้อมูลให้ครบถ้วน !!" ,
                message: `อัตราการสิ้นเปลืองขณะจอดติดเครื่อง : ${cIdle?cIdle:'ยังไม่ระบุ'} , อัตราการสิ้นเปลืองขณะเคลื่อนที่ : ${cMove?cMove:'ยังไม่ระบุ'} , น้ำมัน : ${pttType?pttType:'ยังไม่ระบุ'} `,
                mode:"ios",
                buttons: [t('ok')]
            })
        }else{ 
            const prm = { 
                deviceId:preview?.device?.device_id ,
                consumtionIdle: cIdle ,
                consumpionMove: cMove,
                ptttype: pttType ,
            }
             const res = await  settingConsumption(prm)
             console.log("settingConsumption ", res)
            if(res?.result){ 
                setOpenEdit(false)
                await fetchDevice(preview?.device?.device_id) 
            } 
        }
    }

    const updateDeviceTypeId=async()=>{
        if(preview?.device?.device_id > 0){ 
          const res = await setDeviceIcon({
              deviceId: preview?.device?.device_id ,
              deviceIconId: icon
          })
          if(res){
            await fetchDevice(preview?.device?.device_id) 
            iontoast({
                message: 'Update device icon type succes !',
                ...toastSuccess
            });
          }else{
              iontoast({
                  message: 'Update device icon type Fail !',
                  ...toastFail
              });
          }
          console.log("changeSelection ",res)
      }else{ 
          iontoast({
              message: 'Please select device !!',
              ...toastFail
          });
      }
      }
      const updateIdleTime=async()=>{
        if(idleTime){
            const res = await setBehaviorIdleTime({idleTime:idleTime ,deviceId: preview?.device?.device_id})
            if(res.result){
                iontoast({
                    ...toastSuccess ,
                    message: 'Update device  Success!',
                    position:"middle",
                });
            }else{
                iontoast({
                    ...toastFail ,
                    message: 'Update device  Fail !!',
                    position:"middle",
                });
            }
        }
      }
      const updateSpeeding=async()=>{
        if(speeding){
            const res = await setBehaviorSpeeding({speed:speeding ,deviceId: preview?.device?.device_id})
            if(res.result){
                iontoast({
                    message: 'Update device  Success!',
                    ...toastSuccess
                });
            }else{
                iontoast({
                    message: 'Update device  Fail !!',
                    ...toastFail
                });
            }
        }
      }

      const clearInput=()=>{
        setSpeeding(null)
        setIdleTime(null)
        setCIdle(null)
        setCMove(null)
        setName("")
        setName2("")
        setPhone("")
        setExpire("")
        setNote("")
      }
  
    return(
      <IonModal isOpen={openedit} mode='ios' onIonModalDidDismiss={()=>{setOpenEdit(false);clearInput()}}  >
              <IonContent className='ion-padding' >
                <IonGrid>
                <IonRow   >
                  <IonCol size='10'  > 
                    <IonLabel className='summary-title'><strong> แก้ไข  {preview?.device?.name}</strong> </IonLabel> 
                  </IonCol>
                  <IonCol size='2' className='ion-text-right'> 
                    <IonButton mode='ios' fill='clear' size='small'  onClick={()=>{setOpenEdit(false)}}> <IonIcon icon={closeCircle} />  </IonButton>
                  </IonCol> 
                </IonRow><br/>
                
                
                <Tabs className={"home-screen-tabs "} style={{height:"fit-content"}}>
                    <TabList  >
                        <Tab style={{fontSize:".9em"}}> หลัก </Tab>
                        <Tab style={{fontSize:".9em"}}> ตั้งค่าอัตราสิ้นเปลือง </Tab>
                        <Tab style={{fontSize:".9em"}}> ตั้งค่าไอคอน </Tab> 
                        <Tab style={{fontSize:".9em"}}> พฤติกรรมการขับ </Tab> 
                    </TabList>
                    <TabPanel> 
                        <IonRow   > 
                            <IonCol size='6' > 
                                <IonLabel className='summary-title'> ชื่อเรียก </IonLabel> 
                                <div className='summary-val input'> 
                                <IonInput value={name} mode='ios' onIonChange={(e:any)=>{setName(e.detail.value!)}} ></IonInput>
                                </div> 
                            </IonCol>  
            
                            <IonCol size='6' > 
                                <IonLabel className='summary-title'> ชื่อรอง</IonLabel> 
                                <div className='summary-val input'> 
                                <IonInput value={ name2 } mode='ios'  onIonChange={(e:any)=>{setName2(e.detail.value!)}} ></IonInput>
                                </div> 
                            </IonCol> 
                            <IonCol size='6' > 
                                <IonLabel className='summary-title'> เบอร์โทร</IonLabel> 
                                <div className='summary-val input'> 
                                <IonInput value={phone} mode='ios' onIonChange={(e:any)=>{setPhone(e.detail.value!)}}  ></IonInput>
                                </div> 
                            </IonCol> 
                            <IonCol size='6' > 
                                <IonLabel className='summary-title'> วันหมดอายุ</IonLabel> 
                                <div className='summary-val input'> 
                                <IonInput value={expire} mode='ios' disabled onIonChange={(e:any)=>{setExpire(e.detail.value!)}}  ></IonInput>
                                </div> 
                            </IonCol>    
                            <IonCol size='6' > 
                                <IonLabel className='summary-title'> Note</IonLabel> 
                                <div className='summary-val input'> 
                                <IonTextarea value={note} mode='ios' disabled onIonChange={(e:any)=>{setNote(e.detail.value!)}}  ></IonTextarea>
                                </div> 
                            </IonCol>
                            <IonCol size='6'   >
                                <IonRow className='ion-justify-content-end'  >
                                <IonCol size='2.5' >
                                    <IonButton fill='outline' mode='ios' expand='block' size='small' onClick={()=>{setOpenEdit(false)}}>  {t("close")} </IonButton>
                                </IonCol>
                                <IonCol size='2.5' >
                                    <IonButton  mode='ios' expand='block' size='small' onClick={()=>{updateDInfo()}} > {t("save")}   </IonButton>
                                </IonCol>
                                </IonRow>
                            </IonCol> 
                        </IonRow> 
                    </TabPanel>
                    <TabPanel>
                    <IonRow>
                      {/* <IonCol size='4' className='set-center' style={{alignItems:"flex-start"}}> <IonLabel>ตั้งค่าอัตราสิ้นเปลือง</IonLabel> </IonCol>
                      <IonCol size='8' className='set-center'> <div style={{borderTop:"1px solid #DDD" , width:"100%" }} ></div> </IonCol> */}
                      <IonCol size="12" ></IonCol>
                      <IonCol size='6' > 
                        <div className='summary-val input consumption'> 
                            <IonInput value={cMove} mode='ios'  placeholder='อัตราสิ้นเปลืองขณะรถวิ่ง ลิตร/km' onIonChange={(e:any)=>{setCMove(e.detail.value!)}}  ></IonInput>
                        </div> 
                        <div className='summary-val input consumption'> 
                            <IonInput value={cIdle} mode='ios'  placeholder='อัตราสิ้นเปลืองขณะจอดติดเครื่องยนต์  ลิตร/ชั่วโมง' onIonChange={(e:any)=>{setCIdle(e.detail.value!)}}  ></IonInput>
                        </div>  
                        </IonCol>
                        <IonCol size='6' > 
                           {pttPrices && <IonRadioGroup value={pttType} onIonChange={(e:any)=>{setPttType(e.detail.value)}} >
                            {pttPrices.map((e,i)=>  <IonRadio key={i} value={e.pttType} labelPlacement='end' style={{marginRight:"1rem",fontSize:".9em"}}>{e.name}</IonRadio>) } 
                            </IonRadioGroup>}  
                        </IonCol>
                        <IonCol size='12' >
                            <IonButton 
                            fill='clear' mode='ios' 
                            expand='block' size='small' 
                            onClick={()=>{ 
                                updateConsumtion(); 
                                }}
                            >    Update  </IonButton> 
                        </IonCol>
                        </IonRow>
                        <IonRow>  
                      </IonRow> 
                    </TabPanel>
                    <TabPanel> 
                       <SettingIcon default={icon} deviceId={preview?.device?.device_id} setIcon={(id:any)=>setIcon(id)} />
                        <IonRow>
                            <IonCol size="12" >
                                <IonButton size="small" onClick={()=>{updateDeviceTypeId()}}>
                                    <IonLabel>{t("update")}</IonLabel>
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </TabPanel>
                    <TabPanel> 
                        <IonGrid>
                            <IonRow>
                                <IonCol size="12"> 
                                    <IonLabel color={"dark"} ><strong>กำหนดพฤติกรรมการขับ</strong></IonLabel> 
                                </IonCol>
                                <IonCol size="6" >
                                    <div className="input set-center-row" style={{padding:"0 .5rem 0 .5rem"}}>
                                        <IonText color={"secondary"} ><FontAwesomeIcon icon={faTachometerAltFast} /></IonText> &nbsp;
                                        <IonInput value={speeding} onIonChange={(e:any)=>{setSpeeding(e.detail.value)}} placeholder="ความเร็วที่ยอมรับได้" mode="ios" ></IonInput> &nbsp;
                                        <IonText color={"medium"} ><small>{t("kmHUnit")}</small></IonText>
                                    </div>
                                    <IonButton size="small"  onClick={()=>{updateSpeeding()}}>Update</IonButton>
                                </IonCol>
                                <IonCol size="6" >
                                    <div className="input set-center-row" style={{padding:"0 .5rem 0 .5rem"}}>
                                        <IonInput value={idleTime} onIonChange={(e:any)=>{setIdleTime(e.detail.value)}} placeholder="เวลาจอดติดเครื่องที่ยอมรับได้"  mode="ios" ></IonInput>&nbsp;
                                        <IonText color={"medium"} ><small>{t("min")}</small></IonText>
                                    </div>
                                    <IonButton size="small"  onClick={()=>{updateIdleTime()}}>Update</IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </TabPanel>
                </Tabs>
                    
                {/* ///setting/behavioridletime */}
                </IonGrid>
              </IonContent>
            </IonModal>
    )
  }
  
  const DateTimeRange=({start,end,setDateStart,setDateEnd ,loading,setLoading}:any)=>{
    const dispatch = useDispatch()
    const preview:any = useSelector(getPreviewDevice) 
    const fasttrack:any = useSelector(getFastTrack)
    const [open,setOpen] = useState(false)
    // const [loading,setLoading] = useState(false)
    
    const searchNewTrack=async()=>{ 
        dispatch(setRouteIndex(0))
        setLoading(true)
        console.log("searchNewTrack fasttrack ",fasttrack)
        const deviceId=   preview.device?.device_id
  
        let routeLists:any=[]
        let  rangeDate:any[] = []
        const begin = moment(start);
        const stop = moment(end);
        // const r1 = moment.range(begin, stop); 
        const diff = getRang(begin, stop,"day") // r1.diff('day') ; 
        let datecurrent = new Date(start)
        console.log("diff ",diff)
        for(let i=0; i<diff;i++){
          rangeDate = [...rangeDate , { start: new Date(moment(datecurrent).format("YYYY-MM-DD 00:00:00")).getTime() , end:  new Date(moment(datecurrent).format("YYYY-MM-DD 23:59:59")).getTime() }]  
          datecurrent =  new Date(moment(datecurrent).add(1,'day').format())
        } 
        let routeid = await sha1(`start:${start},end:${end},id:${deviceId},create:${moment().format()}`)   
        let body ={
          rangeDate ,
          deviceId: fasttrack?.deviceId
        } 
        let result = await getFasttrack(
            body , routeid
        )
        console.log("searchNewTrack result ",result)
    
        let tarck={   
            _id : routeid+"1" , 
            route: null ,
            list: result?.fastrack[0]?.list ,   
            bound: result?.fastrack[0]?.bound , 
            count: result?.fastrack[0]?.count,
            datetime_start: result?.fastrack[0]?.datetime_start,
            datetime_stop: result?.fastrack[0]?.datetime_stop ,
            speed_max: result?.fastrack[0]?.speed_max ,
            time_all: result?.fastrack[0]?.time_all,
            distance_all: result?.fastrack[0]?.distance, 
            ...fasttrack
        }
         
        tarck.distance_all = (result?.fastrack[0]?.distance/1000).toFixed(0)+" "+t('kmUnit')
        tarck.time_all =convertMsToTime(result.allDuration)
  
        await result?.fastrack.forEach(async(el:any,index:any) => {   
            routeLists.push(
                {   date: moment(el?.date).format("YYYY-MM-DD") , 
                    distance: (el?.distance/1000).toFixed(0)  ,
                    duration: convertMsToTime(el.duration) ,  
                    routeid: el?.routeid ,
                    bound: el?.bound,
                    list: el?.list ,// listInDate
                    route: el?.bound,
                },
            )
        });
        console.log("routeLists ",routeLists) 
        setLoading(false)  
        
        console.log("setQuickTrackList tarck ",tarck)
        dispatch(setPreviewDevice({active:false , device:null}))
        dispatch(setFastTrack(tarck))
        dispatch(setQuickTrackList(routeLists))
        }
  
        useEffect(()=>{ 
          console.log("loading  ",loading)
        },[loading])
  
    return( <> 
      <div id="click-trigger"  onClick={()=>{setOpen(true)}}
        style={{ width:"100%",fontSize:".8em",backgroundColor:"#EEE",padding:".5rem", borderRadius:"7px"}} >
        <IonRow>
            <IonCol size='5' className='set-center ' style={{padding:"0px"}}>
                <IonLabel>{moment(start).format("DD MMM YY HH:mm")}</IonLabel> 
            </IonCol>
            <IonCol size='1'className='set-center ' style={{padding:"0px"}}>  : </IonCol>
            <IonCol size='5' className='set-center  ' style={{padding:"0px"}}>
                <IonLabel>{moment(end).format("DD MMM YY HH:mm")}</IonLabel>
            </IonCol>
        </IonRow> 
        </div> 
        <IonPopover isOpen={open} 
            onIonPopoverDidDismiss={()=>setOpen(false)} 
            onIonPopoverWillDismiss={()=>setOpen(false)} 
            trigger="click-trigger" mode='ios' 
            className='popover-datetime-range'  
            triggerAction="click"
        >
          <IonContent class="ion-padding">
            <IonGrid>
              <IonRow>
                <IonCol size='6' >
                    <IonDatetime 
                        value={start} 
                        onIonChange={(e:any)=>{return setDateStart(e.detail.value)}} 
                    ></IonDatetime>
                </IonCol>
                <IonCol size='6' >
                    <IonDatetime 
                        value={end} 
                        max={moment().add(7,'day').format("YYYY-MM-DDTHH:mm:ss")} 
                        onIonChange={(e:any)=>{return setDateEnd(e.detail.value)}} 
                    ></IonDatetime>
                </IonCol>
              </IonRow>
            </IonGrid>
  
            <IonFooter>
               <IonGrid>
                 <IonRow>
                    <IonCol size="12"  className='ion-text-right' >
                      <IonButton onClick={()=>{
                        searchNewTrack();
                        setOpen(false)}}
                      > <IonLabel>  {t('save')} </IonLabel> 
                      </IonButton>
                    </IonCol>
                 </IonRow>
               </IonGrid>
            </IonFooter>
          </IonContent>
        </IonPopover>
        <IonLoading isOpen={loading} mode='ios' message={t('loading').toString()} onIonLoadingDidDismiss={()=>{setLoading(false)}} ></IonLoading>
     
      </>
    )
  }
 