import axios from 'axios' 
import config from "./api.config.json"
import i18next from 'i18next';
import { Device } from '@capacitor/device';
import { ToastOptions } from '@ionic/react';
import { StylesConfig } from 'react-select';
import moment from 'moment'; 
  
export const primary = "#ea383d"
export const secondary = "#134985"
 
const APP_URL =  config.mainUrl

const api = axios.create({
  baseURL:"http://10.1.10.152:3103/" , 
  headers:{
    crossdomain: true,
    "Access-Control-Allow-Origin": "*",
  }
})

const headers = {
  "Content-Type":"application/json",
  crossdomain: true,
  "Access-Control-Allow-Origin": "*",
} 

export const colourStyles: StylesConfig = {
  control: (styles: any) => ({ ...styles, backgroundColor: '#EFEFEF' ,padding:'2px', border: 'none' , borderRadius:'6px' , width: '100%' , zIndex: 9992}),
  option: (styles:  any , { data, isDisabled, isFocused, isSelected }: any) => {
      return {
          ...styles,
          backgroundColor: isDisabled
              ? undefined
              : isSelected
              ? data.color
              : isFocused
              ? 'rgba(139, 140, 142, 0.1)'
              : undefined,
          color: isDisabled
              ?  '#ccc'
              : isSelected
              ?   '#202021'
              : data.color,
          cursor: isDisabled ? 'not-allowed' : 'default', 
  
          ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? data.color : ( '#ccc')),
          },
      }
  }
}

export function getStorage(keyStore: any) {
    try {
      let value = localStorage.getItem(keyStore);
      return JSON.parse(value||'');
    } catch {
      return "";
    }
}

export function setCookie(cname:any, cvalue:any, exdays:any) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + JSON.stringify(cvalue) + ";" + expires + ";path=/";
}
export function getCookie(cname:any,) {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return JSON.parse(c.substring(name.length, c.length));
    }
  }
  return "";
}

export const toastFail:ToastOptions = { 
  duration: 3000,
  position: "top",
  color:"danger",
  mode:"ios"
}
export const toastSuccess:ToastOptions = { 
  duration: 3000,
  position: "top",
  color:"success",
  mode:"ios"
}
export const circleOption:ApexCharts.ApexOptions = {
  chart: {
    type: 'pie',
    dropShadow: {
      enabled: true,
      top: 0,
      left: 0,
      blur: 2,
      opacity: 0.03
    },
  },  
  legend: { show: false,},
  stroke: {   show: false,}, 
  responsive: [{
    breakpoint: 480,
    options: {
      chart: {
        width: 200 
      },
      legend: {
        position: 'bottom'
      }
    }
  }]
} 

export const setFavicon = (iconurl:any ) => {
  const cc:any = JSON.parse(localStorage.getItem("theme")|| JSON.stringify({logo: "/favicon.png" }))  
  console.log("setFavicon cc",cc?.logo)
  const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
  if (link) {
    link.href = iconurl ? iconurl:cc?.logo;
  } else {
    const newLink = document.createElement("link");
    newLink.rel = "icon";
    newLink.href = iconurl ? iconurl:cc?.logo;
    document.head.appendChild(newLink);
  }
};

export const getRang=(d1:any, d2:any, unit:"day"|"hour"|"minute"|"second"|"millisecond")=>{
  const diffTime = Math.abs(d2 - d1);
  switch(unit){
    case "day":
      return Number(Math.ceil(diffTime / (1000 * 60 * 60 * 24))); 
    case "hour" :
      return Number(Math.ceil(diffTime / (1000 * 60 * 60))); 
    case "minute":
      return  Number(Math.ceil(diffTime / (1000 * 60)));
    case "second":
      return  Number(Math.ceil(diffTime / 1000));
    case "millisecond":
      return  Number(diffTime)
    default:
      return  -1;
  }
}
 
async function getConfig(){
  let token =await getStorage("token")
  let server =await getStorage("server")
  let url =await getStorage("url") 
  let profile =await getStorage("profile") 
  let domain = await getStorage("domain")
  let sigin = await getStorage("sigin")

  return {token:token,server:server,url:url,userId: profile.id ,domain:domain ,profile:profile ,sigin}
}
 
export function setStorage(keyStore: any, valueStore: any) {
  try {
    localStorage.setItem(keyStore, JSON.stringify(valueStore));
 
  } catch {
      return "";
  }
}  

export const index=async()=>{  
  const domain = APP_URL   //"https://apitracking-universe.attg.cc"
  return await axios.post(`${domain}/${config.type}/v${config.version}/index`,{}).then((res)=>{
    console.log("index res ",res)  
    return res.data
  }).catch((err)=>{ 
    console.log("index err ",err)  
    return { server:[]}
  })
}

export const getWebConfig=async()=>{
  return await axios.get(`${"${conf.domain}/web/v1/config"}`,{
    headers:{  authorization:  config.authorization}
  })
}

export const drawCircle=async(lat:any, lng:any ,circleradius:any)=>{
  //setPosition({ lat:lat, lng:lng})
  var d2r = Math.PI / 180;   // degrees to radians
  var r2d = 180 / Math.PI;   // radians to degrees
  var earthsradius = 3963; 
  var points = 32; 
  console.log("catch redius ",circleradius)
  const radius = circleradius/100
 
   var rlat = (radius / earthsradius) * r2d;
   var rlng = rlat / Math.cos(lat * d2r); 
   var extp = new Array(); 
   for (var i=0; i < points+1; i++) // one extra here makes sure we connect the
   {
      var ex,ey = 0
      var theta = Math.PI * (i / (points/2));
      ex = lng + (rlng * Math.cos(theta)); // center a + radius x * cos(theta)
      ey = lat + (rlat * Math.sin(theta)); // center b + radius y * sin(theta)
       
      extp =  extp.concat([[ey,ex]]) 
   }
  return extp
} 


export const signinWeb=async({...props})=>{ 
  const conf = await getConfig()
  return await  axios.post(`${conf.domain}/web/v1/signin`,{
    account:  props.account ,
    username:   props.username ,
    password:   props.password 
  },{
    headers:{
      ...headers,
     authorization: config.authorization,
     uuid: props.uuid
    }
  }).then((res)=>{ 
    console.log("resSignin",res)
    return res.data
  })
}


export const guestSignin=async({url,guesttoken}:any)=>{  
  const conf = await getConfig()
  const info = await Device.getInfo()
  const uuid = await Device.getId()
  //props.domain+'/signin'
  let now = new Date()
  now.setHours(0)
  now.setMinutes(0)
  now.setSeconds(0)
  if(now.getFullYear()-543 < 2000){
    now.setFullYear(now.getFullYear()+543)
  }
  return await  axios.post(`${url}/guest/signin`,{},{
    headers:{
      authorization: config.authorization,
      guesttoken: guesttoken ,
      singinlong:  now.getTime()
    }
  }).then(res =>{ 
    return res.data
  })
  .catch(()=>{ return null })
}

export const signin=async(props:any)=>{ 
  console.log("signinprops ",props)
  const conf = await getConfig()
  const info = await Device.getInfo()
  const uuid = await Device.getId()
  //props.domain+'/signin'
  return await  axios.post(`${props.url}/signin`,{
    account:  props.account ,
    username:   props.username ,
    password:   props.password 
  }, {
      headers: {
         ...headers,
        "latitude": props.lat,
        "longitude": props.lon,
        "device" : props.mobileVendor,
        "device-model" : info.model , 
        "os": info.operatingSystem,
        "os-version": info.osVersion,
        "device-imei": uuid.identifier ,
        "language": i18next.language,
        "ip" : "124.0.0.1" ,
        "server" :  conf.server ,
        "authorization": config.authorization,
        "version":config.version ,
        "platform" :2, 
    }
  })
  .then((res)=>{ 
    console.log("resSignin",res)
    return res.data
  })
  .catch(()=>{ return null })
}

export async function getUserProfile({...props}) { 
  const conf = await getConfig()  
  let result = await axios.post( conf.url+'/getProfile',{ } ,{
    headers:{ 
      token :  conf.token ,
      language : i18next.language,
      authorization : config.authorization , 
      version : config.version
    }
  }).then(res=>{   
    return res.data
  }).catch(err=>{ 
    return {isMatch:false}
  })
  return result
}

export const login=async ({...props})=>{ 
  const conf = await getConfig()
  console.log("props ",props)
  const res =  await api.post(`${conf.url}/auth/login`,{
    account: props.account,
    username: props.username,
    password: props.password,
    uuid: props.uuid
  },{
    headers:{ 
      publicKey: config.authorization+'baef3fa25d39eb1ac6d2f983e4fbd85feed8da94'
    }
  }).then(async res =>{
    console.log("res data ",res)
    return res.data
  }).catch(err =>{
    console.log("err ",err) 
      return {result:false, detail:'User Not Found !!'} 
  }) 
  console.log(" res.data ",await res)
  return  await res
} 

export async function settingDetailById({...props}) {
  const conf = await getConfig()
  let result = await api.post(conf.server+"/settings/detailById",{deviceId:props.deviceId},{
    headers:{
      language: "th",
      token: `Bearer ${conf.token}` , 
    }
  }  ).then((res)=>{ 
    return res.data
  })
  return result
}

export async function setDeviceIcon({...props}) { 
  const conf = await getConfig() 
  const result = await axios.post( conf.url+'/setting/setdeviceiconid', {
    deviceId: props.deviceId ,
    deviceIconId:  props.deviceIconId,
  },{
    headers:{ 
      authenication : config.authorization ,
      token: conf.token ,  
      language : i18next.language ,
   }
  }).then(res=>{
      console.log("res",res)
      return  res.data.result
  }).catch(err =>{
      console.log("err ",err)
      return false
  })
 
  return result
}
 
export async function byIdType({...props}) {
  const conf = await getConfig()
  let result = await api.post(conf.server+"/settings/byId_Type",{deviceId:props.deviceId,value:props.value, feild:props.feild},{
    headers:{
      language: "th",
      token: `Bearer ${conf.token}` , 
    }
  }  ).then((res)=>{ 
    return res.data
  })
  return result
}

export async function setShowName({...props}) {
  const conf = await getConfig()
  let result = await api.post(conf.server+"/settings/setShowName",{user_id:props.user_id,showName:props.view},{
    headers:{
      language: "th",
      token: `Bearer ${conf.token}` , 
    }
  }  ).then((res)=>{ 
    return res.data
  })
  return result
} 
 
export const editDevice=async({...body})=> {
  console.log("editDevice  " , body)
  const conf = await getConfig()   
   return await axios.post( `${conf.domain}/web/v1/edit/device`, body ,{
    headers:{
      authenication : config.authorization ,
      token :  conf.token ,
      language : i18next.language ,
      version : config.version
    } 
  }).then(res =>{
    console.log("editDevice ", res)
    return res.data
  }).catch(err =>{  
    console.log("editDevice ", err)
    return {result:false}}
  )
}
export const editDeviceRoute=async({...body})=> {
  console.log("editDevice  " , body)
  const conf = await getConfig()   
   return await axios.post( `${conf.domain}/web/v1/edit/deviceroute`, body ,{
    headers:{
      authenication : config.authorization ,
      token :  conf.token ,
      language : i18next.language ,
      version : config.version
    } 
  }).then(res =>{
    console.log("editDeviceRoute ", res)
    return res.data
  }).catch(err =>{  
    console.log("editDeviceRoute ", err)
    return {result:false}}
  )
}


export const setDeviceName=async({...props})=> {
  const conf = await getConfig()   
  const res = await axios.post( `${conf.domain}/${config.type}/v${config.version}/setting/setdevicename`, {
    name2: props.name2 ,
    deviceId: props.deviceId
  },{
    headers:{
      authenication : config.authorization ,
      token :  conf.token ,
      language : i18next.language ,
      version : config.version
    }
  }).then(res =>{
    console.log("res ",res)
    return res.data.result
  }).catch((err )=>{
    console.log("err ",err)
    return false
  })

  return res;
}

export async function logout({...props}){
  const conf = await getConfig()
  let result = await api.post(conf.server+"/auth/logout",{},{
    headers:{
      language: "th",
      token: `Bearer ${conf.token}` , 
    }
  }  ).then((res)=>{ 
    return res.data
  })
  return result
 }

 export const allDevices=async ({...props})=>{  
  const conf = await getConfig()   
  const url =  `${conf.domain}/web/v1/home` //conf?.sigin?.username === "admin" ? `${conf.domain}/web/v1/home` :`${conf.domain}/${config.type}/v${config.version}/home`
  let devices = await axios.get(url,{
    headers:{
      "language":i18next.language   ,
      "token" : conf.token , 
      authorization : config.authorization , 
      version : config.version ,
      ...headers
      }		 
  }).then((res) =>{   
    return  res.data
  }).catch((err)=>{
    console.log("/home err " , err)
    return []
  })
  return devices 
} 

export const deviceByGroup=async ({...props})=>{  
  const conf = await getConfig()   
  const url =  `${conf.domain}/web/v1/devicebygroup`  
  let devices = await axios.get(url,{
    headers:{
      "language": i18next.language ,
      "token" : conf.token , 
      authorization : config.authorization , 
      version : config.version , 
      }		 
  }).then((res) =>{    
    return   {status:200 , devices: res.data} 
  }).catch((err)=>{ 
    return {devices:[], status: 402}
  })
  return devices 
} 



export const groupDevices = async()=>{
  const conf = await getConfig()
  return   await api.post(`${conf.domain}/web/v1/groupdevices`,{ },{
    headers: { token:`${conf.token}`, authorization : config.authorization }
  }).then((res)=>{
    console.log("groupDevices res ",res)
    return res.data
  }).catch((err)=>{ 
    console.log("groupDevices err ",err)
    return [] 
  }) 
} 
export const addGroup = async({name}:any)=>{
  const conf = await getConfig()
  return   await api.post(`${conf.domain}/web/v1/groupdevice/add?groupname=${name}`,{ },{ 
    headers: { 
      token:`${conf.token}`, 
      authorization : config.authorization 
    }
  }).then((res)=>{
    console.log("groupDevices res ",res)
    return res.data
  }).catch((err)=>{ 
    console.log("groupDevices err ",err)
    return [] 
  }) 
} 

export const editGroup = async({groupid,name}:any)=>{
  const conf = await getConfig()
  return   await api.post(`${conf.domain}/web/v1/groupdevice/edit?groupname=${name}&groupid=${groupid}`,{ },{ 
    headers: { 
      token:`${conf.token}`, 
      authorization : config.authorization 
    }
  }).then((res)=>{
    console.log("editGroup res ",res)
    return res.data
  }).catch((err)=>{ 
    console.log("editGroup err ",err)
    return [] 
  }) 
} 

export const delGroup = async({groupid}:any)=>{
  const conf = await getConfig()
  return   await api.post(`${conf.domain}/web/v1/groupdevice/del?groupid=${groupid}`,{ },{ 
    headers: { 
      token:`${conf.token}`, 
      authorization : config.authorization 
    }
  }).then((res)=>{
    console.log("groupDevices res ",res)
    return res.data
  }).catch((err)=>{ 
    console.log("groupDevices err ",err)
    return [] 
  }) 
} 

// export const  deviceOutGroup= async({groupid}:any)=>{
//   const conf = await getConfig()
//   return   await api.post(`${conf.domain}/web/v1/deviceoutgroup?groupid=${groupid}`,{ },{ 
//     headers: { 
//       token:`${conf.token}`, 
//       authorization : config.authorization 
//     }
//   }).then((res)=>{
//     console.log("groupDevices res ",res)
//     return res.data
//   }).catch((err)=>{ 
//     console.log("groupDevices err ",err)
//     return [] 
//   }) 
// } 
export const  deviceInGroup= async({groupid}:any)=>{
  const conf = await getConfig()
  return   await api.post(`${conf.domain}/web/v1/deviceingroup?groupid=${groupid}`,{ },{ 
    headers: { 
      token:`${conf.token}`, 
      authorization : config.authorization 
    }
  }).then((res)=>{ 
    console.log("deviceInGroup res ",res)
    return res.data
  }).catch((err)=>{ 
    console.log("deviceInGroup err ",err)
    return [] 
  }) 
} 
export const  deviceUpdateGroup= async({groupid,deviceId}:any)=>{
  const conf = await getConfig()
  return   await api.post(`${conf.domain}/web/v1/device/updategroup`,{ },{ 
    headers: { 
      token:`${conf.token}`, 
      authorization : config.authorization 
    },
    params:{
      groupid: groupid ,
      deviceId: deviceId
    }
  }).then((res)=>{
    console.log("groupDevices res ",res)
    return res.data
  }).catch((err)=>{ 
    console.log("groupDevices err ",err)
    return [] 
  }) 
} 
  
export const  deviceRemovegroup= async({groupid,deviceId}:any)=>{
  const conf = await getConfig()
  return   await api.post(`${conf.domain}/web/v1/device/removegroup?groupid=${groupid}&deviceId=${deviceId}`,null,{ 
    headers: { 
      token:`${conf.token}`, 
      authorization : config.authorization 
    },
    // params:{
    //   groupid: groupid ,
    //   deviceId: deviceId
    // }
  }).then((res)=>{
    console.log("deviceRemovegroup res ",res)
    return res.data
  }).catch((err)=>{ 
    console.log("deviceRemovegroup err ",err)
    return [] 
  }) 
} 

export const devicetype = async({...props})=>{
  const conf = await getConfig()
  return   await api.post(`${conf.domain}/web/v1/devicetype`,{ },{
    headers: { token:`${conf.token}`}
  }).then((res)=>{
    return res.data
  }).catch((err)=>{ 
    console.log("devicetype err ",err)
    return [] 
  }) 
} 

export const devicesAll = async({...props})=>{
  const conf = await getConfig()
  const res = await api.post(`${conf.server}/devices/all`,{
    user_id: parseInt(props.user_id) , 
  },{
    headers: { token:`Bearer ${conf.token}`}
  }).then(res=>{ 
    return res.data
  }) 
  return await res
}

export async function geofences({...props}){
  const conf = await getConfig()
  let list = await  axios.get(`${conf.domain}/${config.type}/v${config.version}/geometry` ,{
    headers: {
      "language": i18next.language   ,
      "token" : conf.token ,
      "authenication": config.authorization,
      "version": config.version
    }
  }).then(res => { 
     return res.data 
  })
  return list
}

 

export const geofenceAll = async({...props})=>{
  console.log("props ",props)
  const conf = await getConfig()
  const res = await api.post(`${conf.server}/geofences/all`,{
    user_id: parseInt(props.user_id) , 
  },{
    headers: { token:`Bearer ${conf.token}`}
  }).then(res=>{ 
    return res.data
  }) 
  return await res
}

export async function iconList({...props}) {
  const conf = await getConfig()
  let icons = api.post(`${conf.server}/geofences/icon`,{
        "user_id": props.user_id 
    },{
      headers:{ 
        token: `Bearer ${conf.token}` , 
      }
    }).then((res) =>{
      console.log("res ",res )
      return res.data
    }).catch((err)=>{
      console.log("err ",err )
      return []
    })
    return icons
  }

  export async function iconPlaces() {
    const conf = await getConfig()
    let icons = axios.get(conf.url + "/iconlist",{
      headers:{
        language: i18next.language,
          token: conf.token,
          authenication : config.authorization , 
          version : config.version
      }
    }).then((res) =>{
      return res.data
    }).catch((err)=>{
      console.log("err ",err )
      return []
    })
    return icons
  }

  export async function getFilters({...props}) { 
    return {
      general: {
        name: "General",
        translate:{th:"ทั่วไป" , en:"General" },
        choices: [
          { type: "icon", card: true, detail: false },
          { type: "name", card: true, detail: false },
          { type: "emei", card: true, detail: false },
          { type: "status_name", card: true, detail: false },
          { type: "update_time", card: true, detail: false },
          { type: "address", card: true, detail: false },
          { type: "navigate", card: true, detail: false },
          { type: "expired_date", card: true, detail: false },
          { type: "phone_number", card: true, detail: false },
        ],
      },
      sensors:{
        name: "Sensors",
        translate:{ th:"เซนเซอร์" , en:"Sensor" },
        choices: [
          { type: "sensor_fuel", card: true, detail: false },
          { type: "sensor_speed", card: true, detail: false },
          { type: "sensor_battery", card: true, detail: false },
          { type: "sensor_GSM", card: true, detail: false },
          { type: "sensor_GPS", card: true, detail: false },
          { type: "sensor_PTO", card: true, detail: false },
          { type: "sensor_mile", card: true, detail: false },
          { type: "sensor_temperature", card: true, detail: false },
          { type: "driver_card_name", card: true, detail: false },
          { type: "driver_card_number", card: true, detail: false },
        ],
      },
      options:{
        name: "Options",
        translate:{ th:"ตัวเลือก" , en:"Options" },
        choices: [
          { type: "quick_track", card: true, detail: false },
          { type: "share", card: true, detail: false },
          { type: "messages", card: true, detail: false },
          { type: "command", card: true, detail: false },
        ],
      },
    }

  }

export async function deviceIconId({...props}) {  
    let server =await getStorage("serverName")
    let host='https://api-app-s1.andamantracking.dev/app/v1'
    if(server === "server1"){
      host='https://api-app-s1.andamantracking.dev/app/v1'
    }else if(server === "server2"){
      host='https://api-app-s2.andamantracking.dev/app/v1'
    }else if(server === "server3"){
      host='https://api-app-s3.andamantracking.dev/app/v1'
    }else{ host='https://api-app-s1.andamantracking.dev/app/v1'}
  
    let result = await axios.get( host+'/getDeviceIcon',{
      headers:{
        authorization:"63e305ae68ef96ec05c595c7da9005b596af52ea5e42c278e558be28cbdbc10bd0b6c5e1b6aaf13d3c306ce33d6ae6e74bb372a41d6aa70702f4f124edcffaa9",
        token: props.token, 
        deviceId: props.deviceId , 
        language : props.lang ,
     }
    }).then(res=>{ 
        return res.data
    }).catch(err =>{
        console.log("err ",err)
        return {result:false}
    })
    return result
  }

export const devices=async ({...props})=>{  
  const conf = await getConfig()
    console.log("props ", props)
    let device = await axios.post(props.apiHost+'/devices/all', 
    JSON.stringify({  
      user_id: 9641,
      token:"Local Web",
      device_id: 0
    }),
      {  
        headers:{ 
            "user_id": props.user_id,
            "token": conf.token, 
            "Content-type": "application/json",
            "accept": '*/*', 'cache-control': 'no-cache', 'postman-token': '699d0940-5c89-4212-8aa5-6ca483bb08e9',
            "authorization" : "93e305ae68ef96ec05c595c7da9005b596af52ea5e42c278e558be28cbdbc10bd0b6c5e1b6aaf13d3c306ce33d6ae6e74bb372a41d6aa70702f4f124edcffaa9",
          } 
        }).then(async (res) =>{ 
          console.log("res.data ",res)
          let list = await res.data.list
          return list
            // console.log("response ",response.data) 
        })

    
    return [] 
  }

  export const calDistance=(cord1:any , cord2:any )=> {
   // console.log('cord ', cord1 , cord2)
    if (cord1.lat === cord2.lat && cord1.lng === cord2.lng) {
      return 0;
    }

    const radlat1 = (Math.PI * cord1.lat) / 180;
    const radlat2 = (Math.PI * cord2.lat) / 180;

    const theta = cord1.lng - cord2.lng;
    const radtheta = (Math.PI * theta) / 180;

    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

    if (dist > 1) {
      dist = 1;
    }

    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
   // dist = dist * 1.609344; //convert miles to km
    
    return dist 
  } 

  export const polygonArea=(lats:any,lons:any)=> {  
    //calculate area for irregular polygon
    var sum1 = 0;
    var sum2 = 0;  
    for (var t = 0; t < lats.length; t++) { 
        if (t != lats.length - 1) { 
            sum1 +=  Math.sin(lats[t] ) *  Math.sin(lons[t+1] );
            sum2 +=  Math.sin(lons[t] ) * Math.sin(lats[t+1] );  
        } else { 
            sum1 += Math.sin(lats[t] ) * Math.sin(lons[0] );
            sum2 += Math.sin(lons[t] ) * Math.sin(lats[0] );
        }
    }

    var area = (sum1 - sum2) / 2.0;
    return Math.abs(area) ; 
  }


export const getTileProviders=async()=>{
  const conf = await getConfig() 
  const respond =  await  await axios.post( `${conf.domain}/${config.type}/v${config.version}/tileproviders`,{},{ 
    headers: { 
      authenication: config.authorization,
      language: i18next.language ,
      token: conf.token,
      version: config.version
    }
  }).then(res =>{   
        return res.data
     
  }).catch(err =>{
    return  []
  })

  return respond
}



export async function reportBehaviorDevice({...body}:any) {
  console.log("body ",body)
  const conf = await getConfig()   
  const result = await axios.post( `${conf.domain}/app/v2-2/report/behaviorDevice`,body ,
  {
   headers: {
     token: conf.token ,
     authenication : config.authorization ,
     "Accept-Language": i18next.language
   }
 } )
 .then((res)=>{
   return res.data
 }).catch(err =>{
   console.log("errr ",err)
   return []
 })
 return result
}

  export async function historyRoute({...props}){ 
    const conf = await getConfig(); 
    let result = await axios.post(`${conf.domain}/${config.type}/v${config.version}/report/usage`,{
     deviceId: props.deviceId,
     date: props.dateRoute,
     start: props.start,
     end: props.end,
     dateEnd: props.dateEnd,
    },{
     headers: {
       authenication: config.authorization,
       language: i18next.language,
       token: conf.token ,
       version: config.version,
     },
    }).then(res=>{ 
      console.log("historyRoute res ",res)
      return res.data
    }).catch((err)=>{ 
      console.log("historyRoute err ",err)
      return false
    })
    return result
  } 
  
  export async function  repFuelUsaged({...props}){ 
    console.log("propsa ", props)
    const conf = await getConfig(); 
    let result = await axios.post(`${conf.domain}/app/v2-2/report/fuelusage`,props.body,{
        headers: {
          "language": i18next.language ,
          "token" :  conf.token ,
          "authenication" : config.authorization,
          "version": config.version,
          "start": props.start ,
          "end":props.header ,
          "fuelprice": props?.fuelprice ,
          "rate": props?.rate
        },
    }).then(res=>{  
      console.log("repFurlUsage  ", res)
      return res.data
    }).catch((err)=>{ 
      console.log("err drepFuelUsa  ", err)
      return { report:[] , chart: []}
    })
   return result
  }

  export async function pastRoute({...props}){ 
    const conf = await getConfig() 
   let result = await axios.post(`${conf.domain}/${config.type}/v${config.version}/report/usage`,{
    deviceId: props.deviceId,
    date: props.dateRoute,
    start: props.start,
    end: props.end,
    dateEnd: props.dateEnd,
   },{
    headers: {
      authenication: config.authorization,
      language:"th",
      token: conf.token ,
      version: config.version,
    },
   }).then(res=>{ 
     return res.data
   }).catch((err)=>{ 
     return false
   })
   return result
 } 

export  async function routeHistory({...props}){ 
  const conf = await getConfig() 
  return await axios.post(`${conf.domain}/${config.type}/v${config.version}/report/usage`,
  {
    deviceId: props.deviceId,
    date: props.dateRoute,
    start: props.start,
    end: props.end,
    dateEnd: props.dateEnd,
  },{
    headers:{
      authenication: config.authorization,
      language: i18next.language,
      token: conf.token ,
      version: config.version,  
    }
  } ).then((res)=>{
    console.log("res ", res)
    return res.data
  }).catch((err)=>{
    console.log('er ',err)
    return {list:[]}
  })
}
export  async function passRoute(body:any){  
  const conf = await getConfig() 
  return await axios.post(`${conf.domain}/web/v1/passroute `,body,{
    headers:{
      authenication: config.authorization,
      language: i18next.language,
      token: conf.token ,
      version: config.version,  
    }
  }).then((res)=>{
    console.log("passRoute res ", res)
    return res.data
  }).catch((err)=>{
    console.log('passRoute err ',err)
    return {list:[]}
  })
}

 export async function historyById({...props}){ 
  const conf = await getConfig() 
  let history = await api.post(conf.server+"/history/byDeviceId", props.payload ,{
    headers:{
      language: "th",
      token: `Bearer ${conf.token}` , 
    }
  }  ).then((res)=>{
    console.log("historyById ", res)
    return res.data
  })
  return history
 }

 export async function userSetting() {
  const conf= await getConfig()  
  const res = await axios.post(`${conf.domain}/${config.type}/v${config.version}/getusersettingafterlogin`, {},{
    headers:{
      authenication :  config.authorization,
      token :  conf.token ,
      language : i18next.language ,
      version : config.version
    }
  }).then(res =>{  
    return res.data
  }).catch(err =>{
    return { 
      groupDevice: false,
      showname:0,
      defaultMap: 'longdo'
    }
  })
  return res
}


export async function  setdefaultMap({...props}) {  
  const conf = await getConfig()   
  const result = await axios.post( `${conf.domain}/${config.type}/v${config.version}/setting/setdefaultmap`, {},{
    headers:{ 
      authenication : config.authorization ,
      token: conf.token ,  
      language : i18next.language ,
      defaultMap: props.defaultMap
   }
  }).then(res =>{
    console.log("setDefaultMap ",res)
    return res.data.result
  }).catch(err=>{
    console.log("err ",err)
    return false
  })

  return result
}

export async function  setGroupDevices({...props}) { 
  console.log("propsgroupDevice ", props)
  const conf = await getConfig()   
  const result = await axios.post(`${conf.domain}/${config.type}/v${config.version}/setting/groupdevice`, {
    groupDevice: props.groupDevice
  },{
    headers:{ 
      authenication : config.authorization ,
      token: conf.token ,  
      language : i18next.language ,
   }
  }).then(res =>{
    console.log("groupdevice ",res)
    return res.data.result
  }).catch(err=>{
    console.log("err ",err)
    return false
  })

  return result
}

export async function setMarkerName({...props}) { 
  const conf = await getConfig()   
  const result = await axios.post(`${conf.domain}/${config.type}/v${config.version}/setting/setshowmarkername`, {
    deviceId: props.deviceId ,
    isShow: props.show
  },{
    headers:{ 
      authenication : config.authorization ,
      token: conf.token ,  
      language : i18next.language , 
   }
  }).then(res=>{ 
      return  res.data.result
  }).catch(err =>{
      console.log("err ",err)
      return false
  })
 
  return result
} 

export async function setBehaviorIdleTime({idleTime,deviceId}:any) {
  console.log("setBehaviorIdleTime ",idleTime ," deviceId ", deviceId)
  const conf = await getConfig()   
  const result = await axios.post( `${conf.domain}/${config.type}/v${config.version}/setting/behavioridletime`,{} ,
   {
    params:{ idleTime: idleTime ,deviceId:  deviceId},
    headers: {
      token: conf.token ,
      authenication : config.authorization ,
    }
  } ).then((res)=>{
    console.log("setBehaviorIdleTime res ", res)
    return res.data
  }).catch((err)=>{
    console.log("err ",err)
    return {result: false}
  })
  return result
}

export async function setBehaviorSpeeding({speed,deviceId}:any) {
  console.log("setBehaviorSpeeding ",speed ," deviceId ", deviceId)
  const conf = await getConfig()   
  const result = await axios.post( `${conf.domain}/${config.type}/v${config.version}/setting/behaviorspeeding`,{} ,
   {
    params:{ speed: speed ,deviceId: deviceId},
    headers: {
      token: conf.token ,
      authenication : config.authorization ,
    }
  } ).then((res)=>{
    console.log("setBehaviorSpeeding res ", res)
    return res.data
  }).catch((err)=>{
    console.log("err ",err)
    return {result: false}
  })
  return result
}



 export async function getSessions({...props}){
  const conf = await getConfig() 
  let history = await api.post(conf.server+"/auth/sessions",{userId: props.userId} ,{
    headers:{
      language: "th",
      token: `Bearer ${conf.token}` , 
    }
  }  ).then((res)=>{ 
    return res.data
  })
  return history
 }

 export async function revokeSession({...props}){
  const conf = await getConfig() 
  let result = await api.post(conf.server+"/auth/revoke_session",{session: props.session ,id: props.id } ,{
    headers:{
      language: "th",
      token: `Bearer ${conf.token}` , 
    }
  }  ).then((res)=>{ 
    return res.data
  })
  return result
 }


 export async function getGeozone() { 
   const conf = await getConfig() 
   let result = await axios.get(`${conf.domain}/web/v1/geozone`, {
     headers:{
       token: conf.token,
       authenication: config.authorization,
     }
   }).then(res => {
     console.log("getGeozone res ", res)
     return res.data
   }).catch((err )=>{ 
     console.log("getGeozone err ",err)
     return []
   })
  return result
 }
 
 export async function getGeozonePoint(props:any) {
  console.log("getGeozone props ",props) 
   const conf = await getConfig() 
   let result = await axios.get(`${conf.domain}/web/v1/geozonepoint`, {
     headers:{
       token: conf.token,
       authenication: config.authorization,
       geoid: props?.zoneid
     }
   }).then(res => {
     console.log("getGeozone res ", res)
     return res.data
   }).catch((err )=>{ 
     console.log("getGeozone err ",err)
     return []
   })
  return result
 }
 

 export async function allGeometry(){
  const conf = await getConfig() 
  let list:[] = await  axios.get(`${conf.domain}/${config.type}/v${config.version}/geometry` ,{
    headers: {
      "language": i18next.language   ,
      "token" : conf.token ,
      "authenication": config.authorization,
      "version":config.version
    }
  }).then(res => { 
     return res.data 
  }).catch(err => { 
    return []
 })
  return list
}
 
export async function allGeozones({page,pager ,limit}:any){
  const conf = await getConfig() 
  let token =await getStorage("token") 

   let list:[] = await  axios.post(`${conf.domain}/web/v1/geozones`,{} ,{
      headers: {
        "language": i18next.language   ,
        "token" : token ,
        "authenication": config.authorization,
        "version":config.version
      },
      params:{
        pageNo: page ,limit: limit ,pager: pager
      }
    }).then(res => { 
      console.log("zoneslist data",res)
      return res.data
    }).catch(err => { 
      console.log("zoneslist err",err)
      return []
  })
  return list
}

export const newNotifier=async (body:any)=>{ 
  const conf = await getConfig() 
  const token =await getStorage("token")  
  return await  axios.post(`${conf.domain}/web/v1/notifiers/new`,body ,{
    headers: {
      "language": i18next.language   ,
      "token" : token ,
      "authenication": config.authorization,
      "version":config.version
    }, 
  }).then(res => {   
    return res.data
  }).catch(err => {  
    return []
})
}
export const editNotifier=async (body:any, id:any)=>{ 
  const conf = await getConfig() 
  const token =await getStorage("token")  
  return await  axios.post(`${conf.domain}/web/v1/notifiers/edit?notifyId=${id}`,body ,{
    headers: {
      "language": i18next.language   ,
      "token" : token ,
      "authenication": config.authorization,
      "version":config.version
    }, 
  }).then(res => {   
    return res.data
  }).catch(err => {  
    return []
})
}

export const delNotifier=async (id:any)=>{ 
  const conf = await getConfig() 
  const token =await getStorage("token")  
  return await  axios.post(`${conf.domain}/web/v1/notifiers/delete?notifyId=${id}`,null ,{
    headers: {
      "language": i18next.language   ,
      "token" : token ,
      "authenication": config.authorization,
      "version":config.version
    }, 
  }).then(res => {   
    return res.data
  }).catch(err => {  
    return []
})
}

export async function allGeozonesOrder({}){
  const conf = await getConfig() 
  const token =await getStorage("token")  
  const list:[] = await  axios.post(`${conf.domain}/web/v1/geozonesorder`,{} ,{
    headers: {
      "language": i18next.language   ,
      "token" : token ,
      "authenication": config.authorization,
      "version":config.version
    }, 
  }).then(res => {    
    return res.data
  }).catch(err => {  
    return []
})
return list
}

 
export async function geozoneDetail(props:any){
  const conf = await getConfig() 
  let token =await getStorage("token")  
  let list:[] = await  axios.post(`${conf.domain}/web/v1/geozonesbyid`,{} ,{
      headers: {
        "language": i18next.language   ,
        "token" : token ,
        "authenication": config.authorization,
        "version":config.version ,  
        "geoid": props?.geoid 
      }
    }).then(res => {  
      return res.data 
    }).catch(err => { 
      console.log("zoneslist err",err)
      return []
  })
  return list
}

export async function deleteGeozone(props:any) {  
   const token =await getStorage("token")  
   const conf = await getConfig() 
   let result = await axios.post(`${conf.domain}/web/v1/geozone/delete`,{ },{
    headers:{
      token:  token,
      authenication: config.authorization,
      geoid: props?.geoId
    },
    params:{ geoid: props?.geoId }
   }).then(res => {
     console.log("deleteZone res ", res)
     return res.data
   }).catch((err )=>{ 
     console.log("deleteZone err ",err)
     return false
   })
  return result
 }

export async function updateGeozone(props:any) { 
   const token =await getStorage("token")  
   const conf = await getConfig() 
   console.log("props ",props)
   let result = await axios.post(`${conf.domain}/web/v1/geozone/update`,props?.body,{
    headers:{
      token:  token,
      authenication: config.authorization,
      geoid: props?.body.geoId
    }, 
   }).then(res => {
     console.log("updateZone res ", res)
     return res.data
   }).catch((err )=>{ 
     console.log("updateZone err ",err)
     return false
   })
  return result
 }

 export async function updateGeozonePoints(props:any) { 
   const token =await getStorage("token")  
   const conf = await getConfig() 
   console.log("props ",props)
   let result = await axios.post(`${conf.domain}/web/v1/geozone/updatepoints`,props?.body,{
    headers:{
      token:  token,
      authenication: config.authorization,
      geoid: props?.body.geoId
    }, 
   }).then(res => {
     console.log("updateZone res ", res)
     return res.data
   }).catch((err )=>{ 
     console.log("updateZone err ",err)
     return false
   })
  return result
 }

 export async function notifications({begin , end}:any) {
  const conf = await getConfig() 
  let pbegin = new Date(moment().format("YYYY-MM-DDT00:00:00")).getTime()
  let pend =  new Date(moment().format("YYYY-MM-DDT23:59:59")).getTime() 
  if( begin !== undefined){ pbegin = begin}
  if( end !== undefined){  pend = end} 
  return await axios.post(`${conf.domain}/web/v1/notifications?begin=${pbegin}&end=${pend}`, {}, {
    headers:{
      authenication : config.authorization ,
      token : conf.token ,
      language : i18next.language ,
      version : config.version  
    }
  }).then(res =>{ 
    let noti =  res.data  
    return noti
  }).catch(err=>{
    console.log("notifications err ", err)
    return []
  })
}

export async function geozoneNotifier(props:any) {
  const conf = await getConfig() 
  return await axios.post(`${conf.domain}/web/v1/geozone/notifier`, {}, {
    headers:{
      authenication : config.authorization ,
      token : conf.token ,
      language : i18next.language ,
      version : config.version ,
      geoid: props?.geoid
    }
  }).then(res =>{
    return res.data
  }).catch(err=>{
    return []
  })
}


export async function updateGeoNotify(props:any) {
  const conf = await getConfig() 
  return await axios.post(`${conf.domain}/web/v1/geozone/updategeonotify`, props.body, {
    headers:{
      authenication : config.authorization ,
      token : conf.token ,
      language : i18next.language ,
      version : config.version ,
      geoid: props?.body.geoid
    }
  }).then(res =>{
    return res.data
  }).catch(err=>{
    return []
  })
}

export async function getNotifiers() { 
  const conf = await getConfig() 
  return await axios.post(`${conf.domain}/web/v1/notifiers`, {}, {
    headers:{
      authenication : config.authorization ,
      token : conf.token ,
      language : i18next.language ,
      version : config.version , 
    }
  }).then((res:any) =>{ return res.data})
  .catch((err)=>{ return null })

}
export async function getNotifierDevices(id:any) { 
  const conf = await getConfig() 
  return await axios.post(`${conf.domain}/web/v1/notifier/devices?notifierId=${id}`, {}, {
    headers:{
      authenication : config.authorization ,
      token : conf.token ,
      language : i18next.language ,
      version : config.version , 
    }
  }).then((res:any) =>{ return res.data})
  .catch((err)=>{ return null }) 
}

export async function getNotifierInfo(id:any) { 
  const conf = await getConfig() 
  return await axios.post(`${conf.domain}/web/v1/notifier/info?notifierId=${id}`, {}, {
    headers:{
      authenication : config.authorization ,
      token : conf.token ,
      language : i18next.language ,
      version : config.version , 
    }
  }).then((res:any) =>{ return res.data})
  .catch((err)=>{ return null }) 
}
 

export async function getNotifierType() { 
  const conf = await getConfig() 
  return await axios.post(`${conf.domain}/web/v1/notifiers/types`, {}, {
    headers:{
      authenication : config.authorization ,
      token : conf.token ,
      language : i18next.language ,
      version : config.version , 
    }
  }).then((res:any) =>{  
    return res.data})
  .catch((err)=>{ return null })

}

export async function deleteGeometry({...props}){
  const conf = await getConfig() 
  return await axios.post(`${conf.domain}/${config.type}/v${config.version}/deletegeometry`, {
    userId : conf.userId ,
    geoId: props.geoId
  },
  {
    headers:{
      authenication : config.authorization ,
      token : conf.token ,
      language : i18next.language ,
      version : config.version
    }
  }).then(res =>{
    return true
  }).catch(err=>{
    return false
  })
} 

export async function  deviceInGeometry ({...props}) { 
  const conf = await getConfig()  
  let result = await axios.post(`${conf.domain}/${config.type}/v${config.version}/deviceInGeometry/${props.geoId}`,{ },{
    headers: {  
      token : conf.token,
      language : i18next.language ,
      authorization : config.authorization , 
      version : config.version
    }
  }).then(res => {
    return res.data
  }).catch(err=>{ 
    return false
  })
  return result
}
export async function  deviceAroundGeometry ({...props}) { 
  const conf = await getConfig()  
  let result = await axios.post(`${conf.domain}/${config.type}/v${config.version}/deviceAroundGeometry/${props.geoId}`,{ },{
    headers: {  
      token : conf.token,
      language : i18next.language ,
      authorization : config.authorization , 
      version : config.version
    }
  }).then(res => {
    return res.data
  }).catch(err=>{ 
    return false
  })
  return result
}

//  export async function geometry({...props}){ 
//   const conf = await getConfig() 
//   let geozones = await axios
//     .post(conf.url+'/geofences/all' ,{"user_id":9641 }, {
//       headers: { 
//         language: "th",
//         token: `Bearer ${conf.token}` , 
//       },
//     })
//     .then((res) => {  
//       return res.data;
//     })
//     .catch((err) => {
//       console.log("err ", err);
//       return [];
//     });
//   return geozones;
// }
export async function addGuest(body:any) {
  const conf = await getConfig()  
  let result = await axios.post(`${conf.domain}/web/v1/guest/add`, body ,
    { 	
      headers: { 
        authorization: config.authorization,
        language: i18next.language ,
        token: conf.token,
        version: config.version
      }
    }).then(res => {
        console.log('addGeometryres ' ,res)
        return res.data
    }).catch((err )=>{
      console.log("addGeometryerr ",err)
      return {status:false}
    })
    return result
}
///guests
export async function getGuestsAll({}) {
  const conf = await getConfig()  
  let result = await axios.post(`${conf.domain}/web/v1/guests`,{},
    { 	
      headers: { 
        authorization: config.authorization,
        language: i18next.language ,
        token: conf.token,
        version: config.version
      }
    }).then(res => {
        console.log('addGeometryres ' ,res)
        return res.data
    }).catch((err )=>{
      console.log("addGeometryerr ",err)
      return {status:false}
    })
    return result
}

export async function addGeometry({...props}) {
  const conf = await getConfig()  
  let result = await axios.post(`${conf.domain}/web/v1/geozone/add`,{ 
      userId : conf.userId, 
      ...props 
    },
    { 	
      headers: { 
        authenication: config.authorization,
        language: i18next.language ,
        token: conf.token,
        version: config.version
      }
    }).then(res => {
        console.log('addGeometryres ' ,res)
        return res.data
    }).catch((err )=>{
      console.log("addGeometryerr ",err)
      return {status:false}
    })
    return result
}

export async function addGeometryMobile({...props}) {
  const conf = await getConfig()  
  let result = await axios.post(`${conf.domain}/${config.type}/v${config.version}/addgeometry`,{ 
      userId : conf.userId,
      locationName: props.locationName ,
      geometry: props.geometry,
      iconId : props.iconId,
     // type: props.type
    },
    { 	
      headers: { 
        authenication: config.authorization,
        language:'th' ,
        token: conf.token,
        version: config.version
      }
    }).then(res => {
        console.log('addGeometryres ' ,res)
        return res.data
    }).catch((err )=>{
      console.log("addGeometryerr ",err)
      return {status:false}
    })
    return result
}

export async function deviceEvent({...props}) {
  const conf = await getConfig() 
  let result = await axios.post(`${conf.domain}/${config.type}/v${config.version}/deviceEvent`,{
      deviceId: props.deviceId 
    },
    { 	
      headers: { 
        authenication: config.authorization,
        language: i18next.language ,
        token: conf.token,
        version: config.version
      }
    }).then(res => { 
        return res.data
    }).catch((err )=>{
      return []
    })
    return result
}
export async function  deviceEventDate({...props}) { 
  const conf = await getConfig() 
  let result = await axios.post(`${conf.domain}/${config.type}/v${config.version}/deviceEvent`,{
      "deviceId": props.deviceId ,
      "date_start": props.dateStart ,
      "date_end":  props.dateEnd
    },
    { 	
      headers: { 
        authenication: config.authorization,
        language: i18next.language ,
        token: conf.token,
        version: config.version
      }
    }).then(res => {
        console.log(res)
        return res.data
    }).catch((err )=>{
      return []
    })
    return result
}

export async function  menuContentFilter(body:any) {  
  const conf = await getConfig() 
  let result = await axios.post(`${conf.domain}/web/v1/menuContentFilter`,body,{
    headers:{
      token: conf.token,
      authenication: config.authorization,
    }
  }).then(res => {
    console.log(res)
    return res.data
  }).catch((err )=>{
    return  false
  })
 return result 
}
export async function  getFasttrack(body:any,routeid:any) {  
  console.log(" getFasttrack ",body ," ", routeid)
  const conf = await getConfig() 
  let result = await axios.post(`${conf.domain}/web/v1/fasttrack`, body,{
    headers:{
      token: conf.token,
      authenication: config.authorization,
    },
    params:{
      routeid: routeid
    }
  }).then(res => {
    console.log(res)
    return res.data
  }).catch((err )=>{
    console.log(err)
    return  false
  })
 return result 
}

export async function distanceCalulate({...body}:any) {
  console.log("body ",body)
  const conf = await getConfig()  
  const result = await axios.post( `http://localhost:8894/app/v2-2/distanceCalulate`,body,
   {
    headers: {
      token: conf.token ,
      authenication : config.authorization ,
    }
  }).then((res)=>{
    return res.data
  }).catch((err)=>{
    console.log("err ",err)
    return 0.0
  })
  return result
}


export async function reportDistanceDaily({...body}:any) {
  return [
    {
      date: "2023-12-04T00:00:00" ,
      distance: 21
    },
    {
      date: "2023-12-05T00:00:00" ,
      distance: 32
    },
    {
      date: "2023-12-06T00:00:00" ,
      distance: 25
    },
    {
      date: "2023-12-07T00:00:00" ,
      distance: 29
    },
  ]
}

export async function  menuFilter({...props}) {  
  const conf = await getConfig() 
  let result = await axios.post(`${conf.domain}/web/v1/menuFilter`,{},{
    headers:{
      token: conf.token,
      authenication: config.authorization,
    }
  }).then(res => { 
    return res.data
  }).catch((err )=>{
    return  false
  })
 return result
}

export async function  subMenuFilter(body:any) {   
  const conf = await getConfig() 
  let result = await axios.post(`${conf.domain}/web/v1/subMenuFilter`,body,{
    headers:{
      token: conf.token,
      authenication: config.authorization,
    }
  }).then(res => {
    console.log(res)
    return res.data
  }).catch((err )=>{
    return  false
  })
 return result
}

export async function dashStatusRealtime(props:any) { 
  const conf = await getConfig() 
  let result = await axios.post(`${conf.domain}/web/v1/dashboard/statusRealtime`,{
    start:  new Date(moment().subtract(1,'days').format()).getTime() ,
    end: new Date().getTime() ,
    deviceId: 0
  },{
    headers:{
      token: conf.token,
      authenication: config.authorization,
    }
  }).then(res => {
    console.log("res ", res)
    return res.data
  }).catch((err )=>{ 
    console.log("err ",err)
    return  {series:[],categories:[] }
  })
 return result
}

export async function deviceStausTable({...props}){ 
  const table = {
    header: [
    {ref:"name",name:"Name"},{ref:"type",name:"Type"},{ref:"brand",name:"Brand"},
    {ref:"model",name:"Model"},{ref:"imei",name:"IMEI No"},{ref:"sim",name:"Sim Card"},{ref:"speed",name:"Speed"},
    {ref:"statustime",name:"Status Time"},{ref:"location",name:"Location"}],
    contents: [{row:[{}]}]
  }
 return table
}
export async function tableReport({...props}){
   let type = props.type
   return { dateStart: true, dateEnd:false,  }
}
export  const iconType = [
    {
     icon_type_id: 1,
     icon_path: '../assets/types/1.png' ,
     icon_type: 'Car/เก๋ง'
    },
    {
     icon_type_id: 2,
     icon_path: '../assets/types/2.png' ,
     icon_type: 'Truck/รถสิบล้อ'
    },
    {
     icon_type_id: 3,
     icon_path: '../assets/types/3.png' ,
     icon_type: 'Bus/รถบัส'
    },
    {
     icon_type_id: 4,
     icon_path: '../assets/types/4.png' ,
     icon_type: 'Boat/เรือ'
    },
    {
     icon_type_id: 5,
     icon_path: '../assets/types/5.png' ,
     icon_type: 'Cap/รถกะบะ'
    },
    {
     icon_type_id: 6,
     icon_path: '../assets/types/6.png' ,
     icon_type: 'Cement Truck/รถโม่ปูน'
    },
    {
     icon_type_id: 7,
     icon_path: '../assets/types/7.png' ,
     icon_type: 'Crane/รถเครน'
    },
    {
     icon_type_id: 8,
     icon_path: '../assets/types/8.png' ,
     icon_type: 'Embulance/รถพยาบาล'
    },
    {
     icon_type_id: 9,
     icon_path: '../assets/types/9.png' ,
     icon_type: 'Refrigerated Truck/รถตู้บรรทุก(ห้องเย็น)'
    },
    {
     icon_type_id: 10,
     icon_path: '../assets/types/10.png' ,
     icon_type: 'Excavator/รถแม๊คโคร'
    },
    {
     icon_type_id: 11,
     icon_path: '../assets/types/11.png' ,
     icon_type: 'Van/รถตู้'
    } ,
    {
     icon_type_id: 12,
     icon_path: '../assets/types/12.png' ,
     icon_type: 'Motobike/มอเตอร์ไซต์'
    }  ,
    {
     icon_type_id: 13,
     icon_path: '../assets/types/13.png',
     icon_type: 'Tractor/หัวลาก'
    } 
 ]


 export  const defaultData = [
  {
    firstName: 'tanner',
    lastName: 'linsley',
    age: 24,
    visits: 100,
    status: 'In Relationship',
    progress: 50,
  },
  {
    firstName: 'tandy',
    lastName: 'miller',
    age: 40,
    visits: 40,
    status: 'Single',
    progress: 80,
  },
  {
    firstName: 'joe',
    lastName: 'dirte',
    age: 45,
    visits: 20,
    status: 'Complicated',
    progress: 10,
  },
]

export async function commandSend({...props}) { 
  const token = await getStorage("token")
  const conf = await getConfig() 
  const url = "https://mobileapp-s1.andamantracking.dev/app/v2-2" //await getURL() 
  let result = await axios.post(`${conf.domain}/${config.type}/v${config.version}/commandsend`,{} ,{
    headers: {
      deviceid: props.device_id , 
      token: token ,
      authorization : config.authorization,
    }
  }).then((res)=>{
    return res.data
  }).catch((err)=>{
    return []
  })
  return result
}


export async function tablesDevices(){ 
  return await {
    header : [ 
      { id:'name',label:"Device Name" ,show: true } ,
      { id:'type',label:"Device Type" ,show: true } ,
      { id:'edit',label:"Edit" ,show: true } ,
      { id:'delete',label:"Delete" ,show: true } , 
  ],
  detail:{
      active: 120 ,
      inactive:15 ,
      all: 135,
      page: 20 ,
      canAdd: false,
      role:"devices",
      righttable:true
  },
  body: [
      {
          id: 21202 ,
          show: true ,
          column: [
           { id:'name' , label:"*1.2Mazda2sky กษ 1702 แดง *แจ้งให้ระงับสัญญาณ สิ้นเดือน ส.ค.62)" },
           { id:'type' , label:"concox gt06" },
           { id:'delete'  , label:"Delete" },
           { id:'edit' , label:"Edit" },
          ]
       },
       {
           id: 21203 ,
           show: true ,
           column: [
            { id:'name' , label:"*1.6Mazda cx5กล-9412** ระงับสัญญาณ29/10/62" },
            { id:'type' , label:"coban tk103" },
            { id:'delete'  , label:"Delete" },
            { id:'edit' , label:"Edit" },
           ]
        },
        {
            id: 21204 ,
            show: true ,
            column: [
             { id:'name' , label:"*1.6Mazda cx5กล-9412** ระงับสัญญาณ29/10/62" },
             { id:'type' , label:"coban tk103" },
             { id:'delete'  , label:"Delete" },
             { id:'edit' , label:"Edit" },
            ]
         },
         {
             id: 21205 ,
             show: true ,
             column: [
              { id:'name' , label:"*1.6Mazda cx5กล-9412** ระงับสัญญาณ29/10/62" },
              { id:'type' , label:"coban tk103" },
              { id:'delete'  , label:"Delete" },
              { id:'edit' , label:"Edit" },
             ]
          },
          {
              id: 21206 ,
              show: true ,
              column: [
               { id:'name' , label:"*1.6Mazda cx5กล-9412** ระงับสัญญาณ29/10/62" },
               { id:'type' , label:"coban tk103" },
               { id:'delete'  , label:"Delete" },
               { id:'edit' , label:"Edit" },
              ]
           },
        ]
    }
  
}


export async function tablesSensor(){ 
  return await {
    header : [ 
      { id:'device',label:"Device input/output" ,show: true } ,
      { id:'sensor',label:"Sensor/Relay" ,show: true } ,
      { id:'delete',label:"Delete" ,show: true } , 
      { id:'edit',label:"Edit" ,show: true } ,
  ],
  detail: {
      active: 3 ,
      inactive:0 ,
      all: 3,
      page: 1 ,
      canAdd: false,
      role:"sensor",
      righttable:true
  },
  body: [
      {
          id: 1121 ,
          show: true ,
          column: [
           { id:'device' , label:"Accord-1345 *เทส*" },
           { id:'sensor' , label:"PTO" },
           { id:'delete'  , label:"Delete" },
           { id:'edit' , label:"Edit" },
          ]
      },
      {
          id: 1122 ,
          show: true ,
          column: [
           { id:'device' , label:"กรุงเทพฯ ญค-7549 (จักร) " },
           { id:'sensor' , label:"PTO" },
           { id:'delete'  , label:"Delete" },
           { id:'edit' , label:"Edit" },
          ]
      },
      {
          id: 1123 ,
          show: true ,
          column: [
           { id:'device' , label:"กรุงเทพฯ ยท 9459 นม (โจ้) " },
           { id:'sensor' , label:"PTO" },
           { id:'delete'  , label:"Delete" },
           { id:'edit' , label:"Edit" },
          ]
      }
  ]
  
  }
}



export async function tablesUsers(){ 
  return await {
    header : [ 
      { id:'username' ,label:"User Name" ,show: true } ,
      { id:'firstname',label:"First Name" ,show: true } ,
      { id:'lastname' ,label:"Last Name" ,show: true } ,
      { id:'email'    ,label:"Email" ,show: true } , 
      { id:'active'   ,label:"Active" ,show: true } , 
      { id:'edit'     ,label:"Edit" ,show: true } , 
      { id:'delete'   ,label:"Delete" ,show: true } ,  
  ],
  detail: {
    active: 22 ,
    inactive:23 ,
    all: 25,
    page: 20 ,
    canAdd: true,
    role:"users",
    righttable:true
  },
  body: [
    {
        id: 215 ,
        show: true ,
        column: [
            { id:'username' , label:"0611755588" },
            { id:'firstname' , label:"กฉ-5241 นราธิวาส" },
            { id:'lastname'  , label:"..." },
            { id:'email' , label:"0611755588@gmail.com" },
            { id:'active' , label: true },
            { id:'edit' , label:"Edit" },
            { id:'delete' , label:"Delete" },
        ]
    },{
        id: 216 ,
        show: true ,
        column: [
            { id:'username' , label:"0611956649" },
            { id:'firstname' , label:"อรอุมา" },
            { id:'lastname'  , label:"ฟองเจริญ" },
            { id:'email' , label:"jropjopgs@gmail.com" },
            { id:'active' , label: true },
            { id:'edit' , label:"Edit" },
            { id:'delete' , label:"Delete" },
        ]
    },{
        id: 217 ,
        show: true ,
        column: [
            { id:'username' , label:"0612646992" },
            { id:'firstname' , label:"พสิษฐ์ โคตรสุวรรณ" },
            { id:'lastname'  , label:"..." },
            { id:'email' , label:"0612646992@gmail.com" },
            { id:'active' , label: false },
            { id:'edit' , label:"Edit" },
            { id:'delete' , label:"Delete" },
        ]
    },
  ]
  
  }
}

// {
//   "createDate": "30/01/2567 00:00:00",
//   "eventTime": "30/01/2567 12:21:09",
//   "message": "กรุงเทพฯ ญค-7549(จักร)  ออก กทม2 เวลา 30/01/2024 05:21  ( 376.79 m) หมู่บ้านพฤกษา 38 (บางกรวย-ไทรน้อย)  ต.ไทรน้อย อ.ไทรน้อย  จ.นนทบุรี 11150   http://maps.google.com/maps?f=q&q=13.98938,100.324926&z=16",
//   "notiferCode": 2,
//   "notifertype": "ออกพื้นที่"
// }
export const notifyTable=async(notify:any)=>{
   let header =[
    { id:'index',label:"No" ,show: true } ,
    { id:'createDate',label:"Create Date" ,show: true } ,
    { id:'eventTime',label:"Event Time" ,show: true } ,
    { id:'message',label:"Message" ,show: true } ,
    { id:'notifertype',label:"Notifer Type" ,show: true } ,
   ] 
    let page:any = []
    let body:any = []
    await notify.map((noti:any,index:any)=>{
      if(index> 0 && index%5 === 0){
        body.push(page)
        page=[]
      } 
       page.push({
           id: index ,
           column: [
             { id:'index',label:index+1 ,show: true } ,
             { id:'createDate',label: noti.createDate.substr(0,10) } ,
             { id:'eventTime',label: noti.eventTimeStr } ,
             { id:'message',label: noti.message  } ,
             { id:'notifertype',label: noti.notifertype  } ,
           ]  
      })  
   }) 

   let  detail =  {
    active: null ,
    inactive:null ,
    all:  body.length,
    page: 1 ,
    canAdd: false,
    role:"notification",
    righttable:false
}
return {header , detail , body }
}
 

export async function tablesNotification(){ 
  return await {
    header : [ 
      { id:'name',label:"Name" ,show: true } ,
      { id:'device',label:"Device" ,show: true } ,
      { id:'type',label:"Type" ,show: true } , 
      { id:'active',label:"Active" ,show: true } ,
      { id:'format',label:"Format" ,show: true } ,
  ],
  detail: {
      active: 3 ,
      inactive:0 ,
      all: 3,
      page: 1 ,
      canAdd: false,
      role:"notification",
      righttable:false
  },
  body: [
      {
          id: 5442 ,
          column: [
           { id:'name' , label:"ความเร็วเกิน 100" },
           { id:'device' , label: 10 },
           { id:'type'  , label:"แจ้งเตือนพื้นที่" },
           { id:'active' , label: true },
           { id:'format' , label:"อุปกรณ์ {0} ได้มีการเข้า {1} เมื่อเวลา {3}. ขณะนี้  อุปกรณ์อยู่ที่ {4} พิกัด http://maps.google.com/maps?f=q&q={5}&z=16" },
          ]  
      } ,
      {
        id: 5443 ,
        column: [
         { id:'name' , label:"ความเร็วเกิน 100" },
         { id:'device' , label: 10 },
         { id:'type'  , label:"แจ้งเตือนพื้นที่" },
         { id:'active' , label: true },
         { id:'format' , label:"อุปกรณ์ {0} ได้มีการเข้า {1} เมื่อเวลา {3}. ขณะนี้  อุปกรณ์อยู่ที่ {4} พิกัด http://maps.google.com/maps?f=q&q={5}&z=16" },
        ]  
     },
     {
      id: 5444 ,
      column: [
       { id:'name' , label:"ความเร็วเกิน 100" },
       { id:'device' , label: 10 },
       { id:'type'  , label:"แจ้งเตือนพื้นที่" },
       { id:'active' , label: true },
       { id:'format' , label:"อุปกรณ์ {0} ได้มีการเข้า {1} เมื่อเวลา {3}. ขณะนี้  อุปกรณ์อยู่ที่ {4} พิกัด http://maps.google.com/maps?f=q&q={5}&z=16" },
      ]  
  } ,
    ]
  
  }
} 
  const labels = ['2023-11-22T00:10','2023-11-22T00:15','2023-11-22T00:20','2023-11-22T00:25','2023-11-22T00:30','2023-11-22T00:35','2023-11-22T00:40','2023-11-22T00:45','2023-11-22T00:50','2023-11-22T00:55']
  const data = [  -3,-2,-1,0,1,2,3,2,1,0,-1 ]

  export const report1=({})=>{
    return  {
      chart:{
        labels: labels ,
        series:[
          {
            name: "Fuel",
            data: [35,34.4,33,32.64,31,30.45,29,28.12,27.31,26.0,25.32]
          },
          {
            name: "Speed",
            data: [20,34,60,55,64,70,75,76,80,85,82 ]
          }
        ]
      },
      table:{
        headers: [
          { headerSize: '1' ,label:"#" ,id: "no" ,type:'number' },
          { headerSize: '3.5' ,label:"Time" ,id: "time" ,type:'time'},
          { headerSize: '3.5' ,label:"Speed" ,id: "speed" ,type:'text'},
          { headerSize: '3.5' ,label:"Temp" ,id: "temp" ,type:'temp'},
        ] ,
        rows: [
          {  
            cols:  [
                { colSize: '1' ,label: 1 ,id: "no" },
                { colSize: '3.5' ,label: "2023-10-24T00:32" ,id: "time" },   
                { colSize: '3.5' ,label: 70 ,id: "speed" },   
                { colSize: '3.5' ,label:  7 ,id: "temp" },   
              ]    
          },
          {  
            cols:  [
                { colSize: '1' ,label: 2 ,id: "no" },
                { colSize: '3.5' ,label: "2023-10-24T00:32" ,id: "time" },   
                { colSize: '3.5' ,label: 70 ,id: "speed" },   
                { colSize: '3.5' ,label:  7 ,id: "temp" },   
              ]    
          },
          
        ]
      }
    }
  }

  export async function repPieChartDaily({...props}) {
    const conf = await getConfig()   
    let result = await axios.post(`${conf.domain}/${config.type}/v${config.version}/report/summarydate`,{
        deviceId: props.deviceId  ,
        date: props.date
      },{
        headers: {
          "language":  i18next.language ,
          "token" : conf.token,
          "authenication" : config.authorization,
          "version":config.version, 
       }
      }).then(res =>{ 
          return res.data  
      }).catch(()=>{
         return []
      })
    return result
  }

  export async function summarypiechart(body:any) {
    const conf = await getConfig()   
    let result = await axios.post(`${conf.domain}/web/v1/report/summarypiechart`,body,{
        headers: {
          "language":  i18next.language ,
          "token" : conf.token,
          "authenication" : config.authorization,
          "version":config.version, 
       }
      }).then(res =>{ 
        console.log("summarypiechart ",res)
          return res.data  
      }).catch((err)=>{
        console.log("summarypiechart err ",err)
         return []
      })
    return result
  }

  export async function repGasolone(body:any) {
    console.log("body ",body)
    const conf = await getConfig()   
    let result = await axios.post(`${conf.domain}/web/v1/report/gasolone`,body,{
        headers: {
          "language":  i18next.language ,
          "token" : conf.token,
          "authenication" : config.authorization,
          "version":config.version, 
       }
      }).then(res =>{ 
        console.log(" gasolone ", res.data)
          return res.data  
      }).catch(()=>{
         return []
      })
    return result
  }

 
  export async function repGasoloneSpeed(body:any) {
    console.log("repGasoloneSpeed body ",body)
    const conf = await getConfig()   
    let result = await axios.post(`${conf.domain}/web/v1/report/gasolonespeed`,body,{
        headers: {
          "language":  i18next.language ,
          "token" : conf.token,
          "authenication" : config.authorization,
          "version":config.version, 
       }
      }).then(res =>{ 
        console.log(" gasolone ", res.data)
          return res.data  
      }).catch(()=>{
         return []
      })
    return result
  }

  export async function repTemperature(body:any) {
    const conf = await getConfig()   
    let result = await axios.post(`${conf.domain}/web/v1/report/tempurature`,body,{
        headers: {
          "language":  i18next.language ,
          "token" : conf.token,
          "authenication" : config.authorization,
          "version":config.version, 
       }
      }).then(res =>{ 
        console.log("tempurature res ", res)
          return res.data  
      }).catch(()=>{
         return []
      })
    return result
  }
 
  export async function repOverSpeed(body:any) {
    const conf = await getConfig()   
    let result = await axios.post(`${conf.domain}/web/v1/report/overspeed`,body,{
        headers: {
          "language":  i18next.language ,
          "token" : conf.token,
          "authenication" : config.authorization,
          "version":config.version, 
       }
      }).then(res =>{ 
        console.log("repOverSpeed res ", res)
          return res.data  
      }).catch(()=>{
         return []
      })
    return result
  }

  export async function repOverTime(body:any) {
    const conf = await getConfig()   
    let result = await axios.post(`${conf.domain}/web/v1/report/overtime`,body,{
        headers: {
          "language":  i18next.language ,
          "token" : conf.token,
          "authenication" : config.authorization,
          "version":config.version, 
       }
      }).then(res =>{ 
        console.log("repOverTime res ", res)
          return res.data  
      }).catch(()=>{
         return []
      })
    return result
  }

 export  const dowloadRepDistance=async (body:any , name:any ,description:any)=>{
  console.log("dowloadRepDistance body ",body , " name ",name)
    const conf = await getConfig()  
    return await axios.post(`${conf.domain}/web/v1/excel/report/rangedistance/download`, body ,
      { responseType: 'blob' , 
        params:{
          tiltle :name ,
          desciption: description
        }
     }).then(res=>{ 
        console.log("res ",res)
        return res.data 
    }) 
    
  }
  
  export const repCarsDailyUse=async(body:any)=>{ 
    console.log("repCarsDailyUse  ", body)
    const conf = await getConfig() 
    const result = await axios.post(`${conf.domain}/web/v1/reports/deviceUseDaily`,body,{
      headers:{
        token: conf.token ,
        authenication : config.authorization , 
        language: i18next.language
      }
    }).then((res)=>{ 
      return res.data
    })
    console.log("repCarsDailyUse result ",result)
    return result
  }

  export const repAverageDistanceAndFuel=async(body:any)=>{ 
    console.log("repCarsDailyUse  ", body)
    const conf = await getConfig() 
    const result = await axios.post(`${conf.domain}/web/v1/reports/averagedistanceandfuel`,body,{
      headers:{
        token: conf.token ,
        authorization : config.authorization ,
        language: i18next.language ?  i18next.language :'th'
      }
    }).then((res)=>{ 
      return res.data
    })
    console.log("repCarsDailyUse result ",result)
    return result
  }

  export const repFuelusage=async(body:any)=>{ 
    console.log("repFuelusage  ", body)
    const conf = await getConfig() 
    const result = await axios.post(`${conf.domain}/web/v1/report/fuelusage`,body,{
      headers:{
        token: conf.token ,
        authorization : config.authorization ,
        language: i18next.language ?  i18next.language :'th'
      }
    }).then((res)=>{ 
      return res.data
    })
    console.log("repFuelusage result ",result)
    return result
  }

  export const repAverageDistanceAndFuelId=async(body:any)=>{  
    const conf = await getConfig() 
    const result = await axios.post(`${conf.domain}/web/v1/reports/averagedistanceandfuelbyid`,body,{
      headers:{
        token: conf.token ,
        authorization : config.authorization ,
        language: i18next.language ?  i18next.language :'th'
      }
    }).then((res)=>{  
      return res.data
    }).catch(err =>{
      console.log("err ",err)
      return {
        header:  [
            { "no": 1, "label": "Vehicle" },
            { "no": 2, "label": "Consumptionn Move" },
            { "no": 3, "label": "Consumptionn Idle" }
        ],
        rows: []
      }
    }) 
    return result
  }
 
  export const repRangDistance=async(body:any)=>{ 
    console.log("repCarsDailyUse  ", body)
    const conf = await getConfig() 
    const result = await axios.post(`${conf.domain}/web/v1/report/rangedistance`,body,{ 
      headers:{
        token: conf.token ,
        authenication : config.authorization ,
        rangeDatestr: body?.rangeDatestr,
        language: i18next.language
      }
    }).then((res)=>{ 
      console.log("repRangDistance res ", res)
      return res.data
    })
    console.log("repCarsDailyUse result ",result)
    return result
  }

  export const addEditEmployees=async(body:any)=>{ 
    console.log("addEmployees  ", body)
    const conf = await getConfig() 
    const result = await axios.post(`${conf.domain}/web/v1/employees/add`,body,{
      headers:{
        token: conf.token ,
        authenication : config.authorization ,
        language: i18next.language
      }
    }).then((res)=>{ 
      return res.data
    })
    console.log("addEmployees result ",result)
    return result
  }

  export const getEmployees=async()=>{  
    const conf = await getConfig() 
    const result = await axios.post(`${conf.domain}/web/v1/employees`,{},{
      headers:{
        token: conf.token ,
        authenication : config.authorization ,
        language: i18next.language
      }
    }).then((res)=>{ 
      return res.data
    })
    console.log("addEmployees result ",result)
    return result
  }
  export const delEmployees=async(body:any)=>{  
    console.log("delEmployees  ", body)
    const conf = await getConfig() 
    const result = await axios.post(`${conf.domain}/web/v1/employees/delete`,body,{
      headers:{
        token: conf.token ,
        authenication : config.authorization ,
        language: i18next.language
      }
    }).then((res)=>{ 
      return res.data
    })
    console.log("addEmployees result ",result)
    return result
  }

  ///reports/distanceDaily
  export const repDistanceDaily=async(body:any)=>{ 
    console.log("body ",body)
    const conf = await getConfig() 
    const result = await axios.post(`${conf.domain}/web/v1/reports/distanceDaily`,body,{
      headers:{
        token: conf.token ,
        authenication : config.authorization ,
        language: i18next.language
      }
    }).then((res)=>{
      console.log("res repCarsDailyUse ",res)
      return res.data
    })
    return result
  }
 
   export const repBehavior=async(body:any)=>{ 
    console.log("body ",body)
    const conf = await getConfig() 
    const result = await axios.post(`${conf.domain}/app/v2-2/behaviorDevice`,{
      start: body.start ,
      end: body.end,
      deviceId: body.deviceId
    },{
      headers:{
        token: conf.token ,
        authenication : config.authorization ,
        "Accept-Languag":"th",
        language: i18next.language
      }
    }).then((res)=>{ 
      return res.data
    })
    return result
  }

  export const reportIgnition=async(body:any)=>{ 
    console.log("body ",body)
    const conf = await getConfig()  
    const result = await axios.post(`${conf.domain}/web/v1/keyonoff`,{
      start: body.start ,
      end: body.end,
      deviceId: body.deviceId
    },{
      headers:{
        token: conf.token ,
        authenication : config.authorization ,
        "Accept-Languag":"th",
        language: i18next.language
      }
    }).then((res)=>{ 
      console.log("res ",res)
      return res.data
    })
    return result
  }

  export const repClosOpenPTO=async(body:any)=>{
    const conf = await getConfig() 
    const result = await axios.post(`${conf.domain}/web/v1/reports/openclosepto`,{
      start: body.start ,
      end: body.end,
      deviceId: body.deviceId
    },{
      headers:{
        token: conf.token ,
        authenication : config.authorization ,
        "Accept-Languag":"th",
        language: i18next.language
      }
    }).then((res)=>{ 
      return res.data
    })
    return result
  }

  export const repMinuteDaily=async(body:any)=>{
    const conf = await getConfig() 
    console.log('repMinuteDaily body ',body)
    const result = await axios.post(`${conf.domain}/web/v1/reports/minuteDaily`,{
      start: body.start ,
      end: body.end,
      deviceId: body.deviceId
    },{
      headers:{
        token: conf.token ,
        authenication : config.authorization ,
        "Accept-Languag":"th",
        language: i18next.language
      }
    }).then((res)=>{ 
      return res.data
    })
    return result
  }

  export const dashStsSummary=async()=>{
    const conf = await getConfig() 
    const result = await axios.post(`${conf.domain}/web/v1/dashboard/statusSummary`,{},{
      headers:{
        token: conf.token ,
        authenication : config.authorization ,
        "Accept-Languag":"th",
        language: i18next.language
      }
    }).then((res)=>{  
      return res.data
    })
    return result
  }

  export const dashDeviceInZone=async()=>{
    const conf = await getConfig() 
    const result = await axios.post(`${conf.domain}/web/v1/dashboard/inzones`,{},{
      headers:{
        token: conf.token ,
        authenication : config.authorization ,
        "Accept-Languag":"th",
        language: i18next.language
      }
    }).then((res)=>{  
      return res.data
    }).catch((err)=>{
      return {
        devices:[] ,categories:[]
      }
    })
    return result
  }

  export const dashRiskRealtime=async(body:any)=>{ 
    const conf = await getConfig()  
    const result = await axios.post(`${conf.domain}/web/v1/dashboard/dangerRealtime`,body,{
      headers:{
        token: conf.token ,
        authenication : config.authorization ,
        "Accept-Languag":"th",
        language: i18next.language
      }
    }).then((res)=>{   
      return res.data
    }).catch((err)=>{ 
      return {
        series:[] ,categories:[]
      }
    })
    return result
  }

  export const driveOvertime=async(body:any)=>{ 
    const conf = await getConfig()  
    const result = await axios.post(`${conf.domain}/web/v1/dashboard/driveOvertime`,body,{
      headers:{
        token: conf.token ,
        authenication : config.authorization ,
        "Accept-Languag":"th",
        language: i18next.language
      }
    }).then((res)=>{   
      return res.data
    }).catch((err)=>{ 
      return {
        series:[] ,categories:[]
      }
    })
    return result
  }
  export const dashViolations=async(body:any)=>{ 
    const conf = await getConfig()  
    const result = await axios.post(`${conf.domain}/web/v1/dashboard/violations`,body,{
      headers:{
        token: conf.token ,
        authenication : config.authorization ,
        "Accept-Languag":"th",
        language: i18next.language
      }
    }).then((res)=>{   
      return res.data
    }).catch((err)=>{ 
      return {
        series:[] ,categories:[]
      }
    })
    return result
  }

  export const violationSpeedDevice=async(body:any)=>{ 
    const conf = await getConfig()  
    const result = await axios.post(`${conf.domain}/web/v1/dashboard/violationSpeedDevice`,body,{
      headers:{
        token: conf.token ,
        authenication : config.authorization ,
        "Accept-Languag":"th",
        language: i18next.language
      }
    }).then((res)=>{   
      return res.data
    }).catch((err)=>{ 
      return {
        series:[] ,categories:[]
      }
    })
    return result
  }

  export const violationOverIdleDevice=async(body:any)=>{ 
    const conf = await getConfig()  
    const result = await axios.post(`${conf.domain}/web/v1/dashboard/violationOverIdleDevice`,body,{
      headers:{
        token: conf.token ,
        authenication : config.authorization ,
        "Accept-Languag":"th"
      }
    }).then((res)=>{   
      return res.data
    }).catch((err)=>{ 
      return {
        series:[] ,categories:[]
      }
    })
    return result
  }


  export const dashViolation=async(body:any)=>{ 
    const conf = await getConfig()  
    const result = await axios.post(`${conf.domain}/web/v1/dashboard/violation`,body,{
      headers:{
        token: conf.token ,
        authenication : config.authorization ,
        "Accept-Languag":"th"
      }
    }).then((res)=>{   
      return res.data
    }).catch((err)=>{ 
      return {
        series:[] ,categories:[]
      }
    })
    return result
  }

  export const dashViolationOverSpeed=async(body:any)=>{ 
    const conf = await getConfig()  
    const result = await axios.post(`${conf.domain}/web/v1/dashboard/violationOverSpeed`,body,{
      headers:{
        token: conf.token ,
        authenication : config.authorization ,
        "Accept-Languag":"th"
      }
    }).then((res)=>{   
      return res.data
    }).catch((err)=>{ 
      return {
        series:[] ,categories:[]
      }
    })
    return result
  }

  export const dashViolationOverIdle=async(body:any)=>{ 
    const conf = await getConfig()  
    const result = await axios.post(`${conf.domain}/web/v1/dashboard/violationOverIdle`,body,{
      headers:{
        token: conf.token ,
        authenication : config.authorization ,
        "Accept-Languag":"th"
      }
    }).then((res)=>{   
      return res.data
    }).catch((err)=>{ 
      return {
        series:[] ,categories:[]
      }
    })
    return result
  }
  export const dashChartTemperature=async(body:any)=>{ 
    const conf = await getConfig()  
    const result = await axios.post(`${conf.domain}/web/v1/dashboard/chartTemperature`,body,{
      headers:{
        token: conf.token ,
        authenication : config.authorization ,
        "Accept-Languag":"th"
      }
    }).then((res)=>{   
      return res.data
    }).catch((err)=>{ 
      return {
        series:[] ,categories:[]
      }
    })
    return result
  }


  export async function getBehaviorDevice({...body}:any) { 
    const conf = await getConfig()   
    const result = await axios.post( `${conf.domain}/${config.type}/v${config.version}/behaviorDevice`,body ,
     {
      headers: {
        token: conf.token ,
        authenication : config.authorization ,
        "Accept-Language":  i18next.language,  
      }
    } )
    .then((res)=>{
      console.log("res ",res)
      return res.data
    }).catch(err =>{
      console.log("errr ",err)
      return null
    })
    return result
  }
  export async function getBehaviors(body:any) { 
    const conf = await getConfig()   
    const result = await axios.post( `${conf.domain}/web/v1/behaviors`,body ,
     {  headers: {
        token: conf.token ,
        authenication : config.authorization ,
        "Accept-Language":  i18next.language,  
      }
    } )
    .then((res)=>{
      console.log("res ",res)
      return res.data
    }).catch(err =>{
      console.log("errr ",err)
      return null
    })
    return result
  }
 
  export async function getNotifyMessage({...body}:any) { 
    const conf = await getConfig()   
    const result = await axios.post( `${conf.domain}/web/v1/notifers`,body ,
     {
      headers: {
        token: conf.token ,
        authenication : config.authorization ,
        "locale":  i18next.language ? i18next.language : "th-TH",  
      }
    } )
    .then((res)=>{ 
      return res.data
    }).catch(err =>{
      console.log("errr ",err)
      return null
    })
    return result
  }
  export async function getNotifyUnreadMessage({...body}:any) { 
    const conf = await getConfig()   
    const result = await axios.post( `${conf.domain}/web/v1/unreadnotifers`,body ,
     {
      headers: {
        token: conf.token ,
        authenication : config.authorization ,
        "locale":  i18next.language ? i18next.language : "th-TH",  
      }
    } )
    .then((res)=>{ 
      return res.data
    }).catch(err =>{
      console.log("errr ",err)
      return []
    })
    return result
  }

  export async function updateReadMessageNotify({...body}:any) {  
    const conf = await getConfig()   
    const result = await axios.post( `${conf.domain}/web/v1/updatereaded`, body.ids ,
     {
      headers: {
        token: conf.token ,
        authenication : config.authorization ,
        "locale":  i18next.language ? i18next.language : "th-TH" 
      }, 
    } )
    .then((res)=>{ 
      return res.data
    }).catch(err =>{
      console.log("errr ",err)
      return []
    })
    return result
  }

  export async function reportInOutGeozoe({...body}:any) { 
    console.log("body ", body)
    const conf = await getConfig()   
    const result = await axios.post( `${conf.domain}/web/v1/report/inoutgeozone`,body , {
      headers: {
        token: conf.token ,
        authenication : config.authorization ,
        "locale":  i18next.language ? i18next.language : "th-TH",  
      },
      params:{
        geozoneid : body.geozoneid
      }
    } )
    .then((res)=>{ 
      console.log("res  ",res)
      return res.data
    }).catch(err =>{
      console.log("errr ",err)
      return null
    })
    return result
  }
  export async function reportOutInGeozoe({...body}:any) { 
    console.log("body ", body)
    const conf = await getConfig()   
    const result = await axios.post( `${conf.domain}/web/v1/report/outingeozone`,body , {
      headers: {
        token: conf.token ,
        authenication : config.authorization ,
        "locale":  i18next.language ? i18next.language : "th-TH",  
      },
      params:{
        geozoneid : body.geozoneid
      }
    } )
    .then((res)=>{ 
      console.log("res  ",res)
      return res.data
    }).catch(err =>{
      console.log("errr ",err)
      return null
    })
    return result
  }

  export async function reportDeviceInOutGeozoe({...body}:any) { 
    console.log("body ", body)
    const conf = await getConfig()   
    const result = await axios.post( `${conf.domain}/web/v1/report/devicesinoutgeozone`,body , {
      headers: {
        token: conf.token ,
        authenication : config.authorization ,
        "locale":  i18next.language ? i18next.language : "th-TH",  
      },
      params:{
        geozoneid : body.geozoneid
      }
    } )
    .then((res)=>{ 
      console.log("res  ",res)
      return res.data
    }).catch(err =>{
      console.log("errr ",err)
      return null
    })
    return result
  }
  
  export async function repStatusColumn({body,begin,range}:any) {
    const conf = await getConfig()   
    const lang = i18next.language ? i18next.language : "th"
     return await axios.post(`${conf.domain}/web/v1/report/statuscolumn?begin=${begin}&range=${range}`,body,{
      headers: {
        token: conf.token ,
        authorization : config.authorization ,
        "language": lang 
      } 
    }).then(res =>{ 
      console.log("repStatusColumn res ",res)
      return res.data
     })
  }

  export async function repStatusDaily({body,begin,range}:any) {
    const conf = await getConfig()   
    const lang = i18next.language ? i18next.language : "th"
     return await axios.post(`${conf.domain}/web/v1/reports/statuscolumndaily`,body,{
      headers: {
        token: conf.token ,
        authorization : config.authorization ,
        "language": lang 
      } 
    }).then(res =>{ 
      console.log("repStatusColumn res ",res)
      return res.data
     })
  }

  export async function getLongdokey({}) {  
    const conf = await getConfig()   
    const result = await axios.post( `${conf.domain}/web/v1/geozone/longdokey`,{} , {
      headers: {
        token: conf.token ,
        authenication : config.authorization ,
        "locale":  i18next.language ? i18next.language : "th-TH",  
      } 
    } )
    .then((res)=>{ 
      console.log("getLongdokey res  ",res)
      return res.data
    }).catch(err =>{
      console.log("errr ",err)
      return null
    })
    return result
  }

  export async function getPttpricing() {  
    const conf = await getConfig()   
    const result = await axios.get( `${conf.domain}/web/v1/pttpricing`, {
      headers: {
        token: conf.token ,
        authenication : config.authorization ,
        "locale":  i18next.language ? i18next.language : "th-TH",  
      } 
    } )
    .then((res)=>{  
      return res.data
    }).catch(err =>{ 
      return null
    })
    return result
  }

  export const setDeviceImage=async(body:any)=>{ 
    const conf = await getConfig()   
    console.log("setDeviceImage body ",body )
    return await axios.post( `${conf.domain}/web/v1/device/uploadimg`,body, {
      headers: {
        token: conf.token ,
        authenication : config.authorization ,
        "language":  i18next.language ? i18next.language : "th-TH",  
      } 
    }).then((res )=>{
      console.log("setDeviceImage res ",res)
      return res.data
    }).catch((err )=>{
      console.log("setDeviceImage err ",err)
      return false
    })

  }
  export const setDelDeviceImage=async(body:any)=>{ 
    const conf = await getConfig()   
    console.log("setDeviceImage body ",body )
    return await axios.post( `${conf.domain}/web/v1/device/delimg`,body, {
      headers: {
        token: conf.token ,
        authenication : config.authorization ,
        "language":  i18next.language ? i18next.language : "th-TH",  
      } 
    }).then((res )=>{
      console.log("setDelDeviceImage res ",res)
      return res.data
    }).catch((err )=>{
      console.log("setDelDeviceImage err ",err)
      return false
    })

  }
 
  export async function addRouteSet(body:any) {  
    console.log("addRouteSet body ", body)
    const conf = await getConfig()   
    const result = await axios.post( `${conf.domain}/web/v1/addrouteset`,body, {
      headers: {
        token: conf.token ,
        authenication : config.authorization ,
        "locale":  i18next.language ? i18next.language : "th-TH",  
      } 
    } )
    .then((res)=>{ 
      console.log("res  ",res)
      return res.data
    }).catch(err =>{
      console.log("errr ",err)
      return null
    })
    return result
  }

  export async function allRoutesSet() {   
    const conf = await getConfig()   
    const result = await axios.post( `${conf.domain}/web/v1/routesset`,null, {
      headers: {
        token: conf.token ,
        authenication : config.authorization ,
        "locale":  i18next.language ? i18next.language : "th-TH",  
      } 
    } )
    .then((res)=>{ 
      console.log("allRoutesSet  ",res)
      return res.data
    }).catch(err =>{
      console.log("allRoutesSet ",err)
      return null
    })
    return result
  }

  
  

export async function cancleShutdown({...props}) { 
  const conf = await getConfig()  
  let result = await axios.post(`${conf.domain}/app/v2-2/cancelshutdown`,{} ,{
    headers: { 
      "userId": props.userID,
      "deviceId": props.device_id ,
      "language": i18next.language,
      "token" :  conf.token ,
      ...headers
    }		
  }).then(res =>{
    console.log("res ",res)
    return true
  }).catch((err)=>{
    console.log("err ",err)
    return false
  })
  return result
} 


 
  export async function settingConsumption(prm:any) {  
    console.log("settingConsumption prm " , prm)
    const conf = await getConfig()   
    const result = await axios.post( `${conf.domain}/web/v1/setting/consumption?deviceId=${prm.deviceId}&consumtionidle=${prm.consumtionIdle}&consumtionmove=${prm.consumpionMove}&ptttype=${prm.ptttype}`,{}, {
      headers: {
        token: conf.token ,
        authenication : config.authorization ,
        locale:  i18next.language ? i18next.language : "th-TH",  
      }  
    } )
    .then((res)=>{ 
      console.log("res  ",res)
      return res.data
    }).catch(err =>{
      console.log("errr ",err)
      return null
    })
    return result
  }
   
export async function cutengine({...props}) {
  const conf = await getConfig()  
  let result = await axios.post( `${conf.domain}/app/v2-2/cutengine`,{} ,{
    headers : {
      password  : props.password,
      userId: props.userID, 
      language: i18next.language,
      token :  conf.token ,
      authenication : config.authorization ,
      deviceId : props.deviceId
    }
  }).then(res=>{
    console.log("res ",res)
    return true
  }).catch(err=>{
    console.log("err ",err)
    return false
  })
  return result
} 

  export async function getDevices({...body}:any) { 
    const conf = await getConfig()   
    // const result = await axios.post( `${conf.domain}/web/v1/admin/devices`,body ,
    //  {
    //   headers: {
    //     token: conf.token ,
    //     authenication : config.authorization ,
    //     "Accept-Language":  i18next.language ? i18next.language : "th-TH",  
    //   }
    // } )
    // .then((res)=>{ 
    //   console.log("devices ",res)
    //   return res.data
    // }).catch(err =>{
    //   console.log("errr ",err)
    //   return null
    // })
    return [] // result
  }

  export async function getUsers({...body}:any) { 
    const conf = await getConfig()   
    const result = await axios.post( `${conf.domain}/web/v1/admin/users`,body ,
     {
      headers: {
        token: conf.token ,
        authenication : config.authorization ,
        "Accept-Language":  i18next.language ? i18next.language : "th-TH",  
      }
    } )
    .then((res)=>{ 
      console.log("users ",res)
      return res.data
    }).catch(err =>{
      console.log("errr ",err)
      return null
    })
    return result
  }

  export async function addSetRoute({...props}:any){
    console.log("body " ,props.body)
    const conf = await getConfig()
    const resut = await axios.post(`${conf.domain}/web/v1/routeset/add`, props.body ,{
      headers: {
        token: conf.token ,
        authenication : config.authorization ,
        language: i18next.language ? i18next.language : "th-TH",  
        "Accept-Language":  i18next.language ? i18next.language : "th-TH",  
      }
    }).then((res)=>{ 
      console.log("addSetRoute ",res)
      return res.data
    }).catch(err =>{
      console.log("addSetRoute errr ",err)
      return false
    })
    return resut
  }

  export async function editSetRoute(body:any){
    console.log("editSetRoute body " ,body)
    const conf = await getConfig()
    const resut = await axios.post(`${conf.domain}/web/v1/routeset/edit`, body ,{
      headers: {
        token: conf.token ,
        authenication : config.authorization ,
        language: i18next.language ? i18next.language : "th-TH",  
        "Accept-Language":  i18next.language ? i18next.language : "th-TH",  
      }
    }).then((res)=>{ 
      console.log("editSetRoute ",res)
      return res.data
    }).catch(err =>{
      console.log("editSetRoute errr ",err)
      return false
    })
    return resut
  }
  export async function deleteSetRoute(body:any){
    console.log("deleteSetRoute body " ,body)
    const conf = await getConfig()
    const resut = await axios.post(`${conf.domain}/web/v1/routeset/del`, body ,{
      headers: {
        token: conf.token ,
        authenication : config.authorization ,
        language: i18next.language ? i18next.language : "th-TH",  
        "Accept-Language":  i18next.language ? i18next.language : "th-TH",  
      }
    }).then((res)=>{ 
      console.log("deleteSetRoute ",res)
      return res.data
    }).catch(err =>{
      console.log("deleteSetRoute errr ",err)
      return false
    })
    return resut
  } 
  

  export async function getRoutesetTable(){ 
    const conf = await getConfig()
    const resut = await axios.post(`${conf.domain}/web/v1/routeset/table`, {} ,{
      headers: {
        token: conf.token ,
        authenication : config.authorization ,
        language: i18next.language ? i18next.language : "th-TH",  
        "Accept-Language":  i18next.language ? i18next.language : "th-TH",  
      }
    }).then((res)=>{ 
      console.log("getRoutesetTable ",res)
      return res.data
    }).catch(err =>{
      console.log("getRoutesetTable errr ",err)
      return false
    })
    return resut
  }

  export async function delRouteSeted({...props}:any){ 
    const conf = await getConfig()
    const resut = await axios.post(`${conf.domain}/web/v1/routeset/delete`, {} ,{
      headers: {
        token: conf.token ,
        authenication : config.authorization ,
        language: i18next.language ? i18next.language : "th-TH",  
        "Accept-Language":  i18next.language ? i18next.language : "th-TH",  
      },
      params:{
        routeid: props?.routeid
      }
    }).then((res)=>{ 
      console.log("delRouteSeted ",res)
      return res.data
    }).catch(err =>{
      console.log("delRouteSeted errr ",err)
      return false
    })
    return resut
  }

  export async function getRouteDetail({...props}:any){ 
    const conf = await getConfig()
    const resut = await axios.post(`${conf.domain}/web/v1/routeset/detail`, {} ,{
      headers: {
        token: conf.token ,
        authenication : config.authorization ,
        language: i18next.language ? i18next.language : "th-TH",  
        "Accept-Language":  i18next.language ? i18next.language : "th-TH",  
      },
      params:{
        routeid: props?.routeid
      }
    }).then((res)=>{ 
      console.log("delRouteSeted ",res)
      return res.data
    }).catch(err =>{
      console.log("delRouteSeted errr ",err)
      return false
    })
    return resut
  }

  export async function repInOutGeofense({...props}) {
    const result = [
      { 
          in: 1703492999000 ,
          out:1703500199000 ,
          distance: 12,
          duration: 25,
          driver:"-",
      },
      { 
          in: 1703492999000 ,
          out:1703500199000 ,
          distance: 12,
          duration: 25,
          driver:"-",
      },
   ]
   return result;
  }


  export const reportCarsDailyUse=()=>{
    return {
      overview:{
        maxSpeed:  92,
        move:"03:54:54",
        idle:"01:22:41",
        stop:"00:36:52"
      },
      events:[
          {
            "status": 23,
            "start": 1702227793720,
            "end": 1702447049744,
            "statusDuration": 219256024,
            "distance": 129231.41711155641,
            "driver": "",
            "maxSpeed": 0,
            "address": "Geofence  ออฟฟิตโคราช",
            "lat": 14.94558,
            "lng": 14.94558
        },
        {
          "status": 23,
          "start": 1702227793720,
          "end": 1702447049744,
          "statusDuration": 219256024,
          "distance": 129231.41711155641,
          "driver": "",
          "maxSpeed": 0,
          "address": "Geofence  ออฟฟิตโคราช",
          "lat": 14.94558,
          "lng": 14.94558
      },
      {
        "status": 23,
        "start": 1702227793720,
        "end": 1702447049744,
        "statusDuration": 219256024,
        "distance": 129231.41711155641,
        "driver": "",
        "maxSpeed": 0,
        "address": "Geofence  ออฟฟิตโคราช",
        "lat": 14.94558,
        "lng": 14.94558
      },
      ]
    }
  }

export {}

 



// 1 -> 0 acl.object_id as device_id ,
// 2. d.device_enabled , 
// 3. d.name , 
// 4. d.odometer_offset  , 
// 5. d.phone_number , 
// 6. d. account_id , 
// 7. d.device_type_id ,  
// 8. d.icon_id , 
// 9. d.expiration_date , 
// 10. d.owner_id , 
// 11. d.name2 , 
// 12. d.deviceIconId ,
// 13. ec.fuel_liters,
// 14. ec.heading,
// 15 ec.latitude,
// 16  ec.longitude ,  
// 17 -> 16 d.odoCheckToday  ,   
// 18 -> 17 d.odoSummary , 
// 19 -> 18 d.odoToday ,
// 20  ec.fld_driverID , 
// 21 ec.fld_driverMessage , 
// 22. ec.fld_engineLoad , 
// 23. ec.fld_sensorHigh , 
// 24 ec.fld_signalStrength ,
// 25 ec.satellites ,
// 26  ec.speed , 
// 27  ec.status ,
// 28 ec.status_time  ,
// 29 ec.event_stamp ,
// 30 ec.address ,
// 31 ec.id , ec.serverTime,
// 32 ec.temperature ,
// 33 d.canCutEngin,
// 34 ec.virtual_odometer , 
// 35 dt.name as device_type , 
// 36 '' as fullname 
// 37 d.ConsumptionIdle ,
// 38 d.fuelConsumptionMove, 
// 39 d.pttType , 
// 40 '' as ptt , 
// 41 ec.ignition as ignition, 
// 42 go.group_id as group_id , 
// 43 '' as group_name , 
// 44 d.minuteIdle  ,
// 45 d.speed_limit , 
// 46 go.group_id as groupId 
// 47. device_route
 