import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonText, IonTitle, IonToolbar } from "@ionic/react"
import { chevronBackOutline } from "ionicons/icons" 
import axios from "axios"

const Payments=()=>{
    const addcustomer=()=>{
        // axios.post("/newcustomer").then(())
    }

    return(
    <IonPage>
        <IonHeader color='transparent' className='ion-no-border'   > 
        <IonToolbar color='transparent' mode='ios' className='ion-no-padding'>   
                <IonButtons slot="start"  className='ion-hide-md-up  '  >
                <IonButton size='small'  > <IonIcon icon={chevronBackOutline} />  <IonText >Back</IonText> </IonButton>
                </IonButtons>
                <IonTitle style={{fontWeight:"bold",fontSize:"1.3em",marginLeft:"1rem" }} >{'Payments'}</IonTitle>
        </IonToolbar>
        </IonHeader>
        <IonContent fullscreen> 
            <IonButton >
                Add User Card
            </IonButton>
        </IonContent> 
    </IonPage>
    )
}
export default Payments