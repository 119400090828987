import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MapContainer, TileLayer, FeatureGroup, useMap, Polygon, Popup, Tooltip, Marker, Polyline, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { EditControl } from "react-leaflet-draw"
import 'leaflet-draw';
import { useDispatch, useSelector } from 'react-redux';
import { getAddGeo, getCircle, getFindPoint, getFocusZone, getGeoType, getGeoZones, getPointGeozone, getPolygon, getRadius, getStartDraw, setCircle, setGeofenceType, setPointGeozone, setPolygon, setStartDraw } from '../store/mapSlice';
import { defaultPin, editPin, pinstop } from './PublicMarker';
import { IonButton, IonContent, IonIcon, IonImg, IonItem, IonLabel, IonPopover } from '@ionic/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDrawPolygon, faPencil } from '@fortawesome/free-solid-svg-icons';
import { ButtonIconPlace, Invalidate, LayersControlMap, LayersExpandControl, MarkerGeoIcon } from './LeafletLayers';
import { backspaceOutline, close, ellipseOutline, squareOutline, trashBinOutline, trashOutline } from 'ionicons/icons';
import { on } from 'stream';
import { SwipBack } from '../components/AppComponent';
import "./leaflet.css"
import { getOS } from '../store/appSlice';

const GeofencesMap = ({geoType}:any) => { 
    let mapRef = useRef(null)
    const dispatch = useDispatch()
    const [position,setPosition] = useState({ lat: 14.340027, lng:100.586026 })  
    const [zoom,setZoom] = useState(13)
    const geozones :any= useSelector(getGeoZones)
    const foncusZone :any= useSelector(getFocusZone)
    const findPoint :any= useSelector(getFindPoint)
    const startdraw :any= useSelector(getStartDraw)
    const points :any= useSelector(getPointGeozone)
    const [isFinish,setIsFinish] = useState(false)
    const addgeo :any= useSelector(getAddGeo) 
    const type :any= useSelector(getGeoType)
    const radius :any= useSelector(getRadius)
    
 
    useEffect(()=>{
        // @ts-ignore
        mapRef.current?.invalidateSize()
        setTimeout(() => {
            setZoom(14)
        }, 200);

        console.log("selected geozones ",geozones)
 
        return(()=>{
            setZoom(14)
        })
    },[type,foncusZone,findPoint,points,radius])
 
  
   
    const finishDraw=()=>{
        setIsFinish(true) 
        let menu = document.getElementById("menu-context") 
        //@ts-ignore
        menu.style.display = "none" 
    }

    return (
        <div   >
        
        <ButtonIconPlace /> 
        <div id='menu-context'  >
            <IonItem color={'transparent'} lines='none' button  onClick={()=>{finishDraw()}} >Finish Draw  </IonItem>
        </div>
        <ButtonReadyDraw />
        {startdraw && <ControlDrawGeofences /> }
        
        <MapContainer
            id="map-geozones"
            center={[ position.lat, position.lng]}
            zoom={zoom}
            scrollWheelZoom={true}
            zoomControl={false}
            style={{borderRadius:"15px" }}
            ref={async (comp)=>{  
                comp?.invalidateSize() 
                comp?.on("zoomlevelschange",()=>{
                    comp?.invalidateSize() 
                }) 
            }} 
    > 
        <LayersExpandControl />
        <FindPoint />
        {addgeo && <DrawGeozones geoType={geoType} />}
 
        {
            geozones.map((zone:any,index:any)=>
            <FeatureGroup  key={zone.geoId} >
                {zone.show?<>
                <Polygon positions={zone.points} fillOpacity={0.5} color='#F5D423' >
                    <Tooltip 
                        direction="bottom" 
                        className="marker-place-name"
                        offset={[0, -20]}
                        opacity={1}
                        permanent >{zone.name}
                    </Tooltip>
                </Polygon>
                <MarkerGeoIcon icon={zone.icon_category} position={zone?.points[0]} /> 
                </>:<></>
                } 
            </FeatureGroup> 
        )}
        {/* <LayersControlMap  /> */}
        <Invalidate />
    </MapContainer>
   </div>
 );
}; 
export default GeofencesMap;

const FindPoint=()=>{
    const findPoint :any= useSelector(getFindPoint)
    const map = useMap()

    useEffect(()=>{
        console.log("findPoint ",findPoint)
        if(findPoint){
            map.setView(findPoint)
        }
    },[findPoint])

    return(
        <>
         {findPoint && <Marker position={findPoint}  icon={pinstop}  /> }
        </>
    )
}

const ButtonReadyDraw=()=>{
    const startdraw :any= useSelector(getStartDraw)
    const dispatch = useDispatch()
    const os :any= useSelector(getOS)

    return(
    <button className={`btn-ready-draw ${startdraw && "active"}   `} onClick={()=>{dispatch(setStartDraw(!startdraw))}}>
        {/* <IonImg src='../assets/in-zone.svg' /> */}
      <IonLabel color={"dark"} > <FontAwesomeIcon icon={faPencil} /></IonLabel> 
    </button>)
}

const ControlDrawGeofences=()=>{ 
    const dispatch =useDispatch()
    const type :any= useSelector(getGeoType)
    const polygon :any= useSelector(getPolygon)
    const circle :any= useSelector(getCircle)

    const deleteGeofencesDraw=()=>{
        if(type.value === "polygon"){
            dispatch(setPolygon([]))
        }else{
            dispatch(setCircle([]))
        }
    }

    const removeGeofencesDraw=()=>{ 
        if(type.value === "polygon"){
            let lstPolygon = polygon
            lstPolygon = lstPolygon.slice(1,lstPolygon.length)
            dispatch(setPolygon(lstPolygon))
        }else{
            let lstCircle = circle
            lstCircle = lstCircle.slice(1,lstCircle.length)
            dispatch(setCircle(lstCircle))
        }
    }

    return(<div className='control-draw-geofences' >
        <div  className="tooltip">
            <button onClick={()=>{deleteGeofencesDraw()}}   color={"primary"} >
                <IonIcon icon={trashOutline} /> 
            </button>
             <span className="tooltiptext">Delete</span> 
        </div>
        <div style={{marginLeft:"10%",width:'80%',height:'1px',borderTop:'1px solid #EEE'}}></div>
        <div  className="tooltip">
            <button onClick={()=>{removeGeofencesDraw()}}   color={"primary"} className="tooltip"  > 
                <IonIcon icon={backspaceOutline} /> 
            </button>  
            <span className="tooltiptext">Remove</span> 
        </div>
        <div style={{marginLeft:"10%",width:'80%',height:'1px',borderTop:'1px solid #EEE'}}></div>
        <div  className="tooltip">
        <button  color={"primary"} className="tooltip"  onClick={()=>{dispatch(setGeofenceType({value:"polygon",label:"Polygon"}))}}> 
            <IonIcon icon={squareOutline} style={{transform:"rotate(45deg)"}} /> 
        </button> <span className="tooltiptext">Polygon</span> 
        </div>
        <div style={{marginLeft:"10%",width:'80%',height:'1px',borderTop:'1px solid #EEE'}}></div>
        <div  className="tooltip">
        <button  color={"primary"} className="tooltip"    onClick={()=>{dispatch(setGeofenceType({value:"circle",label:"Circle"}))}}> 
            <IonIcon icon={ellipseOutline} /> 
        </button><span className="tooltiptext">Circle</span> 
        </div>

    </div>)
}


const DrawGeozones=({geoType}:any)=>{  
    const polygon :any= useSelector(getPolygon)
    const circle :any= useSelector(getCircle)  
    const type :any= useSelector(getGeoType)
    const addgeo :any= useSelector(getAddGeo) 
    const startdraw :any= useSelector(getStartDraw)
    const [activeMarker,setActiveMarker] = useState(0)
    const dispatch = useDispatch() 
    const circleradius :any= useSelector(getRadius)
    const  [position,setPosition] = useState({lat:0,lng:0})

    const map = useMapEvents({
        async click(e) {
            if(startdraw){  
                console.log("click ", e.latlng )
                setPosition(e.latlng)
                if(geoType === "polygon"){
                    drawPolygon( e.latlng.lat ,e.latlng.lng)
                }else{
                    let positons = await drawCircle( e.latlng.lat ,e.latlng.lng)
                    dispatch( setCircle(positons)) 
                    // map.setView([e.latlng.lat ,e.latlng.lng],18)
                }
            }
        }, 
    })
 
    const dragMarker= async(e:any,posit:any)=>{ 
        let position:any = []  
        position = geoType==='polygon'? polygon.slice(0): circle.slice(0) // copy
        let index:number =  geoType==='polygon'?  polygon.indexOf(posit) :  circle.indexOf(posit)
        
        if(geoType==='polygon'){ //@ts-ignore
            if(polygon[index] != [e.target._latlng.lat,e.target._latlng.lng]  ){   
                position.splice(index, 0);
                position[index] = [e.target._latlng.lat,e.target._latlng.lng ]
                dispatch(setPolygon(position)) 
            } 
        }else{//@ts-ignore
            if(circle[index] != [e.target._latlng.lat,e.target._latlng.lng]  ){   
                position.splice(index, 0);
                position[index] = [e.target._latlng.lat,e.target._latlng.lng ]
                dispatch(setCircle(position)) 
            } 
        }
    }

    const drawCircle=async(lat:any, lng:any)=>{
        //setPosition({ lat:lat, lng:lng})
        var d2r = Math.PI / 180;   // degrees to radians
        var r2d = 180 / Math.PI;   // radians to degrees
        var earthsradius = 3963; 
        var points = 32; 
        const radius = circleradius/100
       
         var rlat = (radius / earthsradius) * r2d;
         var rlng = rlat / Math.cos(lat * d2r); 
         var extp = new Array(); 
         for (var i=0; i < points+1; i++) // one extra here makes sure we connect the
         {
            var ex,ey = 0
            var theta = Math.PI * (i / (points/2));
            ex = lng + (rlng * Math.cos(theta)); // center a + radius x * cos(theta)
            ey = lat + (rlat * Math.sin(theta)); // center b + radius y * sin(theta)
             
            extp =  extp.concat([[ey,ex]]) 
         }
        return extp
    } 

    const drawPolygon=(lat:any,lon:any)=>{
        let position = [lat,lon]
        let addToPolygon:any = [...polygon,position]
        console.log("addToPolygon ",addToPolygon)
        dispatch( setPolygon(addToPolygon)) 
    }
       
    useEffect(()=>{ 
        if(type.value === "circle"){ 
            const updateCircle=async()=>{
                let positons = await drawCircle(position.lat, position.lng)
                dispatch( setCircle(positons)) 
            }
            updateCircle()
        }
    },[circleradius,addgeo])
    
    return(<FeatureGroup>
 
         <Polygon positions={polygon}  color='#ebdb34'  />
         <Polygon positions={circle}   color='#ebdb34'  />
         {
            geoType === "polygon"  &&
            <FeatureGroup> 
                {polygon.map((position:any,index:any)=> 
                  <Marker 
                    draggable={index===activeMarker?true:false}
              
                    icon={
                        index===activeMarker?
                        editPin : defaultPin
                    } 
                    position={position}   
                    ref={(m)=>{
                        m?.on("click",(e)=> {
                            setActiveMarker(index)
                        })
                        m?.on("dragend",(e)=> {
                            dragMarker(e,position)
                        })
                    }} 
                  ></Marker>
                )}
            </FeatureGroup> }
           { geoType === "circle" && <FeatureGroup>
                {circle.map((position:any,index:any)=> 
                  <Marker 
                    draggable={index===activeMarker?true:false} 
                    icon={
                        index===activeMarker?
                        editPin : defaultPin
                    } 
                    position={position}
                    ref={(m)=>{
                        m?.on("click",(e)=> {
                            setActiveMarker(index)
                        })
                        m?.on("dragend",(e)=> {
                            dragMarker(e,position)
                        })
                    }} 
                  ></Marker>
                )}
            </FeatureGroup>
         }
    </FeatureGroup>)
}
 