 
import { IonTitle ,IonButton, IonIcon, IonLabel, IonSegment, IonSegmentButton, IonInput, IonItem, IonNavLink, IonContent, IonPopover, useIonPopover, IonList, IonSelect, IonSelectOption, IonRow, IonCol, useIonToast, IonImg} from  '@ionic/react';
import { IonPage } from "@ionic/react";
import { caretDown, chevronForwardOutline, ellipse, eye, eyeOff, idCard, people, share, shareOutline } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getCookie, getStorage, getWebConfig, guestSignin, index, login, setCookie, setFavicon, setStorage, signin, signinWeb } from '../actions';
import { setSignin } from '../store/appSlice'; 
import {  mobileVendor } from "react-device-detect";
import app from "../api.config.json"
import config from "../api.config.json"
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'; 

import './css/Signin.css'  
import { t } from 'i18next';
import { ChangeLang } from '../components/NavBar';
import moment from 'moment';
const backgroundSea = ['../../assets/img/promthep.jpg','../../assets/img/samed.jpg','../../assets/img/phiphi.jpg']
const servers = [
    {name:"server1" , url:"https://mobileapp-s1.andamantracking.dev"}, 
]

const Signin: React.FC = () => {
    const dispatch = useDispatch()
    const history = useHistory() 
    const [random,setRandom] = useState(1)
    const [server,serServer] = useState('server1')
    const [url,setUrl] = useState('https://apitracking-universe.attg.cc')
    const [location,setLocation] = useState({lat:0,lon:0})
    const [ isPassword , setIsPassword] = useState(true)
    const [account,setAccount] = useState<string>("")
    const [username,setUsername] = useState<string>("")
    const [password,setPassword] = useState<string>("")
    const [serverList,setServerList] = useState(servers)
    const [bacgrouds,setBackgroud] = useState(['assets/img/red-carparking.jpg']) 
    // const [cookies, setCookie] = useCookies(['token']);
    const [guestMode , setGuestMode] = useState(false)
    const [owner,setOwner] = useState(null)
    const [iontoast,dimisstoast] = useIonToast()
    const [tabs,setTabs] = useState(0)
    const [contact ,setContact] = useState<any>(null)

    let interval: string | number | NodeJS.Timeout | undefined 
    const domain = url// "https://apitracking-s1.andamantracking.dev" //"https://apitracking-universe.attg.cc/" //" 
      
    const submit=async()=>{
        setStorage('server', server) 
        const type =  config.type
        const version =  config.version
        const fullAPI = `${domain}/app/v${version}`
        setStorage('url',fullAPI) 
        const result:any = await signin({
            account: account,
            username: username,
            password: password,
            lat: location.lat,
            lon: location.lon,
            mobileVendor: mobileVendor,
            url: fullAPI ,
            domain: domain
        })
        console.log("result ",result)
        if(result){
            let token = result.token
                let profile = {
                    first_name: result.first_name,
                    id: result.id ,
                    last_name: result.last_name ,
                    url_realtime: result.url_realtime
                }
                // setCookie('token',token  )

                setStorage('appVersion', app.versionNumber)  
                setStorage('token',token); 
                setStorage("profile", profile)
                setStorage('isSignin', true)
                setStorage('server', server)
                setStorage('domain',domain)
                setStorage('isGuest',false)
                // setStorage('sigin', {account,username,password})
                setCookie('sigin',  {account,username,password}  , 7)
                dispatch(setSignin(true))
                setTimeout(()=>{
                    history.replace('/home')
                },50)
        } 
    }

    const guestSubmit=async()=>{
        setStorage('server', server) 
        const type =  config.type
        const version =  config.version
        const fullAPI = `${domain}/web/v1`
        const result:any = await guestSignin({ 
            url: fullAPI , 
            guesttoken: btoa(`${username}:${password}:${owner}`)
        })
        console.log("guestSignin result ",result)
        if(result){
            let token = result.token
            if(result.token){
                let profile = {
                    first_name: result.first_name,
                    id: result.id ,
                    last_name: result.last_name ,
                    url_realtime: result.url_realtime
                }
                // setCookie('token',token  )

                setStorage('appVersion', app.versionNumber)  
                setStorage('token',token); 
                setStorage("profile", profile)
                setStorage('isSignin', true)
                setStorage('server', server)
                setStorage('domain',domain)
                setStorage('isGuest',true)
                setStorage('sigin', {account,username,password}) 
               dispatch(setSignin(true))
                setTimeout(()=>{
                    history.replace('/home')
                },50)
            }else{
                iontoast({
                    message :"ไม่พบข้อมูลผู้ใช้ใช้งาน !!" ,
                    mode:"ios" ,
                    duration: 3000 ,
                    color:"warning",
                    position:"top"
                })
            }
               
        } else{
            iontoast({
                message :"ไม่พบข้อมูลผู้ใช้ใช้งาน !!" ,
                mode:"ios" ,
                duration: 3000 ,
                color:"warning",
                position:"top"
            })
        }
    }
    const loadTheme = async() => {
        const savedTheme =await getStorage('theme');
        console.log("savedTheme ",savedTheme)
        const servers = await getStorage("servers")
        
        if (savedTheme && servers) { 
          document.title = t("signin_title")+" | "+savedTheme.name
          setTheme(savedTheme);
          setContact(savedTheme)
          setServerList(servers) 
          return true
        }else{
            return false
        }
    };
    const loadServer = async() => {
        const servers =await getStorage('servers');
    }

    const setTheme = (theme:any) => {
        console.log("setTheme ",theme)
        document.documentElement.style.setProperty('--ion-color-primary', theme.primary);
        document.documentElement.style.setProperty('--ion-color-secondary', theme.secondary);
        document.documentElement.style.setProperty('--ion-color-primary-rgb', theme.pRgb) 
        document.documentElement.style.setProperty('--ion-color-primary-shade',theme.pShade)
        document.documentElement.style.setProperty('--ion-color-primary-tint', theme.sTint)
        document.documentElement.style.setProperty('--ion-color-secondary-rgb', theme.sRgb) 
        document.documentElement.style.setProperty('--ion-color-secondary-shade',theme.sShade)
        document.documentElement.style.setProperty('--ion-color-secondary-tint', theme.sTint)
      
        // Optional: Save theme in localStorage
        // setStorage('theme', theme);
      };
    const configIndex=async()=>{ 
        localStorage.removeItem("theme")
        const localtheme = await loadTheme() 
        if(!localtheme ){
            const result = await index() 
            document.title = t("signin_title")+" | "+result.contact?.name
            setTheme({primary: result.contact.color ,secondary : result.contact.colorSecond  , ...result.contact }) 
            setContact(result?.contact) 
            setStorage('servers', result.server);
            setServerList(result.server) 
            setFavicon( result.contact.logo)
            console.log("result.server ",result.server)
            console.log("result.contact ",result.contact )
            if(result.server && result.server.length > 0){ 
                setUrl(result.server[0].webUrl)
            }
        }

    }

     
    const findLocation=()=>{
        navigator.geolocation.getCurrentPosition((coord)=>{
            if( coord.coords.latitude &&  coord.coords.longitude){
            const position = {
                    lat: coord.coords.latitude ,
                    lon: coord.coords.longitude
                }
                setLocation(position)
            }
        })
    }
    const siginbefore=async()=>{
        let form = await getCookie('sigin')
        console.log("siginbefore form ",form)
        if(form){
            setAccount(form?.account)
            setUsername(form?.username)
            setPassword(form?.password)
        }
    }
    const getparameter=async()=>{
        const query = new URLSearchParams( window.location.search );
        const isguest = query.get("guest");
        
        const localGuest =await getStorage("guest")
        if(localGuest || isguest){  
            setTabs(  1 )

            let localowner = await getStorage("ownerprm")
            if(localowner){
                setOwner(localowner)
            }
        } 
        if(isguest){
            setTabs(  1 )
            const prmusername = query.get("username");
            const prmpassword = query.get("password");
            const prmowner = query.get("owner");
            setStorage("guest",window.location.href.replace(window.location.origin,''))
            if(prmusername){ setUsername(prmusername) }
            if(prmpassword){ setPassword(prmpassword) }
            if(prmowner){ 
                let ownerprm:any = atob(prmowner);
                setOwner(ownerprm)
                setStorage("ownerprm" ,ownerprm)
            } 
            setGuestMode(isguest ? true :false)  
        }
        
    }
    useEffect(()=>{ 
        configIndex()
        getparameter()
        findLocation()
        setStorage('server', server)
        siginbefore()
        let isSign = getStorage("isSignin")
        let isSigninParse = JSON.parse(isSign||"false")
        if(isSigninParse){
            dispatch(setSignin(isSigninParse))
            history.replace('/home')
        }

        const randomBG = ()=>{
            let index = [0,1,2]
            setRandom(Math.floor(Math.random()* bacgrouds.length))
        }
        randomBG()
        interval = setInterval(()=>{
            randomBG()
        },100000)

        return(()=>{
            clearInterval(interval)
        })
    },[ ])

    

    const openLink=(url:any)=>{
        window.open(url, '_blank');
    }

    return(
        <IonPage>
            <div className='sinin-page set-center' style={{backgroundImage:`url('${contact?.bgImage}')`,backgroundSize:'cover',backgroundPosition:"center center"}} >
                <div className='wraper' ></div>
                <div className='public-nav' >
                    <ul>
                        <li style={{border:'none'}}><IonImg src={contact?.logo} style={{width:"2rem"}} alt=''/> </li>
                        <li> <IonLabel  onClick={()=>openLink(contact?.website)}>{t("signin_nav_explore")} <IonIcon icon={shareOutline}  /></IonLabel>  </li>
                        <li> <IonLabel  onClick={()=>openLink(contact?.websiteContact)}>{t("signin_nav_contact")}</IonLabel> </li>
                        <li> 
                            <IonLabel  id="open-popover" >{t("signin_nav_service")} <IonIcon icon={caretDown} style={{fontSize:'.8em'}}  /></IonLabel> 
                            <IonPopover mode='ios' triggerAction='hover' keepContentsMounted={true} trigger="open-popover">
                                <IonList mode='ios' className='services-list'>
                                    <IonItem mode='ios' type='button' lines='full' id="nested-trigger">
                                        <IonLabel>Stable version</IonLabel>
                                        <IonIcon icon={chevronForwardOutline} slot='end' />
                                        {/* Sub menu of Stable version*/}
                                        <ServiceMenu urls={contact?.webServices} />
                                    </IonItem>
                                    {/* <IonItem mode='ios' lines='none'>
                                        <IonLabel>Trang Tracking</IonLabel>
                                        <IonIcon icon={shareOutline} slot='end' />
                                    </IonItem> */}
                                </IonList>
                            </IonPopover>
                        </li>
                        <li><ChangeLang id={"changelang-navbar-menu"} color={"light"} style={"clear"}/></li>
                    </ul>
                   
                </div>
                <div className='reserve-terms' >
                 © {moment().format("YYYY")} All right Reserve by {contact?.name},  <span className='terms' >Terms and permission</span> .
                </div>


               {/* {!guestMode ? <div className="signin-container" > */}
               <div className="signin-container" style={{height:"fit-content"}} >
                    <IonRow>
                         <IonCol size='12'> <IonLabel className="form-description" >START TO BEGIN.</IonLabel><br/>
                            <IonLabel className="form-title" >{tabs > 0 && "Guest"} {t("signin_button")} <IonIcon icon={ellipse} color="primary" /> </IonLabel>
                        </IonCol>
                        <IonCol size='12'>
                        <Tabs className={"signin-screen-tabs "} selectedIndex={tabs}  onSelect={(index) => setTabs(index)}  >
                            <TabList  >
                                <Tab   style={{fontSize:".9em"}}  > Account </Tab>
                                <Tab   style={{fontSize:".9em"}}  > Guest </Tab> 
                            </TabList>
                            <TabPanel> 
                                <form style={{width:'100%'}}>  
                                    <IonSegment value={server} mode='ios' onIonChange={(e:any)=>{serServer(e.detail.value!)}}>
                                        {
                                            serverList.map((server:any,index:any) =>
                                            <IonSegmentButton key={index} mode='ios' onClick={()=>{setUrl(server.webUrl)}} value={server.name} >
                                            <IonLabel>{server.name}</IonLabel>
                                            </IonSegmentButton>
                                            )
                                        } 
                                    </IonSegment><br/> 

                                    <div className='input' >
                                    <IonItem mode='ios' lines='none' color={'transparent'} > 
                                        <IonInput mode='ios'   placeholder={t("signin_account").toString()} value={account} onIonInput={(e)=>{setAccount(e.detail.value!)}}></IonInput>
                                        <IonIcon icon={people} color='medium' slot='end' />
                                    </IonItem> 
                                    </div>
                                    <div className='input' >
                                    <IonItem mode='ios' lines='none' color={'transparent'} > 
                                        <IonInput mode='ios'   placeholder={t("signin_username").toString()} value={username} onIonInput={(e)=>{setUsername(e.detail.value!)}}></IonInput>
                                        <IonIcon icon={idCard} color='medium' slot='end' />
                                    </IonItem>
                                    </div>
                                    <div className='input' >
                                    <IonItem mode='ios' lines='none'  color={'transparent'} > 
                                        <IonInput mode='ios'   placeholder={t("signin_password").toString()} type={isPassword?'password':'text'} value={password} onIonInput={(e:any)=>{setPassword((p) => p = e.detail.value)}}></IonInput>
                                        <IonIcon icon={isPassword?eye:eyeOff} color='medium' slot='end' style={{cursor:'pointer'}} onClick={()=>{setIsPassword(!isPassword)}} />
                                    </IonItem>
                                    </div><br/>

                                    <IonButton style={{width:'10rem'}} mode='ios' expand='block' onClick={()=>submit()} >{t("signin_button")}</IonButton>
                                </form>
                            </TabPanel>
                            <TabPanel> 
                                <form style={{width:'100%',paddingTop:"1rem"}}>  
                                    <div className='input' >
                                    <IonItem mode='ios' lines='none' color={'transparent'} > 
                                        <IonInput mode='ios'   placeholder="Username" value={username} onIonChange={(e)=>{setUsername(e.detail.value!)}}></IonInput>
                                        <IonIcon icon={idCard} color='medium' slot='end' />
                                    </IonItem>
                                    </div> 
                                    <div className='input' >
                                    <IonItem mode='ios' lines='none'  color={'transparent'} > 
                                        <IonInput mode='ios'   placeholder="Password" type={isPassword?'password':'text'} value={password} onIonChange={(e)=>{setPassword(e.detail.value!)}}></IonInput>
                                        <IonIcon icon={isPassword?eye:eyeOff} color='medium' slot='end' style={{cursor:'pointer'}} onClick={()=>{setIsPassword(!isPassword)}} />
                                    </IonItem>
                                    </div> <br/> 
                                    <IonButton style={{width:'10rem'}} mode='ios' expand='block' onClick={()=>guestSubmit()} > {t("signin_button")}</IonButton>
                                </form> 
                            </TabPanel>
                        </Tabs> 
                        </IonCol>
                    </IonRow> 
                </div>  
                {/* :
                 <div className="signin-container" style={{height:"fit-content"}}>
                     <IonRow>
                          <IonCol size='12'> <IonLabel className="form-description" >START TO BEGIN.</IonLabel><br/>
                             <IonLabel className="form-title" >Sign in <IonIcon icon={ellipse} color="primary" /> as Guest</IonLabel>
                         </IonCol>
                         <IonCol size='12'> <br/><br/>
                             <form style={{width:'100%'}}>  
                                 <div className='input' >
                                 <IonItem mode='ios' lines='none' color={'transparent'} > 
                                     <IonInput mode='ios'   placeholder="Username" value={username} onIonChange={(e)=>{setUsername(e.detail.value!)}}></IonInput>
                                     <IonIcon icon={idCard} color='medium' slot='end' />
                                 </IonItem>
                                 </div><br/>
                                 <div className='input' >
                                 <IonItem mode='ios' lines='none'  color={'transparent'} > 
                                     <IonInput mode='ios'   placeholder="Password" type={isPassword?'password':'text'} value={password} onIonChange={(e)=>{setPassword(e.detail.value!)}}></IonInput>
                                     <IonIcon icon={isPassword?eye:eyeOff} color='medium' slot='end' style={{cursor:'pointer'}} onClick={()=>{setIsPassword(!isPassword)}} />
                                 </IonItem>
                                 </div> <br/> 
                                 <IonButton style={{width:'10rem'}} mode='ios' expand='block' onClick={()=>guestSubmit()} >Signin</IonButton>
                             </form>
                         </IonCol>
                     </IonRow>
                 </div>
                 }
            </div> */}
           </div>
        </IonPage>
    )
}

export default Signin;
const openLink=(url:any)=>{
    window.open(url, '_blank');
}

const ServiceMenu=({urls}:{urls:String[]})=>{
    return(
    <IonPopover trigger="nested-trigger" className='nest-option' dismissOnSelect={true} side="right" alignment='start'  triggerAction='hover'> 
        <IonList className='services-list' style={{padding:'0px'}}>
            {
              urls &&  urls?.map((url:any , index:any)=> 
               <IonItem key={index} button={true} detail={false} onClick={()=>{openLink(url)}}>
                    <IonLabel>Server {index+1}</IonLabel>  
                    <IonIcon icon={shareOutline} slot='end' />
                </IonItem>
            )
            }
            
            {/* <IonItem button={true} detail={false} onClick={()=>{openLink('http://103.246.17.125/login.jsp')}}>
                <IonLabel>Server 2</IonLabel>  
                <IonIcon icon={shareOutline} slot='end' />
           </IonItem>
           <IonItem button={true} detail={false} lines='none' onClick={()=>{openLink('http://dlt10.attg.cc/login.jsp')}}>
                <IonLabel>Server 3</IonLabel>  
                <IonIcon icon={shareOutline} slot='end' />
           </IonItem>  */}
       </IonList> 
    </IonPopover>
    )
} 