import { IonAvatar, IonButton, IonButtons, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon,  IonImg,  IonInput,  IonItem,  IonLabel,  IonList,  IonLoading,  IonModal,  IonPopover,  IonRadio,  IonRadioGroup,  IonRippleEffect,  IonRow, IonSegment, IonSegmentButton, IonText, IonTitle, IonToolbar, useIonActionSheet, useIonToast } from "@ionic/react"
import { addCircleOutline, addOutline, arrowDownCircle, arrowRedo, card, checkmarkCircle, chevronBack, chevronForward, close,  closeCircle,  closeCircleOutline,  closeOutline,  eye, eyeOff, flower, flowerOutline, idCard, informationCircleOutline, layersOutline, location, man, pause,  playCircle,  prism,  prismOutline,  removeOutline,  speedometer,  stop } from "ionicons/icons"
import L, { DivIcon, Icon, Marker as MarkerTpe} from "leaflet"
import { useEffect, useMemo, useRef, useState } from "react"
import { FeatureGroup, LayerGroup, LayersControl, Marker, Polyline, Popup, TileLayer, Tooltip, GeoJSON, useMap, useMapEvents, WMSTileLayer, useMapEvent, MapContainer } from "react-leaflet"
import { useDispatch, useSelector } from "react-redux"
import { allGeometry, allGeozones, deviceInGeometry, geozoneDetail, getLongdokey, getStorage, iconList, iconPlaces, primary, setMarkerName, setStorage, toastSuccess, userSetting } from "../actions"
import { getControlPosition, getDeviceBound, getFastTrack, getFocusMarker, getFocusZone, getGeoIcon, getGeoZones, getIndexRoute, getIsMeasure, getLastPoint, getMapRoute, getMapRouting, getMeasureArea, getMeasureDistance, getMeasurement, getOpenStreetView, getPassRoute, getPlayAction, getPlaySize, getPreviewDevice, getRouteId, getRouteList, getSpeedPlay, getStreetView, getTileproviders, getTrackRealtime, getTrackWhilePlay, setControlPosition, setFastTrack, setFocusMarker, setFocusZone, setGeoIcon, setGeozones, setIndexRoute, setIsMeasure, setLastPoint, setMapRoute, setMapRouting, setMeasurement, setOpenStreetView, setPreviewDevice, setRouteID, setRouteSelected, setSpeedPlay, setStreetView, setTrackRealtime, setTrackWhilePlay } from "../store/mapSlice"
import { circle, pinArrowMove, pinIdling, pinOffline, pinstop, pinStop } from "./PublicMarker"
import { getBackup, getDarkMode, getDefaultMap, getDeviceSelect, getIsGroupDevice, getShowLicebse, setDefaultMap, setIsGroupDevice, setShowLicense } from "../store/appSlice"
import Draggable, {DraggableCore} from "react-draggable";  
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faIcons, faLocationDot, faRoute, faRulerCombined, faStreetView, faTags } from "@fortawesome/free-solid-svg-icons"
import { OverlayEventDetail } from "@ionic/core/components"
import { TwitterPicker } from "react-color" 
import  config from "../api.config.json"
import MarkerClusterGroup from 'react-leaflet-cluster' 
import {  useSwiper } from "swiper/react"  
import axios from "axios"   
import Roadnet from "../roadnet.json"
import { ChipStreetView, DeviceName, DeviceStatusNameTime } from "../components/IconStatus"
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import * as maptilersdk from '@maptiler/sdk';  
import { Geolocation } from "@capacitor/geolocation"; 
import { t } from "i18next"
import app from "../api.config.json"
import "@maptiler/sdk/dist/maptiler-sdk.css";  
import "./leaflet.css"
import "leaflet-rotatedmarker" 
import { getRealtimeTrack } from "../store/filterSlice"
import { IconRouteToday } from "../components/AppComponent"
import { Link } from "react-router-dom"  

const tileproviders=[
  {
    name:'longdo',
    nameEN: "ลองดู",  
    url: "https://longdomap.attg.cc/mmmap/img.php?zoom={z}&x={x}&y={y}",
    attribution: "© Longdo Map",
    images: "../../assets/img/layer-longdo.PNG",
    groupId:1
}, 
{
    name: 'OpenStreetMap', 
    nameEN: "โอเพ้นสตรีท",  
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    images: "../../assets/img/layer-openstreet.PNG",
    groupId:1
}, 
{
    name: 'Satellite | ESRI', 
    nameEN: "ดาวเทียม | ESRI",  
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    attribution:
        'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
    images: "../../assets/img/layer-sattlelite.PNG",
    groupId:1
},
{
    name: 'bluemarbl', 
    nameEN: "bluemarbl",  
    url: 'https://ms.longdo.com/mapproxy/tms/1.0.0/bluemarble_terrain/EPSG3857/{z}/{y}/{x}',
    attribution:
        'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
    images: "../../assets/img/layer-sattlelite.PNG",
    groupId:1
},

  // {
  //   name: 'StadiaAlidadeSmoothDark', 
  //   nameEN: "StadiaAlidadeSmoothDark",  
  //   url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
  //   attribution:'&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
  //   images: "../../assets/img/layer-stadiamaps.png",
  //   groupId:1
  // },
  // {
  //   name: 'roadmap', 
  //   nameEN: "roadmap",  
  //   url: 'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
  //   attribution:'&copy; <a href="https://stadiamaps.com/"> Google </a> ',
  //   images: "https://andamantracking.com/file/maptiles/sattlelitelayer.PNG",
  //   groupId: 2
  // }, 
  // {
  //   name: 'hybrid', 
  //   nameEN: "hybrid",  
  //   url: 'http://mt1.google.com/vt/lyrs=y&x=1325&y=3143&z=13',
  //   attribution:'&copy; <a href="https://stadiamaps.com/"> Google </a> ',
  //   images: "https://andamantracking.com/file/maptiles/sattlelitelayer.PNG",
  //   groupId: 2
  // }, 
  // {
  //   name: 'Streets', 
  //   nameEN: "Streets",  
  //   url: 'https://api.maptiler.com/maps/0ed8f772-babe-421f-8912-336b64af0d6e/?key=RpNDwv39Bwb6jrwmO3n1#',
  //   attribution:`<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>`,
  //   images: "https://andamantracking.com/file/maptiles/sattlelitelayer.PNG",
  //   groupId: 3
  // },
  // {
  //   name: 'MapboxRoadnet', 
  //   nameEN: "MapboxRoadnet",  
  //   url: "https://api.mapbox.com/styles/v1/bthongbut/clm901e2x014n01r7gkm8gxo7.html?title=copy&access_token=pk.eyJ1IjoiYnRob25nYnV0IiwiYSI6ImNsNGo5b3Q3ZjE1NG8zcXN2aGxrZDRtbnIifQ.r0o9Uw4EkOMCVQXY1De2gw&zoomwheel=true&fresh=true#12.95/8.01003/98.31164/45.4",  
  //   attribution:`<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>`,
  //   images: "https://andamantracking.com/file/maptiles/sattlelitelayer.PNG",
  //   groupId: 1
  // },  
   
]

// var Stadia_AlidadeSmoothDark = L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.{ext}', {
// 	minZoom: 0,
// 	maxZoom: 20,
// 	attribution: '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
// 	ext: 'png'
// });
// var Stadia_StamenTonerLines = L.tileLayer('https://tiles.stadiamaps.com/tiles/stamen_toner_lines/{z}/{x}/{y}{r}.{ext}', {
// 	minZoom: 0,
// 	maxZoom: 20,
// 	attribution: '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
// 	ext: 'png'
// });
// var Stadia_StamenTonerLabels = L.tileLayer('https://tiles.stadiamaps.com/tiles/stamen_toner_labels/{z}/{x}/{y}{r}.{ext}', {
// 	minZoom: 0,
// 	maxZoom: 20,
// 	attribution: '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
// 	ext: 'png'
// });

const featureGroup=[
  // {
  //   name: 'LabelToner', 
  //   nameEN: "LabelToner",  
  //   url: 'https://tiles.stadiamaps.com/tiles/stamen_toner_lines/{z}/{x}/{y}{r}',
  //   attribution:'&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  //   images: "../../assets/img/layer-labels.png",
  //   groupId:1
  // },
  // {
  //   name: 'LineToner', 
  //   nameEN: "LineToner",  
  //   url: 'https://api.mapbox.com/styles/v1/bthongbut/clrsk4aax007j01qvcofqa35m.html?title=copy&access_token=pk.eyJ1IjoiYnRob25nYnV0IiwiYSI6ImNsNGo5b3Q3ZjE1NG8zcXN2aGxrZDRtbnIifQ.r0o9Uw4EkOMCVQXY1De2gw&zoomwheel=true&fresh=true#5.06/10.56/109.72',
  //   attribution: 'Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>',
  //   images: "../../assets/img/layer-roadlines.png",
  //   groupId:1
  // },
  {
    name: 'Traffic', 
    nameEN: "Traffic",  
    url: 'https://mstraffic1.simplethai.net/mmmap/tile.php?proj=epsg3857&mode=trafficoverlay&zoom={z}&x={x}&y={y}&HD=1&key=736fb33b73ca6dd992f9d618cee793c6',
    attribution: "© Longdo Traffic Map",
    images: "../../assets/img/layer-traffic.png",
    groupId:1
  }
]

export const LayersExpandControl=({defaultmap}:any)=>{
  const defaultMap :any= useSelector(getDefaultMap)
  const [show,setShow] = useState(false)
  const dispatch = useDispatch()
  const [fullcontrol , setFullControl] = useState(false)  
  const [trafficLayer,setTrafficLayer] = useState<any>(null)
  const [layerModal ,setLayerModal ] = useState(false)
  const tileProviders = useSelector(getTileproviders)
  const map = useMap() 

  const selectLayer=(tiles:any)=>{
    dispatch(setDefaultMap(tiles?.name)) 
  } 
  const setDetailLayer=(tiles:any)=>{ 
    if(tiles.name === "Traffic" && !trafficLayer ){ 
      var traffic = L.tileLayer(tiles.url, {
        attribution: tiles.attribution ,
       }).addTo(map);
       setTrafficLayer(traffic)
    }else if(tiles.name === "Traffic" ){
      map.eachLayer(function (layer:any) {  
        if(layer?.options?.attribution === tiles.attribution){
          map.removeLayer(layer)
        }
        setTrafficLayer(null) 
      }) 
    }

  }

  useEffect(()=>{
    const defaulttile=()=>{
      if(!defaultmap ){ defaultmap = "Longdo"}
      let current = tileProviders.find((tile:any)=> tile.name?.toLowerCase().indexOf(defaultmap?.toLowerCase()) > -1 )
     
      selectLayer(current)
      dispatch(setDefaultMap(defaultmap))
    }
    defaulttile()
  },[])
  
  return(<> 
  <div className="ion-hide-md-up layers-expand-control-btn" onClick={()=>{setLayerModal(true)}}  >
    {
      tileProviders.filter((tile:any)=> tile.name?.toLowerCase().indexOf(defaultMap?.toLowerCase()) === 0 ).map((tile:any,index:any)=> 
      <div key={index}   className="layer" style={{backgroundImage: `url(${tile.images})`,width:"4rem",height:"4rem",backgroundSize:"cover",borderRadius:"5px"}} >   </div>
      )
    }
  </div>
    <IonModal isOpen={layerModal} initialBreakpoint={0.4} breakpoints={[0.4,0.5,0.6]} onIonModalDidDismiss={()=>{setLayerModal(false)}}  className="ion-hide-md-up"   > 
      <LayerFullControl setFullControl={(e:any)=>{setFullControl(e)}}  selectLayer={(e:any)=>{selectLayer(e)}}  setDetailLayer={(e:any)=>{setDetailLayer(e)}}/>  
    </IonModal>
  <div className={`layers-expand-control ion-hide-md-down`} onMouseEnter={()=>{setShow(true)}}  onMouseLeave={()=>{setShow(false)}} > 
    {
      tileProviders.filter((tile:any)=> tile.name?.toLowerCase().indexOf(defaultMap?.toLowerCase()) > -1 ).map((tile:any,index:any)=> 
      <div key={index}  className="layer" style={{backgroundImage: `url(${tile.images})`}} >  </div>
      )
    }
   {show && <div className={`layers-tile-expand  `} >
    {tileProviders.slice(0,3).map((tile:any,index:any)=> 
       <div key={index} className="layer" style={{backgroundImage: `url(${tile.images})`}}  onClick={()=>{selectLayer(tile)}}> 
         <small className="name"  >{tile.name.substr(0,6)}</small>
       </div> 
    )}
    <div className="layer set-center" style={{background:"#EEE"}}  onClick={()=>{setShow(false);setFullControl(true)}} >  
      <IonIcon icon={layersOutline}  style={{fontSize:"larger"}} />
      <small className="name"  > More </small>
    </div>
    </div> }
  </div>
    {
      tileProviders.filter((tile:any)=> tile.name?.toLowerCase().indexOf(defaultMap?.toLowerCase()) > -1 ).map((tile:any,index:any)=> 
      <TileLayer
        key={index}
        attribution={tile.attribution}
        url={ tile.url}
      />)}  
    {fullcontrol &&<div className="layers-expand-full-control" >
      <LayerFullControl setFullControl={(e:any)=>{setFullControl(e)}}  selectLayer={(e:any)=>{selectLayer(e)}}  setDetailLayer={(e:any)=>{setDetailLayer(e)}}/> 
   </div>} 
  </>)
}
 
const LayerFullControl=({setFullControl,selectLayer,setDetailLayer}:any)=>{
  const tileProviders = useSelector(getTileproviders)
  return( 
    <IonGrid style={{width:"100%"}}>
      <IonToolbar color={"transparent"}>
        <IonLabel style={{marginLeft:"1rem"}} >Map Type</IonLabel>
        <IonButton slot="end" fill="clear" size="small" onClick={()=>{setFullControl(false)}} >
          <IonIcon icon={closeOutline} />
        </IonButton> 
      </IonToolbar> 
      <IonRow className="ion-justify-content-between ion-xs-padding" >
      {tileProviders.map((tile:any,index:any)=> 
         <IonCol key={index} sizeXs="3.5"  sizeMd="4" style={{position:"relative",paddingBottom:"1rem"}}>
            <div className="layer" 
              style={{backgroundImage: `url(${tile.images})`,width:"100%",height:"100%",minHeight:"4rem",borderRadius:"10px" }}  
              onClick={()=>{selectLayer(tile)}}> 
              <small className="name" style={{position:"absolute",bottom:"0rem",left:".5rem"}} >{tile.name.substr(0,6)}</small>
            </div> 
         </IonCol>
        )}
      </IonRow><br/>
      <IonToolbar color={"transparent"}>
        <IonLabel style={{marginLeft:"1rem"}} >Map Details</IonLabel>  
      </IonToolbar>  
      <IonRow className="ion-justify-content-betwee ion-padding"> 
      {featureGroup.map((tile:any,index:any)=> 
         <IonCol key={index}  sizeXs="3.5"  sizeMd="4" style={{position:"relative",paddingBottom:"1rem"}}>
            <div className="layer" style={{backgroundImage: `url(${tile.images})`,width:"100%",height:"100%",minHeight:"4rem",borderRadius:"10px"}}  
              onClick={()=>{ setDetailLayer(tile) }}
            > 
              <small className="name" style={{position:"absolute",bottom:"0rem",left:".5rem"}} >{tile.name.substr(0,)}</small>
            </div> 
         </IonCol>
        )}
      </IonRow>
    </IonGrid> 
  )
}

export const LayersControlMap=()=>{ 
  // const defaultMap :any= useSelector(getDefaultMap)
  const [defaultmap ,setDefaultmap] = useState("longdo")
  const dispatch = useDispatch() 
  const [roadnet, setRoadnet]  = useState(null)   
  const [showRoadnet,setShowRoadnet] = useState(true)
  const isDarkMode :any= useSelector(getDarkMode)
  const tileProviders = useSelector(getTileproviders)
  const esriToken = "AAPK0d522380d96a4c0e929572fc5a68feceDS20CU6T7yhywMekEMVmTLTT7uMXb6a-ynqWxQEDhwu6jIZsynnC7EKOoijWMl7g"
  maptilersdk.config.apiKey = 'RpNDwv39Bwb6jrwmO3n1';

  const map = useMap()
  const mapevent = useMapEvents({
    click: () => {
      // mapevent.locate()
    },
    zoom:()=>{ 
      if(map.getZoom()>12){ 

        setShowRoadnet(true)
      }else{  
        setShowRoadnet(false)
      }
    }
  })  

  useEffect(()=>{
    const getdefaultSett=async()=>{ 
      let defaultset = await getStorage("default") 
      setDefaultmap(defaultset?.defaultMap)
    }
    getdefaultSett() 

    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    map?.invalidateSize()
    // if(prefersDark.matches){ 
    //   dispatch(setDefaultMap('StadiaAlidadeSmoothDark'))
    // }else{ 
    //   dispatch(setDefaultMap('OpenStreetMap'))
    // }
    
    // if(isDarkMode){ 
    //   dispatch(setDefaultMap('Satellite | ESRI'))
    //   setDefaultmap('Satellite | ESRI')
    // }  
  },[ map ,isDarkMode])
 

  const parseJsonToGeoJson=async()=>{
    let mygeojson:any = {type: "FeatureCollection", features: []}
    Roadnet.data.map((point:any)=>{ 
      let coordinate = [parseFloat(point.longitude), parseFloat(point.latitude)];
      let properties = {point , ...{  
        "name": `roadnet-${parseFloat(point.longitude)},${parseFloat(point.latitude)}`,  
        "iconUrl":`http://portal.attg.cc/file/roadnet.svg`,
        "iconSize": [20, 20], 
    }};       
      let feature:any = {"type": "Feature", "geometry": {"type": "Point", "coordinates": coordinate}, "properties": properties}
      //@ts-ignore
      mygeojson.features.push(feature);
    }) 
    
   setRoadnet(mygeojson)   
  }

  useEffect(()=>{
    parseJsonToGeoJson()   
  },[])

  function createMarker(feature:any, latlng:any) {
    let mark = L.marker(latlng ,{ icon :L.icon({
        iconUrl:`http://portal.attg.cc/file/roadnet.svg`,
        iconSize: [20, 20], 
      })
    })   
    return mark
  } 

  return (<> 
    <LayersControl 
    // @ts-ignore
    position={'topright'} >  
   
    { 
      tileProviders.map((tile:any)=>  
        <LayersControl.BaseLayer key={tile.name} name={tile.name} 
        checked={ defaultmap?.toLowerCase() === tile.name?.toLowerCase() ? true:false}>  
             
               {
                    tile.groupId === 1?
                    <TileLayer 
                      attribution={tile.attribution}
                      url={tile.url}
                    /> : 
                    <ReactLeafletGoogleLayer
                      apiKey={""}
                      //AIzaSyCBZkyGixJtsUWNlF-apgT7HI8W5Cur71I
                      //@ts-ignore 
                      type={tile.name}
                    /> 
                       
            } 

      </LayersControl.BaseLayer >
     )}   
    </LayersControl> 
    </>
  )
}

export let indexRoute =0
export let indexRouteList =0
export const PlayControlFloat: React.FC = ({play,pause,stop}:any) => {
  const dispatch = useDispatch()
  const routeid :any= useSelector(getRouteId)
  const list :any= useSelector(getRouteList)
  const position :any= useSelector(getControlPosition)
  const [point,setPoint] = useState({x:0, y:0})
  const [speeds,setSpeeds] = useState([{name:"4x",val:200},{name:"3x",val:400},{name:"2x",val:800},{name:"1x",val:1000},{name:"-1x",val:1200},{name:"-2x",val:1400}])
  const speed :any= useSelector(getSpeedPlay)
  const playing  :any= useSelector(getRouteId)
  const playSize :any= useSelector(getPlaySize)
  const indexPlay :any= useSelector(getIndexRoute)
  const [action,setAction] = useState("pause")
  const [playinterval,setPlayInterval] = useState(0)
  const changePosition=(e:any)=>{
    setPoint({x:e.clientX , y:e.clientY})
  }
  let interval :any;
  useEffect(()=>{
    setPoint(position)
    if(routeid !== ""){
      indexRouteList = list.findIndex((e:any)=> e._id === routeid) 
      if(indexRouteList === undefined || indexRouteList === null){
        indexRouteList = 0
      }
    }

  },[point,indexPlay,playSize])

  const speedFormat=(speed:any)=>{
    if(speed >= 1000 && speed <1200){
      return "1 sec"
    }else if(speed >= 1200 && speed <1400){
      return "1.2 sec"
    }else if(speed >= 1400  ){
      return "1.4 sec"
    }else{
      return speed+" millisec"
    }
  }
 

  const changeSpeed=(speed:any)=>{
    clearInterval(playinterval)
    dispatch(setSpeedPlay(speed))
  }
  const stopPlay=()=>{
    try{  
      setAction("stop")
      indexRoute =0
      clearInterval(playinterval)
    }catch(err){
      console.log(err)
    }
  
  }

  const closeControl=()=>{
    stopPlay()
    dispatch(setRouteID(""))
    indexRoute =0
  }

  return(
    <>{routeid !== "" ?
        <Draggable   >
        <div className="play-control-float" 
        style={{left:point.x+'px', top:point.y+'px'}}> 
         
          <div className="title ion-text-justify"    > 
            <IonRow>
              <IonCol size="10" > <IonLabel >REPLAY TOOLBAR</IonLabel>  </IonCol>
              <IonCol size="2"  className="set-center">  <IonIcon icon={close} onClick={()=>{closeControl()}} />  </IonCol>
            </IonRow> 
          </div>
          <div className="speed-list" >
            {speeds.map((e,index)=>
            <IonButton key={index} 
            shape="round" color={"secondary"} 
            fill={speed==e.val ?'solid' :'outline'} mode='md' size="small" 
            onClick={()=>{changeSpeed(e.val)}} >
              <small>{e.name}</small>
            </IonButton>
            )}
          </div>
          <IonRow>
            <IonCol size="8" > <IonLabel style={{fontWeight:"bold"}}>Delay {speedFormat(speed)} </IonLabel> </IonCol>
            <IonCol size="4" className="ion-text-right" > <IonIcon icon={eye} color='medium' /> </IonCol>
          </IonRow>
           <IonButton size="small" onClick={()=>{setAction("playing");play()}} fill={action =="playing"?"clear":"solid"} disabled={action =="playing"?true:false}  shape="round" > <IonIcon icon={playCircle} /> </IonButton>
           <IonButton size="small" onClick={()=>{setAction("pause")}}   fill={action =="pause"?"clear":"solid"}   disabled={action =="pause"?true:false}    shape="round" > <IonIcon icon={pause} /> </IonButton>
           <IonButton size="small" onClick={()=>{setAction("stop");stopPlay()}}    fill={action =="stop"?"clear":"solid"}    disabled={action =="stop"?true:false}     shape="round" > <IonIcon icon={stop} /> </IonButton>
        </div>
        </Draggable>:
      <div></div>
      }   </>
  )
}

export const PlayControl = ({speed,setSpeed,play,pauseAction,stopAction}:any) => {
  const dispatch = useDispatch() 
  const [point,setPoint] = useState({x:window.innerWidth / 2, y: window.innerHeight / 2})
  const [speeds,setSpeeds] = useState([{name:"4x",val:200},{name:"3x",val:400},{name:"2x",val:800},{name:"1x",val:1000},{name:"-1x",val:1200},{name:"-2x",val:1400}])
 
  const playSize :any= useSelector(getPlaySize) 
  const action :any= useSelector(getPlayAction) 
  const [speedPopup,setSpeedPopup] = useState(false)
  
  useEffect(()=>{
     
  },[action,point,playSize])

  const speedFormat=(speed:any)=>{ 
    if(speed.val>= 1000 && speed.val <1200){
      return "1 sec"
    }else if(speed.val >= 1200 && speed.val <1400){
      return "1.2 sec"
    }else if(speed.val >= 1400  ){
      return "1.4 sec"
    }else{
      return speed.val+" millisec"
    }
  } 

  const changeSpeed=(speed:any)=>{
    console.log("action ",action) 
    return setSpeed(speed) 
     
  }

  const closeControl=()=>{
    dispatch(setRouteID(""))
    dispatch(setRouteSelected(null))
    dispatch(setFastTrack(null))
    indexRoute =0 
    return stopAction()
  }
  

  return(  <>
        <div className="play-control-mobile " style={{right:".5rem",bottom: "10rem"}} >
          <IonButton size="small"  fill="clear"   color="primary"  shape="round"  id="speed-popup" onClick={()=>{setSpeedPopup(true)}}> 
             <small>{speed.val}</small> 
         </IonButton>
         <IonPopover isOpen={speedPopup} onDidDismiss={()=>{setSpeedPopup(false)}} trigger="speed-popup" side="right" alignment="start">
              <IonContent>
              {speeds.map((e,index)=>
                  <IonButton key={index} shape="round" color={"secondary"} 
                    fill={speed.val==e.val ?'solid' :'outline'} 
                    mode='md' size="small" 
                    onClick={()=>{pauseAction();changeSpeed(e)}} 
                  ><small>{e.name}</small></IonButton>
              )}
              </IonContent>
            </IonPopover>
         <div style={{width:"80%",height:'3px',background:"#EEE",borderRadius:"5px",margin:"5px 0 5px"}} > </div>
          <IonButton size="small" 
           onClick={()=>{return play()}} 
           fill={action ==="playing"?"outline":"solid"}  color="primary"  shape="round" > 
             <IonIcon icon={playCircle}  /> 
           </IonButton>
           <IonButton size="small" onClick={()=>{return pauseAction()}}   
           fill={action ==="pause"?"outline":"solid"}   color="primary"   shape="round" > 
             <IonIcon icon={pause} /> 
           </IonButton>
           <IonButton size="small" onClick={()=>{return stopAction()}}    
           fill={action ==="stop"?"outline":"solid"}    color="primary"  shape="round" > 
            <IonIcon icon={stop} /> 
           </IonButton>
        </div> 
    </>
  )
}

export const Invalidate=()=>{
  const map = useMap()
  useEffect(()=>{ map.invalidateSize() },[map])

 return(<></>)
}

export const MarkerPlayRoute=()=>{
  const routeid :any= useSelector(getRouteId)
  const list :any= useSelector(getRouteList) 
  const map = useMap()

  useEffect(()=>{
      map.invalidateSize()
     if(indexRoute > 0){
      console.log("list[indexRouteList].list["+indexRoute+"] ",list[indexRouteList].list[indexRoute])
      // map.setView([
      //    list[indexRouteList].list[indexRoute].latitude , 
      //    list[indexRouteList].list[indexRoute].longitude 
      // ])
      //console.log("marker ",list[indexRouteList].list[indexRoute])
    }
  },[indexRoute, routeid]) 

  return(
    <>
    {
      routeid !== "" && list[indexRouteList].list.length > 0 &&  indexRoute <= list.length-1? 
      <FeatureGroup>
        <Marker icon={ new Icon({ 
          iconUrl: `../assets/types/type-1.png`,
          iconSize: [60, 60],
          rotationOrigin: "center",
          iconAnchor: [25, 70],
          popupAnchor: [0, 0],
          tooltipAnchor: [0, 0],
        })}  
        ref={(marker:any) =>{
          marker?.setLatLng([
            list[indexRouteList].list[indexRoute].latitude , 
            list[indexRouteList].list[indexRoute].longitude 
         ])
        }}
        position={[
           list[indexRouteList].list[indexRoute].latitude , 
           list[indexRouteList].list[indexRoute].longitude 
        ]}
        >  
        </Marker> 
        <Marker   
          ref={(markref:any) =>{
            markref?.setRotationAngle(Number(list[indexRouteList].list[indexRoute].heading))
            markref?.setRotationOrigin("center" )
          }}
          icon={  
            list[indexRouteList].list[indexRoute].status === 7  ? pinArrowMove :
            list[indexRouteList].list[indexRoute].status === 23 ? pinStop:
            list[indexRouteList].list[indexRoute].status === 24 ? pinIdling:
            pinOffline
          }  
          position={[
             list[indexRouteList].list[indexRoute].latitude, 
             list[indexRouteList].list[indexRoute].longitude
          ]}
        />  
        <PopupMarker content={list[indexRouteList].list[indexRoute]} title={list[indexRouteList].name} />
        {/* <Popup>{list[indexRouteList].name}</Popup> */}
      </FeatureGroup>:<></>
      }
    </>
  )
}

export const HistoryRoutePolyline=({route}:any)=>{
  const routeid :any= useSelector(getRouteId)
  const list :any= useSelector(getRouteList)
  const map = useMap()
  useEffect(()=>{
    console.log("route ",route)
    // if(routeid.length > 0 && routeid === route._id ){
    //   map.fitBounds(list[indexRouteList].route)
    // }
  },[routeid])

  return(
    <FeatureGroup>
       <Polyline pathOptions={{ color: route.color }} positions={route.route} />
        
    </FeatureGroup>
  )
}

export const PopupMarker=(props:any)=>{
  const content = props.content
   

  return (
    <>
      <Popup className="popup" >
        <div className="popup-content">
          <IonRow>
            <IonCol size="12" className="ion-text-center" >
              <IonLabel className="popup title">{props.title}</IonLabel>
            </IonCol>
            <IonCol size="12" className="ion-text-center"  >
              {
                content.status === 7 ?
                <IonChip color={"success"}  > Move {content.event_stamp}</IonChip>:
                content.status === 23 ?
                <IonChip color={"danger"}  > Stop {content.event_stamp}</IonChip>: 
                <IonChip color={"warning"}  > Idle {content.event_stamp}</IonChip>
              } 
            </IonCol> 
          </IonRow>
          <IonRow  style={{borderTop:"1px solid #CCC"}}>
            <IonCol size="12" className="ion-text-center" > 
              <IonLabel className="popup speed" >
                  <IonIcon  icon={speedometer} /> {content.speed} km/h &nbsp; | &nbsp;
              </IonLabel>
              <IonLabel className="popup street-view">
                  <a href={`http://maps.google.com/maps?q=&layer=c&cbll=${content.latitude},${content.longitude}`} target="_blank"><IonIcon  icon={man} /> Street View</a>
              </IonLabel>
            </IonCol> 
          </IonRow>
          {content.driver_id != "" && content.driver_id !== null ? 
          <IonRow  style={{borderTop:"1px solid #CCC"}}>
            <IonCol size="12" className="ion-text-center" >
              <IonLabel className="popup driver"><IonIcon icon={idCard} color="success" />{content.driver_id} {content.driver_message}</IonLabel>
            </IonCol>
          </IonRow>:<></>
          }
          <IonRow style={{borderTop:"1px solid #CCC"}}>
            <IonCol size="12" className="ion-text-center" >
              <IonLabel className="popup address">{content.address}</IonLabel>
            </IonCol>
          </IonRow>
        </div>
      </Popup>
    </>
  )

}

interface MapRouting{
  start: {
      name: String 
      position: {
          lat:  Number
          lng: Number
      }
  }
  end: {
      name: String 
      position: {
          lat:  Number
          lng: Number
      }
  }
}


export interface RouteMapSearch{
  allroutes: []
  subroutes:{ coordinates:[] , distance: Number}
  data: {
    distance : Number 
  }
  features: []
}
export const RouteMapSearch=({modalref}:any)=>{ 
  const maproute:RouteMapSearch|any  = useSelector(getMapRoute)
  const routing:any = useSelector(getMapRouting) 
  const realtimeTrack :any= useSelector(getRealtimeTrack) 
  const preview :any= useSelector(getPreviewDevice) 
  const [zoom,setZoom] = useState(6)  
  const map = useMapEvents({
    click() {
      if(maproute &&  !realtimeTrack && !preview ){
        if(modalref){  
          //slide  down-up top div#container-map-route
          let el = document.getElementById("container-map-route")
          if(el?.classList.contains('slide-out')){
            el?.classList.remove("slide-out") 
            el?.classList.add("slide-in") 
            modalref?.current?.setCurrentBreakpoint(0.1) 
          }else{
            el?.classList.remove("slide-in") 
            el?.classList.add("slide-out") 
            modalref?.current?.setCurrentBreakpoint(0.01) 
          }
          
        }
      } 
    }, 
  })

  const eventHandlers = useMemo(
    () => ({
      tooltipopen(e:any) { 
        e.target.setStyle({color: app.color})  
      },
      tooltipclose(e:any){ 
        e.target.setStyle({color: app.color})  
      },
      popupopen(e:any) { 
        e.target.setStyle({color: app.color})  
      },
      popupclose(e:any){ 
        e.target.setStyle({color: app.color})  
      }
    }),
    [],
  )

  useEffect(()=>{  
    if(maproute &&  !realtimeTrack && !preview){ 
     map.fitBounds(maproute?.allroutes) 
     map.on('zoom',(e)=> {   
      setZoom(e.target._zoom)
     })  
    }
  },[maproute ])

  const distanceTime=(seconds:any)=>{
    let result = ""; 
    if (seconds >= 60 && seconds < 3600) {
        const minutes = Math.floor(seconds / 60);
        result += minutes + " นาที";
        seconds %= 60;
    } 
    if (seconds >= 3600  && seconds < 86400 ) {
        const hours = Math.floor(seconds / 3600);
        result += hours + " ชั่วโมง ";
        seconds %= 3600;
    } 
    if (seconds >= 86400) {
        const days = Math.floor(seconds / 86400);
        result += days + " วัน ";
        seconds %= 86400;
    }  
    return result.trim();
}

  return(maproute &&  <FeatureGroup> 
    {  maproute?.subroutes.map((e:any, coordIndex:any)=>
        <Polyline key={coordIndex} positions={e?.coordinates} pathOptions={{ color: app.color }}  eventHandlers={eventHandlers} >
          { zoom  >= 13 &&<Tooltip   
            direction="bottom"  permanent  opacity={1}
            className="route-road"
            offset={[0,0]}
          > 
             { distanceTime(maproute?.features[coordIndex].properties?.interval) }   
          </Tooltip>} 
        </Polyline>
       )
    } 
  </FeatureGroup>)
}

export const MarkerIcon=({device,markersize ,markerCreate , select ,swiper}:any)=>{ 
  const map = useMap()
  const preview :any= useSelector(getPreviewDevice)
  const showLicense :any= useSelector(getShowLicebse)
  const dispatch = useDispatch() 
  const focusMarker :any= useSelector(getFocusMarker)
  const [popup , setPopup] = useState()
  const maprouting:MapRouting | any = useSelector(getMapRouting)
  const [ionaction] = useIonActionSheet();
  const [loading, setIonLoading] = useState(false)
  const showname :any= useSelector(getShowLicebse)

  const doActive=async()=>{ 
    map.invalidateSize()
    await map.setView([preview.device.latitude,preview.device.longitude],15) 
    //@ts-ignore
    setTimeout(()=>{
      //@ts-ignore
      popup?.openPopup() 
    },1500) 
  }
   
  useEffect(()=>{  
    if(preview.active && preview.device?.device_id === device?.device_id){  
      // doActive() 
      map.setView([preview.device.latitude,preview.device.longitude],15) 
    }
  },[preview,showLicense,focusMarker])
 

  const clickHandlers = useMemo(() => ({
      click(e:any) {  
        e.target.openPopup()  
        setTimeout(()=>{
          return select()
        },300)
      }, 
      contextmenu(){ 
        
      }
    }),
    [map],
  )
  const markerCreated=(marker:MarkerTpe)=>{  
    if(marker){ 
      var mapBounds = map?.getBounds(); 
      var shouldBeVisible = mapBounds?.contains(marker?.getLatLng()); 
      if ( marker?.getIcon() && !shouldBeVisible  ) {
          map?.removeLayer(marker);
      } else if (!marker?.getIcon() && shouldBeVisible  ) {
          map?.addLayer(marker);
      } 
    }
  }


  const clickMapRouting =async ()=>{
    if(maprouting?.start !== null && maprouting?.end !== null ){
        ionaction({
            header: device.name ,
            cssClass: "actions-markers",
            mode:"ios" ,
            buttons: [
              {
                text: 'ตั้งเป็นต้นทาง', 
                data: {
                  action: 'start',
                },
                handler:async ()=>{
                    console.log("Set Start ")
                    const routing = {
                      start: {
                          name: device.name ,
                          position: {
                              lat: device.latitude ,
                              lng: device.longitude
                          }
                      } ,
                      end: maprouting?.end
                  }
                    await dispatch(setMapRouting(routing))
                    getRoute(routing)
                }
              },
              {
                text: 'ตั้งเป็นปลายทาง', 
                data: {
                  action: 'end',
                },
                handler:async ()=>{  
                    console.log("Set End ")
                    const routing = {
                      start: maprouting?.start  ,
                      end: {
                          name: device.name ,
                          position: {
                              lat: device.latitude ,
                              lng: device.longitude
                          }
                      }
                  }
                    await dispatch(setMapRouting(routing))
                  getRoute(routing)
                }
              },
            ]
        })
    }else{
        try {
            const position = await Geolocation.getCurrentPosition({
                enableHighAccuracy: true,
                timeout: 20000,
                maximumAge: 0
            })
            if(position){
                const lat = position?.coords.latitude 
                const lng = position?.coords.longitude
                let routingbegin ={
                    name: "ตำแหน่งของคุณ" ,
                    position: {
                        lat: lat,
                        lng: lng
                    }
                } 
                let routing = { 
                    start: routingbegin ,
                    end: {
                        name: device?.name ,
                        position: {
                            lat: device?.latitude,
                            lng: device?.longitude
                        }
                    }  
                }
                console.log("routing ",routing)
                await dispatch(setMapRouting(routing))
                getRoute(routing)
            } 
        }catch(err){
            console.log("err ",err)
        } 
    }
}

  const getRoute=async(range:any)=>{  
    setIonLoading(true)
    console.log("maprouting ",maprouting)
    let allroutes:any = [] 
    const lkey = await getLongdokey({})
    const {features , data ,meta} = await axios.get("https://api.longdo.com/RouteService/geojson/route",{
        params:{
            flon: range?.start?.position?.lng,
            flat: range?.start?.position?.lat,
            tlon: range?.end?.position?.lng ,
            tlat: range?.end?.position?.lat,
            mode:'d',
            type: 17,
            locale:'th',
            key:lkey
        }
    }).then((res)=>{ 
        console.log("data ",res.data )
        return res.data
    }).catch(err =>{ setIonLoading(false) })
    console.log("features ",features )
    console.log("data ",data )
    console.log("meta ",meta )

    let subroutes:any = []
    await features.map((fea:any)=>{
        let coordinates = fea.geometry?.coordinates.map((e:any)=> { return [e[1],e[0]] }) 
        var distance = fea?.properties?.distance  
        subroutes.push({coordinates , distance}) 
        allroutes = allroutes.concat(coordinates) 
        map?.fitBounds(allroutes)  
    } )  
    dispatch(setMapRoute({
        allroutes : allroutes ,
        subroutes: subroutes ,
        data: data ,
        features: features
    }))  
    let el = document.getElementById("container-map-route")
    el?.classList.add("slide-in")
    setIonLoading(false)
  }
  
  return(
    <div  > 
      <IonLoading isOpen={loading} onIonLoadingDidDismiss={()=>{setIonLoading(false)}} />
     {
      device !== null && device.event_id !== null  ? 
      <div>
      { <Marker 
       eventHandlers={clickHandlers}
       position={[Number(device.latitude), Number(device.longitude)]} 
       ref={(markerRef:any)=>{  
          if(preview?.active && preview?.device?.name === device?.name){ 
            markerRef?.openPopup()
          }else{
            markerRef?.closePopup()
          } 
           
          if(markerRef !== null){  
            map.on('zoom',(e)=>{   
              if(!showname){ 
                if(e.target._zoom >=  13 ){
                  markerRef?.openTooltip()
                }else{
                  markerRef?.closeTooltip()
                }
              }else{
                if(e.target._zoom < 4 ){
                  markerRef?.closeTooltip()
                }else{
                  markerRef?.openTooltip()
                }
              }
            })
            // if(markerRef){ markerCreated(markerRef) } 
          } 
        }}
       icon={
        device?.image? new DivIcon({ 
          className: "marker-img-divicon" ,
          html: `<div style="background-image:url("${device?.image}");width:4.5rem;height:4.5rem;" > 
            <img src="${device?.image}" style="width:100%;border-radius: 50%;"/>
           </div> `,
          iconSize: markersize.iconSize ,
          iconAnchor: markersize.iconAnchor
        }) :
        device.deviceIconId !== null && device.deviceIconId !== undefined ?
        new Icon({ 
          iconUrl: `../assets/types/type-${device.deviceIconId}.png`,
          rotationOrigin: "center",
          iconSize: markersize.iconSize ,
          iconAnchor: markersize.iconAnchor,
          popupAnchor: [0, 0],
          tooltipAnchor: [0, 0],
        }): new Icon({ 
          iconUrl: `../assets/types/type-1.png`, 
          rotationOrigin: "center",
          iconSize: markersize.iconSize ,
          iconAnchor: markersize.iconAnchor,
          popupAnchor: [0, 0],
          tooltipAnchor: [0, 0],
        })
       }
      > 
        <Tooltip  
            direction="bottom" 
            className="marker-place-name"
            offset={[0, -20]}
            opacity={1}  permanent={showname}
          >
            <IonLabel color={"dark"}>{ 
              device.name2&&device.name2!==""? 
              device.name2:device.name
            }</IonLabel>
        </Tooltip> 
         
        <Popup ref={(p:any)=>{
          p?.on("add",()=>{setPopup(p) })
        }}   offset={[0,-65]}  autoPan={true}   >
          <div id={"popup-marker-device-"+device?.device_id} className="set-center" style={{width:"17rem",fontSize:".9em"}} >  
            <IonGrid className="ion-no-padding" >
              <IonRow>
                  <IonCol size="3"><strong>{t("vehicle")}</strong></IonCol>        <IonCol size="9"> <IonLabel color={"dark"} >{device.name2&&device.name2!==""? device.name2:device.name} </IonLabel></IonCol>
                  <IonCol size="3"><strong>{t("title_status")}</strong></IonCol>   <IonCol size="9"> <IonLabel color={device?.status === 7 ?"success": device?.status === 24?"warning":device?.status === 23 ?"danger":"medium" } ><b>{device?.status_name}</b> {device?.status_time} </IonLabel> </IonCol>
                  <IonCol size="3"><strong>{t("title_time")}</strong></IonCol>     <IonCol size="9"> <IonLabel color={"dark"} >{device?.event_stamp}  </IonLabel></IonCol>
                  <IonCol size="3"><strong>{t("title_speed")}</strong></IonCol>    <IonCol size="9"> <IonLabel color={"dark"} >{device?.speed} {t("kmHUnit")}</IonLabel></IonCol>
                  <IonCol size="3"><strong>{t("title_location")}</strong></IonCol> <IonCol size="9"> <IonLabel color={"dark"} >{device?.address} </IonLabel></IonCol>
                  <IonCol size="3"><strong>{t("position")}</strong></IonCol>       <IonCol size="9"> <IonLabel color={"tertiary"} className="cursor-pointer" onClick={()=> window.open(`https://www.google.com/maps/dir/?api=1&destination=${device?.latitude},${device?.longitude}` ,"_blank")}> <u>{device?.latitude}/{device?.longitude}</u> </IonLabel> </IonCol>
                  <IonCol size="3"><strong>{t("title_driver")}</strong></IonCol>   <IonCol size="9"> <IonLabel color={"dark"} >{device?.fld_driverMessage} {device?.fld_driverID} </IonLabel></IonCol> 
                  <IonCol size="12"  className="set-center  " style={{justifyContent: 'flex-start',flexDirection:"row",paddingTop:".3rem"}}  >
                      <ChipStreetView device={device} />
                      <IconRouteToday device={device} swiper={swiper} ischip={true} />  
                      <IonChip onClick={()=>{return select() }}  color="secondary">
                          <IonLabel  color="dark"><small>More</small></IonLabel>
                          <IonAvatar className="set-center" >
                              <IonIcon icon={chevronForward}  color="dark" />
                          </IonAvatar>
                      </IonChip>
                  </IonCol> 
              </IonRow>
            </IonGrid>
          </div>
        </Popup> 
      </Marker>} 
 
      { <Marker   
          ref={(markRf:any)=>{
            markRf?.setRotationOrigin("center")
            markRf?.setRotationAngle(device.heading)
            // if(markRf){markerCreated(markRf) } 
          }}
          icon={ new DivIcon({
            className: `marker-status-divicon  `, 
            iconSize: markersize.statusiconSize ,
            iconAnchor: markersize.statusiconAnchor,
            html:  `<div style="width:1.3rem;height:1.3rem;border-radius:50%;" class="set-center ${
              device.online===0? 'marker-offline':
              device.speedLimit && device?.speed > device.speedLimit && device.status===7? 'marker-overspeed': 
              !device.speedLimit && device?.speed > 90 && device.status===7? 'marker-overspeed': 
              device.status===7? 'marker-move': 
              device.status===23?'marker-stop': 
              device.status===24?'marker-idle':'marker-offline'
            } " style="padding:1px;border-radius:50%; border:
               ${(device.speedLimit && device?.speed > device.speedLimit && device.status===7) ||( !device.speedLimit && device?.speed > 90 && device.status===7) ? '1px solid red' : 'none'
            }" class="set-center" >  
              <img src="../../assets/icon/circle-up-regular.svg" /> 
              ${device?.ignition ?  '<i class="fa-solid fa-key" style="position: absolute;right:-7px;bottom:-2px;color:orange;z-index:999;" ></i>' :'' }
            </div> `
          })} 
          position={[Number(device.latitude), Number(device.longitude)]} 
             
        /> } 
        </div>:<></>
        }  
      </div>
    )
  }


export const MarkersGeoZones=()=>{
  const zones :any= useSelector(getGeoZones)
  
  useEffect(()=>{
    console.log("zones ",zones)
  },[])

  return(
    <FeatureGroup>
      {
        zones.length>0 ?<>
        {
          zones.map((zone:any)=>
            <MarkerGeoIcon key={zone.geoId} position={zone.points[0]} icon={zone.icon} />
          )
        }
      </>:<></>
      }
      

    </FeatureGroup>
  )
}

export const MarkerGeoIcon=(props:any)=>{ 
  const foncusZone :any= useSelector(getFocusZone)
  const geozones :any= useSelector(getGeoZones)
  const map = useMap()

  useEffect(()=>{
    if(foncusZone){
      // console.log("focus ", foncusZone , geozones)
      // let find = geozones.find((zone:any)=> zone.geoId === foncusZone)
      //  map.fitBounds(find.points) 
    } 
  },[foncusZone])


//   const clickMapRouting =async ()=>{
//     if(maprouting?.start !== null && maprouting?.end !== null ){
//         ionaction({
//             header: props.name ,
//             cssClass: "actions-markers",
//             mode:"ios" ,
//             buttons: [
//               {
//                 text: 'ตั้งเป็นต้นทาง', 
//                 data: {
//                   action: 'start',
//                 },
//                 handler:async ()=>{
//                     console.log("Set Start ")
//                     const routing = {
//                       start: {
//                           name: props.name ,
//                           position: {
//                             lat: props.position[0],
//                             lng: props.position[1]
//                           }
//                       } ,
//                       end: maprouting?.end
//                   }
//                     await dispatch(setMapRouting(routing))
//                     // getRoute(routing)
//                 }
//               },
//               {
//                 text: 'ตั้งเป็นปลายทาง', 
//                 data: {
//                   action: 'end',
//                 },
//                 handler:async ()=>{  
//                     console.log("Set End ")
//                     const routing = {
//                       start: maprouting?.start  ,
//                       end: {
//                           name: props.name ,
//                           position: {
//                             lat: props.position[0],
//                             lng: props.position[1]
//                           }
//                       }
//                   }
//                     await dispatch(setMapRouting(routing))
//                   // getRoute(routing)
//                 }
//               },
//             ]
//         })
//     }else{
//         try {
//             const position = await Geolocation.getCurrentPosition({
//                 enableHighAccuracy: true,
//                 timeout: 20000,
//                 maximumAge: 0
//             })
//             if(position){
//                 const lat = position?.coords.latitude 
//                 const lng = position?.coords.longitude
//                 let routingbegin ={
//                     name: "ตำแหน่งของคุณ" ,
//                     position: {
//                         lat: lat,
//                         lng: lng
//                     }
//                 } 
//                 let routing = { 
//                     start: routingbegin ,
//                     end: {
//                         name: props?.name ,
//                         position: {
//                             lat: props.position[0],
//                             lng: props.position[1]
//                         }
//                     }  
//                 }
//                 console.log("routing ",routing)
//                 await dispatch(setMapRouting(routing))
//                 //getRoute(routing)
//             } 
//         }catch(err){
//             console.log("err ",err)
//         } 
//     }
// }

  return(
    <>
       <Marker position={props.position}
          icon={new Icon({ 
              iconUrl: `../assets/icon-place/map-icon-${props.icon !== null && props.icon !==  undefined?props.icon: 0}.png`,
              iconSize: [25, 25],
              rotationOrigin: "center",
              iconAnchor: [0, 0], 
            })
       }>
        <Popup>
          <div> 
            <IonChip   color="secondary">
              <IonAvatar className="set-center">
                  <IonIcon icon={arrowRedo}  color="dark" />
              </IonAvatar>
              <IonLabel  color="dark">เส้นทาง</IonLabel>
            </IonChip>
          </div>
        </Popup>
       </Marker>
    </>
  )
}





export const MeasureDistance=()=>{
  const dispatch = useDispatch()
  // const [isopen,setIsopen] = useState(false)
  const measure  :any= useSelector(getIsMeasure)
  const lastPoint :any= useSelector(getLastPoint)
  const measurement :any= useSelector(getMeasurement)
  const distance :any= useSelector(getMeasureDistance)
  const area :any= useSelector(getMeasureArea)

  const closeMeasurement=()=>{
    dispatch(setIsMeasure(false))
    dispatch(setLastPoint({lat:0,lng:0}))
    dispatch(setMeasurement([]))
  }

  // useEffect(()=>{},[isopen])

  const popover = useRef<HTMLIonPopoverElement>(null);
  const [isopen, setIsopen] = useState(false);

  // const openPopover = (e: any) => {
  //   popover.current!.event = e;
  //   setIsopen(true);
  // };


  return(
    <div style={{position:"relative"}}> 
      <IonButton fill="clear" size="small" onClick={()=>{setIsopen(!isopen)}}  color={isopen?"warning":"medium"} style={{cursor:'pointer'}} id="popover-measuerment">
       <FontAwesomeIcon icon={faRulerCombined} style={{fontSize:'1em'}}  /> 
      </IonButton> 

      {/* <IonPopover ref={popover}  translucent
        isOpen={isopen}  backdropDismiss={false} 
        onDidDismiss={() => setIsopen(false)}  
        mode="ios"    className="popover-measuerment"
      >  */}
      { isopen && <div className="measurmement-control"  style={{position:"absolute" , width:"17rem", top:"3rem",right:"2rem"}} >
        <div className="top" >
        <IonRow>
          <IonCol size="10" className="set-center"  style={{alignItems:"flex-start"}} >
             <IonLabel>Measure distance and areas</IonLabel> 
          </IonCol>
          <IonCol size="2" >
             <IonButton fill="clear" size="small" color={"danger"} onClick={()=>{setIsopen(false)}} > <IonIcon icon={closeCircle} /> </IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12" >
            <div className="separate" ></div>
          </IonCol>
          <IonCol size="12" style={{paddingBottom:"0px"}} >
            <IonButton className="  set-center"  fill="clear" color={"primary"} onClick={()=>{dispatch(setIsMeasure(true))}}> 
              <IonIcon icon={addCircleOutline}   /> 
              &nbsp;<IonLabel  style={{fontSize:".7em"}}>Create new measurement</IonLabel> 
            </IonButton>
          </IonCol>
        </IonRow>
        </div>
        {
          measure ? 
        <div className="bottom">
        <IonRow>
          <IonCol size="10" >
             <IonLabel>Measure distance and areas</IonLabel> <br/><br/>
          </IonCol>
          <IonCol size="2" className="ion-text-center" >
            <IonButton fill="clear" size="small"  onClick={()=>{closeMeasurement()}}>  <IonIcon icon={closeCircleOutline} /> </IonButton>
          </IonCol>
          <IonCol size="12" >
            <div className="separate" ></div>
          </IonCol> 
        </IonRow>
        <IonRow>
          <IonCol size="12" >
             <IonLabel color={"medium"}>Last Point</IonLabel> 
          </IonCol>
          <IonCol size="4" >
             <IonLabel >Latitude</IonLabel> 
          </IonCol>
          <IonCol size="8" >
             <IonLabel >{lastPoint.lat}</IonLabel> 
          </IonCol>
          <IonCol size="4" >
             <IonLabel >Longitude</IonLabel> 
          </IonCol>
          <IonCol size="8" >
             <IonLabel >{lastPoint.lng}</IonLabel> 
          </IonCol>
        </IonRow><br/><br/>
        {
          measurement.length > 1 ? 
        <IonRow>
          <IonCol size="12" >
            <div className="separate" ></div>
          </IonCol> 
          <IonCol size="4" >
             <IonLabel >Distance</IonLabel> 
          </IonCol>
          <IonCol size="8" >
             <IonLabel >{distance}</IonLabel> 
          </IonCol>
        </IonRow>:<></>
      }
      {
        measurement.length > 2 ? 
        <IonRow> 
          <IonCol size="12" >
            <div className="separate" ></div>
          </IonCol> 
          <IonCol size="4" >
             <IonLabel >Area</IonLabel> 
          </IonCol>
          <IonCol size="8" >
             <IonLabel >~ {area}  squre kilometer.</IonLabel> 
          </IonCol>
          <IonCol size="12" >
            <div className="separate" ></div>
          </IonCol> 
        </IonRow>:<></>
      }
        <IonRow>
          <IonCol size="12" className="ion-text-right" >
              <IonButton   fill="clear" color={"primary"} style={{width:"fit-content",fontSize:"smaller"}} onClick={()=>{setIsopen(false);closeMeasurement()}}><IonLabel>Finish</IonLabel></IonButton>
          </IonCol>
        </IonRow>
        </div>:<></>
        }
      </div> }
      {/* </IonPopover> */}


      {/* <IonButton fill="clear" size="small" onClick={()=>{setIsopen(!isopen)}}   color={isopen?"warning":"medium"} style={{cursor:'pointer'}} id="popover-measuerment">
       <FontAwesomeIcon icon={faRulerCombined} style={{fontSize:'1em'}}  /> 
      </IonButton> */}
{/* 
      <IonPopover isOpen={isopen}  onDidDismiss={()=>{setIsopen(false)}} mode="ios" className="popover-measuerment" trigger="popover-measuerment" triggerAction="click">
      <div className="measurmement-control" >
        <div className="top" >
        <IonRow>
          <IonCol size="10" className="set-center"  style={{alignItems:"flex-start"}} >
             <IonLabel>Measure distance and areas</IonLabel> 
          </IonCol>
          <IonCol size="2" >
             <IonButton fill="clear" size="small" color={"danger"} onClick={()=>{setIsopen(false)}} > <IonIcon icon={closeCircle} /> </IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12" >
            <div className="separate" ></div>
          </IonCol>
          <IonCol size="12" style={{paddingBottom:"0px"}} >
            <IonButton className="  set-center"  fill="clear" color={"primary"} onClick={()=>{dispatch(setIsMeasure(true))}}> 
              <IonIcon icon={addCircleOutline}   /> 
              &nbsp;<IonLabel  style={{fontSize:".7em"}}>Create new measurement</IonLabel> 
            </IonButton>
          </IonCol>
        </IonRow>
        </div>
        {
          measure ? 
        <div className="bottom">
        <IonRow>
          <IonCol size="10" >
             <IonLabel>Measure distance and areas</IonLabel> <br/><br/>
          </IonCol>
          <IonCol size="2" className="ion-text-center" >
            <IonButton fill="clear" size="small"  onClick={()=>{closeMeasurement()}}>  <IonIcon icon={closeCircleOutline} /> </IonButton>
          </IonCol>
          <IonCol size="12" >
            <div className="separate" ></div>
          </IonCol> 
        </IonRow>
        <IonRow>
          <IonCol size="12" >
             <IonLabel color={"medium"}>Last Point</IonLabel> 
          </IonCol>
          <IonCol size="4" >
             <IonLabel >Latitude</IonLabel> 
          </IonCol>
          <IonCol size="8" >
             <IonLabel >{lastPoint.lat}</IonLabel> 
          </IonCol>
          <IonCol size="4" >
             <IonLabel >Longitude</IonLabel> 
          </IonCol>
          <IonCol size="8" >
             <IonLabel >{lastPoint.lng}</IonLabel> 
          </IonCol>
        </IonRow><br/><br/>
        {
          measurement.length > 1 ? 
        <IonRow>
          <IonCol size="12" >
            <div className="separate" ></div>
          </IonCol> 
          <IonCol size="4" >
             <IonLabel >Distance</IonLabel> 
          </IonCol>
          <IonCol size="8" >
             <IonLabel >{distance}</IonLabel> 
          </IonCol>
        </IonRow>:<></>
      }
      {
        measurement.length > 2 ? 
        <IonRow> 
          <IonCol size="12" >
            <div className="separate" ></div>
          </IonCol> 
          <IonCol size="4" >
             <IonLabel >Area</IonLabel> 
          </IonCol>
          <IonCol size="8" >
             <IonLabel >~ {area}  squre kilometer.</IonLabel> 
          </IonCol>
          <IonCol size="12" >
            <div className="separate" ></div>
          </IonCol> 
        </IonRow>:<></>
      }
        <IonRow>
          <IonCol size="12" className="ion-text-right" >
              <IonButton   fill="clear" color={"primary"} style={{width:"fit-content",fontSize:"smaller"}} onClick={()=>{setIsopen(false);closeMeasurement()}}><IonLabel>Finish</IonLabel></IonButton>
          </IonCol>
        </IonRow>
        </div>:<></>
        }
      </div> 
      </IonPopover>  */}
    </div>
  )
}

export const ButtonIconPlace=()=>{
  const selectIcon  :any= useSelector(getGeoIcon)
  const modal = useRef<HTMLIonModalElement>(null);
  const dispatch = useDispatch()
  const [icons,setIcons] = useState([{
    category_id: 1 ,
    category_name: "การเดินทาง, การติดต่อสื่อสาร",
    icon: [  {id: 9, name: 'PTT'}]
  }])
  
  function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
    if (ev?.detail.role === 'confirm') {
      console.log(`Hello, ${ev.detail.data}!`);
    }
  }
  function confirm() {
    modal.current?.dismiss('confirm');
  }

  useEffect(()=>{
    const fetchIcons=async()=>{  
          let icon:any = await iconPlaces() //iconList({token,apiHost})
          console.log("iconPlaces ",icon) 
          setIcons(icon.category)
        }  
      fetchIcons()
  },[selectIcon]) 

  const chooseIcon=(id:any)=>{
    dispatch(setGeoIcon(id))
  }

  return(
    <>
      <button color={"transparent"} id="open-modal" className="btn-ready-draw icon-place"  >
        <FontAwesomeIcon icon={faIcons} />
      </button>
      <IonModal mode="ios" ref={modal} trigger="open-modal" onWillDismiss={(ev) => onWillDismiss(ev)}>
          <IonHeader className=" ion-no-border"> 
             <IonToolbar>
             <IonTitle slot="start" >ICONs Place</IonTitle>
              <IonButtons slot="end">
                <IonButton strong={true} onClick={() => confirm()}>
                  close
                </IonButton>
              </IonButtons> 
             </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
          <div className="ion-padding" style={{width:"100%",height:"90vh",overflow:"scroll"}}>
                <IonRow style={{marginBottom:"1rem"}}>
                    <IonCol size="3" className="set-center" > 
                        <IonImg
                            src={"../assets/icon-place/map-icon-" + selectIcon.id + ".png"} alt=""
                            style={{marginBottom:".5rem",width:"2.5rem"}}
                        /> 
                        <small style={{ color: "#666", textAlign: "center" ,fontSize:".7em" }}>{"Current Icon"}</small>
                    </IonCol>
                </IonRow>
                {
                    icons.map((icon:any) =>
                    <IonRow key={icon.category_id}  style={{marginBottom:"1rem"}}>
                        <IonCol size="12" >
                           <strong style={{ color: "#000" }}>{icon.category_name}</strong>
                        </IonCol>
                        {
                            icon.icon.map((el:any,index:any)=>
                            <IonCol size="3" className="set-center" style={{position:"relative"}} key={el.id} onClick={()=>{chooseIcon(el)}} >
                                {
                                    selectIcon.id === el.id ?  
                                    <IonIcon icon={checkmarkCircle} color={"success"} style={{position:"absolute",bottom:"2rem",right:".5rem"}}/>:<></>
                                }
                                
                                <IonImg
                                    src={"../assets/icon-place/map-icon-" + el.id + ".png"} alt=""
                                    style={{marginBottom:".5rem",width:"2.5rem"}}
                                />
                                <small style={{ color: "#666", textAlign: "center" ,fontSize:".7em" }}>{el.name}</small>
                            </IonCol>
                        )} 
                    </IonRow>
                )} 
              </div> 
          </IonContent>
        </IonModal>
    </>
  )
}

export const SegmentZoomControl=(props:any)=>{  
  const triggerButton=(trigger:any)=>{ 
    if(trigger === "remove"){
      return props.action(props.zoom-1)
    }else{
      return props.action(props.zoom+1)
    }
  }

  return(
    <div className="segment-zoom-control " > 
      <div className="button-groups " >
        <button color={"light"} className="ripple"  aria-label="remove" onClick={()=>{return triggerButton("remove")}}>
          <IonIcon icon={removeOutline} /> 
        </button>
        <button color={"light"} className="ripple"   aria-label="center">
          <IonLabel >{props.zoom}</IonLabel> 
        </button>
        <button color={"light"} className="ripple"    aria-label="add" onClick={()=>{return triggerButton("add")}}>
          <IonIcon  icon={addOutline} /> 
        </button>
      </div>
    </div>
  )
}

export const DevicesMarker=(props:any)=>{
  const devices :any= useSelector(getBackup)
  const preview :any= useSelector(getPreviewDevice)
  const trackingRealtime :any= useSelector(getTrackRealtime)
  const bounds :any= useSelector(getDeviceBound)
  const map = useMap()
  const isGroup :any= useSelector(getIsGroupDevice)

  useEffect(()=>{

    if(preview.active){
      if(trackingRealtime.length >0){
        let find:any = trackingRealtime.find((track:any)=>track.device_id === preview.device.device_id)
       console.log("trackingRealtime ",trackingRealtime)
        console.log("findtrackingRealtime ",find?.route)
        if(find !==null && find !== undefined){
          map?.fitBounds(find.route)
        }else{ 
          deviceLastPreview()
        }
      }else{
        deviceLastPreview()
      }
    }else{ 
      if(bounds.length>0){
        map?.fitBounds(bounds)
      }
    }

  },[preview,trackingRealtime,bounds,isGroup])

  const deviceLastPreview=async()=>{
    devices.map((device:any)=>{
      if(device.device_id === preview.device.device_id){
        map?.setView([ device.latitude, device.longitude],12)  
      }
    })
  }
  return(
    <> 
    {
      isGroup ?
       <MarkerClusterGroup  
          ref={ (markers:any) =>{
            markers?.on('clusterclick',(a:any)=> {
               a.layer.zoomToBounds();
             }); 
            }
          }
          disableClusteringAtZoom={12}
          spiderfyDistanceMultiplier={2}
          spiderfyOnMaxZoom={false}  
          showCoverageOnHover= {false}
          zoomToBoundsOnClick={false} >
          {
            devices.filter((device:any)=> device.event_id!==null).map((device:any,index:any)=>
              <MarkerIcon key={index} device={device} vehicle={props.vehicle}  />
            )
          }
        </MarkerClusterGroup>:
        <FeatureGroup>
          {
            devices.filter((device:any)=> device.event_id!==null).map((device:any,index:any)=>
              <MarkerIcon key={index} device={device} vehicle={props.vehicle}  />
            )
          }
        </FeatureGroup>
    } 
    </>
  )

}

export const MarkerPassRoute=({index}:any)=>{
  const passroute :any= useSelector(getPassRoute)  
  useEffect(()=>{

  },[passroute])
  
  return( <FeatureGroup>
        {/* {passroute?.list.length > 0? 
        <Marker position={passroute?.route[index]}
            icon={passroute?.deviceIcon !== null && passroute?.deviceIcon !== undefined ?
              new Icon({ 
                iconUrl: `../assets/types/type-${passroute?.deviceIcon}.png`,
                iconSize: [60, 60],
                rotationOrigin: "center",
                iconAnchor: [25, 60], 
              }): new Icon({ 
                iconUrl: `../assets/types/type-1.png`,
                iconSize: [60, 60],
                rotationOrigin: "center",
                iconAnchor: [25, 60], 
              })
            }>
            <Popup minWidth={30} offset={[5, -65]}> 
             <IonGrid>
                <IonRow>
                  <IonCol size="12" ><strong>{t('date')}</strong> {passroute?.list[index].event_stamp}  </IonCol> 
                  <IonCol size="12" ><strong>{t('home_speed')}</strong> {passroute?.list[index].speed} {t('kmHUnit')}</IonCol> 
                  <IonCol size="12" > 
                    <strong>{t('home_address')}</strong><br/>
                    {passroute?.list[index].address}
                  </IonCol>  
                  <IonCol size="9" > 
                    <strong>{t('home_driver')}</strong><br/>
                    {passroute?.list[index].driver_message}
                  </IonCol>
                </IonRow>
             </IonGrid>
               
              </Popup>
            </Marker>:<></>}

            {passroute?.list.length > 0 ?
            <Marker 
            //@ts-ignore 
            rotationOrigin="center"  
            ref={(markerRef:any)=>{
               markerRef?.setRotationAngle(passroute?.list[index].heading)
            }}
            icon={ new DivIcon({
              className: `marker-status-divicon ${ 
                passroute?.list[index].status ===7? 'marker-move': 
                passroute?.list[index].status ===23?'marker-stop': 
                passroute?.list[index].status ===24?'marker-idle':'marker-offline'
              }`,
              iconSize: [20, 20], 
              iconAnchor: [5, 78],  
            })} 
            position={[Number(passroute?.list[index].latitude), Number(passroute?.list[index].longitude)]} 
               
         />
         :<></>
      } */}
  </FeatureGroup> )
}


export const MarkerQuickTrack=({index}:any)=>{
  const fastTrack :any= useSelector(getFastTrack)  
  useEffect(()=>{

  },[fastTrack])
  
  return( <FeatureGroup>
        {fastTrack?.list.length > 0? 
        <Marker position={fastTrack?.bound[index]}
            icon={fastTrack?.deviceIcon !== null && fastTrack?.deviceIcon !== undefined ?
              new Icon({ 
                iconUrl: `../assets/types/type-${fastTrack?.deviceIcon}.png`,
                iconSize: [60, 60],
                rotationOrigin: "center",
                iconAnchor: [25, 60], 
              }): new Icon({ 
                iconUrl: `../assets/types/type-1.png`,
                iconSize: [60, 60],
                rotationOrigin: "center",
                iconAnchor: [25, 60], 
              })
            }>
            <Popup minWidth={30} offset={[5, -65]}> 
             <IonGrid>
                <IonRow>
                  <IonCol size="12" ><strong>{t('date')}</strong> {fastTrack?.list[index].event_stamp}  </IonCol> 
                  <IonCol size="12" ><strong>{t('home_speed')}</strong> {fastTrack?.list[index].speed} {t('kmHUnit')}</IonCol> 
                  <IonCol size="12" > 
                    <strong>{t('home_address')}</strong><br/>
                    {fastTrack?.list[index].address}
                  </IonCol>  
                  <IonCol size="9" > 
                    <strong>{t('home_driver')}</strong><br/>
                    {fastTrack?.list[index].driver_message}
                  </IonCol>
                </IonRow>
             </IonGrid>
               
              </Popup>
            </Marker>:<></>}

            {fastTrack?.list.length > 0 ?
            <Marker 
            //@ts-ignore 
            rotationOrigin="center"  
            ref={(markerRef:any)=>{
               markerRef?.setRotationAngle(fastTrack?.list[index].heading)
            }}
            icon={ new DivIcon({
              className: `marker-status-divicon set-center ${ 
                fastTrack?.list[index].status ===7? 'marker-move': 
                fastTrack?.list[index].status ===23?'marker-stop': 
                fastTrack?.list[index].status ===24?'marker-idle':'marker-offline'
              }`,
              html:`<div style="color:#FFF;" ><i class="fa-regular fa-circle-stop"></i> </div>` ,
              iconSize: [20, 20], 
              iconAnchor: [5, 78],  
            })} 
            position={[Number(fastTrack?.list[index].latitude), Number(fastTrack?.list[index].longitude)]} 
               
         />
         :<></>
      }
  </FeatureGroup> )
}

export const RouteTrackingRealtime=()=>{
  const trackingRealtime :any= useSelector(getTrackRealtime)
  useEffect(()=>{ 
  },[trackingRealtime])
  return(
  <FeatureGroup>
    {
      trackingRealtime.map((route:any,index:any)=>
       <RouteRealtime key={index} route={route} />
      )
    }
  </FeatureGroup>)
}

const RouteRealtime=({route}:any)=>{
  const [lineType,setLineType] = useState("dashed")
  const [color,setColor] = useState(config.color)
  const trackingRealtime :any= useSelector(getTrackRealtime)
  const dispatch = useDispatch()

  useEffect(()=>{
    console.log("RouteRealtime ",route)
    setColor(route?.line.color)
    setLineType(route?.line.isDash?"dashed":"normal")
  },[])

  const handleChangeComplete = (color:any) => {
    setColor(color.hex);
  };

  const saveRouteRealtime=()=>{ 
    let updateRealtime = trackingRealtime

    const tracking = {
      device_id: route.device_id ,
      name: route.name ,
      line: {
          isDash:lineType==="dashed"?true: false  ,
          dashArray:lineType==="dashed"?[5, 10]:null ,
          color: color
      },
      route: route.route
    }
    const indexOf = updateRealtime.findIndex((route:any)=> route.device_id === route.device_id)
    updateRealtime = updateRealtime.slice(0,indexOf)
    updateRealtime[indexOf] = tracking

    dispatch(setTrackRealtime(updateRealtime))
  }

  return(<FeatureGroup>
    {
      route.route.map((point:any,index:any)=>
        <Marker key={index} position={point} icon={
          new DivIcon({
            className: "realtime-point-marker"
          })
        } > 
        </Marker>
      )
    }
    <Polyline 
      positions={route.route} 
      pathOptions={{color: color }}  
      dashArray={   route?.line.dashArray } 
    >
    <Popup>
      <h6><strong> {route.name} </strong></h6>
      <IonRadioGroup value={lineType} onIonChange={(e:any)=>{setLineType(e.detail.value!)}}>
          <IonRadio value="dashed" labelPlacement="end">
             <IonLabel>Dashed line</IonLabel>
          </IonRadio>
          <IonRadio value="normal" labelPlacement="end">
             <IonLabel>Normal line</IonLabel>
          </IonRadio>
      </IonRadioGroup>
      <br/> <br/>
      <IonLabel>Route Color</IonLabel>
      <br/>  <br/>  
      <TwitterPicker color={color} onChangeComplete={handleChangeComplete } />
      <br />
      <IonButton  color={"secondary"} size="small" onClick={()=>{saveRouteRealtime()}} > <IonLabel>Save</IonLabel> </IonButton>
    </Popup>
  </Polyline>
  </FeatureGroup>)
}


export const ToolbarMap=({mode}:any)=>{
   const [toast] = useIonToast()
   const dispatch = useDispatch()
   const showname = useSelector(getShowLicebse)
   
   const updateShowName=async(e:any)=>{
      const res = await setMarkerName({show: e?'show':'hide'})  
      if(res){
          toast({
              message: 'Update success!!',
              ...toastSuccess
          });
            
          setTimeout(async()=>{
            const settings = await userSetting()    
            console.log("setting ",settings)
            dispatch(setIsGroupDevice(settings.groupDevice))  
          },500)
          
        dispatch(setShowLicense(e))
        setStorage('showname',e)
      }else{
        toast({
          message: 'อัพเดตข้อมูลไม่สำเร็จ !!',
          duration: 3000,
          position: 'top',
          color:'warning'
        });
       }  
    }
  
    
  return(
    <div className={`toolbar-map ${mode!==undefined && mode}`} >
      <div  className="toolbar-map-inner set-center" > 
        <MeasureDistance /> 
      </div>
      <div  className="toolbar-map-inner set-center" >
        <StreetviewSelect />
      </div>
      <div  className="toolbar-map-inner set-center" >
        <IonButton size="small" fill="clear"  color={showname?"primary":"medium"} onClick={()=>{updateShowName(!showname)}} >
          <FontAwesomeIcon icon={faTags}  style={{fontSize:'1em'}}/> 
        </IonButton>
      </div>
      {/* <div  className="toolbar-map-inner set-center" >
        <FocusMarker />
      </div> */}
      {/* <div  className="toolbar-map-inner set-center" >
        <ToolbarGeofence />
      </div> */}
    </div>
  )
}

const FocusMarker=()=>{
  const focusMarker :any= useSelector(getFocusMarker)
  const [popupFocus,setPopupFocus] = useState(false)
  const dispatch = useDispatch()

  useEffect(()=>{
      if(focusMarker){
        setPopupFocus(true)
      }else{}
  },[focusMarker])

  return(<>
    <IonButton 
      onClick={()=>{setPopupFocus(true)}} 
      id="popup-focus-marker" fill="clear" 
      size="small" color={"secondary"}  
    >
        <FontAwesomeIcon icon={faLocationDot} style={{fontSize:'1.4em'}}/> 
    </IonButton>
    <IonPopover  mode="ios" showBackdrop={false}  isOpen={popupFocus} 
        onDidDismiss={()=>{dispatch(setFocusMarker(null)); setPopupFocus(false)}} 
        trigger="popup-focus-marker" className="popup-focus-marker"
      >
        <IonContent class="ion-padding">
           
            <IonRow className="ion-justify-content-end" > 
              {!focusMarker &&<IonCol size="10"  className="set-center ion-text-center" >
                <IonLabel color={"medium"} ><small>Select Marker to show device info !!</small></IonLabel>
              </IonCol>}
              <IonCol size="2" className="set-center"  >
                <IonButton size="small" color={"danger"} fill="clear" 
                  onClick={()=>{ setPopupFocus(false) }}
                > 
                  <IonIcon icon={closeCircle} />
                </IonButton>
              </IonCol>
            </IonRow> 
            {focusMarker &&
            <IonGrid> 
              <IonRow>
              <IonCol  style={{paddingBottom:'0'}}>
                  <DeviceName size="1em" name={focusMarker?.name} update={focusMarker?.event_stamp} showRatePonit={true} />  
                  <DeviceStatusNameTime online={focusMarker?.online} status={focusMarker?.status} statusName={focusMarker?.status_name} statusTime={focusMarker?.status_time} />
                    <br/>
                  <small>{focusMarker?.event_stamp}</small> 
                </IonCol>  
              </IonRow>
            </IonGrid>
            
            } 
           
        </IonContent>
   </IonPopover>
  </>)
}
 
const StreetviewSelect=()=>{
  const dispatch = useDispatch()
  const streetview :any= useSelector(getStreetView) 
  const istreetview :any= useSelector(getOpenStreetView)  

  useEffect(()=>{
      
  },[])
  
  return(<>
      <IonButton 
        onClick={()=>{dispatch(setStreetView(!streetview)); dispatch(setOpenStreetView(!istreetview)) }} 
        fill="clear" size="small" color={streetview?"warning":"medium"}  
      >
        <FontAwesomeIcon icon={faStreetView}  style={{fontSize:'1.4em'}}/> 
      </IonButton>

  </>)
} 
export const DevicesInZone=({devices,zone }:any)=>{
  const swiper = useSwiper()
  const dispatch = useDispatch()

  return(<div>
    <IonButton size="small" color={"dark"} fill="clear" onClick={()=>{dispatch(setFocusZone(null));swiper.slidePrev()}} >
     <IonIcon icon={chevronBack} />&nbsp; {zone?.name}
    </IonButton>
    <div className="toolbar-geofence-popup-content ion-padding"  style={{height:"75vh",borderRadius:"10px",marginTop:"1rem"}}>
       {
        devices.map((dev:any , index:any)=> 
        <IonItem  
          key={index} lines="none" style={{borderBottom:"1px solid #DDD", cursor:"pointer"}}
          color={"transparent"} className="ion-text-wrap" 
        > 
          <IonLabel>{dev.name}</IonLabel> 
        </IonItem>
        )
       }
    </div>
  </div>)
}
export const ToolbarGeofenceItem=({zone,selected}:any)=>{
  const swiper = useSwiper() 
  return(
    <IonItem 
    onClick={()=>{swiper.slideNext() ;return selected(zone)}}
    lines="none" color={"transparent"} className="ion-text-wrap" 
    >
      <IonImg src={`../assets/icon-place/map-icon-${zone?.icon !== null && zone?.icon !==  undefined?zone?.icon: 0}.png`} style={{ width:"1.3rem",marginRight:".5rem" }} />
      <IonLabel style={{fontSize:".9em"}} >{zone?.name}</IonLabel>
      <IonIcon icon={chevronForward} slot="end" style={{fontSize:".9em"}} />
    </IonItem>
  )
}

const MapInvalidate=({position}:any)=>{
  const map = useMap()
  useEffect(()=>{ 
    map.invalidateSize()
    
    setTimeout(()=>{
      if(map){
        map.invalidateSize()
        // map.setView(position, 17) 
      }
    },500)
  },[map])

  return(<FeatureGroup>
     <Marker 
          position={position} 
          icon={ new Icon({
              iconUrl:  "../assets/icon-place/map-icon-0.png", 
               iconSize: [30, 30],
            })
          }/>
  </FeatureGroup>)
}

export const EventMap=({position}:any)=>{
  const defaultmap = {
      name:'longdo',
      nameEN: "ลองดู",  
      url: "https://longdomap.attg.cc/mmmap/img.php?zoom={z}&x={x}&y={y}&key=cc2cfcc6fd6e2673395611b352382892&proj=epsg3857&HD=1",
      attribution: "© Longdo Map",
      images: "https://andamantracking.com/file/maptiles/longdolayer.PNG",
      groupId:1
    } 
    
  return(
      <div style={{ borderRadius:'10px'}}>  
      <MapContainer 
        zoomControl={false}  maxZoom={20} 
        center={position}
        zoom={10} 
        id="map-event"
        scrollWheelZoom={true} 
        ref={(comp:any) => {
          comp?.invalidateSize() 
          comp?.setView(position)
          comp?.on("zoom",()=>{
            comp?.invalidateSize()
          })
        }} 
      > 
       <MapInvalidate position={position} /> 
       <FeatureGroup> 
        {/* <ZoomControl position="topleft"  />    */}
        <TileLayer 
          attribution={"© Longdo Map"}
          url={"https://longdomap.attg.cc/mmmap/img.php?zoom={z}&x={x}&y={y}&key=cc2cfcc6fd6e2673395611b352382892&proj=epsg3857&HD=1"} />
      </FeatureGroup>

       <LayersControlMap   />
      </MapContainer>
  </div>
  )
}
 

export default  {} 
  

