import { IonAccordion, IonAccordionGroup, IonButton, IonButtons, IonCard, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonMenuButton, IonModal, IonPage, IonRadio, IonRadioGroup, IonRoute, IonRow, IonSearchbar, IonSegment, IonSegmentButton, IonSpinner, IonText, IonTextarea, IonTitle, IonToolbar, useIonAlert, useIonToast } from "@ionic/react"
import { useEffect, useLayoutEffect, useRef, useState, useTransition } from "react"
import { useDispatch, useSelector } from "react-redux"
import { allDevices, allGeozones, allGeozonesOrder, allRoutesSet, deviceByGroup, deviceInGeometry, editDevice, geozoneDetail, getPttpricing, getStorage, repCarsDailyUse, setBehaviorIdleTime, setBehaviorSpeeding, setDeviceIcon, setDeviceName, setStorage, settingConsumption, editDeviceRoute, toastFail, toastSuccess, userSetting, getNotifyMessage, notifications, menuFilter } from "../actions"
import { setDevices, setBackupDevices, getBackup, getDevices, setFilterDevices, getIsGroupDevice, setSignin, getDeviceGroup, setDeviceGroup, getUnReadMsg, setNotifierMessage, setUnreadMsg, getProfile, getIsSignin } from "../store/appSlice"
 
import { FeatureGroup, MapContainer } from "react-leaflet"
import { DevicesInZone,  LayersExpandControl, MarkerIcon, PlayControl, RouteMapSearch, ToolbarGeofenceItem, ToolbarMap } from "../leaflet/LeafletLayers" 
import { Swiper, SwiperSlide } from "swiper/react"
import { getFastTrack, getGeoZones,   getIsMeasure, getMapRoute, getMapRouting, getPreviewDevice, getRouteIndex, setFocusZone, setGeozones, setGeozonesBackup, setMapRoute, setMapRouting, setPlayAction, setPreviewDevice, setRouteIndex } from "../store/mapSlice"
import { DeviceSensorTable, PreviewRouteHistory, SummaryDevice } from "../components/DevicesContainer"
import { t } from "i18next"
import { grid, gridOutline, move, carSport, carSportOutline, stopOutline, cloudOffline, cloudOfflineOutline, stop, closeCircleOutline,   arrowForward, closeOutline, swapVerticalOutline, chevronForward, filterOutline, closeCircle,  addCircleOutline, trashBin, carOutline, prismOutline, notificationsOutline } from "ionicons/icons"
import { getContentFilter, setAddress, setCommand, setContentFilter, setEmei, setExpireDate, setIconFilter, setMessage, setName, setNavigation, setPhoneNumber, setQuickTrack, setRealtimeTrack, setSensorBattery, setSensorCardName, setSensorCardNumber, setSensorFuel, setSensorGPS, setSensorGSM, setSensorMile, setSensorPTO, setSensorSpeed, setSensorTemperature, setShare, setStatusName, setToAll, setUpdateTime } from "../store/filterSlice"
import ListDevice from "../components/ListDevice"
import MarkerClusterGroup from "react-leaflet-cluster"
import L, { Map as MapType , Marker as MarkerType, PointExpression} from "leaflet"
import { GeofencesMarker, MeasureDistanceFeature, RouteQuickTrack, SelectPlaceToStreetView } from "../leaflet/TrackingMap"
import LoadingProgress from "../components/LoadingProgress" 
// import { useCookies } from "react-cookie"
import { Redirect } from "react-router"
import { Swiper as SwiperType } from "swiper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTurnUp, faTurnDown, faUpLong, faCircle, faBridge, faLocationDot, faFerry, faGasPump, faSatelliteDish, faTachometer, faTachometerAltFast, faTruckFast, faDrawPolygon, faTable, faBell } from "@fortawesome/free-solid-svg-icons"
import { FullscreenControl } from "react-leaflet-fullscreen";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { DialogDevice, SettingIcon } from "../components/OptionDialogs"
import { DeviceStatusNameTime } from "../components/IconStatus"
 
import DevicesTable from "../components/DevicesTable"
import { DeviceAsGroup, PreveiewDevice, PttPriceType } from "./schema"
 
import { NotiferMessageContent, NotificationsButton } from "../components/Menu"
import { getNotify, setNotify } from "../store/menuSlice"
import { getVoiceEnable } from "../store/settingSlice"
import * as Moment from "moment-timezone"
import { extendMoment } from 'moment-range'
import { AuthCheck } from "./Home"
import apiconf from "../api.config.json"
 
import 'react-tabs/style/react-tabs.css';
import "react-leaflet-fullscreen/styles.css";
import "./css/Home.css"

import "leaflet/dist/leaflet.css";
import "leaflet-rotatedmarker" 
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw'; 
import { CardDevice, LDevice } from "../components/CardDevice"
import FilterDevices from "../components/FilterDevices"

const moment = extendMoment(Moment);
moment.locale("th")

interface iconsizes{
    iconSize:  PointExpression 
    iconAnchor: PointExpression 
    statusiconSize:PointExpression 
    statusiconAnchor: PointExpression 
} 
const iconsizes:iconsizes[] = [
    {
      iconSize: [40, 40], 
      iconAnchor: [15, 50],
      statusiconSize: [15, 15], 
      statusiconAnchor: [0, 58],  
    },
    {
      iconSize: [50, 50], 
      iconAnchor: [15, 60],
      statusiconSize: [15, 17], 
      statusiconAnchor: [0, 68],  
    },
    {
      iconSize: [60, 60],
      iconAnchor: [25, 70],
      statusiconSize: [20, 20], 
      statusiconAnchor: [5, 78],
    },
]

interface GeoJson{
    type: String 
    features: any[]
  } 
let lastUpdate: any = null;


const HomeScreen: React.FC  =()=>{
    const backup :any= useSelector(getBackup)
    const devices =  useSelector(getDevices)
    const [RfSwip, setRfSwip] = useState<SwiperType | null>(null)
    const [status,setStatus] = useState("all")
    const [keyword,setKeyword] = useState("")
    const template :any= useSelector(getContentFilter)
    const [limit,setLimit] = useState(10)
    const [loading , setLoading] = useState(false)
    // const [cookies, setCookie] = useCookies(['token']);
    const [modalref,setModalRef] = useState<HTMLIonModalElement|null>(null);
    const maprouting :any= useSelector(getMapRouting)
    const [zoneselected , selectZone] = useState<null>(null) 
    const maproute:RouteMapSearch|any = useSelector(getMapRoute) 
    const previewDevice :any= useSelector(getPreviewDevice)
    const [map ,setMapRef] = useState<MapType|null>(null)
    const [mobileMap ,setMobileMapRef] = useState<MapType|null>(null) 
    const fastTrack :any= useSelector(getFastTrack)  
    const [tabs,setTabs] = useState("track")
    const [openEdit ,setOpenEdit] = useState(false)  
    const [group,setGroup] = useState("-1")
    const [showgroup , setSowGroup] = useState(false) 
    const voiceEnable  :any= useSelector(getVoiceEnable) 
    const notify:any = useSelector(getNotify)
    const [signin , setsignin] = useState<any>({username:""})
    const [activity,setActvity] = useState({
        "maxSpeed": 102,
        "move": "03:51:39",
        "stop": "14:56:19",
        "allExpense": 215.19893333333334,
        "lastPttPrice": "* อ้างอิงราคาน้ำมันจาก บริษัท ปตท.จำกัด (มหาชน) วันที่ 09/08/2024 05:00:00 Gasohol E20 35.24",
        "idle": "00:37:04"
    })
    const deviceGroup:DeviceAsGroup[] |any= useSelector(getDeviceGroup);
    const [isPending, startTransition] = useTransition();
    const dispatch = useDispatch() 

    const getMenuFilter=async()=>{
        const menufilter = await menuFilter({});  
        menufilter.sort((a:any, b:any) =>  a.type - b.type)  
        let iconc = menufilter.find((e:any)=> e.type ===10 )
        let iconl = menufilter.find((e:any)=> e.type === 110)

        let namec = menufilter.find((e:any)=>  e.type === 11)
        let namel = menufilter.find((e:any)=> e.type ===111 )

        let imeic = menufilter.find((e:any)=> e.type ===12  )
        let imeil = menufilter.find((e:any)=> e.type === 112)

        let addressc = menufilter.find((e:any)=> e.type ===13  )
        let addressl = menufilter.find((e:any)=> e.type === 113)

        let stsNamec = menufilter.find((e:any)=> e.type ===14 )
        let stsNamel = menufilter.find((e:any)=> e.type === 114)

        let updateTimec = menufilter.find((e:any)=> e.type ===15  )
        let updateTimel = menufilter.find((e:any)=> e.type === 115)

        let navc = menufilter.find((e:any)=> e.type ===16  )
        let navl = menufilter.find((e:any)=> e.type === 116)

        let expirec = menufilter.find((e:any)=> e.type ===17  )
        let expirel = menufilter.find((e:any)=> e.type === 117)

        let phonec = menufilter.find((e:any)=> e.type ===18 )
        let phonel = menufilter.find((e:any)=> e.type === 118)

        let fuelc = menufilter.find((e:any)=> e.type ===19 )
        let fuell = menufilter.find((e:any)=> e.type === 119)

        let speedc = menufilter.find((e:any)=> e.type ===20 )
        let speedl = menufilter.find((e:any)=> e.type === 120)

        let batteryc = menufilter.find((e:any)=> e.type ===21  )
        let batteryl = menufilter.find((e:any)=> e.type === 121)

        let gsmc = menufilter.find((e:any)=> e.type ===22  )
        let gsml = menufilter.find((e:any)=> e.type === 122)

        let gpsc = menufilter.find((e:any)=> e.type ===23 )
        let gpsl = menufilter.find((e:any)=> e.type === 123)

        let milec = menufilter.find((e:any)=> e.type ===24 )
        let milel = menufilter.find((e:any)=> e.type === 124)

        let tempc = menufilter.find((e:any)=> e.type ===25 )
        let templ = menufilter.find((e:any)=> e.type === 125)
        let driverMessc = menufilter.find((e:any)=> e.type ===26 )
        let driverMessl = menufilter.find((e:any)=>  e.type === 126)
        let driverIdc = menufilter.find((e:any)=> e.type ===27  )
        let driverIdl = menufilter.find((e:any)=> e.type === 127)
        let quickTrackc = menufilter.find((e:any)=> e.type ===28 )
        let quickTrackl = menufilter.find((e:any)=> e.type === 128)
        let sharec = menufilter.find((e:any)=> e.type ===29 )     
        let sharel = menufilter.find((e:any)=> e.type === 129)
        let messagec = menufilter.find((e:any)=> e.type ===30 )
        let messagel = menufilter.find((e:any)=> e.type ===130 )
        let trackRealtimec = menufilter.find((e:any)=> e.type ===31 )
        let trackRealtimel = menufilter.find((e:any)=> e.type === 131)
        let ptoc = menufilter.find((e:any)=> e.type === 32 )
        let ptol = menufilter.find((e:any)=> e.type === 132)
        let cmdc = menufilter.find((e:any)=> e.type === 33)
        let cmdl = menufilter.find((e:any)=> e.type === 133)
     
        dispatch(setIconFilter({card: iconc?.card ,list: iconl?.list ,type: template === "card" ? iconc?.type : iconl?.type }))
        dispatch(setName({card: namec?.card,list: namel?.list , type: template === "card" ? namec?.type : namel?.type})) 
        dispatch(setEmei({card: imeic?.card,list: imeil?.list ,type:  template === "card" ? imeic?.type : imeil?.type })) 
        dispatch(setAddress({card: addressc?.card,list:addressl?.list ,type: template === "card" ? addressc?.type : addressl?.type  })) 
        dispatch(setStatusName({card: stsNamec?.card,list:stsNamel?.list ,type: template === "card" ? stsNamec?.type : stsNamel?.type  })) 
        dispatch(setUpdateTime({card:updateTimec?.card,list:updateTimel?.list ,type:template === "card" ? updateTimec?.type : updateTimel?.type  })) 
        dispatch(setNavigation({card:navc?.card,list:navl?.list  ,type: template === "card" ? navc?.type : navl?.type  })) 
        dispatch(setExpireDate({card:expirec?.card,list:expirel?.list ,type:template === "card" ? expirec?.type : expirel?.type  })) 
        dispatch(setPhoneNumber({card:phonec?.card,list:phonel?.list ,type: template === "card" ? phonec?.type : phonel?.type  })) 
        // dispatch(setRatePoint({card:e?.card,list:updateTime?.list})) 
      
        dispatch(setSensorFuel({card: fuelc?.card,list: fuell?.list ,type: template === "card" ? fuelc?.type : fuell?.type  }))   
        dispatch(setSensorSpeed({card: speedc?.card,list: speedl?.list ,type:  template === "card" ? speedc?.type :speedl?.type }))  
        dispatch(setSensorBattery({card: batteryc?.card,list: batteryl?.list ,type:  template === "card" ? batteryc?.type :batteryl?.type }))  
        dispatch(setSensorGSM({card: gsmc?.card,list: gsml?.list ,type: template === "card" ? gsmc?.type :gsml?.type }))    
        dispatch(setSensorGPS({card: gpsc?.card,list: gpsl?.list ,type: template === "card" ? gpsc?.type :gpsl?.type }))      
        dispatch(setSensorMile({card: milec?.card,list: milel?.list ,type:  template === "card" ? milec?.type :milel?.type  }))  
        dispatch(setSensorTemperature({card: tempc?.card,list: templ?.list ,type:  template === "card" ? tempc?.type :templ?.type  }))    
        dispatch(setSensorCardName({card: driverMessc?.card,list: driverMessl?.list ,type:  template === "card" ? driverMessc?.type :driverMessl?.type }))  
        dispatch(setSensorCardNumber({card: driverIdc?.card,list: driverIdl?.list   ,type:   template === "card" ? driverIdc?.type :driverIdl?.type }))   
      
        dispatch(setQuickTrack({card: quickTrackc?.card,list: quickTrackl?.list  ,type:  template === "card" ? quickTrackc?.type :quickTrackl?.type }))   
        dispatch(setShare({card: sharec?.card,list: sharel?.list  ,type:  template === "card" ? sharec?.type :sharel?.type }))   
        dispatch(setMessage({card: messagec?.card,list: messagel?.list   ,type:  template === "card" ? messagec?.type :messagel?.type }))    
        dispatch(setRealtimeTrack({card: trackRealtimec?.card,list:trackRealtimel?.list  ,type:  template === "card" ? trackRealtimec?.type :trackRealtimel?.type })) 
        dispatch(setSensorPTO({card: ptoc?.card,list: ptol?.list ,type:  template === "card" ? ptoc?.type :ptol?.type  })) 
        dispatch(setCommand({card: cmdc?.cardc,list: cmdl?.list   ,type:  template === "card" ? cmdc?.type :cmdl?.type }))   
        dispatch(setToAll(menufilter))  
    }

      
    const boudsdevice =async(devices:any)=>{
        if(devices.length > 0 && !previewDevice?.active && !zoneselected && !fastTrack){ 
            if(lastUpdate === null){
                let bound =await devices.map((el:any)=>{ 
                    return [el.latitude , el.longitude]
                })   
                bound = bound.filter((e:any) =>  e[0] != null &&  e[0] != '-') 
                map?.fitBounds(bound,{ padding: [50 ,-50]}) 
            } 
            map?.on("move",(e)=>{
                map?.invalidateSize()
            })
        }else if(devices.length === 0){ 
            map?.fitBounds([[20.326948, 97.676988] , [18.410117, 104.029578] , [8.303058, 98.217127],[5.638321, 101.143800]],)
            
        }else{
           
        }

       
    } 
    
    const logOut=async()=>{     
        setTimeout(()=>{ 
          localStorage.removeItem("isSignin")
          localStorage.removeItem("token") 
          dispatch(setSignin(false))
        },200)   
    } 

    useEffect(()=>{  
        const getdevices=async()=>{ 
            let signinuser = await getStorage("signin")
            let contFil = await getStorage('content') 
            let geozoneorder = await allGeozonesOrder({})
            dispatch(setGeozones(geozoneorder))
            dispatch(setGeozonesBackup(geozoneorder))
            dispatch(setContentFilter(contFil)) 
            setsignin(signinuser) 
            setLoading(true)
            let result = await allDevices({})  
            result = await result.filter((value:any, index:any, array:any) => 
              array.indexOf(value) === index
            )  
            await dispatch(setDevices(result))
            await dispatch(setBackupDevices(result)) 
            setLoading(false)


            let deviceGroup = await deviceByGroup({})  
            if(deviceGroup.status === 200){
                dispatch(setDeviceGroup(deviceGroup?.devices)) 
            }else{
                logOut()
            }
            
        }
        // getMenuFilter()
        getdevices() 
        getNotifyMess()
        if(maprouting?.start && maprouting?.end){
            console.log(" maprouting ", maprouting?.start)
            let mainpg = document.getElementById("main-page")!
            //@ts-ignore
            mainpg.classList.add("bg-light-shade")
            RfSwip?.slideTo(3)
        }else {
            let mainpg = document.getElementById("main-page")!
            //@ts-ignore
            mainpg.classList.remove("bg-light-shade")
            
        } 
        return(()=>{
            map?.invalidateSize()
        })
    },[maprouting , window.location.pathname ,lastUpdate ])

    const searchDevice=async(val:any)=>{
        setKeyword(val)
        if(val.length > 1){
            setSowGroup(false)
            const needle = val 
            const filter = await backup.filter((e:any)=> e.name?.toLowerCase().indexOf(needle) > -1) 
            dispatch(setDevices(filter))
        }else{
            dispatch(setDevices(backup))
        }
    }

    const changeStatus=async(val:any)=>{ 
        setStatus(val)
        await dispatch(setFilterDevices(val)) 
    }
    const distanceCal=(dis:any)=>{
        if(dis >= 1000 ){ 
            let km = dis/1000 
            return km.toFixed(1)+" กิโลเมตร"
        }else{
            return dis+" เมตร"
        }
    }

    const turncode=(code:any, type:any)=>{
        if(type === 1 ){
            if(code === 0){ return "เลี้ยวซ้าย"}        
            else if(code === 1){ return "เลี้ยวขวา"}
            else if(code === 2){ return "เลี้ยวซ้ายเล็กน้อย"}
            else if(code === 3){ return "เลี้ยวขวาเล็กน้อย"}
            else if(code === 4){ return ""}
            else if(code === 5){ return "ตรงไป"}
            else if(code === 6){ return "เข้าโทลเวย์"}
            else if(code === 9){ return "มาถึง"}
            else if(code === 11){ return "การเดินทางด้วยเรือเฟอร์รี่"}
        }else if(type === 2 ){
            if(code === 0){ return  <FontAwesomeIcon icon={faTurnUp} style={{transform: "rotate(-90deg)"}}/>}        
            else if(code === 1){ return <FontAwesomeIcon icon={faTurnDown} style={{transform: "rotate(-90deg)"}} /> }
            else if(code === 2){ return <FontAwesomeIcon icon={faUpLong} style={{transform: "rotate(-20deg)"}} />}
            else if(code === 3){ return <FontAwesomeIcon icon={faUpLong} style={{transform: "rotate(20deg)"}} />}
            else if(code === 4){ return <FontAwesomeIcon icon={faCircle}  />}
            else if(code === 5){ return <FontAwesomeIcon icon={faUpLong} />}
            else if(code === 6){ return <FontAwesomeIcon icon={faBridge} />}
            else if(code === 9){ return <FontAwesomeIcon icon={faLocationDot} />}
            else if(code === 11){ return <FontAwesomeIcon icon={faFerry} />}
        }
    }
    const viewdistance=(fea:any)=>{  
        const coordinates = fea?.geometry?.coordinates.map((e:any)=>{ return [e[1],e[0]] })
        map?.fitBounds(coordinates) 
    }

    const distanceTime=(seconds:any)=>{
        let result = ""; 
        if (seconds >= 60 && seconds < 3600) {
            const minutes = Math.floor(seconds / 60);
            result += minutes + ` ${t("min")}`;
            seconds %= 60;
        } 
        if (seconds >= 3600  && seconds < 86400 ) {
            const hours = Math.floor(seconds / 3600);
            result += hours + ` ${t("hh")}`;
            seconds %= 3600;
        } 
        if (seconds >= 86400) {
            const days = Math.floor(seconds / 86400);
            result += days + ` ${t("dd")}`;
            seconds %= 86400;
        }  
        return result.trim();
    }
    
    const closeRouting=()=>{  
        dispatch(setMapRouting({ start: null , end: null  }))
        dispatch(setMapRoute(null))
        RfSwip?.slideTo(0)
    }

    const stsSize=(online:any , sts:any)=>{
        let dests:any[]
        if(online > 0 ){ 
            dests  = backup.filter((e:any)=> e.online === 1 && e.status === sts)
        }else{
            dests = backup.filter((e:any)=> e.online === 0)
        }
      return dests.length
    }

    
    const getNotifyMess=async()=>{
         
        let mbegin = moment().add(7,'hour').format("YYYY-MM-DDT00:00:00")
        let mend = moment().add(7,'hour').format("YYYY-MM-DDT23:59:59")
       
        let begin = new Date(mbegin).getTime()
        let end =  new Date(mend).getTime() 
        let message = await notifications({begin,end}) 
        let unread = message.filter((e:any)=> !e.readed)  
        dispatch(setUnreadMsg(unread))
    
        dispatch(setNotifierMessage(message)) 
        dispatch(setNotify({
          update:  new Date().getTime(),
          message:message
        }))  
      }

    const selectDevice=(device:any)=>{
        map?.getCenter()
        // modalref?.setCurrentBreakpoint(0.85)
        // map?.setView([device?.latitude , device?.longitude]); 
        // map?.invalidateSize()
        // map?.whenReady(()=>{
        //     map?.panTo([device?.latitude , device?.longitude]); 
        //     // map?.setView([device?.latitude , device?.longitude])
        // })  
        // setTimeout(()=>{ 
        //     map?.panTo([device?.latitude , device?.longitude]); 
        // },2000)
    }

    return(<IonPage >  
        <AuthCheck /> 
        <div id="main-page"> 
        <IonGrid style={{width:"100%", }}>
            <LoadingProgress mapref={map} updatetime={(e:any)=>{ lastUpdate=e }} />  
          <IonRow>
            <IonCol  sizeXs="12" sizeSm="12" sizeMd={tabs === "device" ? "10":"4"}  sizeXl={tabs === "device" ? "10":"3"}   style={{position:"relative"}} >   
            <Tabs className={"home-screen-tabs "} >
                <TabList   >
                    <Tab onClick={()=>{setTabs("track")}} style={{fontSize:"1.15em"}}>  
                        <IonIcon mode="ios" icon={carSportOutline} />
                    </Tab>
                    <Tab onClick={()=>{setTabs("zone")}}  style={{fontSize:"1.1em"}}>  
                       <IonIcon mode="ios" icon={prismOutline} /> 
                    </Tab>
                    <Tab onClick={()=>{setTabs("device")}}style={{fontSize:"1.1em"}}>  
                       <IonIcon mode="ios" icon={gridOutline} /> 
                    </Tab>
                    <Tab onClick={()=>{setTabs("notify"); }}style={{fontSize:"1.1em"}}>   
                       <NotificationsButton /> 
                    </Tab>
                </TabList>
                <TabPanel> 
                    <Swiper
                    slidesPerView={1}
                    draggable={false}
                    allowTouchMove={false}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper:any) =>{setRfSwip(swiper); console.log(swiper)}}
                    >
                    <SwiperSlide style={{ borderRadius:"5px"}}> 
                        <IonRow> 
                            <IonCol size="1.5" className="ion-hide-md-up"  >
                                <IonMenuButton />
                            </IonCol>
                            <IonCol >
                              <IonSearchbar style={{paddingLeft:"0px"}} mode="ios" placeholder={t("home_search").toString()} value={keyword} onIonInput={(e)=>{ searchDevice(e.detail.value)}}  ></IonSearchbar>
                            </IonCol>
                            <IonCol size='1.5' className='set-center'  >
                                <FilterDevices />
                            </IonCol> 
                        </IonRow>
                        {!showgroup && <SegmentStatus 
                         status={status} 
                         stsSize={(a:any,b:any)=>stsSize(a,b)} 
                         changeStatus={(e:any)=>{changeStatus(e)}} 
                         devices={devices} 
                        />  }
                        
                        {signin !== "admin" &&  <IonRow   style={{marginTop:"1rem",fontSize:".8em",fontWeight:"bold"}}> 
                            <IonCol>
                              <IonCheckbox checked={showgroup} onIonChange={(e)=>{setSowGroup(e.detail.checked)}} labelPlacement="end">{t("home_show_group_device")}</IonCheckbox>
                            </IonCol>
                        </IonRow> }

                           
                            {loading && <IonItem lines="none" className="ion-text-center" >
                                <IonLabel> <IonSpinner name={"crescent"} style={{width:"15px",height:"15px"}}  /> &nbsp;<IonText style={{fontSize:".9em"}} >{t('loading')}</IonText></IonLabel>
                            </IonItem> } 
                                <IonList style={{overflowY:"scroll",height:"83vh"}}>  
                                <IonContent color={"light"}>
                                      
                                {showgroup ? <IonAccordionGroup  className="devices-accordion-group"   onIonChange={(e)=>{  setGroup( JSON.stringify(e.detail.value) )}}>
                                    {
                                        deviceGroup.map((d:DeviceAsGroup|any , indexg:any)=> 
                                        <IonAccordion key={indexg} value={JSON.stringify(d.group_id)}>
                                            <IonItem slot="header" color="light">
                                             <IonLabel className="ion-no-margin"> {d.group} </IonLabel>
                                            </IonItem>
                                            <div className=" " slot="content">
                                            {
                                                template === 'card' ?<>
                                                    { status === "all" ?<>{ d.devices.slice(0,limit).map((device:any,index:any)=>
                                                   
                                                    <CardDevice 
                                                        device={device} key={index} modal={modalref} 
                                                        select={(e:any)=>{ 
                                                            setTimeout(()=>{ map?.setView([device.latitude , device.longitude]);   },500) 
                                                            modalref?.setCurrentBreakpoint(0.85) }
                                                        } mapref={map} 
                                                     /> 
                                                    )}</>:<>
                                                    { d.devices.filter((device:any)=>
                                                        status === "offline" ? device.online === 0 :
                                                        status === "move" ? device.status === 7 :
                                                        status === "idle" ? device.status === 24 :  device.status === 23 
                                                    ).map((device:any,index:any)=>
                                                    //@ts-ignore
                                                    <CardDevice 
                                                        device={d.device} key={index} modal={modalref} 
                                                        select={(e:any)=>{ 
                                                            setTimeout(()=>{ map?.setView([device.latitude , device.longitude]);   },500) 
                                                            modalref?.setCurrentBreakpoint(0.85)}
                                                        } 
                                                        mapref={map}
                                                    /> 
                                                    )}  
                                                    </>}   
                                                </>:
                                                <>  <ListDevice status={status} devices={d.devices}  mapref={map} /> </>
                                                } 
                                            </div>
                                        </IonAccordion>
                                    )
                                } 
                                </IonAccordionGroup>:<div>
                              
                                        { status === "all" ?<>{  devices.slice(0,limit).map((device:any,index:any)=>
                                            <LDevice device={device} key={index} modal={modalref} select={(el:any)=>{selectDevice(device)  }} mapref={map} /> 
                                        // <CardDevice device={device} key={index} modal={modalref} select={(el:any)=>{ map?.setView([device?.latitude , device?.longitude]);  modalref?.setCurrentBreakpoint(0.85) }} mapref={map} /> 
                                        )}</>:<>
                                        {  devices.filter((device:any)=>
                                            status === "offline" ? device.online === 0 :
                                            status === "move" ? device.status === 7 :
                                            status === "idle" ? device.status === 24 :  device.status === 23 
                                        ).map((device:any,index:any)=> 
                                            <LDevice device={device} key={index} modal={modalref} select={(el:any)=>{ selectDevice(device) }} mapref={map} /> 
                                            // <CardDevice device={ device} key={index} modal={modalref} select={(e:any)=>{ modalref?.setCurrentBreakpoint(0.85) ;map?.setView([device?.latitude , device?.longitude])  }} mapref={map}/> 
                                        )}  
                                        </>}   
                                        {/* {
                                    template === 'card' ?<></>:
                                    <>  <ListDevice status={status} devices={ devices}  mapref={map} /> </>
                                }  */}
                                </div>}
                                {!showgroup &&  
                                    <IonInfiniteScroll
                                        onIonInfinite={(ev) => {
                                        if(limit < devices.length){
                                            setLimit(limit+5)
                                        }
                                        setTimeout(() => ev.target.complete(), 500);
                                        }}
                                    >
                                        <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                    </IonInfiniteScroll>}
                            </IonContent>
                            </IonList>
                        </SwiperSlide> 
                         
                        <SwiperSlide style={{background:"#FFF",borderRadius:"5px"}}>
                            <PreviewRouteHistory mapref={map} />
                        </SwiperSlide>   
                        <SwiperSlide  style={{backgroundColor:"#FFF",borderRadius:"5px"}}>
                        <IonGrid   className="ion-padding"> 
                            <IonRow >
                                <IonCol size="1" className="set-center" > 
                                <img src="../assets/icon/dotRouting.svg" style={{height:"60%"}} />
                                </IonCol> 
                                <IonCol size="11" style={{fontSize:"small"}} > 
                                    <IonRow>
                                        <IonCol size="10" > 
                                            <div style={{border:".5px solid #DDD" , padding:"2px 0 2px 5px" , borderRadius:"5px"}} >
                                                <IonLabel style={{fontSize:".9em"}} >{maprouting?.start?.name}</IonLabel> <br/>
                                                <small>{maprouting?.start?.position?.lat}  -  {maprouting?.start?.position?.lng} </small>
                                            </div>
                                        </IonCol>
                                        <IonCol className="set-center" size="2" >
                                            <IonButton fill="clear" color={"primary"} onClick={()=>{closeRouting()}} >
                                                <IonIcon icon={closeOutline} />
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="10" > 
                                            <div style={{border:".5px solid #DDD" , padding:"2px 0 2px 5px" , borderRadius:"5px"}} >
                                                <IonLabel>{maprouting?.end?.name}</IonLabel> <br/>
                                                <small>{maprouting?.end?.position?.lat}  -  {maprouting?.end?.position?.lng} </small>
                                            </div>
                                        </IonCol>
                                        <IonCol className="set-center" size="2" >
                                            <IonButton fill="clear" color={"dark"} >
                                                <IonIcon icon={swapVerticalOutline} />
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                
                                </IonCol> 
                            </IonRow>  <br/>  
                            <div style={{width:"100%", height:".3rem",backgroundColor:"#EEE",marginBottom:".5rem"}} ></div>
                            <IonRow> 
                                <IonCol size="10" > 
                                    <IonLabel color={"dark"} > 
                                        <IonText color={"primary"} > 
                                        <strong style={{fontSize:"1.2em"}}>{distanceTime(maproute?.data?.interval) }</strong> 
                                        </IonText> ({distanceCal(maproute?.data?.distance)} )
                                    </IonLabel> <br/>
                                    <IonLabel color={"dark"} style={{display:"flex", alignItems:"center"}}><small>{maprouting?.start?.name} &nbsp; <IonIcon icon={arrowForward} />  &nbsp; {maprouting?.end?.name}</small></IonLabel> 
                                </IonCol> 
                            </IonRow> 
                            <IonRow> 
                                <IonCol size="12" >
                                    <div  style={{overflowY:"scroll",height:"71vh"}} >
                                        {
                                            maproute?.features.map((e:any, findex:any)=> 
                                                <RowDirection key={findex } 
                                                    viewdistance={(fea:any)=>{viewdistance(e)}}   
                                                    turncode={(e:any,type:any)=> turncode(e ,type)}   
                                                    distanceCal={(e:any)=>{distanceCal(e)}}  
                                                    properties={e.properties}
                                                    feature={e}
                                                /> 
                                            )
                                        }
                                    </div>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                        </SwiperSlide>
                    </Swiper> 
            </TabPanel>
            <TabPanel >
                <GeozoneStation mapref={map} selectzone={(e: any)=>{ selectZone(e) }  }  />
            </TabPanel>
            <TabPanel>
                <DevicesTable 
                devices={devices} mapref={map} 
                editDevice={(d:any)=>{dispatch(setPreviewDevice({active:true ,device:d }));setOpenEdit(true);map?.setView([d.latitude , d.longitude]) }} 
                >
                    <IonRow>
                        <IonCol sizeXs="12" sizeSm="12" sizeMd="1.5"  className="set-center-row" style={{ justifyContent:"center"}}> 
                            <IonLabel>
                                <IonIcon icon={filterOutline} /> &nbsp;
                                <strong style={{fontSize:"1.2em"}}>{t("home_setting_device")}</strong>
                            </IonLabel> 
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="6" className="set-center" > 
                            <IonSearchbar 
                            style={{paddingLeft:"0px"}} mode="ios" placeholder="ค้นหา" value={keyword} 
                            onIonInput={(e)=>{ searchDevice(e.detail.value)}}  
                            ></IonSearchbar>
                        </IonCol>
                        <IonCol> 
                        {!showgroup && <SegmentStatus 
                         status={status} 
                         stsSize={(a:any,b:any)=>stsSize(a,b)} 
                         changeStatus={(e:any)=>{changeStatus(e)}} 
                         devices={devices} 
                        /> }
                        </IonCol>
                        
                    </IonRow>

                </DevicesTable>
            </TabPanel>
            <TabPanel>
               <NotiferMessageContent  notify={notify}    voiceEnable={voiceEnable} />
            </TabPanel>
          
            </Tabs>
            </IonCol>
            <IonCol sizeXs="12" sizeSm="12" sizeMd={tabs === "device" ? "2":"8"}   sizeXl={tabs === "device" ? "2":"9"}    className="ion-hide-md-down"   > 
                <ToolbarMap />
                <MapHomeScreen setMapRef={(map:any)=>{ setMapRef(map);boudsdevice(devices )}}  swiper={RfSwip}  />
            </IonCol>
          </IonRow>
        </IonGrid>
         <DialogDevice   />  
        <ModalMapMobile setMapRef={(map:any)=>{setMobileMapRef(map)}} setModalRef={(modal:any)=>{setModalRef(modal.current); }}  />
        <ModalEditDevice openedit={openEdit}  setOpenEdit={(e:any)=>setOpenEdit(e)} />
     </div> 

     {/* {previewDevice?.active && tabs === "track" && <DeviceSummaryPopup device={previewDevice?.device} activity={activity} swiper={RfSwip} /> } */}
    </IonPage>)
}  
export default HomeScreen


const SegmentStatus=({status,stsSize,changeStatus,devices}:any)=>{

    return(
        <IonSegment color='light' mode='ios' value={status} className="status-device "  onIonChange={(e)=>{changeStatus(e.detail.value!)}}>
        <IonSegmentButton mode='ios' value="all"  > 
            <IonText className="status-size" > <strong> ( {devices.length} ) <br/> {t('home_segment_all')} </strong></IonText>
            <IonText className="status-name" >
            <IonIcon icon={status==='all'?grid:gridOutline} /><br/>
            <IonLabel>  
                {t('home_segment_all')}
            </IonLabel>
            </IonText>  
        </IonSegmentButton>
        <IonSegmentButton mode='ios' value="move">
            <IonText className="status-size" > <strong> ( {stsSize(1,7)} )  <br/> {t('home_segment_move')} </strong></IonText>
            <IonText className="status-name" >
                <IonIcon icon={move} />  <br/>
                <IonLabel> 
                    {t('home_segment_move')}
                </IonLabel>
            </IonText>   
        </IonSegmentButton>
        <IonSegmentButton mode='ios' value="idle"> 
            <IonText className="status-size" > <strong> ( {stsSize(1,24)} )  <br/> {t('home_segment_idle')} </strong></IonText>
            <IonText className="status-name" >
                <IonIcon icon={status==='idle'? carSport:carSportOutline} /> <br/>
                <IonLabel> 
                    {t('home_segment_idle')}
                </IonLabel>
            </IonText>    
        </IonSegmentButton>
        <IonSegmentButton mode='ios' value="stop">
            <IonText className="status-size" > <strong> ( {stsSize(1,23)} )  <br/> {t('home_segment_stop')} </strong></IonText>
            <IonText className="status-name" >
                <IonIcon icon={status ==='stop'? stop:stopOutline} /> <br/>
                <IonLabel>  
                    {t('home_segment_stop')}
                </IonLabel>
            </IonText>    
        </IonSegmentButton>
    <IonSegmentButton mode='ios' value="offline">
            <IonText className="status-size" > <strong> ( {stsSize(0,1)} ) <br/> {t('home_segment_offline')}</strong></IonText>
            <IonText className="status-name" >
                <IonIcon icon={status==='offline'? cloudOffline:cloudOfflineOutline} /> <br/>
                <IonLabel> 
                    {t('home_segment_offline')}
                </IonLabel>
            </IonText>     
        </IonSegmentButton>
    </IonSegment>
    )
}


const RowDirection=({viewdistance , turncode , distanceCal ,properties ,feature}:any)=>{
    return(
        <IonRow  onClick={()=>{viewdistance(feature)}}  className="row-direction"  >
            <IonCol size="2" className="set-center" >
               <IonLabel color={properties.turn=== 4 ?"primary":"dark"} style={{fontSize:properties.turn=== 4 ?".7em": "1em"}}> {turncode(properties.turn , 2 )}  </IonLabel>
            </IonCol>
            <IonCol size="10" style={{borderBottom:"1px solid #DDD"}}> 
                <IonLabel  color={"dark"} style={{fontSize:".9em"}}>
                 {turncode(properties.turn , 1 )}  {distanceCal(properties.distance)}
                </IonLabel><br/>
                <IonLabel  color={"dark"}  style={{fontSize:".8em"}}>
                 {properties.name}
                </IonLabel>
            </IonCol>
       </IonRow>
    )
}

const ModalMapMobile=({setMapRef,setModalRef}:any)=>{
    const modal = useRef<HTMLIonModalElement>(null);
    const [isOpen , setIsOpen] = useState(false)
    const page = useRef(null);
  
    const [presentingElement, setPresentingElement] = useState<HTMLElement | null>(null);
    function dismiss() {
        setIsOpen(false)
        modal.current?.dismiss();
    }
    useEffect(()=>{ 
        if( window.location.pathname.indexOf("/home") < 0){
            dismiss()
        }else{
            setIsOpen(true)
        } 
    },[window.location.pathname])

    return(
    <IonModal  className="ion-hide-md-up"  isOpen={isOpen}   ref={modal}  onIonModalDidPresent={()=>{return setModalRef(modal)}}
        initialBreakpoint={0.05}  
        breakpoints={[0.05,0.1,0.2, 0.3,0.4,0.5,0.6,0.7, 0.8 , 0.9, 0.95,  1]}
        backdropDismiss={false}
        showBackdrop={false}
        keepContentsMounted={true}
        backdropBreakpoint={1}
    >
        <IonHeader className="ion-no-border " >
           <IonToolbar  >
              <IonButton size="small" fill="clear" mode="ios" slot="end" onClick={()=>{modal.current?.setCurrentBreakpoint(0.25)}}>
                 <IonIcon icon={closeCircleOutline} />
              </IonButton>
           </IonToolbar>
        </IonHeader>
        <IonContent> 
            <ToolbarMap  mode={"mobile"} />
            <MapHomeScreen setMapRef={(map:any)=>{setMapRef(map); }}  mode={"mobile"} />
        </IonContent>
    </IonModal> 
    )
}

const Signout=()=>{ 
    const dispatch = useDispatch()
    // const [cookies, setCookie, removeCookie] = useCookies(['token']);

    useEffect(()=>{
        localStorage.removeItem("server")
        localStorage.removeItem("domain")
        localStorage.removeItem("profile")
        localStorage.removeItem("url")
        localStorage.removeItem("token")
        localStorage.removeItem("isSignin") 
        

        dispatch(setSignin(false))
    },[])
    return(<Redirect to="/signin" />)
}



let max = 0
let playindex =0  
let count = 0
const MapHomeScreen=({setMapRef, swiper}:any)=>{ 
    const dispatch = useDispatch()
    const [zoom,setZoom] = useState(16)
    const backup :any= useSelector(getBackup)
    const isGroup :any= useSelector(getIsGroupDevice) 
    const isMeasure :any= useSelector(getIsMeasure)   
    const [markerGroup, setMarkerGroup] = useState<MarkerType[]|[]>([])
    const [mapref , setMapref] = useState<MapType|null>(null)
    const [markerJsonGroup , setMarkerJsonGroup] = useState<GeoJson[]>()
    const fastTrack :any= useSelector(getFastTrack)  
    const routeIndex :any= useSelector(getRouteIndex)
    const [speed,setSpeed] = useState ({name:"4x",val:200})
    const [interval,setinterval] = useState(0) 
    const [dSetting , setDefaultSetting] = useState({
         showName :  "1" ,
         defaultMap :  "Longdo" ,
         groupDevice : false,
         googleMapEnabled : null
    })
    
    const randomIconSize = ()=>{ 
        let index = Math.floor(Math.random()* iconsizes.length) 
        return  iconsizes[zoom>17 ?2 : 1]
    }
    const createClusterCustomIcon = function (cluster:any) {
        return L.divIcon({
          html:  
            `<div class="cluster-inner set-center" >${ cluster.getChildCount()/2  }</div>  `, 
          className: `cluster-outer ${ cluster.getChildCount()/2 >5 ?'large':'small' }  `,
          iconSize: L.point(40, 40, true)
        });
    };

    useEffect(()=>{  
        const getDefaultMap=async()=>{
            let defaultset = await getStorage("default") 
            
            let setting = await userSetting()  
            setDefaultSetting(setting)
            setStorage("default", setting) 
        }
        getDefaultMap()
        if(markerGroup.length  > backup.length ){
           // placeMarkersInBounds()
        } 
        // parseToGeoJson() 
    },[backup])

    const play=(e:any)=>{  
        if(routeIndex < fastTrack?.list.length-2){ 
            let interval:any = setInterval(()=>{  
                count+=1 
                dispatch(setRouteIndex(count)) 
            },speed.val)
            setinterval(interval)
            dispatch(setPlayAction("playing"))
        }else{
            stop(null) 
            count=0
            dispatch(setRouteIndex(0))
        }
    }
    const pause=(e:any)=>{
        if(routeIndex<15){
            console.log("please wait ")
        }else{
            console.log("click pause ",e)
            dispatch(setPlayAction("pause")) 
            clearInterval(interval) 
        }
    }
    const stop=(e:any)=>{
        if(routeIndex<15){
            console.log("please wait ")
        }else{
            console.log("click stop ",e)
            count=0 
            dispatch(setPlayAction("stop")) 
            dispatch(setRouteIndex(0)) 
            clearInterval(interval)
        }
    } 

    return(<> 
   {fastTrack&&<PlayControl speed={speed} setSpeed={(e:any)=>{return setSpeed(e)}} play={(e:any)=>{return  play(e)}} pauseAction={(e:any)=>{return  pause(e)}} stopAction={(e:any)=>{return  stop(e)}}/> }
    <MapContainer
        id="map-homescreen" className="mobile"
        center={[13.714538, 100.510928]}
        zoom={16}
        minZoom={1}  
        scrollWheelZoom={true}
        zoomControl={false}  
        style={{borderRadius:"15px"  ,marginBottom:".3rem"}}
        ref={async (comp)=>{   
            comp?.invalidateSize()  
            comp?.on('load',(e)=>{ })
            comp?.on("zoomlevelschange",(e)=>{
                comp?.invalidateSize() 
                setZoom(e.target._zoom)
            }) 
           
            if(comp){
                setMapref(comp)
                return setMapRef(comp)
            } 
        }} 
    > 
        <FullscreenControl />
        <SelectPlaceToStreetView /> 
        <LayersExpandControl defaultmap={dSetting?.defaultMap}  />
       {fastTrack &&  <RouteQuickTrack current={routeIndex} stop={stop} />  }
        <GeofencesMarker />
          {isMeasure && <MeasureDistanceFeature /> }
 
        { isGroup || backup.length > 200?
          <MarkerClusterGroup   
            iconCreateFunction={createClusterCustomIcon}  
            iconAnchor={[25, 70]}
            disableClusteringAtZoom={13}
            spiderfyDistanceMultiplier={2}
            spiderfyOnMaxZoom={false}  
            showCoverageOnHover= {false}
            zoomToBoundsOnClick={false} 
            ref={ (markers:any) =>{
                 markers?.on('clusterclick',(a:any)=> {
                    a.layer.zoomToBounds(); 
                }); 
            }
            }>
            { backup.filter((device:any)=> device.latitude !== null).map((device:any,index:any)=>
                <MarkerIcon key={index} size={backup.length} swiper={swiper}
                    device={device} vehicle={device.name}  markersize={randomIconSize()}  
                />
            ) }
            </MarkerClusterGroup>:
             <FeatureGroup>
                { backup.filter((device:any)=> device.latitude !== null ).map((device:any,index:any)=>
                 <MarkerIcon key={index} size={backup.length} swiper={swiper}
                    device={device} vehicle={device.name}  markersize={randomIconSize()}  
                 />
                ) }
             </FeatureGroup>
        }  
        
        <RouteMapSearch modalref={null}/>
    </MapContainer>
    </>
    )
} 

 
interface GeozoneStation{
    mapref: MapType | null  
    selectzone: (e: any)=> void
}
const GeozoneStation=({mapref , selectzone}:GeozoneStation)=>{
    const [swiperRef , setSwiperRef] = useState<SwiperType | null>(null) 
    const [devicesInZon,setDeviceInZone] = useState([])
    const [selectZone,setSelectZone] = useState(null)
    const geozones :any= useSelector(getGeoZones)   
    const [geozoneSearch , setGeozoneSearch ] = useState([])
    const [ keyword,setKeyword ] = useState("")  
    const dispatch = useDispatch() 

    const getDeviceInGeo=async(zone:any)=>{
        let updatezone:any[] = geozones
        let zoneindex = updatezone.findIndex((gz)=> gz?.id ===  zone?.id ) 
        if(zoneindex >= 0){
            let zonedetail:any = await geozoneDetail({geoid: zone?.id})   
            let zoneWithPoint = { ...zonedetail, ...zone }
            let bound =  []
            bound = zonedetail?.position.map((p:any) =>{ return [p.lat , p.lon ] })
            let zoneWithBound =  {...zoneWithPoint,...{bound: bound}} 
            setSelectZone({...zoneWithPoint,...bound})
            
            mapref?.fitBounds(bound)
            updatezone = await geozones.map((z:any,index:any)=>{
                if(index === zoneindex){
                    return zoneWithBound
                }else{
                    return z
                }
            })  
            dispatch(setGeozones(updatezone))  
            if(zoneWithBound.position ){  
                const deviceingeo = await deviceInGeometry({geoId: zone.id})  
                console.log("deviceingeo ",deviceingeo)
                setDeviceInZone(deviceingeo)
                dispatch(setFocusZone(zoneWithBound)) 
            }
            return selectzone({...zoneWithPoint,...{bound:bound}})
        }
    } 
    useEffect(()=>{  
        console.log("geozones ",geozones)
    },[devicesInZon])

    const searchDevice=(word:any)=>{ 
        setKeyword(word)
        if(word.length >= 2 ){
            let filterName = geozones.filter((e:any)=> e.name.toLowerCase().indexOf(word.toLowerCase()) > -1 )  
            setGeozoneSearch(filterName)
        }else{ 
            setGeozoneSearch(geozones)
        }
    }
    const slidechange=(e:SwiperType)=>{
        if(e?.activeIndex === 0){
            selectzone(null)
        }
    }

    return(
    <div style={{}} > 
      <Swiper 
        slidesPerView={1} 
        draggable={false}  
        onSlideChange={(e) => {slidechange(e);console.log('slide change',e)}}
        onSwiper={(swiper) => setSwiperRef(swiper)}
        allowTouchMove={false}
        style={{height:"100%"}}
      >
        <SwiperSlide  > 
            <IonRow>
                <IonCol> 
                    <IonSearchbar 
                        style={{paddingLeft:"0px"}} mode="ios" value={keyword} 
                        onIonInput={(e)=>{ searchDevice(e.detail.value)}} 
                        placeholder={t("search").toString()} 
                    ></IonSearchbar>
                </IonCol> 
            </IonRow>
            <div  style={{overflowY:"scroll",width:"100%",height:"70vh"}} >
                { keyword.length>0 ? 
                    <IonList > 
                        {  geozoneSearch.map((zone:any,index:any)=>
                        <ToolbarGeofenceItem 
                            key={index} zone={zone} selected={(e:any)=>{getDeviceInGeo(e); console.log("selected ",e) }}
                        /> 
                        )} 
                    </IonList> : 
                    <IonList> 
                        {  geozones.map((zone:any,index:any)=>
                        <ToolbarGeofenceItem 
                            key={index} zone={zone} selected={(e:any)=>{getDeviceInGeo(e); console.log("selected ",e) }}
                        /> 
                        )} 
                    </IonList> 
                } 
            </div> 
        </SwiperSlide> 
        <SwiperSlide>
            <DevicesInZone devices={devicesInZon} zone={selectZone} slideBack={(e:any)=>{selectzone(null)}} />
        </SwiperSlide>  
      </Swiper> 
    </div>
    )
}


const DeviceSummaryPopup=({device,swiper}:any)=>{
    const dispatch = useDispatch()
    const [activity,setActvity] = useState<any>(null)
    const previewDevice :any= useSelector(getPreviewDevice)
    const [routes , setRoutes] = useState<any>([])
    const [iontoast] = useIonToast();
    const modal = useRef<HTMLIonModalElement>(null);
    const [ionalert] = useIonAlert();
    const [openedit,setOpenEdit] = useState(false) 

    const getActivity=async()=>{
        let result = await repCarsDailyUse({
            deviceId:  device?.device_id,
            start: new Date(moment().format("YYYY-MM-DD")+" 00:00:00").getTime() ,
            end: new Date(moment().format("YYYY-MM-DD")+" 23:59:59").getTime()
        })
        setActvity(result)
        console.log("repCarsDailyUse result ",result) 
} 
    useEffect(()=>{
        if(previewDevice.active){ 
            console.log("preview active ")
            getActivity()
        } 
    },[previewDevice])

    const getroute=async()=>{
        let res = await allRoutesSet()
        console.log("home get allRoutesSet res ",res)
        setRoutes(res)
    }

    const setRouteToDevice=async(routeid:any)=>{
        const body = {
            routeId: routeid ,  
            deviceId: device?.device_id
        }
        const res = await editDeviceRoute(body)
        console.log("setupRoute res" ,res )
        iontoast({
            message: res?.message,
            duration: 3000,
            position: "top",
            color: res?.result ? "success" : "danger" ,
            mode:"ios"
          }); 
       modal?.current?.dismiss()
       if(res.result){
        const devices = await allDevices({})  
        await dispatch(setDevices(devices))
        await dispatch(setBackupDevices(devices))   
       }
    }

    const confirmRemoveRoute=(name:any)=>{
        ionalert({
            mode:'ios',
            header: ` นำเส้นทางออก`,
            message: `ยืนยันที่จะนำเส้นทาง ${name}  ออกจากทะเบียน ${device?.name} หรือไม่ ?`,
            buttons: [
              {
                text: 'ยกเลิก',
                role: 'cancel', 
              },
              {
                text: 'ยืนยันลบเส้นทาง',
                role: 'confirm',
                handler: () => { 
                    removeRoutefromDevice()
                },
              },
            ], 
          })
    }

    const removeRoutefromDevice=async()=>{
        const body = {
            routeId: null ,  
            deviceId: device?.device_id
        }
        const res = await editDeviceRoute(body)
        console.log("setupRoute res" ,res )
        iontoast({
            message: res?.message,
            duration: 3000,
            position: "top",
            color: res?.result ? "success" : "danger" ,
            mode:"ios"
       });
       modal?.current?.dismiss()
        
       if(res.result){
        const devices = await allDevices({})  
        await dispatch(setDevices(devices))
        await dispatch(setBackupDevices(devices))   
       }
    }
    

    return(device&&<div className="ion-padding device-summary-popup ion-hide-lg-down" >
        <div style={{width:"100%"}} className="ion-text-right" > 
           
        </div>
        <IonToolbar>
            <IonRow>
                <IonCol size="2.5" >
                <IonButton 
                    fill="clear" mode="ios"   
                    onClick={()=>{
                        swiper.slideTo(0); 
                        dispatch(setPreviewDevice({active:false , device: null}))
                    }}
                 > 
                    <IonIcon icon={closeCircleOutline} /> 
                </IonButton> 
                </IonCol>
                <IonCol size="9.5" >
                    <IonLabel style={{fontSize:"larger"}}><strong>{device?.name2 ? device?.name2 : device?.name}</strong> </IonLabel> <br/> 
                    <DeviceStatusNameTime online={device.online} status={device.status} statusName={device.status_name} statusTime={device.status_time} /><br/> 
                </IonCol> 
            </IonRow>
            
        </IonToolbar> 
        <DeviceSensorTable device={device} />
        <div className="devider"></div>
        <SummaryDevice device={device} edit={(e:any)=>{setOpenEdit(e)}} /> 
            <IonRow> 
                    <IonCol size="12"  className="set-center" style={{alignItems:"flex-start"}} >
                        <IonLabel> เส้นทางที่กำหนด </IonLabel>
                        {
                        device?.deviceRoute ?
                        <IonButton fill="outline" mode="ios" size="small" onClick={()=>{confirmRemoveRoute(device?.deviceRoute)}}  >
                           <IonLabel>{device?.deviceRoute}</IonLabel> &nbsp;
                           <IonIcon icon={trashBin} />
                        </IonButton> :
                        <IonButton fill="outline" mode="ios" size="small" id={"set-route-"+device?.device_id}> ยังไม่กำหนดเส้นทาง 
                            <IonModal ref={modal} mode="ios" trigger={"set-route-"+device?.device_id} onIonModalWillPresent={()=>{getroute()}}   > 
                                <IonContent className="ion-padding" >
                                     <IonList  >
                                        <IonListHeader> รายการเส้นทาง </IonListHeader>
                                        {
                                            routes.map((route:any, index:any)=> 
                                             <IonItem  key={index} >
                                               <IonLabel>
                                                 <strong>{route.name}</strong><br/>
                                                 <small>ระยะทาง {(route.allDistance/1000).toFixed(2)} ก.ม. </small>
                                               </IonLabel> <br/>
                                               <IonButtons>
                                                 <IonButton fill="clear"  onClick={()=>{setRouteToDevice(route.id)}} > <IonIcon icon={addCircleOutline} />  <IonLabel> กำหนดให้อุปกรณ์ </IonLabel></IonButton>
                                               </IonButtons>
                                             </IonItem>
                                            )
                                        }
                                     </IonList>
                                </IonContent> 
                            </IonModal>
                        </IonButton>
                    }
                    </IonCol>
            </IonRow>
          <div className="devider"></div>
          
          <strong>กิจกรรมวันนี้</strong><br/>
          <IonGrid>
            <IonRow  style={{fontSize:".9em"}}>
                <IonCol size="6" ><IonLabel color={"daker"}>{t("status_move")}</IonLabel> </IonCol>
                <IonCol size="6" className="ion-text-right" > <IonLabel color={"success"}><strong>{activity?.overview?.move} {t("hrs")}  </strong></IonLabel></IonCol> 
                
                <IonCol size="6" ><IonLabel color={"daker"}>{t("status_idle")}</IonLabel> </IonCol>
                <IonCol size="6" className="ion-text-right" > <IonLabel  color={"warning"}><strong>{activity?.overview?.idle} {t("hrs")}</strong></IonLabel></IonCol>
                
                <IonCol size="6" ><IonLabel color={"daker"}>{t("status_stop")}</IonLabel> </IonCol>
                <IonCol size="6" className="ion-text-right" > <IonLabel  color={"danger"}><strong>{activity?.overview?.stop} {t("hrs")}</strong></IonLabel></IonCol> 
               
                <IonCol size="6" ><IonLabel color={"daker"}>ค่าใช้จ่าย</IonLabel> </IonCol>
                <IonCol size="6" className="ion-text-right" > <IonLabel  color={"danger"}><strong>{activity?.overview?.allExpense?.toFixed(2)} {t("bath")}  </strong></IonLabel></IonCol>
            </IonRow>
            {
                device?.odoToday !== null && 
                <IonRow>
                    <IonCol size="6" ><IonLabel color={"daker"}>{t("distance_today")}</IonLabel> </IonCol>
                    <IonCol size="6" className="ion-text-right" > <IonLabel  color={"dark"}><strong>{device?.odoToday? device?.odoToday.toFixed(2):"-"} {t("kmUnit")}</strong></IonLabel></IonCol>
                </IonRow>
            }
          </IonGrid>
          <div className="devider"></div>
          <strong>เชื้อเพลิง</strong><br/>

          <IonRow   style={{fontSize:".9em"}} >
            <IonCol size="12" className="set-center" > 
              <img src="../../assets/img/fuel.svg"  style={{width:"80%"}} />
            </IonCol> 
            <IonCol size="7" ><IonLabel color={"daker"}>  ใช้ไป </IonLabel></IonCol>
            <IonCol size="2" className="ion-text-center"> <IonLabel color={"dark"}>{device?.fuel_liters}  </IonLabel></IonCol>
            <IonCol size="3" className="ion-text-right" > <IonLabel color={"dark"}>  <small><strong>{t("lit")}</strong></small></IonLabel></IonCol>

            <IonCol size="7" > <IonLabel color={"daker"}> สิ้นเปลืองขณะเคลื่อนที่</IonLabel></IonCol>
            <IonCol size="2" className="ion-text-center"  > <IonLabel color={"dark"}>{device?.consumptionMove}</IonLabel></IonCol>  
            <IonCol size="3" className="ion-text-right" > <IonLabel color={"dark"}> <small><strong>{t("kmLit")}</strong></small></IonLabel></IonCol>  

            <IonCol size="7" > <IonLabel color={"daker"}> สิ้นเปลืองขณะจอดติด</IonLabel></IonCol>
            <IonCol size="2" className="ion-text-center"  > <IonLabel color={"dark"}>{device?.consumptionIdle}</IonLabel></IonCol>  
            <IonCol size="3" className="ion-text-right" > <IonLabel color={"dark"}><small><strong>{t("litHour")}</strong></small></IonLabel></IonCol>  

            <IonCol size="7" > <IonLabel color={"daker"}> PTT</IonLabel></IonCol>
            <IonCol size="5"   > <IonLabel color={"dark"}>  <strong> {device?.ptt} </strong> </IonLabel></IonCol>   
          </IonRow>
          <div className="devider"></div> 
          <strong>Network Parameters</strong><br/>
          <IonGrid>
            <IonRow  style={{fontSize:".9em"}}>
                <IonCol size="6" ><IonLabel color={"daker"}>GSM</IonLabel> </IonCol>
                <IonCol size="6" className="ion-text-right" > <IonLabel color={"dark"}>{device?.fld_signalStrength}</IonLabel></IonCol>  

                <IonCol size="6" ><IonLabel color={"daker"}>Country</IonLabel> </IonCol>
                <IonCol size="6" className="ion-text-right" > <IonLabel color={"dark"}> Thailand </IonLabel></IonCol> 
            </IonRow>
         </IonGrid> 
         <div className="devider"></div>
         <strong>GPS Device Parameter</strong><br/>
          <IonGrid>
            <IonRow  style={{fontSize:".9em"}}>  
                <IonCol size="8" ><IonLabel color={"daker"}>GPS</IonLabel></IonCol>
                <IonCol size="4" className="ion-text-right" > <IonLabel color={"dark"}>{device?.satellites}</IonLabel></IonCol>  
 
                <IonCol size="8" ><IonLabel color={"daker"}>Battery</IonLabel></IonCol>
                <IonCol size="4" className="ion-text-right" > <IonLabel color={"dark"}>{device?.fld_sensorHigh}</IonLabel></IonCol>  

                <IonCol size="8" > <IonLabel color={"daker"}>Temperature</IonLabel></IonCol>
                <IonCol size="4" className="ion-text-right" > <IonLabel color={"dark"}>{device?.temperature}</IonLabel></IonCol>   
            </IonRow>
         </IonGrid>
 
         <ModalEditDevice openedit={openedit} setOpenEdit={(e:any)=>{setOpenEdit(e)}} />
    </div>)
}


export const ModalEditDevice=({openedit,setOpenEdit}:any)=>{
    const preview:PreveiewDevice |any= useSelector(getPreviewDevice)   
    const [pttPrices , setPttPrices] = useState<PttPriceType[]>([])
    const [ionalert,dimissAlert] = useIonAlert()
  
    const [toast] = useIonToast(); 
    const dispatch = useDispatch();
  
    const [name,setName] = useState("")
    const [name2,setName2] = useState("")
    const [phone,setPhone] = useState("") 
    const [expire,setExpire] = useState("") 
    const [note,setNote] = useState("")
  
    const [cIdle,setCIdle] = useState<any>(null)  
    const [cMove,setCMove] = useState<any>(null)  
    const [pttType , setPttType] = useState<any>(null)

    const [icon,setIcon] = useState(1) 
    const [idleTime , setIdleTime] = useState<any>(null)
    const [speeding , setSpeeding] = useState<any>(null)
    const [iontoast,dimisstoast] = useIonToast()
  
    const speedFormat=(speed:any)=>{ 
      return Number(speed).toFixed(0)
    }

    const fetchDevice=async(id:any)=>{
        const devices = await allDevices({}) //devicesAll({user_id: parseInt(user_id) }) 
        await dispatch(setDevices(devices))
        await dispatch(setBackupDevices(devices))   
        let view = devices.find((e:any)=> e.device_id == id)
        dispatch(setPreviewDevice({active:true, device:view})) 
    }
  
    const pttpriceing=async()=>{
      const ptts = await getPttpricing()
      setPttPrices(ptts) 
    }
  
    useEffect(()=>{   
      pttpriceing()
      if(preview?.device){
        console.log("preview?.device ",preview?.device)
        setName(preview?.device?.name ? preview?.device.name : "" )
        setName2(preview?.device?.name2 ? preview?.device.name2 : "" )
        setPhone(preview?.device?.phone_number ? preview?.device.phone_number : "" ) 
        setExpire(preview?.device?.expiration_date ? preview?.device.expiration_date : "")
        setCIdle(preview?.device?.consumptionIdle)
        setCMove(preview?.device?.consumptionMove)
        setPttType(preview?.device?.pttType)
        setIcon(preview?.device?.deviceIconId)
        setIdleTime(preview?.device?.minuteIdle)
        setSpeeding(preview?.device?.speedLimit) 
      }
    },[preview])
  
  
    const updateDInfo=async()=>{ 
      const expire = new Date(preview?.device.expiration_date).getTime()
      const body ={
        deviceId: preview?.device.device_id ,
        expire:  expire,
        phoneNumber: phone ,
        note:"",
        name , name2  ,
      }
      const result = await editDevice(body)
      const res = await setDeviceName({name2: name2 ,deviceId:  preview?.device.device_id  }) 
      console.log("setDeviceName res ",res)
      if(result.result){
        toast({
          message: 'Update device success!!',
          ...toastSuccess
        }); 
      }
      await fetchDevice(preview?.device?.device_id)
      setOpenEdit(false)
    }
  
    const updateConsumtion=async()=>{
        if(cIdle === null || cMove === null ||pttType === null ){
            ionalert({
                header: "กรุณาระบุข้อมูลให้ครบถ้วน !!" ,
                message: `อัตราการสิ้นเปลืองขณะจอดติดเครื่อง : ${cIdle?cIdle:'ยังไม่ระบุ'} , อัตราการสิ้นเปลืองขณะเคลื่อนที่ : ${cMove?cMove:'ยังไม่ระบุ'} , น้ำมัน : ${pttType?pttType:'ยังไม่ระบุ'} `,
                mode:"ios",
                buttons: [t('ok')]
            })
        }else{ 
            const prm = { 
                deviceId:preview?.device?.device_id ,
                consumtionIdle: cIdle ,
                consumpionMove: cMove,
                ptttype: pttType ,
            }
             const res = await  settingConsumption(prm)
             console.log("settingConsumption ", res)
            if(res?.result){ 
                setOpenEdit(false)
                await fetchDevice(preview?.device?.device_id) 
            } 
        }
    }

    const updateDeviceTypeId=async()=>{
        if(preview?.device?.device_id > 0){ 
          const res = await setDeviceIcon({
              deviceId: preview?.device?.device_id ,
              deviceIconId: icon
          })
          if(res){
            await fetchDevice(preview?.device?.device_id) 
            iontoast({
                message: 'Update device icon type succes !',
                ...toastSuccess
            });
          }else{
              iontoast({
                  message: 'Update device icon type Fail !',
                  ...toastFail
              });
          }
          console.log("changeSelection ",res)
      }else{ 
          iontoast({
              message: 'Please select device !!',
              ...toastFail
          });
      }
      }
      const updateIdleTime=async()=>{
        if(idleTime){
            const res = await setBehaviorIdleTime({idleTime:idleTime ,deviceId: preview?.device?.device_id})
            if(res.result){
                iontoast({
                    ...toastSuccess ,
                    message: 'Update device  Success!',
                    position:"middle",
                });
            }else{
                iontoast({
                    ...toastFail ,
                    message: 'Update device  Fail !!',
                    position:"middle",
                });
            }
        }
      }
      const updateSpeeding=async()=>{
        if(speeding){
            const res = await setBehaviorSpeeding({speed:speeding ,deviceId: preview?.device?.device_id})
            if(res.result){
                iontoast({
                    message: 'Update device  Success!',
                    ...toastSuccess
                });
            }else{
                iontoast({
                    message: 'Update device  Fail !!',
                    ...toastFail
                });
            }
        }
      }

      const clearInput=()=>{
        setSpeeding(null)
        setIdleTime(null)
        setCIdle(null)
        setCMove(null)
        setName("")
        setName2("")
        setPhone("")
        setExpire("")
        setNote("")
      }
  
    return(
      <IonModal isOpen={openedit} mode='ios' onIonModalDidDismiss={()=>{setOpenEdit(false);clearInput()}}  >
              <IonContent className='ion-padding' >
                <IonGrid>
                <IonRow   >
                  <IonCol size='10'  > 
                    <IonLabel className='summary-title'><strong> แก้ไข  {preview?.device?.name}</strong> </IonLabel> 
                  </IonCol>
                  <IonCol size='2' className='ion-text-right'> 
                    <IonButton mode='ios' fill='clear' size='small'  onClick={()=>{setOpenEdit(false)}}> <IonIcon icon={closeCircle} />  </IonButton>
                  </IonCol> 
                </IonRow><br/>
                
                <IonRow   > 
                  <IonCol size='6' > 
                    <IonLabel className='summary-title'> ชื่อเรียก </IonLabel> 
                    <div className='summary-val input'> 
                      <IonInput value={name} mode='ios' onIonChange={(e)=>{setName(e.detail.value!)}} ></IonInput>
                    </div> 
                  </IonCol>  
  
                  <IonCol size='6' > 
                    <IonLabel className='summary-title'> ชื่อรอง</IonLabel> 
                    <div className='summary-val input'> 
                      <IonInput value={ name2 } mode='ios'  onIonChange={(e)=>{setName2(e.detail.value!)}} ></IonInput>
                    </div> 
                  </IonCol> 
                  <IonCol size='6' > 
                    <IonLabel className='summary-title'> เบอร์โทร</IonLabel> 
                    <div className='summary-val input'> 
                      <IonInput value={phone} mode='ios' onIonChange={(e)=>{setPhone(e.detail.value!)}}  ></IonInput>
                    </div> 
                  </IonCol> 
                  <IonCol size='6' > 
                    <IonLabel className='summary-title'> วันหมดอายุ</IonLabel> 
                    <div className='summary-val input'> 
                      <IonInput value={expire} mode='ios' disabled onIonChange={(e)=>{setExpire(e.detail.value!)}}  ></IonInput>
                    </div> 
                  </IonCol>    
                  <IonCol size='6' > 
                    <IonLabel className='summary-title'> Note</IonLabel> 
                    <div className='summary-val input'> 
                      <IonTextarea value={note} mode='ios' disabled onIonChange={(e)=>{setNote(e.detail.value!)}}  ></IonTextarea>
                    </div> 
                  </IonCol>
                  <IonCol size='6'   >
                    <IonRow className='ion-justify-content-end'  >
                      <IonCol size='2.5' >
                        <IonButton fill='outline' mode='ios' expand='block' size='small' onClick={()=>{setOpenEdit(false)}}>  {t("close")} </IonButton>
                      </IonCol>
                      <IonCol size='2.5' >
                        <IonButton  mode='ios' expand='block' size='small' onClick={()=>{updateDInfo()}} > {t("save")}   </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonCol> 
                </IonRow> 
                <Tabs className={"home-screen-tabs "} style={{height:"fit-content"}}>
                    <TabList  >
                        <Tab style={{fontSize:".9em"}}> ตั้งค่าอัตราสิ้นเปลือง </Tab>
                        <Tab style={{fontSize:".9em"}}> ตั้งค่าไอคอน </Tab> 
                        <Tab style={{fontSize:".9em"}}> พฤติกรรมการขับ </Tab> 
                    </TabList>
                    <TabPanel>
                    <IonRow>
                      {/* <IonCol size='4' className='set-center' style={{alignItems:"flex-start"}}> <IonLabel>ตั้งค่าอัตราสิ้นเปลือง</IonLabel> </IonCol>
                      <IonCol size='8' className='set-center'> <div style={{borderTop:"1px solid #DDD" , width:"100%" }} ></div> </IonCol> */}
                      <IonCol size="12" ></IonCol>
                      <IonCol size='6' > 
                        <div className='summary-val input consumption'> 
                            <IonInput value={cMove} mode='ios'  placeholder='อัตราสิ้นเปลืองขณะรถวิ่ง/km/ลิตร' onIonChange={(e)=>{setCMove(e.detail.value!)}}  ></IonInput>
                        </div> 
                        <div className='summary-val input consumption'> 
                            <IonInput value={cIdle} mode='ios'  placeholder='อัตราสิ้นเปลืองขณะจอดติดเครื่องยนต์/ลิตร/ชั่วโมง' onIonChange={(e)=>{setCIdle(e.detail.value!)}}  ></IonInput>
                        </div>  
                        </IonCol>
                        <IonCol size='6' > 
                            <IonRadioGroup value={pttType} onIonChange={(e)=>{setPttType(e.detail.value)}} >
                            {pttPrices.map((e,i)=>  <IonRadio key={i} value={e.pttType} labelPlacement='end' style={{marginRight:"1rem",fontSize:".9em"}}>{e.name}</IonRadio>) } 
                            </IonRadioGroup> 
                        </IonCol>
                        <IonCol size='12' >
                            <IonButton 
                            fill='clear' mode='ios' 
                            expand='block' size='small' 
                            onClick={()=>{ 
                                updateConsumtion(); 
                                }}
                            >    Update  </IonButton> 
                        </IonCol>
                        </IonRow>
                        <IonRow>  
                      </IonRow> 
                    </TabPanel>
                    <TabPanel> 
                       <SettingIcon default={icon} deviceId={preview?.device?.device_id} setIcon={(id:any)=>setIcon(id)} />
                        <IonRow>
                            <IonCol size="12" >
                                <IonButton size="small" onClick={()=>{updateDeviceTypeId()}}>
                                    <IonLabel>{t("update")}</IonLabel>
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </TabPanel>
                    <TabPanel> 
                        <IonGrid>
                            <IonRow>
                                <IonCol size="12"> 
                                    <IonLabel color={"dark"} ><strong>กำหนดพฤติกรรมการขับ</strong></IonLabel> 
                                </IonCol>
                                <IonCol size="6" >
                                    <div className="input set-center-row" style={{padding:"0 .5rem 0 .5rem"}}>
                                        <IonText color={"secondary"} ><FontAwesomeIcon icon={faTachometerAltFast} /></IonText> &nbsp;
                                        <IonInput value={speeding} onIonChange={(e)=>{setSpeeding(e.detail.value)}} placeholder="ความเร็วที่ยอมรับได้" mode="ios" ></IonInput> &nbsp;
                                        <IonText color={"medium"} ><small>{t("kmHUnit")}</small></IonText>
                                    </div>
                                    <IonButton size="small"  onClick={()=>{updateSpeeding()}}>Update</IonButton>
                                </IonCol>
                                <IonCol size="6" >
                                    <div className="input set-center-row" style={{padding:"0 .5rem 0 .5rem"}}>
                                        <IonInput value={idleTime} onIonChange={(e)=>{setIdleTime(e.detail.value)}} placeholder="เวลาจอดติดเครื่องที่ยอมรับได้"  mode="ios" ></IonInput>&nbsp;
                                        <IonText color={"medium"} ><small>{t("min")}</small></IonText>
                                    </div>
                                    <IonButton size="small"  onClick={()=>{updateIdleTime()}}>Update</IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </TabPanel>
                </Tabs>
                    
                {/* ///setting/behavioridletime */}
                </IonGrid>
              </IonContent>
            </IonModal>
    )
  }
  
 