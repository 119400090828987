import { Redirect, Route, useLocation } from 'react-router-dom';
import { IonApp, IonCol, IonRouterOutlet, IonRow, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import HomeSplitpane from './pages/HomeSplitpane';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* import '@ionic/react/css/palettes/dark.always.css'; */
/* import '@ionic/react/css/palettes/dark.class.css';  */
/* import '@ionic/react/css/palettes/dark.system.css'; */

/* Theme variables */
import './theme/variables.css';
import Signin from './pages/Signin';
import { useEffect, useState } from 'react';
import { setWindowWidth, getDefaultMap, getIsSignin, getWindowWidth, setSignin, setOS, setDarkMode, setDefaultMap } from './store/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getStorage, getTileProviders, setStorage, userSetting } from './actions';
import { Device } from '@capacitor/device';
import { setMapDark, setTileproviders } from './store/mapSlice';
import { ReactNotifications } from 'react-notifications-component';
import Menu from './components/Menu';
import IdleTimerComponent from './components/IdleTimerComponent';
import NavBar from './components/NavBar';
import Admin from './pages/Admin';
import Behaviors from './pages/Behaviors';
import Dashboards from './pages/Dashboards';
import Geozones from './pages/Geozones';
import GroupDevice from './pages/GroupDevice';
import GroupMonitor from './pages/GroupMonitor';
import GroupRouteHistory from './pages/GroupRouteHistory';
import Guests from './pages/Guests';
import HomeScreen from './pages/HomeScreen';
import Longdo from './pages/Longdo';
import Maintenance from './pages/Maintenance';
import Manual from './pages/Manual';
import PlayBack from './pages/Mdvr/PlayBack';
import RealtimeMdvr from './pages/Mdvr/RealtimeMdvr';
import Notifications from './pages/Notifications';
import NotificationSetting from './pages/NotificationSetting';
import PageNotFound from './pages/PageNotFound';
import Passroute from './pages/Passroute';
import PassrouteMap from './pages/PassrouteMap';
import Reports from './pages/Reports';
import RepTravelSum from './pages/Reports/RepTravelSum';
import SetRoute from './pages/SetRoute';
import { Settings } from './pages/Settings';
import Employees from './pages/settings/Employees';
import TravelRoute from './pages/TravelRoute';
import TravelRouteAdd from './pages/TravelRouteAdd';
import Payments from './payment/Payments';
import DrivingPoints from './pages/BeahaviorDriving'; 
import { t } from 'i18next';
import SEOTitle from './components/SEOTitle';



setupIonicReact();

const App: React.FC = () =>{
  const dispatch = useDispatch()  
  const isSinin :any= useSelector(getIsSignin) 
  const width =useSelector(getWindowWidth) 
  const [os,setOs] = useState("ios")  
  const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');  
  
  const setDeviceModel=async()=>{ 
    dispatch(setWindowWidth(window.innerWidth))
    const info = await Device.getInfo()   
    setOs(info.operatingSystem.toLowerCase())
    dispatch(setOS(info.operatingSystem.toLowerCase()))  
  }
  
  const checkSignin=async()=>{
    const issigni = await getStorage("isSignin")
    if(issigni){
      dispatch(setSignin(issigni))
    }
  }
  
  const checkDarkMode=async()=>{
    const isDark = await getStorage("darkmode")
    const darkmap = await getStorage("darkmap") 
    console.log("darkmap ", typeof darkmap , darkmap)
    dispatch(setMapDark(darkmap === null ||  darkmap === undefined ||  darkmap === true  ||  darkmap === '' ?true:false))    
      //document.body.classList.add( "map-dark" )
    if(isDark){  
      dispatch(setDarkMode(isDark)) 
    }
  } 
 
  const getDefaultMap=async()=>{  
    if(isSinin){ 
      let setting = await userSetting()  
      setStorage("default", setting) 
      dispatch(setDefaultMap(setting?.defaultMap))
      const tiles = await getTileProviders()
      dispatch(setTileproviders(tiles))
    } 
  }
  
  useEffect(()=>{    
    checkSignin()
    // window.addEventListener("resize", ()=>setDeviceModel());
    setDeviceModel() 
    dispatch(setWindowWidth(window.innerWidth))
    checkDarkMode() 

    getDefaultMap()  
  },[width,prefersDark,isSinin])

  
  return(
  <IonApp>
    <ReactNotifications />
    <IonReactRouter>
      <Menu/>   
      {
     ! isSinin ?   
        <SEOTitle  >
             <IonRouterOutlet id="main">  
              <Route
                   path="*"
                   component={ PageNotFound  }
               />
                   <Route path='/signin' component={Signin} exact={true} />  
                   <Route path='/home' component={HomeSplitpane} exact={true} />
                   <Route exact path="/" render={() => <Redirect to="/signin" />} />
               </IonRouterOutlet>
          </SEOTitle>
         :    
         <IonRow  >  
           <IdleTimerComponent /> 
           <NavBar />   
           <SEOTitle  >
           <IonCol style={{paddig:'0px'}}  >
               <IonRouterOutlet id="main" >  
                 <Route path='/signin' component={Signin} exact={true} />  
                 <Route path='/home' component={HomeSplitpane} exact={true} />
                 {/* <Route path='/map' component={Map} exact={true} /> */}
                 <Route path='/settings' component={Settings} exact={true} />
                 <Route path="/dashboard" component={Dashboards} exact={true} />
                 <Route path='/routehistory' component={Passroute} exact={true} />
                 <Route path='/routehistoryMap' component={PassrouteMap} exact={true} />
                 <Route path="/geofences" component={Geozones} exact={true} />
                 <Route path='/manual' component={Manual} exact={true} /> 
                 <Route path='/admin' component={Admin} exact={true} /> 
                 <Route path='/reports' component={Reports} exact={true} />  
                 <Route path='/notification' component={Notifications} exact={true} /> 
                 <Route path='/maintenance' component={ Maintenance} exact={true} /> 
                 <Route path='/suggestRoute' component={TravelRoute} exact={true} /> 
                  <Route path='/suggestRoute/add' component={TravelRouteAdd} exact={true} /> 
                 <Route path={'/drivingPoint'} component={DrivingPoints} exact={true} />
                 <Route path={'/behaviors'} component={Behaviors} exact={true} /> 
                 <Route path={'/longdotest'} component={Longdo} exact={true} />
                 <Route path={'/reports/repTravelSum'} component={RepTravelSum} exact={true} />
                 <Route path='/notificationSetting' component={NotificationSetting} exact={true} /> 
                 <Route path={'/mdvr/playback'} component={PlayBack} exact={true} />
                 <Route path={'/mdvr/realtime'} component={RealtimeMdvr} exact={true} />
                 <Route path={'/mdvr/realtime'} component={RealtimeMdvr} exact={true} />
                 <Route path={'/employees'} component={Employees} exact={true} />
                 <Route path={"/groupdevice"} component={GroupDevice} exact={true}  />
                 <Route path={"/guests"} component={Guests} exact={true}  />
                 <Route path={"/setRoute"} component={SetRoute} exact={true}  /> 
                 <Route path={"/groupmonitor"} component={GroupMonitor} exact={true}  /> 
                 <Route path={"/grouproutehistory"} component={GroupRouteHistory} exact={true}  /> 
                 <Route path={"/payments"} component={Payments} exact={true}  /> 
                 {/* <Route path={"/splitpane"} component={Splitpane} exact={true}  />  */}
                 
                  
                 <Route exact path="/" render={() => <Redirect to="/home" />} />
               </IonRouterOutlet>
             </IonCol>
             </SEOTitle>
         </IonRow>   
     }   
    </IonReactRouter>
  </IonApp>
)};

export default App;
