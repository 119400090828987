import { IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid,  IonHeader,  IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonLoading, IonPage,  IonPopover,  IonRow, IonSearchbar, IonSelect, IonSelectOption, IonSpinner, IonText, IonTitle, IonToolbar, useIonAlert } from '@ionic/react';
import { useEffect,  useRef,  useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'; 
import { getBackup, getLoading, setBackupDevices, setDevices, setLoading } from '../store/appSlice';
import { AuthCheck } from './Home';
import { chevronBackOutline, closeCircle } from 'ionicons/icons'; 
import { ReportsSelection, DateTypeFilter, DeviceTypeFiltew, DateStartReport, DateEndReport, MonthlyReport, SearchPDF, ExportExcel, ExportPDF, DateRangPopover } from '../components/ReportComponent/ReportComponent';
import { DeviceSearchPopup, DropDownDevice, DropDownDeviceMultiple, DropDownGeozone  } from '../components/AppComponent';
import RepSpeedFuel from './Reports/RepSpeedFuel';
import RepCarDailyUse from './Reports/RepCarDailyUse';
import RepDistanceDaily from './Reports/RepDistanceDaily'; 
import RepTravelSum from './Reports/RepTravelSum';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import 'moment/dist/locale/th';   
import RepBehavior from './Reports/RepBehavior'; 
import RepEventIdle from './Reports/RepEventIdle';
import RepEventStop from './Reports/RepEventStop';
import RepIgnition from './Reports/RepIgnition';
import RepCloseOpenPTO from './Reports/RepCloseOpenPTO';
import { reportbyid } from '../components/ReportComponent/ReportById';
import ReportMinuteDaily from './Reports/ReportMinuteDaily';
import RepCircleDaily from './Reports/ChartReport/RepCircleDaily';
import RepFuel from './Reports/ChartReport/RepFuel';
import RepTemp from './Reports/ChartReport/RepTemp';
import DeviceInOut from './Reports/GeofenseReport/DeviceInOut';
import NavBar from '../components/NavBar';
import RepOverSpeed from './Reports/RepOverSpeed';
import { useHistory } from 'react-router';
import RepFuelUsaged from './Reports/RepFuelUsaged'; 
import { allDevices } from '../actions';
import DevicesInOut from './Reports/GeofenseReport/DevicesInOut';

import './css/Reports.css'
import '../theme/style.css';   
import RepDistanceRange from './Reports/RepDistanceRange';
import RepStatusBar from './Reports/ChartReport/RepStatusBar';
import RepStatusBarDaily from './Reports/ChartReport/RepStatusBarDaily';
import RepFuelSpeed from './Reports/ChartReport/RepFuelSpeed';
import { t } from 'i18next';
import RepAverageFuelDistance from './Reports/ChartReport/RepAverageFuelDistance';
import RepFuelUsagedById from './Reports/RepFuelUsagedById';
import RepFuelUasage from './Reports/RepFuelUasage';

const moment = extendMoment(Moment);
moment.locale("th")

const menuReports = [
  {
    id: "0001",
    namegroup:"รายงานยานพาหนะ",
    reports: [
      { id: "0011", name:"รายงานการใช้ความเร็ว การใช้น้ำมัน" , multiple: false, orientation:"portrait" , enable:false ,daily: false},
      { id: "0012", name:"รายงานการใช้ความเร็ว อุณหภูมิ"  , orientation:"portrait" , enable:false  ,daily: false},  
      { id: "0013", name:"รายงานสรุปรวมการใช้รถ" , multiple: false, orientation:"portrait"   , enable:false ,daily: false},  
      { id: "0014", name:"รายงานพฤติกรรมการขับรถรายคัน"  , multiple: false , orientation:"portrait"  , enable:true ,daily: false},  
      { id: "0015", name:"รายงานสรุปเที่ยวการเดินทาง"  , multiple: false , orientation:"portrait"  , enable:true ,daily: false},  
      { id: "0016", name:"รายงานการใชใ้น้ำมัน  (ระบุเอง) "  , multiple: false , orientation:"portrait"  , enable:true ,daily: false},  
      { id: "0017", name:"รายงานการใชใ้น้ำมัน รายเดือน"  , multiple: false , orientation:"portrait"  , enable:true ,daily: false},  
      
    ]
  },
  {
    id: "0002",
    namegroup:"รายงานการเดินทาง",
    reports: [
      { id: "0021", name:"รายงานการใช้รถรายวัน" , orientation:"landscape" ,multiple: false, enable:true  ,daily: false}, 
      { id: "0023", name:"รายงานข้อมูลการเดินทางรายนาที" , orientation:"landscape" ,multiple: false, enable:true  ,daily: false},  
      { id: "0026", name:"รายงานระยะทางรายวัน" , orientation:"landscape" ,multiple: false , enable:true  ,daily: false}, 
      { id: "0027", name:"รายงานระยะทาง ช่วงเวลา"  , multiple: true , orientation:"landscape"  , enable:true ,daily: true},  
    ]
  },
  {
    id: "0007",
    namegroup:"รายงานน้ำมัน",
    reports: [
      { id: "0016", name:"รายงานการใช้น้ำมัน  (ระบุเอง) "  , multiple: false , orientation:"portrait"  , enable:true ,daily: false},  
      { id: "0017", name:"รายงานการใช้น้ำมัน รายเดือน"  , multiple: false , orientation:"portrait"  , enable:true ,daily: false},  
      { id: "0052", name:"รายงานกราฟน้ำมัน" , multiple: false, orientation:"portrait"  ,enable:true ,daily: false},
      { id: "0056", name:"รายงานกราฟน้ำมัน และความเร็ว" , multiple: false, orientation:"portrait"  ,enable:true ,daily: false},
      { id: "0057", name:"รายงานเฉลี่ยการใช้น้ำมันกับระยะทาง  " , multiple: true, orientation:"portrait" ,enable:true  ,daily: true},  
      { id: "0071", name:"รายงานการใช้น้ำมัน  รายวัน" , multiple: true, orientation:"landscape" ,enable:true  ,daily: true},  
    
    ]
  },
  {
    id: "0003",
    namegroup:"รายงานเหตุการณ์",
    reports: [
      { id: "0031", name:"จอดติดเครื่องยนต์" , orientation:"landscape" ,multiple: false , enable:true  ,daily: false}, 
      { id: "0032", name:"รายงานการหยุดรถ" , orientation:"landscape" ,multiple: false, enable:true,daily: false}, 
      { id: "0033", name:"เปิด-ปิด กุญแจ" , orientation:"landscape" ,multiple: false,enable:true,daily: false}, 
      { id: "0034", name:"รายงานการรูดใบขับขี่" , orientation:"portrait" ,multiple: false, enable:false ,daily: false}, 
      { id: "0035", name:"เปิด-ปิด PTO" , orientation:"portrait" ,multiple: false, enable:true, daily: false},  
    ]
  },
  {
    id: "0004",
    namegroup:"รายงานพื้นที่",
    reports: [
      { id: "0041", name:"การเข้า-ออกเขตพื้นที่/สถานี" , orientation:"landscape" ,multiple: false,enable:true ,daily: false}, 
      { id: "0042", name:"การออก-เข้าเขตพื้นที่/สถานี" , orientation:"landscape" ,multiple: false, enable:true,daily: false}, 
      { id: "0043", name:"เขตพื้นที่/สถานี" , orientation:"portrait" ,multiple: true,enable:true ,daily: false}, 
      // { id: "0041", name:"การออก พื้นที่" , orientation:"portrait" ,multiple: false, }, 
      // { id: "0042", name:"เข้า-ออกพื้นที่", orientation:"portrait" } 
    ]
  },
  {
    id: "0005",
    namegroup:"รายงานกราฟ",
    reports: [
      { id: "0051", name:"รายงานสถานะ กราฟวงกลม" , multiple: false, orientation:"portrait" ,enable:true  ,daily: false},  
      // { id: "0054", name:"รายงานสถานะ กราฟแท่ง" , multiple: false, orientation:"portrait" ,enable:true} , 
      { id: "0052", name:"รายงานกราฟน้ำมัน" , multiple: false, orientation:"portrait"  ,enable:true ,daily: false},
      { id: "0056", name:"รายงานกราฟน้ำมัน และความเร็ว" , multiple: false, orientation:"portrait"  ,enable:true ,daily: false},
      { id: "0053", name:"รายงานกราฟอุณหภูมิ" , multiple: false, orientation:"portrait" ,enable:true,daily: true} ,
      { id: "0054", name:"รายงานสถานะกราฟแท่งรายคัน  " , multiple: false, orientation:"portrait" ,enable:true  ,daily: false},  
      { id: "0055", name:"รายงานสถานะกราฟแท่งรายวัน  " , multiple: true, orientation:"portrait" ,enable:true  ,daily: true},  
      { id: "0057", name:"รายงานเฉลี่ยการใช้น้ำมันกับระยะทาง  " , multiple: true, orientation:"portrait" ,enable:true  ,daily: true},  
    ]
  },
  {
    id: "0006",
    namegroup:"รายงานกฎหมายกรมขนส่งทางบก",
    reports: [
      { id: "0061", name:"รายงานความเร็วเกิน" , orientation:"portrait" , multiple: false ,enable:true,daily: false}, 
      { id: "0062", name:"รายงานขับเกิน 4 ชั่วโมง" , orientation:"portrait" , multiple: false ,enable:true,daily: false} , 
    ]
  }
] 


const Reports: React.FC = () => { 
  const [loading , setLoading] = useState(false)
  const devices :any= useSelector(getBackup)
  const dispatch = useDispatch()
  const [report,setReport] = useState({ id: "0021", name:"รายงานการใช้รถรายวัน" , orientation:"landscape" ,multiple: false, enable:true  ,daily: false} )
  const [datetype,setDateType] = useState({value:"date",label:"รายวัน"})
  const [devicetype,setDeviceType] = useState({value:"one",label:"รายคัน"})
  const [vehihle,setVehicle] = useState({device_id:0 ,value: 0,name:"Select"})
  const [vehicleCheck,setVehicleCheck] = useState([{checked:false, device_id:0 ,value: 0,label:"Select"}])
  const [vehicles,setVehicles] = useState<any[]>([])
  const [datestart,setDateStart] = useState(moment().format("YYYY-MM-DDT00:00").toString())
  const [dateend,setDateEnd] = useState(moment().format("YYYY-MM-DDT23:59").toString()) 
  const [month ,setMonth] = useState(moment().format("YYYY-MM-DDTHH:mm").toString())
  const [openDate,setOpenDate] = useState(false)  
  const [logo,setLogo] =  useState('https://andamantracking.com/public/logo-report.png')
  const [pieUri, setPieUri] = useState("")
  const [generate, setGenerate] = useState(false)
  const [reporting,setReporting] = useState<any>([])
  const [loadReport,setLoadReport] = useState(false) 
  const [fuelprice,setFuelPrice] = useState<any>()
  const [fuelRate,setFuelRate] = useState<any>()
  const [zoneSelect, selectGeoZone] = useState<any>({value:0 , name:"Select"})
  const [keyword,setKeyword] = useState<any>("")
  const [reportDetail,setReportDetail] = useState(null) 
  const [reportReady,setReportReady] = useState(false)
  const [options,setOptions] = useState<any[]>([])
  const [optionsBackup,setOptionsBackup] = useState<any[]>([])
  const [alert] = useIonAlert(); 
  const history = useHistory()

  useEffect(()=>{   
    const getdevices=async()=>{
      setLoading(true)
      let result = await allDevices({}) 
      setLoading(false)
      await dispatch(setDevices(result))
      await dispatch(setBackupDevices(result)) 
      let options = result.map((e:any)=> {return {value: e.device_id ,label: e.name, ...e } })
      setOptions(options)   
      let optionscheck:any[] = result.map((e:any)=> {return {checked:false, value: e.device_id ,label: e.name, ...e } })
      optionscheck.unshift({checked:false, device_id:0 ,value: 0,label:"ทั้งหมด"})
      setOptionsBackup(optionscheck)
      setVehicleCheck(optionscheck) 
   } 
   getdevices()  
  },[ ])
 
  const searhReport=async()=>{   
    setLoadReport(true)  
    var vehihcles :any= [0]
    if(report?.multiple){
      vehihcles = options.filter((e)=> e.checked).map((e)=> { return e.value})
    }else if(report?.id === "0041" || report?.id === "0042"){
      vehihcles = [vehihle.device_id]
    } 
    let reportContent:any = {success:false}
    try {
      reportContent =await reportbyid({
        report , datestart , dateend ,vehihle ,vehihcles , more:{fuelprice: fuelprice ,ratefuel: fuelRate , geozoneid: zoneSelect?.value }
      })
    } catch (error) { 
      setLoadReport(false) 
    } 
    setLoadReport(false) 
    setLoading(false)  
    console.log("reportContent ",reportContent)
    setReportReady(reportContent.success) 
    if(reportContent.success){
      setReportDetail(reportContent) 
      setTimeout(()=>{
        setReportReady(true) 
       },500)
    }else if(!reportContent.success){
      setLoadReport(false)  
      alert({
        header: 'ไม่พบข้อมูล',
        // subHeader: 'A Sub Header Is Optional',
        message: 'ไม่พบข้อมูลของรายงาน '+report?.name+" ที่เลือก." ,
        buttons: [t('ok')],
        mode:"ios"
      })
    } 
  }

  // useEffect(()=>{ },[vehihle ,vehicleCheck])

  function setselected(e:any){
    if(report.multiple ){ 
      let updatevehihcles = vehicles
      let isAdd = vehicles.find((v)=> v.value === e.value )
      if(!isAdd){ 
        updatevehihcles.push(e) 
        setVehicles(updatevehihcles)  
      }
    } else{
      console.log("setVehicle ")
      setVehicle(e)
    } 
  }

  const checkedVehicleOption=(index:any , val:any)=>{
    console.log("updateopts ",val)
    let updateopts = vehicleCheck
    if(val.value === 0 && val.checked){  
      updateopts = vehicleCheck.map((e )=>{
        return {...e,...{checked:true}}
      })  
    }else if(val.value === 0 && !val.checked){  
      updateopts = vehicleCheck.map((e )=>{
        return {...e,...{checked:false}}
      })  
    } else{
      setVehicleCheck(updateopts)
      updateopts.splice(index , 0)
      updateopts[index] = val 
    }
    console.log("checkedVehicleOption ",updateopts)
    setOptions(updateopts)
    setVehicleCheck(updateopts)
  }

  function removeVehicle(index:any){
    let updatevehihcles = vehicles
    updatevehihcles.splice(index,1) 
    setVehicles(updatevehihcles)  
  }

  const changeDateWithMonth=(date:any)=>{ 
    setDateStart(moment(date).startOf('month').format("YYYY-MM-DDT00:00:00"))
    setDateEnd(moment(date).endOf('month').format("YYYY-MM-DDT23:59:59"))
  }

  return (
    <IonPage>
    <IonLoading isOpen={loadReport}  mode='ios' onIonLoadingDidDismiss={()=>{setLoadReport(false)}}  message="...Loading"    />
  
      <div className=' ion-padding' >
        <IonHeader className='toolbar-report ion-no-border ion-hide-md-down' > 
           <ul  className='toolbar' style={{paddingLeft:'1rem'}}>
              <li> <ReportsSelection 
                groups={menuReports} 
                selected={report} 
                select={(e:any)=>{ 
                  if(e?.id === "0017"){  
                    changeDateWithMonth(datestart)
                    setDateType({value:"month",label:"รายเดือน"})  
                  }
                  setReportDetail(null);
                  setReportReady(false) ;
                  return setReport(e)
                }} 
              /></li> 
              <li className='separator'>|</li>
              <li> <DateTypeFilter type={datetype} setType={(e:any)=>{return setDateType(e) }}/> </li><li className='separator'>|</li>
              {/* <li> <DeviceTypeFiltew  type={devicetype} multiple={report?.multiple} setType={(e:any)=>{return setDeviceType(e) }}/>  </li><li className='separator'>|</li> */}
              <li style={{position:"relative",minWidth:"15rem",display:"flex" , flexDirection:"row" , justifyContent:"center" , alignItems:"center"}} >  
               {report?.multiple ? 
                <DropDownDeviceMultiple 
                  vehicles={options} setVehicle={(index:any, val:any)=>{ checkedVehicleOption(index , val)  }}  
                  optionsbackup={optionsBackup}   setOptions={(opts:any)=>{setOptions(opts)}}   keyword={keyword}  setKeyword={(e:any)=>setKeyword(e)}
                /> :  
                <DropDownDevice  
                  vehihle={vehihle} setVehicle={(e:any)=>{ setselected(e) }}  
                  optionsbackup={options}  
                /> }
                {loading && <IonSpinner name={"circles"} />}
             </li>  
             { Number(report?.id) > 40  &&  Number(report?.id) <50 &&<li>
              <DropDownGeozone zoneSelect={zoneSelect} selectZone={(z:any)=>{console.log("selectZone ", z ); selectGeoZone(z)}} />
             </li>}
           </ul>

           <ul  className='toolbar   '  >
             { datetype.value === "date" ? <li style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly"}}><IonButton size='small' fill='clear' color={"dark"}>เริ่มต้น</IonButton> 
                <DateStartReport date={datestart} openDate={setOpenDate} bg={true} showtime={true}/> 
                <IonButton size='small' fill='clear' color={"dark"}>ถึง</IonButton>  
                <DateEndReport date={dateend} openDate={setOpenDate} bg={true}   showtime={true} />
             </li>:
             <li>
              <MonthlyReport value={month} setMonth={(e:any)=>{
                 changeDateWithMonth(e)
                return setMonth(e)}} />
             </li>
            } 
             <li className='separator'>|</li>
             {report.id === "0016" && <li style={{display:"flex" , flexDirection:"row"}} > 
                <IonInput value={fuelprice} placeholder='Fuel Price' className='input-fuel' mode='ios' type='number'   onIonChange={(e)=>{setFuelPrice(e.detail.value!)}} >  </IonInput>
                <IonInput value={fuelRate} placeholder='Fuel Rate'  className='input-fuel'  mode='ios' type='number'   onIonChange={(e)=>{setFuelRate(e.detail.value!)}} >  </IonInput>
              </li> }
             <li> 
                <IonButton onClick={()=>{setReportReady(false) ;searhReport() }} color={"primary"} mode='ios' fill='clear' size='small' >
                {generate ? <IonSpinner name="bubbles"></IonSpinner>:<></>}  ค้นหา 
                </IonButton>
              </li> 
          </ul> 
        </IonHeader> 
        {/* <IonGrid className='ion-hide-md-up'>
          <IonRow  >
            <IonCol size='12' >
              <ReportsSelection groups={menuReports} selected={report} 
              select={(e:any)=>{
                console.log("ReportsSelection ",e)
                if(e?.id === "0017"){  setDateType({value:"month",label:"รายเดือน"})  }
                setReportDetail(null);
                setReportReady(false) ;
                return setReport(e)}} />
            </IonCol>
            <IonCol size='12' > 
              <DateTypeFilter type={datetype} setType={(e:any)=>{return setDateType(e) }}/>
              <DeviceTypeFiltew  type={devicetype} multiple={report?.multiple} setType={(e:any)=>{return setDeviceType(e) }}/>  
            </IonCol>
            <IonCol size='12'>
              <DropDownDevice  
                vehihle={vehihle} 
                setVehicle={(e:any)=>{ setselected(e)  }}  
              /> 
            </IonCol> 
          </IonRow> 
          { datetype.value === "date" ? <IonRow>
              <IonCol size='6' >
                <DateStartReport date={datestart} openDate={setOpenDate} bg={true} />
              </IonCol>
              <IonCol size='6' >
              <DateEndReport date={dateend} openDate={setOpenDate} bg={true} />
              </IonCol>
          </IonRow>:<IonRow>
            <IonCol size='12'>
              <MonthlyReport value={month} setMonth={(e:any)=>{
                 changeDateWithMonth(e)
                return setMonth(e)}} />
            </IonCol>
          </IonRow>}<br />
          <IonRow>
            <IonCol size='12'>
              <IonButton mode='ios' expand='block'>
                  Search
              </IonButton>
            </IonCol>
          </IonRow> 
        </IonGrid>  */}
      </div>

      <IonContent  scrollY  > 
        <div className='report-content-container set-center' style={{position:'relative',marginTop:".3rem",marginBottom:"2rem"}} > 
          <IonButton fill='clear' size='small' 
            onClick={()=>{ setReportReady(false) }} 
            style={{position:"absolute", top:'1rem', right:'3rem' ,zIndex: 9999}} 
          >  <IonIcon icon={closeCircle} />  
          </IonButton> 
          <div  className={`paper-report  ${report.orientation} `} > 
        
            {reportReady && <ReportContent report={report} reportData={reportDetail}/> }  
          </div> 
        </div> 
        <DateRangPopover start={datestart} setStart={(e:any)=>{setDateStart(e) }} end={dateend} setEnd={setDateEnd} openDate={setOpenDate} isOpen={openDate} daily={report?.daily}  />
       <div style={{width:"100%" , height:"5rem"}} ></div>
      </IonContent> 
    </IonPage>
  );
};

export default Reports;
  
const ReportContent=({report,reportData}:any)=>{

  return( <div id='main-report-content' >
      {report.id === "0011" && <RepSpeedFuel table={reportData.table} detail={reportData.detail} chart={reportData.chart} orientation={report?.orientation}/>} 
      {/* {report.id === "0011" && <RepSpeedFuel table={reportData.table} detail={reportData.detail} chart={reportData.chart} />}  */}
      {report.id === "0014" && <RepBehavior  table={reportData.table} detail={reportData.detail} pdf={reportData.pdf} orientation={report?.orientation}/> }
      {report.id === "0015" && <RepTravelSum polyline={reportData.polyline} detail={reportData.detail} overview={reportData.overview} orientation={report?.orientation}/> }
      {report.id === "0016" && <RepFuelUsaged table={reportData.table} detail={reportData.detail} overview={reportData.tableOverview} chart={reportData?.chart} orientation={report?.orientation}>   
         <h1>รายงานการใชใ้น้ำมัน</h1>
        </RepFuelUsaged> }
      {report.id === "0017" && <RepFuelUsagedById table={reportData.table} detail={reportData.details}   orientation={report?.orientation} /> }
      
      {report.id === "0021" && <RepCarDailyUse  table={reportData.table} overview={reportData.overview} details={reportData.details} orientation={report?.orientation}/>}
      {report.id === "0023" && <ReportMinuteDaily table={reportData.table} details={reportData.details} maxSpeed={reportData.maxSpeed} orientation={report?.orientation}/>}
      {report.id === "0025" && <RepTravelSum /> }
      {report.id === "0026" && <RepDistanceDaily details={reportData.details} table={reportData.table} /> }
      {report.id === "0027" && <RepDistanceRange  details={reportData.details} table={reportData.table}  origin={reportData.origin}  /> }

      {report.id === "0031" && <RepEventIdle table={reportData.table}   details={reportData.details} orientation={report?.orientation}/>}
      {report.id === "0032" && <RepEventStop table={reportData.table}   details={reportData.details} orientation={report?.orientation}/>}
      {report.id === "0033" && <RepIgnition  table={reportData.table} details={reportData.details} orientation={report?.orientation}/>}
      {report.id === "0035" && <RepCloseOpenPTO table={reportData.table} details={reportData.details} orientation={report?.orientation}/>}

      {report.id === "0041"   && <DeviceInOut table={reportData.table} details={reportData.details} orientation={report?.orientation} />}
      {report.id === "0042" && <DeviceInOut table={reportData.table} details={reportData.details} orientation={report?.orientation} />}
      {report.id === "0043" && <DevicesInOut table={reportData.table} details={reportData.details} orientation={report?.orientation} />}


      {report.id === "0051" && <RepCircleDaily chart={reportData.chart} details={reportData.details} orientation={report?.orientation}/> }
      {report.id === "0052" && <RepFuel   chart={reportData.chart} details={reportData.details} orientation={report?.orientation}/>}
      {report.id === "0053" && <RepTemp  chart={reportData.chart} details={reportData.details} orientation={report?.orientation}/>}
      {report.id === "0054" && <RepStatusBar  chart={reportData.chart} details={reportData.details} orientation={report?.orientation}/>}
      {report.id === "0055" && <RepStatusBarDaily chart={reportData.chart} details={reportData.details} orientation={report?.orientation}/>}
      {report.id === "0056" && <RepFuelSpeed   chart={reportData.chart} details={reportData.details} orientation={report?.orientation}/>}
      {report.id === "0057" && <RepAverageFuelDistance chart={reportData.chart} details={reportData.details} orientation={report?.orientation}/>}
       

      {report.id === "0061"  && <RepOverSpeed table={reportData.table} details={reportData.details} orientation={report?.orientation} /> }
      {report.id === "0062"  && <RepOverSpeed table={reportData.table} details={reportData.details} orientation={report?.orientation}/> }
      {report.id === "0071"  && <RepFuelUasage table={reportData.table} details={reportData.details} orientation={report?.orientation} />} 
      </div>
  )
}

