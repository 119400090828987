import { IonButton, IonCol, IonIcon, IonLabel, IonRow, IonText, IonTitle } from "@ionic/react";
import moment from "moment";
import { useState, useRef, useEffect, LegacyRef } from "react";
import { configPDF, pdfMake } from "../../../lib/pdfMake" 
import { printOutline } from "ionicons/icons";
import { BarChart } from '@mui/x-charts/BarChart';
import { t } from "i18next";
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";

 
const RepAverageFuelDistance=({chart,details,orientation}:any)=>{
    console.log("chart ",chart?.series)
    const [chartImg,setChartImg] = useState("") 
    const [chartref , setChartRef] = useState<ApexCharts|null>(null)
    const chartRef:LegacyRef<ReactApexChart> | null = useRef(null)

    var apexchrt :any = null ;
    let options:any = {
        series:[] ,
        chart: {
            id: "avg-feuldistance-bar" ,
            type: 'bar',
            height: 350 ,width: 800
          },
          plotOptions: {
            bar: {
              horizontal: true,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
            //   text: '$ (thousands)'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val:any, opts:any) {
                return  val.toFixed(2)+` ${opts.seriesIndex > 0 ? t('litKilometer'): t('litHour') } 
                ( ${chart.series[opts.seriesIndex ].rawdata[opts.dataPointIndex]}  ${opts.seriesIndex > 0 ? t('kmUnit'):''} ) `
              }
            }
          }
       } 

       

      useEffect(()=>{  
        const drawChart=async()=>{
          try{
            var element= document.querySelector("#bar") 
            if(element ){ 
              console.log("drawChart element ",element)
                options.series = chart.series 
                // options.colors = chart.color
                // options.labels= chart.labels
                let upH = 350
                let categories = await   chart.categories.map((cat:any)=> { 
                    upH= upH+20
                    return  cat
                })
                console.log("categories ",categories)
                options.chart.height = upH
                options.xaxis.categories = categories
                console.log("options ", options)
                var chrt:any = new ApexCharts(element, options)
                setChartRef(chrt)
                await chrt.render() 
                setTimeout(async()=>{ 
                    let {imgURI}:any = await chrt?.dataURI()
                    console.log("imgURI ",imgURI)
                    setChartImg( imgURI) 
                },1500)  
            }
          }catch(err){
            console.log("draw chart err ", err)
          }
           
        }  
        drawChart()
      },[ ])

      const printReport=async ()=>{  
        pdfMake.createPdf({ 
            watermark: { text: 'Andaman Tracking', color: '#FD5F06', opacity: 0.1, bold: true, italics: false },
            images:{ chart: chartImg  }, 
            pageOrientation: orientation,
            content:[  
                {text: details.reportName , fontSize: 16 , bold:true  , alignment:'center', marginBottom: 15},
                {text:`วันที่ ${moment(details.start).format("HH:mm DD MMMM  YYYY")} - ${moment(details.end).format("HH:mm  DD MMMM YYYY")} `, alignment:'center'},
             
                { image:"chart" , width: 350}
            ],
            defaultStyle: {
                font: "THSarabunNew",
            },
            header: function (currentPage, pageCount) {
                return {
                    columns: [
                        { 
                            text: details.reportName ,
                            fontSize: 10 ,
                            marginLeft: 16,
                            margin: [20, 20, 50, 20] 
                        } ,
                        { text: "Page " + currentPage.toString() + ' of ' + pageCount, alignment: 'right', style: 'normalText', margin: [0, 20, 50, 0] }
                    ]
                }
        },
            footer: {
                columns: [
                    { 
                        text:"บริษัท อันดามันแทร็คกิ้ง จำกัด | Andaman Tracking .Co.Ltd  @Generate on  tracking.andamantracking.com" ,
                        fontSize: 9 ,
                        marginLeft: 15
                    } 
                ]
            },
        }).open()
      }

    return(
    <div  className="ion-padding" >     
       <IonButton  
            onClick={()=>{printReport()}}
            mode="ios" fill="clear" 
            style={{position:"relative", top:"1rem", right:"1rem"}} 
        >
            <IonLabel  style={{fontSize:".8em"}}>Print</IonLabel>  &nbsp;
            <IonIcon icon={printOutline}  /> 
        </IonButton>
       <IonRow className="ion-margin-bottom" >
            <IonCol size="12" className="ion-text-center ion-padding-left" >
                <IonTitle className="ion-margin-bottom ion-no-padding">{details.reportName}</IonTitle> 
  
                <IonLabel style={{fontSize:'.85em'}}>
                    <IonText className="ion-margin-end" >วันที่</IonText> 
                    <IonText>{moment(details.start).format("HH:mm  DD MMMM YYYY")}</IonText> -
                    <IonText>{moment(details.end).format("HH:mm  DD MMMM YYYY")}</IonText>
                </IonLabel>
            </IonCol>
            <IonCol size="12" className="set-center" >
                <div id={"bar"} >   
                </div>
                {/* <Chart  id={"bar"}
                  ref={chartRef}
                  options={options}
                  series={chart?.series}
                  type="bar"
                  width={"800"}
                /> */}
                {/* {chart &&  <BarChart
                  dataset={dataset}
                  xAxis={[
                    { 
                      data: chart?.categories.map((cat:any)=> {  
                          return  cat
                      }),
                      scaleType: 'band',
                    }, 
                    { scaleType: 'band', dataKey: 'month', tickPlacement: 'middle', tickLabelPlacement:"middle" },
                  ]} 
                  series={chart?.series.map((ser:any)=> { return  { ...ser,...{label: ser.name}}   }) }
                 
                  width={800}
                  grid={{ vertical: true }}
                  height={300}
                /> } */}
            </IonCol>
       </IonRow>
    </div>
    )
}

export default  RepAverageFuelDistance;