import { useIonRouter } from "@ionic/react";
import { t } from "i18next";
import { useEffect } from "react"
import { useHistory } from "react-router"
import { setFavicon } from "../actions";

const SEOTitle=({children}:any)=>{
    const history = useHistory()
    const router = useIonRouter();
    const cc:any = localStorage.getItem("theme") || JSON.stringify({name:" GPS Tracking "})
    const addname =   " | "+ JSON.parse(cc)?.name 
    const pageTitles: { [key: string]: string } = {
          "/signin":t("signin_title"),
          "/home": "ติดตามรถเรียลไทม์ ", 
          "/settings":"ตั้งค่า",
          "/dashboard":"แดชบอร์ด",
          "/routehistory":"เส้นทางย้อนหลัง",
          "/geofences":"เขตพื้นที่",
          "/manual":"คู่มือ",
          "/reports":"รายงาน",
          "/admin":"แอดมิน",
          "/notification":"การแจ้งเตือน",
          "/notificationSetting":"ตั้งค่าการแจ้งเตือน",
          "/maintenance":"ซ่อมบำรุง",
          "/suggestRoute":"แนะนำเส้นทาง",
          "/suggestRoute/add":"เพิ่มการแนะนำเส้นทาง",
          "/reports/repTravelSum":"รายงานสรุปการเดินทาง",
          "/mdvr/playback":"เครื่องเล่น MDVR",
          "/mdvr/realtime":"MDVR เรียลไทม์",
          "/longdotest":"",
          "/employees":"พนักงาน",
          "/groupdevice":"จัดกลุ่มอุปกรณ์",
          "/guests":"ผู้รับเชิญ",
          "/setRoute":"กำหนดเส้นทาง",
          "/groupmonitor":"มอนิเตอร์แบบกลุ่ม",
          "/grouproutehistory":"เส้นทางย้อนหลังแบบกลุ่ม", 
          "/behaviors":"พฤติกรรมการขับขี่"
        };

      

    useEffect(()=>{ 
        setFavicon(null)
        document.title = pageTitles[router.routeInfo.pathname]+addname || JSON.parse(cc)?.name;
    },[router.routeInfo.pathname])
    return(
        children
    )
}
export default SEOTitle