import { IonAccordion, IonAccordionGroup, IonButton, IonCol, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonLoading, IonPage, IonRow, IonText, IonTitle } from "@ionic/react"
import NavBar from "../components/NavBar";
import { DropDownDeviceMultiple } from "../components/AppComponent";
import { useEffect, useState } from "react";
import { allDevices, getBehaviors, reportBehaviorDevice } from "../actions";
import { setLoading, setDevices, setBackupDevices } from "../store/appSlice";
import { useDispatch } from "react-redux";
import { DateEndReport, DateRangPopover, DateStartReport } from "../components/ReportComponent/ReportComponent";
import * as Moment from "moment";
import { closeCircle, informationCircleOutline, search } from "ionicons/icons";
import { extendMoment } from "moment-range";
import axios from "axios";
import { t } from "i18next";
import FileSaver from "file-saver";
import * as Excel from "exceljs";
//import 'sheetjs-style' 
import "./css/Behaviors.css"
import { borderCells } from "../lib/Excels";
const moment = extendMoment(Moment);

moment.locale("th")

interface THeaders {
  "no": Number
  "label":  String
  "width": String |any
}
interface TRows{
  "vehicle":  string
  "cols":  any[]
}
interface DeviceBehavior{
  "vehicle": String
  "behavior": Behaviors[]
}
interface Behaviors{
  "id": String
  "deviceId": Number
  "code":  String
  "codeName": String
  "deviceBrand": String
  "eventTime": Number
  "eventTimeStr": String
  "lat": Number
  "lon": Number
  "address": String
  "driverId":String
  "driverName":String
  "speed": Number
}

const Behaviors=()=>{
    const [vehicleCheck ,setVehicleCheck] = useState<any[]>([])
    const [options,setOptions] = useState([])
    const dispatch = useDispatch()
    const [datestart,setDateStart] = useState(moment().format("YYYY-MM-DDT00:00").toString())
    const [dateend,setDateEnd] = useState(moment().format("YYYY-MM-DDT23:59").toString())
    const [openDate,setOpenDate] = useState(false)  
    const [loading , setLoading] = useState(false)

    const [theaders,setTheaders] = useState<THeaders[]>([])
    const [trows,setTRows] = useState<TRows[]>([])
    const [behavios,setBehavior] = useState<DeviceBehavior[]>([])
    const [dbehavior ,setDBehavior] = useState<DeviceBehavior | null>(null)
    const [overviewBehav ,setOverviewBehav] = useState<any[]  | null>(null)

    const [accordion,setAccordion] = useState<any>("")

    const checkedVehicleOption=(index:any , val:any)=>{
        console.log("updateopts ",val)
        let updateopts:any[] = vehicleCheck
        if(val.value === 0 && val.checked){  
          updateopts = vehicleCheck.map((e )=>{
            return {...e,...{checked:true}}
          })  
        }else if(val.value === 0 && !val.checked){  
          updateopts = vehicleCheck.map((e )=>{
            return {...e,...{checked:false}}
          })  
        } else{
          setVehicleCheck(updateopts)
          updateopts.splice(index , 0)
          updateopts[index] = val 
        }
        setVehicleCheck(updateopts)
      }
      

    useEffect(()=>{   
        const getdevices=async()=>{
          setLoading(true)
          let result = await allDevices({}) 
          setLoading(false)
          await dispatch(setDevices(result))
          await dispatch(setBackupDevices(result)) 
          let options = result.map((e:any)=> {return {value: e.device_id ,label: e.name, ...e } })
          setOptions(options)   
          let optionscheck:any[] = result.map((e:any)=> {return {checked:false, value: e.device_id ,label: e.name, ...e } })
          optionscheck.unshift({checked:false, device_id:0 ,value: 0,label:"ทั้งหมด"})
          
          setVehicleCheck(optionscheck) 
       } 
       getdevices()  
    },[ ]) 

    const searchBehavior=async ()=>{
      setDBehavior(null)
      setLoading(true)
        console.log("vehicleCheck ",vehicleCheck)
        let start = new Date(datestart).getTime()
        let end = new Date(dateend).getTime()
        let devices = vehicleCheck?.filter((e)=> e.value!=0)?.map((val:any)=>{ return val.value })

       const rangestart = new Date(datestart);
       const rangeend   = new Date(dateend); 
       const range1 = moment.range(rangestart, rangeend); 
       const range = range1.diff('days')  
       let daterange = []
       
        let datebegin =moment(datestart).format() 
        for(let i=0; i<=range;i++){
           daterange.push({ 
                date:  moment(datebegin).format('DD/MM/YYYY') ,
                start: new Date(moment(datebegin).format("YYYY-MM-DD 00:00:00")).getTime(),
                end: new Date(moment(datebegin).format("YYYY-MM-DD 23:59:59")).getTime(),
            }) 
            datebegin = moment(datebegin).add(1,'day').format()
        } 
        
        let body = {
            start:start ,
            end:end,
            daterange:daterange ,
            devices: devices
        } 
        console.log("body ",body)
        let reportData= await getBehaviors(body)
        if(reportData){
          setTheaders(reportData?.theaders )
          setTRows(reportData?.trows )
          setBehavior(reportData?.behaviors )
        }
        
        console.log("reportData ",reportData)
        setLoading(false)
    }


    function myCallback({ code }:any) {
      return code=== "91" ? "Sharp Turn to Right" :  code=== "92" ? "Sharp Turn to Left" :code=== "129" ? "Harsh Braking" :code=== "130" ? "Harsh Acceleration" :"Idle Overtime"
    }
    const findBehavior=(b:TRows)=>{
      console.log("b ",b)
      const behav:any = behavios.find((e)=> e.vehicle.indexOf(b?.vehicle) > -1) 
      console.log("behav ",behav)
      if(behav){
        setDBehavior(behav)
        // @ts-ignore
        const result:any = Object.groupBy(behav?.behavior, myCallback);  
        const res:any =   Object.values(result);  
        console.log("res ",res)
        if(res.length > 0){  
          setAccordion(res[0][0].code)
        }
        setOverviewBehav(res)
      }
    }

    const printExcel=async ()=>{
      try{   
        var options = {
            filename: './streamed-workbook.xlsx',
            useStyles: true,
            useSharedStrings: true
        }; //@ts-ignore 
        let workbook = new Excel.Workbook(options);
        var ws = workbook.addWorksheet( "พฤติกรรมการขับขี่" , {properties:{tabColor:{theme:1}}});   
        ws.columns =[{width:25},{width: 25},{width: 25},{width: 25},{width: 25},{width: 25},{width: 25}]
        let header:any = []
        theaders.map((e)=>{
          header = [...header , e.label]
        })

        ws.mergeCells('A1', 'J1'); 
        ws.getCell("A1").value = "พฤติกรรมการขับขี่"
        ws.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center'  }; 
        ws.getCell("A1").font = { bold: true, size: 16 }; 
        ws.mergeCells('A2', 'J2'); 
        ws.getCell("A2").alignment = { vertical: 'middle', horizontal: 'center'  }; 
        ws.getCell("A2").value =  `วันที่ ${moment( datestart).format("DD MMMM   HH:mm")} - ${moment( dateend).format(" DD MMMM  HH:mm")}`
        ws.addRow(['สรุปภาพรวม'])
        {overviewBehav?.map((e)=>
          ws.addRow([e[0]?.codeName , e.length+" รายการ"]) 
        )}
        ws.addRow([''])

        ws.addRow(header)
        const rowheader = ws.getRow(9); 
            rowheader.eachCell({includeEmpty: true}, ((cell:any) => {
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }; 
                cell.font = { bold: true ,  family: 4, size: 12, underline: false, }
                cell.border = borderCells
            }));  

        await trows.map(async(row:TRows,index:any)=> {
          let rows = [row?.vehicle]
          await row?.cols.map((c)=>{
            rows = [...rows , c]
          })
          ws.addRow(rows)
        })

        await behavios.map((d)=>{
          let sheetname = d?.vehicle.replaceAll("*", '')
          console.log("sheetname ",sheetname)
          var sheet = workbook.addWorksheet( sheetname ); 
          sheet.columns =[{width:5},{width: 20},{width: 40},{width: 25}]
          sheet.state = 'visible';
          sheet.addRow([ sheetname ])
          sheet.addRow([ "" ])
          sheet.addRow([ "order" ,"Behavior" ,"Address" , "Time" ])
          d?.behavior.map((e,index)=>{
            sheet.addRow([index+1 , e?.codeName , e?.address , moment(e?.eventTime).format("DD MMM YYYY HH:mm")  ])
          })
        })

        let fileName=`พฤติกรรมการขับขี่-${moment(datestart).format("DD MMMM   HH:mm")} - ${moment(dateend).format(" DD MMMM  HH:mm")}.xlsx`;  
            workbook.xlsx.writeBuffer() 
            .then(function(buffer:any) { 
                const data: Blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                FileSaver.saveAs(data, fileName);
            }); 
        

      }catch(err){
        console.log("err ",err)
      }
    }
      
    return(
    <IonPage>
       <IonLoading isOpen={loading}  message={t("loading").toString()} onIonLoadingDidDismiss={()=>{setLoading(false)}}  />
      
        <IonContent className="ion-padding" >
            <IonRow>
                <IonCol size="12" >  
                        <IonTitle className="ion-no-padding" >พฤติกรรมการขับขี่</IonTitle> <br/>
                        <div className="set-center-row" style={{justifyContent:"flex-start"}} >
                          <DropDownDeviceMultiple 
                              vehicles={vehicleCheck} 
                              setVehicle={(index:any, val:any)=>{ checkedVehicleOption(index , val)  }}  
                              optionsbackup={options}  
                          /> 

                          <div className="set-center-row" style={{maxWidth:"30rem"}}> 
                              <DateStartReport date={datestart} openDate={setOpenDate} bg={true} showtime={true}/> 
                              <IonButton size='small' fill='clear' color={"dark"}>ถึง</IonButton>  
                              <DateEndReport date={dateend} openDate={setOpenDate} bg={true}   showtime={true} /> 
                          </div>
                          <IonButton mode="ios" color={"dark"} fill="clear" onClick={()=>{searchBehavior()}} > 
                            <IonLabel>ค้นหา</IonLabel>
                          </IonButton>
                        </div>
                </IonCol>
                <IonCol  > 
                  <IonRow>
                     <IonCol   > 
                        <IonRow >
                         {theaders.length > 0 && 
                         <IonCol size="12" className="set-center-row" style={{justifyContent: 'flex-start',}}> 
                              <IonButton 
                                size="small" color={"danger"} fill="clear" 
                                onClick={()=>{
                                  setTheaders([])
                                  setTRows([])
                                  setBehavior([])
                                  setDBehavior(null)}
                                }
                              > 
                                 <IonIcon icon={closeCircle} />   &nbsp; <IonLabel>Close</IonLabel>
                              </IonButton>
                             <IonButton onClick={()=>{printExcel()}}  
                                mode="ios" fill="clear"     >
                                  <img src="../assets/icon/excel.svg" style={{width:"1.7rem"}} />&nbsp;
                                  <IonLabel style={{color:"#12753D", fontSize:".8em"}}> Excel </IonLabel> 
                              </IonButton> 
                            </IonCol> 
                          } 
                            <IonCol size="12" >  
                            {behavios.length > 0 && <div  className="table" > 
                              <table >
                                <tr  className=" bg-light-shade">
                                  {
                                    theaders.map((head:THeaders,index:any)=> 
                                      <th style={{width: head?.width}} key={index} className="ion-text-center " >
                                        {head?.label}
                                      </th>
                                    )
                                  }
                                  <th style={{width: "5%"}}  className="ion-text-center "> # </th>
                                </tr> 
                                  {
                                    trows.map((row:TRows,index:any)=>
                                    <tr  key={index} 
                                      className={` ${index%2==0 ? "bg-light":"bg-light-tint"} `}   
                                    >
                                      <td > {row?.vehicle} </td>
                                      {
                                        row?.cols.map((c,i) => <td key={i} className="ion-text-center" > {c}  </td>)
                                      } 
                                      <td>
                                        <IonButton  size="small" fill="clear" mode="ios" onClick={()=>{
                                        findBehavior(row)
                                      }} > <IonIcon icon={informationCircleOutline} /></IonButton>
                                      </td>
                                    </tr>
                                    )
                                  } 
                                </table>
                              </div>}
                            </IonCol>
                        </IonRow>
                    
                     </IonCol>
                     {dbehavior && <IonCol size="3" >
                      <IonListHeader > 
                        <IonButton size="small" fill="clear" color={"danger"} onClick={()=>{setDBehavior(null)}} > <IonIcon icon={closeCircle} /> </IonButton>
                        <h2 className="ion-no-margin" >{dbehavior?.vehicle}</h2> 
                      </IonListHeader>
                       {/* {overviewBehav?.map((e)=>
                          <IonLabel>{e[0]?.codeName} ({e.length}) &nbsp;&nbsp;&nbsp;&nbsp;</IonLabel> 
                        )} */}
                      <div className="devider" ></div>
                      <div     style={{height:"65vh",overflow:"scroll" }}>
                      <IonAccordionGroup expand="inset" value={accordion} onIonChange={(e)=>{setAccordion(e.detail.value)}}>
                        {overviewBehav?.map((bahav)=> <IonAccordion value={bahav[0]?.code}>
                          <IonItem slot="header" color="light">
                            <IonLabel>{bahav[0]?.codeName} ({bahav.length})</IonLabel>
                          </IonItem>
                          <div className="ion-padding" slot="content"  style={{height:"50vh",overflow:"scroll" }}>
                            {bahav.map((e:any,index:any)=> 
                            <IonItem key={index}  >
                                <IonLabel class="ion-text-wrap"> 
                                  <IonText>{index+1}) {e?.codeName} <sub>{  moment(e?.eventTime).format("DD MMM YYYY HH:mm")  }</sub></IonText> <br/>
                                  <small>{e?.address}</small>
                                </IonLabel> <br/> 
                            </IonItem> 
                            )} 
                          </div>
                        </IonAccordion>)}
                      </IonAccordionGroup>
                      </div>

                      {/* <IonList style={{height:"65vh",overflow:"scroll" }} > 
                        
                        { dbehavior?.behavior.map((e:any,index:any)=> 
                          <IonItem key={index} >
                              <IonLabel class="ion-text-wrap"> 
                                <IonText>{index+1}) {e?.codeName} <sub>{  moment(e?.eventTime).format("DD MMM YYYY HH:mm")  }</sub></IonText> <br/>
                                <small>{e?.address}</small>
                              </IonLabel> <br/>
                             
                          </IonItem> 
                        ) }
                      </IonList>*/}
                     </IonCol> } 
                  </IonRow>
                
                   
              
                </IonCol>
            </IonRow>
          <DateRangPopover start={datestart} setStart={(e:any)=>{setDateStart(e) }} end={dateend} setEnd={setDateEnd} openDate={setOpenDate} isOpen={openDate} daily={false}  />
      </IonContent>
    </IonPage>
    )
}
export default Behaviors;