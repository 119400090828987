import { IonButton, IonCol, IonContent, IonIcon, IonLabel, IonRow, IonText, IonTitle } from "@ionic/react"
import { printOutline } from "ionicons/icons";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { configPDF, pdfMake } from "../../../lib/pdfMake" 
import ReactApexChart from "react-apexcharts";

const RepFuelSpeed=({chart,details}:any)=>{
    const [chartImg,setChartImg] = useState("") 
    const [chartref , setChartRef] = useState<ApexCharts|null>(null)
    const chartRef:any = useRef()
    var apexchrt :any = null ;

    let options:any = {
        series: [] , 
        // title: {
        //     text: 'ปริมาณน้ำมัน(เปอร์เซ็น/ลิตร)',
        //     align: 'left'
        // },
        chart: 	{ id: "line-fuel"	, width: 700 , height: 350 ,  type: 'area' , fontFamily: 'Inter , IBM Plex Sans Thai , sans-serif'}, 
        fill: 	{ type:'solid' , pattern: {
            strokeWidth: 0.5
          }	}, 
          borderColor: "#1d92d1",
        stroke: { curve: 'smooth'	, width: 1.5 }, 
        markers:{ size: [2 ,2,2,2,1] , strokeWidth: .3 , },
        tooltip:{ shared: false,  },
        colors: ['#fce303','#fc1803','#278000','#134985'],
        dataLabels: {  enabled: false,} ,
        xaxis: { type: 'datetime', categories: [] ,
            labels: {  formatter: function (value:any) {  
               return  value ? moment(value).format("HH:mm DD/MM") : "-"
            } },
        }, 
        yaxis: { 
            title: { text: '-'  },  
            labels: {
                formatter: function (value:any) {
                return value? value.toFixed(0): 0 ;
                }
            },
            max: 120 
  
        }, 
      };

      useEffect(()=>{
        const drawchart=async()=>{
            console.log("RepFuelSpeed chart ",chart)
            var element= document.querySelector("#line-fuel")  
            options.xaxis.categories = chart?.xCategories
            let idleColor = '#FAD434'
            let moveColor = '#278000'
            let allColor = '#9DD0D7'
            let stopColor = '#F30106'
            options.colors=[allColor,stopColor,moveColor,idleColor] 

            let series:any = []
                series[0]= chart.seriesAll
                series[0].color = allColor

                series[1]= chart.seriesStop
                series[1].color = stopColor

                series[2]= chart.seriesMove 
                series[2].color = moveColor

                series[3]= chart.seriesIdle
                series[3].color = idleColor
 
                series[4]= chart.seriesSpeedAll
                series[4].color = "#FD5F06"

            options.series = series 

            console.log("options ",options)
            try{
                if(!element?.innerHTML){ 
                  apexchrt =await new ApexCharts(element, options).render()
                 console.log("apexchrt ",apexchrt)
                 setChartRef(apexchrt)  
                 
                } else{     
                    ApexCharts.exec("line-fuel" , "updateOptions", options ).then((e:any)=>{
                        e.dataURI().then(({ imgURI, blob }:any) => {  
                            setChartImg(imgURI)
                         })  
                    })   
                }
            }catch(err){

            }
        }
        drawchart() 
      },[chartref])

    const printReport=async()=>{    
        pdfMake.createPdf({ 
            watermark: { text: 'Andaman Tracking', color: '#FD5F06', opacity: 0.1, bold: true, italics: false },
            images:{ chart: chartImg  },
            content:[  
                {text: details?.reportName , fontSize: 16 , bold:true  , alignment:'center', marginBottom: 15},
                {text:`วันที่ ${moment(details?.start).format("DD MMMM   HH:mm")} - ${moment(details?.end).format(" DD MMMM  HH:mm")} `, alignment:'center'},
                {text:`ทะเบียนรถ  ${details?.name} `, alignment:'center'}, 
                { image:"chart" ,width: 500}
            ],
            defaultStyle: {
                font: "THSarabunNew",
            },
            header: function (currentPage, pageCount) {
                return {
                    columns: [
                        { 
                            text: details?.name ,
                            fontSize: 10 ,
                            marginLeft: 16,
                            margin: [20, 20, 50, 20] 
                        } ,
                        { text: "Page " + currentPage.toString() + ' of ' + pageCount, alignment: 'right', style: 'normalText', margin: [0, 20, 50, 0] }
                    ]
                }
        },
            footer: {
                columns: [
                    { 
                        text:"บริษัท อันดามันแทร็คกิ้ง จำกัด | Andaman Tracking .Co.Ltd  @Generate on  tracking.andamantracking.com" ,
                        fontSize: 9 ,
                        marginLeft: 15
                    } 
                ]
            },
        }).open()
    }
 
    const updateChrtImg=async(chart:any)=>{
        console.log(" chartRef ",chartRef)
        
    }

    const updateChartSeries=  ()=>{
        let series:any = []
        series[0]= chart.seriesAll
        series[1]= chart.seriesStop
        series[2]= chart.seriesMove 
        series[3]= chart.seriesIdle
    options.series = series

       if(chart?.xCategories){ 
        let apexupdate = ApexCharts.exec("line-fuel", "updateOptions",{xaxis:{categories: chart?.xCategories }}); 
        updateChrtImg(apexupdate) 
      }
      return series 
    }

    return(
    <div  className="ion-padding" >    
        <IonButton  
           onClick={()=>{printReport()}}
           mode="ios" fill="clear" 
           style={{position:"relative", top:"1rem", right:"1rem"}} 
       >
           <IonLabel  style={{fontSize:".8em"}}>Print</IonLabel>  &nbsp;
           <IonIcon icon={printOutline}  /> 
       </IonButton>
       <IonRow className="ion-margin-bottom" >
            <IonCol size="12" className="ion-text-center ion-padding-left" >
                <IonTitle className="ion-margin-bottom ion-no-padding">{details.reportName}</IonTitle> 
 
                <IonLabel style={{fontSize:'.85em'}}>
                    <IonText className="ion-margin-end" >วันที่</IonText> 
                    <IonText>{moment(details.start).format("DD MMMM   HH:mm")}</IonText> -
                    <IonText>{moment(details.end).format(" DD MMMM  HH:mm")}</IonText>
                </IonLabel><br/>
                <IonLabel style={{fontSize:'.9em'}}>
                    <IonText>ทะเบียนรถ​ : {details?.name} </IonText>
                </IonLabel>
            </IonCol>
            <IonCol size="12" className="set-center" >
                <div id={"line-fuel"} >  
                  {/* <ReactApexChart ref={chartRef}  options={options} series={updateChartSeries()} type='line' height={350} width={700} /> */}
                </div>
            </IonCol>
       </IonRow>
   </div>
    )
}

export default RepFuelSpeed;